export default {
  get: () => {
    return [
      // {
      //   name: 'Aqua Suite Home',
      //   routeName: 'landingpage',
      //   icon: 'tmlogo',
      // },
      {
        name: 'Dashboard',
        routeName: 'dashboard',
        icon: 'metrics',
        menuItems: [
          // {
          //   name: 'Leaderboards',
          //   routeName: 'leaderboards',
          //   hideOnMobile: true,
          // },
          {
            name: 'Dashboard',
            routeName: 'dashboard',
          },
          {
            name: 'Session Analysis',
            routeName: 'session_analysis',
            hideOnMobile: true,
            query: { fromMenu: true },
          },
        
        ],
      },
      {
        name: 'Activity',
        icon: 'activity',
        routeName: 'session_list',
        menuItems: [
          {
            name: 'Sessions List',
            routeName: 'session_list',
          },
          {
            name: 'Whiteboard',
            routeName: 'whiteboard',
            hideOnMobile: true,
          },
        ],
      },
      {
        name: 'Emergency & Urgent Care',
        icon: 'emergencyIcon',
        routeName: 'session_emergency_list',
        hideOnMobile: true,
      },
      {
        name: 'Summary',
        icon: 'session',
        routeName: 'session_summary',
        hideOnMobile: true,
        menuItems: [
          {
            name: 'Session Summary',
            routeName: 'session_summary',
          },
          {
            name: 'Session Planning',
            routeName: 'session_planning',
          },
          {
            name: 'Sessions by Identifier',
            routeName: 'session_by_identifier',
            hideOnMobile: true,
          },
          {
            name: 'Sessions by Theatre',
            routeName: 'session_by_theatre',
            hideOnMobile: true,
          },
          {
            name: 'Sessions by Week',
            routeName: 'session_week',
          },
        ],
      },
      {
        name: 'Session Scheduler',
        icon: 'scheduler',
        routeName: 'session_scheduler',
        query: { fromMenu: true },
        hideOnMobile: true,
        menuItems: [
          {
            name: 'Session Scheduler',
            routeName: 'session_scheduler',
            query: { fromMenu: true },
          },   
          {
            name: 'Scheduler Sessions',
            routeName: 'session_scheduler_list',
            query: { fromMenu: true },
          }
        ],
      },
      {
        name: 'Patients',
        icon: 'patient',
        routeName: 'patient_search',
        hideOnMobile: true,
        menuItems: [
          // {
          //   name: "Lists",
          //   routeName: "/listselection",
          // },
          {
            name: 'Patient Search',
            routeName: 'patient_search',
          },
          {
            name: 'Patient Planning',
            routeName: 'waiting_list',
          },
        ],
      },
      {
        name: 'Live Patients',
        icon: 'livepatients',
        routeName: 'live_patients',
        hideOnMobile: true,
      },
      // {
      //   name: 'PreAssessments',
      //   icon: 'preassessment',
      //   routeName: '/patientpreassessment/search',
      //   hideOnMobile: true,
      // },
      {
        name: 'Live Locations',
        routeName: 'live_locations',
        icon: 'patientlocation',
        hideOnMobile: true,
      },
      {
        name: 'Working Lists',
        routeName: 'working_lists',
        icon: 'smartlists',
        hideOnMobile: true,
      },
      {
        name: 'Resources',
        routeName: 'resources',
        icon: 'resources',
        hideOnMobile: true,
        //menuItems: [
        //  {
        //    name: 'Filter by time',
        //    routeName: '/resources/time',
        //    icon: 'filter',
        //  },
        //],
      },
      {
        name: 'Reports',
        routeName: 'report_list',
        icon: 'reports',
        hideOnMobile: true,
        menuItems: [
          // {
          //   name: 'Report Subscriptions',
          //   routeName: '/report/subscriptions',
          //   icon: 'printer',
          // },
        ],
      },
      {
        name: 'Administration',
        routeName: 'admin_home',
        icon: 'cog',
        hideOnMobile: true,
      },
    ];
  },
};
