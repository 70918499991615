const ResourceListSelection = () => import('/src/components/resources/ResourceListSelection');
// const ResourceListDetail = () => import('/src/components/resources/ResourceListDetail');
// const ResourcesPlannedListSelection = () => import('/src/components/resources/ResourcesPlannedListSelection');

export default [
  {
    path: '/resources',
    name: 'resources',
    component: ResourceListSelection,
    meta: {
      title: 'Resources',
      tags: ['Landing Page'],
      functionCode: 'ACCESSRESOURCES',
      hideBackButton: true,
      isLandingRoute: true,
    },
  },
  // {
  //   path: 'detail',
  //   name: 'resourcelistdetail',
  //   props: true,
  //   component: ResourceListDetail,
  //   meta: {
  //     title: 'Resource List Detail',
  //     functionCode: 'ACCESSRESOURCES',
  //     backRoutes: ['resources'],
  //   },
  // },
  // {
  //   path: 'planned',
  //   name: 'resourcesplannedlistselection',
  //   component: ResourcesPlannedListSelection,
  //   meta: {
  //     title: 'Planned Resources',
  //     functionCode: 'ACCESSRESOURCES',
  //     hideBackButton: true,
  //   },
  // },
];
