export const Validate = {
  nhsNumber: function (nhsNumber, isCHINumber) {
    /**
     * Multiply a value by its position, using the NHS number strategy
     * @param {Number} digit the single-digit portion of the number
     * @param {Number} index The 0-indexed position of `digit` within the NHS number
     * @returns {Number} the result of the 'multiply by (11-position)' calculation
     **/
    function multiplyByPosition(digit, index) {
      // multiple each digit by 11  minus its position (indexed from 1)
      return digit * (11 - (index + 1));
    }

    /**
     * Add two values together. Useful for use in `reduce` calls
     * @param {Number} previousValue the initial value
     * @param {Number} currentValue the value to add to the initial value
     * @returns {Number} the sum of the two parameters
     **/
    function addTogether(previousValue, currentValue) {
      return previousValue + currentValue;
    }

    if (nhsNumber) {
      // string replace white space
      nhsNumber = nhsNumber.replace(/\s/g, '');

      // The NHS NUMBER, the primary identifier of a PERSON, is a unique identifier for a  PATIENT  within the NHS in England and Wales.
      // The check digit is validated using the Modulus 11 algorithm and the use of this algorithm is mandatory. There are 5 steps in the validation of the check digit:
      // https://datadictionary.nhs.uk/attributes/nhs_number.html

      // The Community Health Index (CHI) is a population register, which is used for patients resident in Scotland for health care purposes.
      // The CHI number is a unique numeric identifier, allocated to each patient on first registration with the Service The CHI number is a 10-character code
      // consisting of the 6 - digit patient date of birth(format: ddmmyy), two digits, a 9th digit which is always even for females and odd for males
      // and an arithmetical check digit.
      // http://www.datadictionary.wales.nhs.uk/index.html#!WordDocuments/communityhealthindexchinumber.htm

      /**
       * Validate an NHS number
       * @param {Number,  String} nhsNumber The NHS number to validate. This may be a String or a number.
       * @returns {Boolean} `true` IFF the NHS number validates, else `false`
       **/

      // pre-flight checks
      if (nhsNumber === undefined || nhsNumber === null || isNaN(Number(nhsNumber)) || nhsNumber.toString().length !== 10) {
        return false;
      }

      // convert numbers to strings, for internal consistency
      if (Number.isInteger(nhsNumber)) {
        nhsNumber = nhsNumber.toString();
      }

      if (isCHINumber) {
        let d = nhsNumber.substring(0, 2),
          m = nhsNumber.substring(2, 4),
          y = nhsNumber.substring(4, 6);
        d = Number(d);
        m = Number(m);
        var daysInMonth = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        if (m < 1 || m > 12 || d < 1 || d > daysInMonth[--m]) {
          return false;
        }
      }

      // Step 1: Multiply each of the first 9 numbers by (11 - position indexed from 1)
      // Step 2: Add the results together
      // Step 3: Divide the total by 11 to get the remainder
      let nhsNumberAsArray = nhsNumber.split('');
      let remainder = nhsNumberAsArray.slice(0, 9).map(multiplyByPosition).reduce(addTogether, 0) % 11;

      let checkDigit = 11 - remainder;

      // replace 11 for 0
      if (checkDigit === 11) {
        checkDigit = 0;
      }

      let providedCheckDigit = nhsNumberAsArray[9];

      // Do the check digits match?
      return checkDigit === Number(providedCheckDigit);
    }
  },
  chiNumber: function (chiNumber) {
    if (chiNumber) {
      // string replace white space
      chiNumber = chiNumber.replace(/\s/g, '');

      // The Community Health Index (CHI) is a population register, which is used for patients resident in Scotland for health care purposes.
      // The CHI number is a unique numeric identifier, allocated to each patient on first registration with the Service The CHI number is a 10-character code
      // consisting of the 6 - digit patient date of birth(format: ddmmyy), two digits, a 9th digit which is always even for females and odd for males
      // and an arithmetical check digit.
      // http://www.datadictionary.wales.nhs.uk/index.html#!WordDocuments/communityhealthindexchinumber.htm

      if (chiNumber === undefined || chiNumber === null || isNaN(Number(chiNumber)) || chiNumber.toString().length !== 10) {
        return false;
      }

      if (Number.isInteger(chiNumber)) {
        chiNumber = chiNumber.toString();
      }
      let d = chiNumber.substring(0, 2),
        m = chiNumber.substring(3, 2);
      d = Number(d);
      m = Number(m);
      var daysInMonth = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      if (m < 1 || m > 12 || d < 1 || d > daysInMonth[--m]) {
        return false;
      }
    }
  },
};
