import axios from 'axios';
import { store } from '/src/store';
import { router } from '/src/router';
import { AccountService } from '/src/services/account';
import { JwtFunctions } from '/src/mixins/JwtFunctions';

const API_URL = process.env.VUE_APP_APIURL;

const debugLog = (...args) => router.app.$options.methods.debugLog(...args);

const app_computed = () => router.app.$options.computed;

const exposedEndpoints = [
  'account/policy',
  'account/badge/positions',
  'account/validate/username',
  'account/signin',
  'account/signin/pin',
  'account/from_personnel_id',
  'account/register',
  'account/refresh',
  'account/Forgot/Password',
  'account/Forgot/Pin',
  'User/check/username',
  'User/check/badgeid',
  'AQUAProduct',
  'property/global/external',
  'error/create',
  'Scan/external',
  'AllowedDevice/valid',
  'AuthenticationLevel',
  'User/register/update',
  'Email/send',
  'version',
];

const ignoredAPICallCountEndpoints = [
  API_URL + 'account/policy',
  API_URL + 'account/refresh',
  API_URL + 'account/keep-alive',
  API_URL + 'account/badge/positions',
  API_URL + 'function/lock/manage',
  API_URL + 'session/actual/schema',
  API_URL + 'session/schema',
  API_URL + 'session/planned/signoff',
  API_URL + 'form/active/data/bulk/update',
  API_URL + 'error/create',
  API_URL + 'version',
];

const windowUID = () => {
  return router.$browserStorage.aquaWindowUID;
};

const isEndpointExposed = (config) => {
  return exposedEndpoints.some((x) => config && config.url.toLowerCase().endsWith(x.toLowerCase()));
};

const isEndpointCallCountIncluded = (config) => {
  return (
    config &&
    ((config.data && ((typeof config.data === 'string' && config.data.includes('"byPassIgnoredAPICallCount":true')) || config.data.byPassIgnoredAPICallCount)) ||
      !ignoredAPICallCountEndpoints.find((entry) => entry.toLowerCase() == config.url.toLowerCase()))
  );
};

const incrementAPICallCount = (config) => {
  if (isEndpointCallCountIncluded(config)) {
    store.dispatch('incrementAPICallCount', config.url.toLowerCase());
  }
};
const decrementAPICallCount = (config) => {
  store.dispatch('decrementAPICallCount', config.url.toLowerCase());
};

const handleAuth = (config, user, sessionLocked) => {
  if (!user && config?.url.toLowerCase().endsWith('account/signin') && config.data.grant_type == 'external' && config.data.provider == 'windows') {
    // Logging in using windows AD authentication
    delete config.headers.common['Authorization'];
    config.withCredentials = true;
  } else {
    config.withCredentials = false;

    // DO NOT set the Authorization Bearer token on exposed endpoints!!
    // Doing so will cause the API to report back with a false "Session has expired" error.

    if (!isEndpointExposed(config) && !JwtFunctions.hasAccessTokenExpired(user)) {
      config.headers['Authorization'] = 'Bearer ' + user.accessToken;
    } else if (isEndpointExposed(config)) {
      config.headers['Authorization'] = null;
    } else if (!sessionLocked) {
      throw new axios.Cancel('Operation canceled due to invalid access token. {' + config.url + '}');
    }
  }
};

const shouldProcessRequest = () => {
  return Boolean((!router.$browserStorage.isLoggingIn && !router.$browserStorage.isLoggingOut) || router.$browserStorage.isLoggingIn == windowUID() || router.$browserStorage.isLoggingOut == windowUID());
};

const processRequest = (config, user) => {
  if (!config.url.startsWith(API_URL)) {
    config.url = API_URL + config.url;
  }

  incrementAPICallCount(config);

  // Attach the AbortController
  const abortIdentifier = { UID: windowUID(), endpoint: config.url };
  store.dispatch('addAbortController', abortIdentifier);

  config.data ??= {};
  config.data.signal = store.getters.getEndpointAbortSignal(abortIdentifier);

  handleAuth(config, user, router.$browserStorage.lockSession ?? false);

  return config;
};

axios.interceptors.request.use(
  (config) => {
    return new Promise((resolve) => {
      if (config && !isEndpointExposed(config) && !config.url.toLowerCase().endsWith('account/refresh')) {
        // A jwt token refresh is in progress, wait for it to finish by retrying the request every seconds, but only 10 times
        if (router.$browserStorage.isRefreshingToken && (!config.retryCount || config.retryCount <= 10)) {
          config.retryCount = (config.retryCount ?? 0) + 1;
          router.$AQUA_Timer.sleep().then(() => {
            debugLog(`AQUA: 📡 [AXIOS] request.use ♻️ retry: ( ${config.retryCount} ) -> ${config.url}`);

            axios(config);
          });
        } else {
          config.retryCount = null;
          router.$browserStorage.isRefreshingToken = null;
        }
      }

      if (config && (!config.retryCount || !router.$browserStorage.isRefreshingToken)) {
        if (shouldProcessRequest()) {
          const user = app_computed().currentUser.get();

          if (!isEndpointExposed(config)) {
            // Check for expired access token and refresh if required
            AccountService.refreshToken(user)
              .then((result) => {
                if (result) {
                  debugLog('AQUA: 📡 [AXIOS] request.use::refreshToken 🎉 Token refreshed');
                }
                resolve(processRequest(config, user));
              })
              .catch((error) => {
                debugLog(`AQUA: 📡 [AXIOS] request.use::refreshToken 💥 TOKEN REFRESH ERROR`, error);

                router.replace({ name: 'clear_cache' });
              });
          } else {
            resolve(processRequest(config, user));
          }
        } else {
          throw new axios.Cancel(`Operation canceled due to pending Login/Logout. {${config?.url ?? 'UNKOWN_URL'}}`);
        }
      }
    });
  },
  (error) => {
    // Detach the AbortController
    const config = error?.response?.config ?? error?.config;
    if (config) {
      store.dispatch('removeAbortController', { UID: windowUID(), endpoint: config.url });
    }

    return Promise.reject(error?.response ?? error);
  }
);

axios.interceptors.response.use(
  (response) => {
    const config = response.config;
    if (config) {
      decrementAPICallCount(config);

      // Detach the AbortController
      store.dispatch('removeAbortController', { UID: windowUID(), endpoint: config.url });
    }

    const user = router.$browserStorage.currentUser;

    if (response?.data?.messages?.length && user && !parseInt(user.HideBadges)) {
      response.data.messages.forEach((message) => {
        store.dispatch('addBadgeMessage', {
          status: 'success',
          icon: 'success',
          title: message.Message,
          description: message.Message,
        });
      });
    }

    return response;
  },
  (error) => {
    const config = error?.config;
    if (config) {
      decrementAPICallCount(config);

      // Detach the AbortController
      store.dispatch('removeAbortController', { UID: windowUID(), endpoint: config.url });
    }

    if (!error || router.$browserStorage.isLoggingOut == windowUID()) return null;

      debugLog(`AQUA: ♻️ axios.interceptors.request.use(error) -> ${getErrorDescription(error)}`);

    const isRecordLockedError = errorContainsString(error, 'record locked');
    const sessionHasExpired = errorContainsString(error, 'session has expired');
    const isInvalidToken = errorContainsString(error, 'invalid access') || errorContainsString(error, 'invalid refresh');

    if (isInvalidToken || sessionHasExpired) {
      router.$browserStorage.lockSession = true;
      AccountService.logoutExtended(router.$browserStorage.currentUser, false, true);

      router.replace({ name: 'locked' });
      return Promise.reject(null);
    }

    // If an error occurs, log it to the database unless,
    //  1. the error occurred in the creation of a DB Error entry
    //  2. the error is a Record Locked error (these are handled by the FunctionLock component)
    //  3. the error is a Session expiry
    //  4. the error is a Invalid JWT token
    if (!isRecordLockedError && !sessionHasExpired && !isInvalidToken && config && !config.url.toLowerCase().endsWith('error/create')) {
      // console.log({
      //   isRecordLockedError,
      //   sessionHasExpired,
      //   isInvalidToken,
      //   errorCreate: config.url.toLowerCase().endsWith('error/create'),
      // });

      store.dispatch('addAppMessage', {
        status: 'danger',
        icon: 'error',
        error: error.response ?? error,
      });
    }

    return Promise.reject({ message: store.getters.lastAppMessage, errorResponse: error?.response ?? error });
  }
);

const getErrorDescription = (error) => {
  return error?.response?.data?.description != null ? error.response.data.description : error?.message != null ? error.message : 'Unknown Error';
};

const errorContainsString = (error, criteria) => {
  return getErrorDescription(error).toLowerCase().includes(criteria.toLowerCase());
};
