const GenericRoute = () => import('/src/components/application/_GenericRoute');

const SessionSummary = () => import('/src/components/route/session/SessionSummary');
const SessionListing = () => import('/src/components/route/session/SessionListing');
const SessionAnalysis = () => import('/src/components/route/session/SessionAnalysis');
const SessionAnalysisList = () => import('/src/components/route/session/SessionAnalysisList');
const SessionScheduler = () => import('/src/components/route/session/SessionScheduler');
const SessionSchedulerList = () => import('/src/components/route/session/SessionSchedulerList');
const EmergencySessionListing = () => import('/src/components/route/session/EmergencySessionListing');
const SessionWeek = () => import('/src/components/route/session/SessionWeek');
const SessionView = () => import('/src/components/route/session/SessionLanding');

const SessionAction = () => import('/src/components/route/session/action/SessionAction');

const SessionByTheatre = () => import('/src/components/route/session/SessionByTheatre');
const SessionByIdentifier = () => import('/src/components/route/session/SessionByIdentifier');

const SessionPlanning = () => import('/src/components/route/session/SessionPlanning');
const SessionPlanMaintenance = () => import('/src/components/route/session/action/actions/SessionPlanMaintenance');
const SessionPlanningFilter = () => import('/src/components/route/session/views/SessionPlanningFilter');
const SessionPlanningWithDateFilter = () => import('/src/components/route/session/views/SessionPlanningWithDateFilter');

const SessionCreate = () => import('/src/components/route/session/SessionCreate');

const SessionFinalise = () => import('/src/components/route/session/views/SessionFinalise');
const SessionFinaliseView = () => import('/src/components/route/session/views/SessionFinaliseView');

const Booking = () => import('/src/components/booking/Booking');

const Whiteboard = () => import('/src/components/route/session/Whiteboard');
const WhiteboardDailyView = () => import('/src/components/route/session/WhiteboardDailyView');
const WhiteboardFilter = () => import('/src/components/route/session/views/WhiteboardFilter');

import { routeAssistant } from '/src/mixins/routeAssistant';
import { store } from '/src/store';
import { browserStorage } from '/src/mixins/browserStorage';
import { router } from '/src/router';


export default [
  {
    path: '/session',
    component: GenericRoute,
    meta: {
      title: 'Sessions',
    },
    children: [
      {
        path: 'list/:y?/:m?/:d?',
        name: 'session_list',
        component: SessionListing,
        meta: {
          title: 'Session Listing',
          tags: ['Landing Page'],
          functionCode: 'SESSIONVIEW',
          hideBackButton: true,
          isLandingRoute: true,
        },
      },

      {
        path: 'summary',
        name: 'session_summary',
        component: SessionSummary,
        meta: {
          title: 'Session Summary',
          tags: ['Landing Page'],
          functionCode: 'SESSION',
          hideBackButton: true,
          isLandingRoute: true,
        },
      },

      {
        path: 'scheduler',
        name: 'session_scheduler',
        component: SessionScheduler,
        meta: {
          title: 'Scheduling Overview',
          functionCode: 'SESSIONSCHEDULER',
          hideBackButton: true,
          areaDisabled: true,
          isLandingRoute: true,
        },
        beforeEnter: (to, from, next) => {
          if (to?.query?.fromMenu) {
          store.dispatch('updateAppFormStore', {
            Form: 'SessionSchedulerFilter',
            SelectedAreaID: null,
            StartDate: null,
            EndDate: null,
            SessionRecordID: null,
            SessionPlanID: null,
            SessionDate: null,
            ShowEmpty: null,
            ShowCancelled: null,
          });
          }
          next();
        }

      },

      {
        path: 'scheduler/list/:y?/:m?/:d?/:areaid?/:specialtyid?',
        name: 'session_scheduler_list',
        component: SessionSchedulerList,
        meta: {
          title: 'Session Scheduler',
          functionCode: 'SESSIONSCHEDULER',
          areaDisabled: true,
          backRoutes: ['session_scheduler'],
        },  
        beforeEnter: (to, from, next) => {
          if (to?.query?.fromMenu) {
            console.log('clearing')
          store.dispatch('updateAppFormStore', {
            Form: 'SessionSchedulerFilter',
            StartDate: null,
            EndDate: null,
            SessionRecordID: null,
            SessionPlanID: null,
            SessionDate: null,
            ShowEmpty: null,
            ShowCancelled: null,
          });
          }
          if (Object.keys(to.params).length === 0) {
            router.replace({
              name: 'session_scheduler_list',
              params: { areaid:  browserStorage.app.currentArea?.ID, specialtyid: null, ...browserStorage.app.$routeAssistant.dateToRouteParams(new Date()) },
              query: {
                fromMenu: true,
              }
            });
          } else {
            next()
          }
          
          
        }
      },

      {
        path: 'analysis',
        name: 'session_analysis',
        component: SessionAnalysis,
        meta: {
          title: 'Session Analysis',
          functionCode: 'SESSIONANALYSIS',
          hideBackButton: true,
          areaDisabled: true,
          isLandingRoute: true,
        },
        beforeEnter: (to, from, next) => {
          if (to?.query?.fromMenu) {
          store.dispatch('updateAppFormStore', {
            Form: 'SessionAnalysisFilter',
            StartDate: null,
            EndDate: null,
            SelectedAreaID: null,
            IncludeEmergency: null,
          });
          }
          next();
        }
      },

      {
        path: 'analysis/list/:y?/:m?/:d?/:areaid?/:specialtyid?',
        name: 'session_analysis_list',
        component: SessionAnalysisList,
        meta: {
          title: 'Session Analysis',
          functionCode: 'SESSIONANALYSIS',
          areaDisabled: true,
          backRoutes: ['session_analysis'],
        },
      },

      {
        path: 'emergency/:y?/:m?/:d?',
        name: 'session_emergency_list',
        component: EmergencySessionListing,
        meta: {
          title: 'Emergency & Urgent Care',
          tags: ['Landing Page'],
          functionCode: 'EMERGENCY',
          hideBackButton: true,
          isLandingRoute: true,
        },
      },

      {
        path: 'planning',
        component: GenericRoute,
        meta: {
          title: 'Sessions Planning',
        },
        children: [
          {
            path: ':planid',
            name: 'session_planning_detail',
            component: SessionPlanMaintenance,
            meta: {
              title: 'Sessions Planning',
              functionCode: 'SESSIONPLANUPDATE',
              areaDisabled: true,
              backRoutes: ['session_planning'],
            },
          },
          {
            path: '',
            name: 'session_planning',
            component: SessionPlanning,
            meta: {
              title: 'Sessions Planning',
              tags: ['Landing Page'],
              functionCode: 'SESSIONPLAN',
              hideBackButton: true,
              isLandingRoute: true,
            },
          },
        ],
      },

      {
        path: 'bytheatre',
        name: 'session_by_theatre',
        component: SessionByTheatre,
        meta: {
          title: 'Sessions by Template',
          functionCode: 'SESSION',
          hideBackButton: true,
        },
      },

      {
        path: 'byidentifier',
        name: 'session_by_identifier',
        component: SessionByIdentifier,
        meta: {
          title: 'Sessions by Identifier',
          functionCode: 'SESSION',
          hideBackButton: true,
        },
      },

      {
        path: 'week/:diff?/:toggle?',
        name: 'session_week',
        component: SessionWeek,
        meta: {
          title: 'Session Week Listing',
          functionCode: 'SESSION',
          backRoutes: ['session_summary', 'session_planning'],
        },
      },

      {
        path: 'filter',
        component: GenericRoute,
        meta: {
          title: 'Session Filter',
        },
        children: [
          {
            path: 'withdate/:fromplan',
            name: 'session_filter_with_date',
            component: SessionPlanningWithDateFilter,
            meta: {
              title: 'Session Filter',
              // functionCode: 'SESSION',
              backRoutes: ['session_planning', 'session_list'],
            },
          },
          {
            path: '',
            name: 'session_filter',
            component: SessionPlanningFilter,
            meta: {
              title: 'Session Filter',
              // functionCode: 'SESSION',
              backRoutes: ['session_list', 'session_summary'],
            },
          },
        ],
      },

      {
        path: 'create',
        name: 'session_create',
        component: SessionCreate,
        meta: {
          title: 'Session Create',
          functionCode: 'SESSIONCREATE',
          backRoutes: ['session_summary', 'session_week'],
        },
      },

      {
        path: ':sessionrecordid?/:sessionplanid?/:y?/:m?/:d?/:listtype?',
        component: GenericRoute,
        meta: {
          title: 'Operating List',
        },
        children: [
          {
            path: 'finalise',
            component: GenericRoute,
            meta: {
              title: 'Session Finalise',
            },
            children: [
              {
                path: 'view',
                name: 'session_finalise_view',
                component: SessionFinaliseView,
                meta: {
                  title: 'Session Finalise View',
                  functionCode: 'SESSIONFINALISE',
                  areaDisabled: true,
                  backRoutes: ['session_finalise', 'session_view'],
                },
              },
              {
                path: '',
                name: 'session_finalise',
                component: SessionFinalise,
                meta: {
                  title: 'Session Finalise',
                  functionCode: 'SESSIONFINALISE',
                  areaDisabled: true,
                  backRoutes: ['session_view'],
                },
              },
            ],
          },
          {
            path: 'action/:actiontype',
            name: 'session_action',
            component: SessionAction,
            meta: {
              title: 'Session Action',
              functionCode: 'SESSION',
              areaDisabled: true,
              isSessionAction: true,
              backRoutes: ['session_view'],
            },
          },
          {
            path: 'booking/:operationrecordid?/:pasepisodeid?/:patientid?/:surgicalemergency?/:slotnum?/:fromoplist?',
            component: GenericRoute,
            meta: {
              title: 'Patient Booking',
            },
            children: [
              {
                path: 'update',
                name: 'session_booking_update',
                component: Booking,
                meta: {
                  title: 'Patient Booking Update',
                  functionCode: ['BOOKEMERAMEND','BOOKAMEND'],
                  backRoutes: ['session_view','waiting_list_pas_episode'],
                },
              },
              {
                path: ':readonly?',
                name: 'session_booking',
                component: Booking,
                meta: {
                  title: 'Patient Booking',
                  functionCode: ['VIEWBOOK','VIEWEMERBOOK','BOOKPAT', 'BOOKEMERPAT'],
                  backRoutes: [
                    'session_view',
                    'patient_view',
                    'waiting_list_pas_episode',
                    'working_list_view',
                    'episode_view',                    
                    'session_scheduler_list',
                    'waiting_list_view'
                  ],
                },
              },
            ],
          },
          {
            path: '',
            name: 'session_view',
            component: SessionView,
            meta: {
              title: 'Operating List',
              areaDisabled: true,
              backRoutes: [
                'session_summary',
                'session_list',
                'session_emergency_list',
                'session_by_theatre',
                'session_by_identifier',
                'session_week',
                'episode_view',
                'patient_view',
                'working_list_view',
                'working_list_dashboard',
                'live_locations',
                'whiteboard',
                'session_scheduler_list',
                'session_analysis_list',
                'whiteboard_daily_view',
                'episode_function_observation'
              ],
            },
            beforeEnter: (to, from, next) => {
              if (from?.name != 'episode_view') {
                store.dispatch('updateAppFormStore', {
                  Form: 'CompletedEmergencySessionRecordID',
                  Value: null,
                });
              }
              const funcCode =
                to.params.listtype && to.params.listtype.toUpperCase() == 'EMER'
                  ? ['SURGEMERGENCYLIST', 'FULLEMERGENCYLIST']
                  : 'OPLIST';
              if (routeAssistant.app.$options.methods.checkAccessRights(funcCode)) {
                next();
              } else {
                store.dispatch('clearAppMessages');
                store.dispatch('addAppMessage', {
                  status: 'warning',
                  title: 'Access Denied',
                  description: 'User has insufficient access rights. Please contact your system administrator.',
                  isPersistentMessage: true,
                });
              }
            },
          },
        ],
      },
    ],
  },

  {
    path: '/whiteboard',
    component: GenericRoute,
    meta: {
      title: 'Whiteboard',
    },
    children: [
      {
        path: 'fullscreen',
        name: 'whiteboard_fullscreen',
        component: Whiteboard,
        meta: {
          title: 'Whiteboard',
          layout: 'fullscreen',
          functionCode: 'OPLISTWHITE',
          backRoutes: ['whiteboard'],
        },
      },
      {
        path: 'filter',
        name: 'whiteboard_filter',
        component: WhiteboardFilter,
        meta: {
          title: 'Whiteboard Filters',
          functionCode: 'OPLISTWHITE',
          backRoutes: ['whiteboard'],
        },
      },
      {
        path: '',
        name: 'whiteboard',
        component: Whiteboard,
        meta: {
          title: 'Whiteboard',
          tags: ['Landing Page'],
          functionCode: 'OPLISTWHITE',
          hideBackButton: true,
          isLandingRoute: true,
        },
      },
      
    ],
  },
  {
    path: '/whiteboard/daily/:y?/:m?/:d?/:areaid?/:specialtyid?',
    name: 'whiteboard_daily_view',
    component: WhiteboardDailyView,
    meta: {
      title: 'Whiteboard - Daily View',
      functionCode: 'OPLISTWHITE',
      backRoutes: ['session_analysis'],
    },
  },
];
