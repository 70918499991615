// Session Type
const SessionType = () => import('/src/components/admin/session/SessionType');
const SessionTypeForm = () => import('/src/components/admin/session/forms/SessionTypeForm');

// Session Profile
const SessionProfile = () => import('/src/components/admin/session/SessionProfile');
const SessionProfileForm = () => import('/src/components/admin/session/forms/SessionProfileForm');

// Emergency and Urgent Care Lists
const SessionUnscheduled = () => import('/src/components/admin/session/SessionUnscheduled');
const SessionUnscheduledForm = () => import('/src/components/admin/session/forms/SessionUnscheduledForm');

// Time Template
const SessionTimeTemplate = () => import('/src/components/admin/session/SessionTimeTemplate');
const SessionTimeTemplateForm = () => import('/src/components/admin/session/forms/SessionTimeTemplateForm');

// Slot Template
const SessionSlotTemplate = () => import('/src/components/admin/session/SessionSlotTemplate');
const SessionSlotTemplateForm = () => import('/src/components/admin/session/forms/SessionSlotTemplateForm');

// Slot Type
const SessionSlotType = () => import('/src/components/admin/session/SessionSlotType');
const SessionSlotTypeForm = () => import('/src/components/admin/session/forms/SessionSlotTypeForm');

// Session Action Type
const SessionActionType = () => import('/src/components/admin/session/SessionActionType');
const SessionActionTypeForm = () => import('/src/components/admin/session/forms/SessionActionTypeForm');

// Exception Dates
const SessionExceptionDates = () => import('/src/components/admin/session/SessionExceptionDates');

// Maintenance Plans
const LocationMaintenance = () => import('/src/components/admin/maintenanceplan/LocationMaintenance');
const LocationMaintenanceList = () => import('/src/components/admin/maintenanceplan/views/LocationMaintenanceList');
const LocationMaintenanceForm = () => import('/src/components/admin/maintenanceplan/views/LocationMaintenanceForm');

// Recurrence Patterns
const RecurrencePattern = () => import('/src/components/admin/recurrencepattern/RecurrencePattern');
const RecurrencePatternList = () => import('/src/components/admin/recurrencepattern/views/RecurrencePatternList');
const RecurrencePatternView = () => import('/src/components/admin/recurrencepattern/views/RecurrencePatternForm');

export default [
  // ======================================== \\
  // Session Profile
  // ======================================== \\
  {
    path: 'session/profile',
    name: 'admin_sessionprofile',
    component: SessionProfile,
    meta: {
      title: 'Session Profile Maintenance',
      tags: ['Administration'],
      functionCode: 'SESSIONADMIN',
      backRoutes: ['admin_home'],
    },
  },
  {
    path: 'session/profile/:sessionprofileid',
    name: 'admin_sessionprofile_detail',
    component: SessionProfileForm,
    meta: {
      title: 'Session Profile Maintenance',
      tags: ['Administration'],
      functionCode: 'SESSIONADMIN',
      backRoutes: ['admin_sessionprofile'],
    },
  },

  // ======================================== \\
  // Emergency and Urgent Care Lists
  // ======================================== \\
  {
    path: 'session/unscheduled',
    name: 'admin_sessionunscheduled',
    component: SessionUnscheduled,
    meta: {
      title: 'Emergency and Urgent Care Lists',
      tags: ['Administration'],
      functionCode: 'SESSIONADMIN',
      backRoutes: ['admin_home'],
    },
  },
  {
    path: 'session/unscheduled/:unscheduledid',
    name: 'admin_sessionunscheduled_detail',
    component: SessionUnscheduledForm,
    meta: {
      title: 'Emergency and Urgent Care Lists',
      tags: ['Administration'],
      functionCode: 'SESSIONADMIN',
      backRoutes: ['admin_sessionunscheduled'],
    },
  },

  // ======================================== \\
  // Session Types
  // ======================================== \\
  {
    path: 'session/type',
    name: 'admin_sessiontype',
    component: SessionType,
    meta: {
      title: 'Session Type Maintenance',
      tags: ['Administration'],
      functionCode: 'SESSIONADMIN',
      backRoutes: ['admin_home'],
    },
  },
  {
    path: 'session/type/:sessiontypeid',
    name: 'admin_sessiontype_detail',
    component: SessionTypeForm,
    meta: {
      title: 'Session Type Maintenance',
      tags: ['Administration'],
      functionCode: 'SESSIONADMIN',
      backRoutes: ['admin_sessiontype'],
    },
  },

  // ======================================== \\
  // Time Template
  // ======================================== \\
  {
    path: 'session/time/template',
    name: 'admin_sessiontimetemplate',
    component: SessionTimeTemplate,
    meta: {
      title: 'Time Template Maintenance',
      tags: ['Administration'],
      functionCode: 'SESSIONADMIN',
      backRoutes: ['admin_home'],
    },
  },
  {
    path: 'session/time/template/:timetemplateid',
    name: 'admin_sessiontimetemplate_detail',
    component: SessionTimeTemplateForm,
    meta: {
      title: 'Time Template Maintenance',
      tags: ['Administration'],
      functionCode: 'SESSIONADMIN',
      backRoutes: ['admin_sessiontimetemplate'],
    },
  },

  // ======================================== \\
  // Slot Template
  // ======================================== \\
  {
    path: 'session/slot/template',
    name: 'admin_sessionslottemplates',
    component: SessionSlotTemplate,
    meta: {
      title: 'Slot Template Maintenance',
      tags: ['Administration'],
      functionCode: 'SESSIONADMIN',
      backRoutes: ['admin_home'],
    },
  },
  {
    path: 'session/slot/template/:slottemplateid',
    name: 'admin_sessionslottemplate_detail',
    component: SessionSlotTemplateForm,
    meta: {
      title: 'Slot Template Maintenance',
      tags: ['Administration'],
      functionCode: 'SESSIONADMIN',
      backRoutes: ['admin_sessionslottemplates'],
    },
  },

  // ======================================== \\
  // Slot Type
  // ======================================== \\
  {
    path: 'session/slot/type',
    name: 'admin_sessionslottypes',
    component: SessionSlotType,
    meta: {
      title: 'Slot Type Maintenance',
      tags: ['Administration'],
      functionCode: 'SESSIONADMIN',
      backRoutes: ['admin_home'],
    },
  },
  {
    path: 'session/slot/type/:slottypeid',
    name: 'admin_sessionslottype_detail',
    component: SessionSlotTypeForm,
    meta: {
      title: 'Slot Type Maintenance',
      tags: ['Administration'],
      functionCode: 'SESSIONADMIN',
      backRoutes: ['admin_sessionslottypes'],
    },
  },

  // ======================================== \\
  // Session Action Type Properties
  // ======================================== \\
  {
    path: 'session/action/type',
    name: 'admin_sessionactiontypes',
    component: SessionActionType,
    meta: {
      title: 'Session Action Type Properties Maintenance',
      tags: ['Administration'],
      functionCode: 'SESSIONADMIN',
      backRoutes: ['admin_home'],
    },
  },
  {
    path: 'session/action/type/:actiontypeid',
    name: 'admin_sessionactiontype_detail',
    component: SessionActionTypeForm,
    meta: {
      title: 'Session Action Type Properties Maintenance',
      tags: ['Administration'],
      functionCode: 'SESSIONADMIN',
      backRoutes: ['admin_sessionactiontypes'],
    },
  },

  // ======================================== \\
  // Exception Dates
  // ======================================== \\
  {
    path: 'exception/date',
    name: 'admin_exceptiondates',
    component: SessionExceptionDates,
    meta: {
      title: 'Exception Dates Maintenance',
      tags: ['Administration'],
      functionCode: 'SESSIONADMIN',
      backRoutes: ['admin_home'],
    },
  },

  // ======================================== \\
  // Maintenance Plans
  // ======================================== \\
  {
    path: 'maintenance/plan',
    component: LocationMaintenance,
    meta: {
      title: 'Maintenance Plan Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':maintenanceid',
        name: 'admin_maintenanceplan_detail',
        component: LocationMaintenanceForm,
        meta: {
          title: 'Maintenance Plan Maintenance',
          tags: ['Administration'],
          functionCode: 'LOCMAINTPLANEDIT',
          backRoutes: ['admin_maintenanceplan_list', 'session_planning'],
        },
      },
      {
        path: '',
        name: 'admin_maintenanceplan_list',
        component: LocationMaintenanceList,
        meta: {
          title: 'Maintenance Plan Maintenance',
          tags: ['Administration'],
          functionCode: 'LOCMAINTPLANEDIT',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // Recurrence Patterns
  // ======================================== \\
  {
    path: 'recurrence/pattern',
    component: RecurrencePattern,
    meta: {
      title: 'Recurrence Pattern Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':patternid',
        name: 'admin_recurrencepattern_detail',
        component: RecurrencePatternView,
        meta: {
          title: 'Recurrence Pattern Maintenance',
          tags: ['Administration'],
          functionCode: 'SESSIONADMIN',
          backRoutes: ['admin_recurrencepattern_list'],
        },
      },
      {
        path: '',
        name: 'admin_recurrencepattern_list',
        component: RecurrencePatternList,
        meta: {
          title: 'Recurrence Pattern Maintenance',
          tags: ['Administration'],
          functionCode: 'SESSIONADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },
];
