import { render, staticRenderFns } from "./NotAuth.vue?vue&type=template&id=5120e552&scoped=true"
import script from "./NotAuth.vue?vue&type=script&lang=js"
export * from "./NotAuth.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5120e552",
  null
  
)

export default component.exports