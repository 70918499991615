const axios = require('axios');

export const AQUAProductService = {
  getAQUAProduct: function (
    payload = {
      ProductEnum: null,
      Enabled: true,
    }
  ) {
    return axios
      .post(
        // URL
        'AQUAProduct',
        // DATA
        payload
      )
      .then((response) => {
        return response.data.result;
      });
  },
};
