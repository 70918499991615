// Area maintainace
const AreaList = () => import('/src/components/admin/area/AreaList');
const AreaDetail = () => import('/src/components/admin/area/AreaDetail');

// Location maintainance
const Location = () => import('/src/components/admin/location/Location');
const LocationForm = () => import('/src/components/admin/location/forms/LocationEdit');

// Location Group maintainance
const LocationGroup = () => import('/src/components/admin/location/LocationGroup');
const LocationGroupForm = () => import('/src/components/admin/location/forms/LocationGroupEdit');

// Location Type maintainance
const LocationType = () => import('/src/components/admin/location/LocationType');
const LocationTypeList = () => import('/src/components/admin/location/forms/LocationTypeList');
const LocationTypeForm = () => import('/src/components/admin/location/forms/LocationTypeForm');

export default [
  // ======================================== \\
  // User maintainace
  // ======================================== \\
  {
    path: 'area',
    name: 'admin_areas',
    component: AreaList,
    meta: {
      title: 'Area Maintenance',
      tags: ['Administration'],
      functionCode: 'LOCATIONADMIN',
      backRoutes: ['admin_home'],
    },
  },
  {
    path: 'area/:id',
    name: 'admin_area_detail',
    component: AreaDetail,
    meta: {
      title: 'Area Detail',
      tags: ['Administration'],
      functionCode: 'LOCATIONADMIN',
      backRoutes: ['admin_areas'],
    },
  },

  // ======================================== \\
  // Location maintainace
  // ======================================== \\
  {
    path: 'location',
    name: 'admin_locations',
    component: Location,
    meta: {
      title: 'Location Maintenance',
      tags: ['Administration'],
      functionCode: 'LOCATIONADMIN',
      backRoutes: ['admin_home'],
    },
  },
  {
    path: 'location/:locationid',
    name: 'admin_location_detail',
    component: LocationForm,
    meta: {
      title: 'Location Maintenance',
      tags: ['Administration'],
      functionCode: 'LOCATIONADMIN',
      backRoutes: ['admin_locations'],
    },
  },

  // ======================================== \\
  // Location Group maintainace
  // ======================================== \\
  {
    path: 'location/group',
    name: 'admin_locationgroups',
    component: LocationGroup,
    meta: {
      title: 'Location Groups Maintenance',
      tags: ['Administration'],
      functionCode: 'LOCATIONADMIN',
      backRoutes: ['admin_home'],
    },
  },
  {
    path: 'location/group/:locationgroupid',
    name: 'admin_locationgroup_detail',
    component: LocationGroupForm,
    meta: {
      title: 'Location Groups Maintenance',
      tags: ['Administration'],
      functionCode: 'LOCATIONADMIN',
      backRoutes: ['admin_locationgroups'],
    },
  },

  // ======================================== \\
  // Location Type maintainace
  // ======================================== \\
  {
    path: 'location/type',
    component: LocationType,
    meta: {
      title: 'Location Type Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':locationtypeid',
        name: 'admin_locationtype_detail',
        component: LocationTypeForm,
        meta: {
          title: 'Location Type Maintenance',
          tags: ['Administration'],
          functionCode: 'LOCATIONADMIN',
          backRoutes: ['admin_locationtype'],
        },
      },
      {
        path: '',
        name: 'admin_locationtype',
        component: LocationTypeList,
        meta: {
          title: 'Location Type Maintenance',
          tags: ['Administration'],
          functionCode: 'LOCATIONADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },
];
