<template>
  <div id="GenericSearchBox">
    <form class="form" role="form" novalidate @submit.prevent="submitSearch(true)" :aria-label="ariaLabel">
      <div class="searchinputwrapper">
        <label for="searchText">Search</label>
        <input
          id="searchText"
          class="searchinput"
          autocomplete="off"
          type="text"
          :maxlength="maxlength"
          v-lengthcheck
          v-model="searchText"
          :placeholder="placeholder"
          v-format_text="'search'"
          v-initialfocus="initialfocus"
          @input="submitted = false"
        />
        <button v-if="searchText != ''" class="searchclear" type="button" @click.stop.prevent="clearSearch()" aria-label="Clear search text" />
        <button class="button" type="submit" aria-label="Submit search text">SEARCH</button>
      </div>
      <!-- <div class="errormessage" v-if="searchText == ''">Search criteria is required</div> -->
      <div class="errormessage" v-if="!meetsMinimumLength">Search term must be at least {{ minlength || 3 }} characters</div>
    </form>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'GenericSearchBox',
  props: ['value', 'placeholder', 'ariaLabel', 'initialfocus', 'manualonly', 'ignorecache', 'minlength', 'maxlength'],
  data() {
    return {
      searchText: this.value,
      submitted: false,
      routeUID: this.$route.name,
    };
  },
  watch: {
    value: function (newVal) {
      this.searchText = newVal;
    },
    searchText: debounce(function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.submitted = false;
        if (!this.isNullOrEmpty(newVal) && newVal.length >= (this.minlength ?? 3)) {
          this.submitSearch(false);
        } else {
          this.clearSearch(false);
        }
      }
    }, 500),

    storeSearchText: function (newVal) {
      this.searchText = newVal;
    },
  },
  computed: {
    ...mapGetters(['getSearchText']),

    storeSearchText: function () {
      return this.getSearchText(this.routeUID);
    },

    meetsMinimumLength: function () {
      return this.isNullOrEmpty(this.searchText) || this.searchText.length >= (this.minlength ?? 3);
    },
  },
  created() {
    this.searchText = !this.ignorecache ? this.storeSearchText ?? this.value : this.value;
  },
  methods: {
    clearSearch: function (clearText = true) {
      if (clearText || this.meetsMinimumLength) {
        this.searchText = '';
      }

      this.submitted = false;
      this.emitEvents();
    },

    submitSearch: function (manualSubmit) {
      this.submitted = true;
      this.emitEvents(manualSubmit);
    },

    emitEvents: function (manualSubmit) {
      if (!this.manualonly || manualSubmit) {
        this.setSearchText(this.routeUID, this.searchText);
        this.$emit('input', this.searchText);

        if (this.isNullOrEmpty(this.searchText) || this.meetsMinimumLength) {
          this.$emit('searchSubmitted', this.searchText);
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
#GenericSearchBox {
  .searchinputwrapper {
    .searchclear {
      right: 44px;
    }
  }
}
</style>
