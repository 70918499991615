const GenericRoute = () => import('/src/components/application/_GenericRoute');

// Extended Data
const ExtendedData = () => import('/src/components/admin/extendeddata/ExtendedData');

// Alert maintainace
const AlertList = () => import('/src/components/admin/alert/AlertList');
const AlertDetail = () => import('/src/components/admin/alert/views/AlertDetails');

// Anaesthetic Type
const AnaestheticType = () => import('/src/components/admin/maintenance/AnaestheticType');
const AnaestheticTypeList = () => import('/src/components/admin/maintenance/views/AnaestheticTypeList');
const AnaestheticTypeForm = () => import('/src/components/admin/maintenance/views/AnaestheticTypeForm');

// Anaesthetic Type Group
const AnaestheticTypeGroup = () => import('/src/components/admin/maintenance/AnaestheticTypeGroup');
const AnaestheticTypeGroupList = () => import('/src/components/admin/maintenance/views/AnaestheticTypeGroupList');
const AnaestheticTypeGroupForm = () => import('/src/components/admin/maintenance/views/AnaestheticTypeGroupForm');

// Admission Type
const AdmissionType = () => import('/src/components/admin/maintenance/AdmissionType');
const AdmissionTypeList = () => import('/src/components/admin/maintenance/views/AdmissionTypeList');
const AdmissionTypeForm = () => import('/src/components/admin/maintenance/views/AdmissionTypeForm');

// Admission Type Group
const AdmissionTypeGroup = () => import('/src/components/admin/maintenance/AdmissionTypeGroup');
const AdmissionTypeGroupList = () => import('/src/components/admin/maintenance/views/AdmissionTypeGroupList');
const AdmissionTypeGroupForm = () => import('/src/components/admin/maintenance/views/AdmissionTypeGroupForm');

// Admission Method
const AdmissionMethod = () => import('/src/components/admin/maintenance/AdmissionMethod');
const AdmissionMethodList = () => import('/src/components/admin/maintenance/views/AdmissionMethodList');
const AdmissionMethodForm = () => import('/src/components/admin/maintenance/views/AdmissionMethodForm');

// Specialty
const Specialty = () => import('/src/components/admin/specialty/Specialty');
const SpecialtyList = () => import('/src/components/admin/specialty/forms/SpecialtyList');
const SpecialtyForm = () => import('/src/components/admin/specialty/forms/SpecialtyForm');

// Specialty Group
const SpecialtyGroup = () => import('/src/components/admin/specialty/SpecialtyGroup');
const SpecialtyGroupList = () => import('/src/components/admin/specialty/forms/SpecialtyGroupList');
const SpecialtyGroupForm = () => import('/src/components/admin/specialty/forms/SpecialtyGroupForm');

// ASA
const ASA = () => import('/src/components/admin/ASA/ASA');
const ASAList = () => import('/src/components/admin/ASA/views/ASAList');
const ASAForm = () => import('/src/components/admin/ASA/views/ASAForm');

// CEPOD
const CEPOD = () => import('/src/components/admin/CEPOD/CEPOD');
const CEPODList = () => import('/src/components/admin/CEPOD/views/CEPODList');
const CEPODForm = () => import('/src/components/admin/CEPOD/views/CEPODForm');

// Clinical Event Type
const ClinicalAreaSubType = () => import('/src/components/admin/clinical/area/subtype/ClinicalAreaSubType');
const ClinicalAreaSubTypeList = () =>
  import('/src/components/admin/clinical/area/subtype/views/ClinicalAreaSubTypeList');
const ClinicalAreaSubTypeForm = () =>
  import('/src/components/admin/clinical/area/subtype/views/ClinicalAreaSubTypeForm');

// Clinical Category
const ClinicalCategory = () => import('/src/components/admin/clinical/category/ClinicalCategory');
const ClinicalCategoryList = () => import('/src/components/admin/clinical/category/views/ClinicalCategoryList');
const ClinicalCategoryForm = () => import('/src/components/admin/clinical/category/views/ClinicalCategoryForm');

// Clinical Event
const ClinicalEvent = () => import('/src/components/admin/clinical/event/ClinicalEvent');
const ClinicalEventList = () => import('/src/components/admin/clinical/event/views/ClinicalEventList');
const ClinicalEventForm = () => import('/src/components/admin/clinical/event/views/ClinicalEventForm');

// Clinical Priority
const ClinicalPriority = () => import('/src/components/admin/clinical/priority/ClinicalPriority');
const ClinicalPriorityList = () => import('/src/components/admin/clinical/priority/views/ClinicalPriorityList');
const ClinicalPriorityForm = () => import('/src/components/admin/clinical/priority/views/ClinicalPriorityForm');

// Admin Category
const AdminCategory = () => import('/src/components/admin/admincategory/AdminCategory');
const AdminCategoryList = () => import('/src/components/admin/admincategory/views/AdminCategoryList');
const AdminCategoryForm = () => import('/src/components/admin/admincategory/views/AdminCategoryForm');

// Gender
const Gender = () => import('/src/components/admin/gender/Gender');
const GenderList = () => import('/src/components/admin/gender/views/GenderList');
const GenderForm = () => import('/src/components/admin/gender/views/GenderForm');

//GP Components
const GP = () => import('/src/components/admin/gp/GP');
const GPList = () => import('/src/components/admin/gp/views/GPList');
const GPForm = () => import('/src/components/admin/gp/views/GPForm');

// IntendedManagement
const IntendedManagement = () => import('/src/components/admin/intendedmanagement/IntendedManagement');
const IntendedManagementList = () => import('/src/components/admin/intendedmanagement/views/IntendedManagementList');
const IntendedManagementForm = () => import('/src/components/admin/intendedmanagement/views/IntendedManagementForm');

// Operation Type
const OperationType = () => import('/src/components/admin/operationtype/OperationType');
const OperationTypeList = () => import('/src/components/admin/operationtype/views/OperationTypeList');
const OperationTypeForm = () => import('/src/components/admin/operationtype/views/OperationTypeForm');

// Practice
const Practice = () => import('/src/components/admin/practice/Practice');
const PracticeList = () => import('/src/components/admin/practice/views/PracticeList');
const PracticeForm = () => import('/src/components/admin/practice/views/PracticeForm');

// Reasons
const ReasonList = () => import('/src/components/admin/reasons/ReasonList');
const ReasonForm = () => import('/src/components/admin/reasons/forms/ReasonForm');
const ReasonDetailsForm = () => import('/src/components/admin/reasons/forms/ReasonDetailForm');

// Tourniquet Site
const TourniquetSite = () => import('/src/components/admin/tourniquetSite/TourniquetSite');
const TourniquetSiteForm = () => import('/src/components/admin/tourniquetSite/forms/TourniquetSiteForm');

// Landing Page
const LandingPageTemplate = () => import('/src/components/admin/landingpage/LandingPage');
const LandingPageTemplateList = () => import('/src/components/admin/landingpage/views/LandingPageTemplateList');
const LandingPageTemplateForm = () => import('/src/components/admin/landingpage/views/LandingPageTemplateForm');
const LandingPageLayout = () => import('/src/components/admin/landingpage/views/LandingPageTemplateLayoutView');
const LandingPageFilterEdit = () => import('/src/components/admin/landingpage/views/LandingPageTemplateEdit');
const LandingPageFilterSummary = () => import('/src/components/admin/landingpage/views/LandingPageTemplateSummary');
const LandingPageFilter = () => import('/src/components/admin/landingpage/views/LandingPageTemplateFilters');

// Priority Types
const PriorityTypes = () => import('/src/components/admin/session/forms/SessionPriorityTypes');
const PriorityTypesForm = () => import('/src/components/admin/session/forms/SessionPriorityTypesForm');

export default [
  // ======================================== \\
  // Admin Category
  // ======================================== \\
  {
    path: 'category',
    component: AdminCategory,
    meta: {
      title: 'Admin Category Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':admincategoryid',
        name: 'admin_category_detail',
        component: AdminCategoryForm,
        meta: {
          title: 'Admin Category Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_category'],
        },
      },
      {
        path: '',
        name: 'admin_category',
        component: AdminCategoryList,
        meta: {
          title: 'Admin Category Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // Priority Types
  // ======================================== \\
  {
    path: 'priority/type',
    name: 'admin_prioritytypes',
    component: PriorityTypes,
    meta: {
      title: 'Priority Types Maintenance',
      tags: ['Administration'],
      functionCode: 'GENERALADMIN',
      backRoutes: ['admin_home'],
    },
  },
  {
    path: 'priority/type/:typeid',
    name: 'admin_prioritytype_detail',
    component: PriorityTypesForm,
    meta: {
      title: 'Priority Types Maintenance',
      tags: ['Administration'],
      functionCode: 'GENERALADMIN',
      backRoutes: ['admin_prioritytypes'],
    },
  },

  // ======================================== \\
  // Reasons
  // ======================================== \\
  {
    path: 'reason',
    name: 'admin_reasons',
    component: ReasonList,
    meta: {
      title: 'Reason Maintenance',
      tags: ['Administration'],
      functionCode: 'GENERALADMIN',
      backRoutes: ['admin_home'],
    },
  },
  {
    path: 'reason/:reasonid',
    name: 'admin_reasons_detail',
    component: ReasonForm,
    meta: {
      title: 'Reason Maintenance',
      tags: ['Administration'],
      functionCode: 'GENERALADMIN',
      backRoutes: ['admin_reasons'],
    },
  },
  {
    path: 'reason/detail/:reasondetailid',
    name: 'admin_reasonsdetail_detail',
    component: ReasonDetailsForm,
    meta: {
      title: 'Reason Details Maintenance',
      tags: ['Administration'],
      functionCode: 'GENERALADMIN',
      backRoutes: ['admin_reasons'],
    },
  },

  // ======================================== \\
  // Tourniquet Site
  // ======================================== \\
  {
    path: 'tourniquet/site',
    name: 'admin_tourniquetsites',
    component: TourniquetSite,
    meta: {
      title: 'Tourniquet Site Maintenance',
      tags: ['Administration'],
      functionCode: 'GENERALADMIN',
      backRoutes: ['admin_home'],
    },
  },
  {
    path: 'tourniquet/site/:tourniquetsiteid',
    name: 'admin_tourniquetsite_detail',
    component: TourniquetSiteForm,
    meta: {
      title: 'Tourniquet Site Maintenance',
      tags: ['Administration'],
      functionCode: 'GENERALADMIN',
      backRoutes: ['admin_tourniquetsites'],
    },
  },

  // ======================================== \\
  // Anaesthetic Type
  // ======================================== \\
  {
    path: 'anaesthetic/type',
    component: AnaestheticType,
    meta: {
      title: 'Anaesthetic Type Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':anaesthetictypeid',
        name: 'admin_anaesthetictype_detail',
        component: AnaestheticTypeForm,
        meta: {
          title: 'Anaesthetic Type Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_anaesthetictype'],
        },
      },
      {
        path: '',
        name: 'admin_anaesthetictype',
        component: AnaestheticTypeList,
        meta: {
          title: 'Anaesthetic Type Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // Anaesthetic Type Group
  // ======================================== \\
  {
    path: 'anaesthetic/type/group',
    component: AnaestheticTypeGroup,
    meta: {
      title: 'Anaesthetic Type Group Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':anaesthetictypegroupid',
        name: 'admin_anaesthetictypegroup_detail',
        component: AnaestheticTypeGroupForm,
        meta: {
          title: 'Anaesthetic Type Group Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_anaesthetictypegroup'],
        },
      },
      {
        path: '',
        name: 'admin_anaesthetictypegroup',
        component: AnaestheticTypeGroupList,
        meta: {
          title: 'Anaesthetic Type Group Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // Admission Type
  // ======================================== \\
  {
    path: 'admission/type',
    component: AdmissionType,
    meta: {
      title: 'Admission Type Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':admissiontypeid',
        name: 'admin_admissiontype_detail',
        component: AdmissionTypeForm,
        meta: {
          title: 'Admission Type Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_admissiontype'],
        },
      },
      {
        path: '',
        name: 'admin_admissiontype',
        component: AdmissionTypeList,
        meta: {
          title: 'Admission Type Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // Admission Type Group
  // ======================================== \\
  {
    path: 'admission/type/group',
    component: AdmissionTypeGroup,
    meta: {
      title: 'Admission Type Group Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':admissiontypegroupid',
        name: 'admin_admissiontypegroup_detail',
        component: AdmissionTypeGroupForm,
        meta: {
          title: 'Admission Type Group Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_admissiontypegroup'],
        },
      },
      {
        path: '',
        name: 'admin_admissiontypegroup',
        component: AdmissionTypeGroupList,
        meta: {
          title: 'Admission Type Group Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // Admission Method
  // ======================================== \\
  {
    path: 'admission/method',
    component: AdmissionMethod,
    meta: {
      title: 'Admission Method Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':admissionmethodid',
        name: 'admin_admissionmethod_detail',
        component: AdmissionMethodForm,
        meta: {
          title: 'Admission Method Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_admissionmethod'],
        },
      },
      {
        path: '',
        name: 'admin_admissionmethod',
        component: AdmissionMethodList,
        meta: {
          title: 'Admission Method Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // Specialty
  // ======================================== \\
  {
    path: 'specialty',
    component: Specialty,
    meta: {
      title: 'Specialty Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':specialtyid',
        name: 'admin_specialty_detail',
        component: SpecialtyForm,
        meta: {
          title: 'Specialty Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_specialty'],
        },
      },
      {
        path: '',
        name: 'admin_specialty',
        component: SpecialtyList,
        meta: {
          title: 'Specialty Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // Specialty Group
  // ======================================== \\
  {
    path: 'specialty/group',
    component: SpecialtyGroup,
    meta: {
      title: 'Specialty Group Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':specialtygroupid',
        name: 'admin_specialtygroup_detail',
        component: SpecialtyGroupForm,
        meta: {
          title: 'Specialty Group Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_specialtygroup'],
        },
      },
      {
        path: '',
        name: 'admin_specialtygroup',
        component: SpecialtyGroupList,
        meta: {
          title: 'Specialty Group Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // ASA
  // ======================================== \\
  {
    path: 'asa',
    component: ASA,
    meta: {
      title: 'ASA Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':asaid',
        name: 'admin_asa_detail',
        component: ASAForm,
        meta: {
          title: 'ASA Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_asa'],
        },
      },
      {
        path: '',
        name: 'admin_asa',
        component: ASAList,
        meta: {
          title: 'ASA Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // CEPOD
  // ======================================== \\
  {
    path: 'cepod',
    component: CEPOD,
    meta: {
      title: 'CEPOD Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':cepodid',
        name: 'admin_cepod_detail',
        component: CEPODForm,
        meta: {
          title: 'CEPOD Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_cepod'],
        },
      },
      {
        path: '',
        name: 'admin_cepod',
        component: CEPODList,
        meta: {
          title: 'CEPOD Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // Clinical Event Type
  // ======================================== \\
  {
    path: 'clinical/event/type',
    component: ClinicalAreaSubType,
    meta: {
      title: 'Clinical Event Type Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':dataid',
        name: 'admin_clinicalareasubtype_detail',
        component: ClinicalAreaSubTypeForm,
        meta: {
          title: 'Clinical Event Type Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_clinicalareasubtype'],
        },
      },
      {
        path: '',
        name: 'admin_clinicalareasubtype',
        component: ClinicalAreaSubTypeList,
        meta: {
          title: 'Clinical Event Type Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // Clinical Category
  // ======================================== \\
  {
    path: 'clinical/category',
    component: ClinicalCategory,
    meta: {
      title: 'Clinical Category Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':dataid',
        name: 'admin_clinicalcategory_detail',
        component: ClinicalCategoryForm,
        meta: {
          title: 'Clinical Category Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_clinicalcategory'],
        },
      },
      {
        path: '',
        name: 'admin_clinicalcategory',
        component: ClinicalCategoryList,
        meta: {
          title: 'Clinical Category Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // Clinical Event
  // ======================================== \\
  {
    path: 'clinical/event',
    component: ClinicalEvent,
    meta: {
      title: 'Clinical Event Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':dataid',
        name: 'admin_clinicalevent_detail',
        component: ClinicalEventForm,
        meta: {
          title: 'Clinical Event Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_clinicalevent'],
        },
      },
      {
        path: '',
        name: 'admin_clinicalevent',
        component: ClinicalEventList,
        meta: {
          title: 'Clinical Event Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // Clinical Priority
  // ======================================== \\
  {
    path: 'clinical/priority',
    component: ClinicalPriority,
    meta: {
      title: 'Clinical Priority Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':clinicalpriorityid',
        name: 'admin_clinicalpriority_detail',
        component: ClinicalPriorityForm,
        meta: {
          title: 'Clinical Priority Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_clinicalpriority'],
        },
      },
      {
        path: '',
        name: 'admin_clinicalpriority',
        component: ClinicalPriorityList,
        meta: {
          title: 'Clinical Priority Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // Gender
  // ======================================== \\
  {
    path: 'gender',
    component: Gender,
    meta: {
      title: 'Gender Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':genderid',
        name: 'admin_gender_detail',
        component: GenderForm,
        meta: {
          title: 'Gender Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_gender'],
        },
      },
      {
        path: '',
        name: 'admin_gender',
        component: GenderList,
        meta: {
          title: 'Gender Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // Intended Management
  // ======================================== \\
  {
    path: 'intended/management',
    component: IntendedManagement,
    meta: {
      title: 'Intended Management Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':intendedmanagementid',
        name: 'admin_intendedmanagement_detail',
        component: IntendedManagementForm,
        meta: {
          title: 'Intended Management Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_intendedmanagement'],
        },
      },
      {
        path: '',
        name: 'admin_intendedmanagement',
        component: IntendedManagementList,
        meta: {
          title: 'Intended Management Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // Practitioner
  // ======================================== \\
  {
    path: 'practitioner',
    component: GP,
    meta: {
      title: 'Practitioner Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':gpid',
        name: 'admin_practitioner_detail',
        component: GPForm,
        meta: {
          title: 'Practitioner Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_practitioner'],
        },
      },
      {
        path: '',
        name: 'admin_practitioner',
        component: GPList,
        meta: {
          title: 'Practitioner Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // Operation Type
  // ======================================== \\
  {
    path: 'operation/type',
    component: OperationType,
    meta: {
      title: 'Operation Type Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':operationtypeid',
        name: 'admin_operationtype_detail',
        component: OperationTypeForm,
        meta: {
          title: 'Operation Type Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_operationtype'],
        },
      },
      {
        path: '',
        name: 'admin_operationtype',
        component: OperationTypeList,
        meta: {
          title: 'Operation Type Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // Practice
  // ======================================== \\
  {
    path: 'practice',
    component: Practice,
    meta: {
      title: 'Practice Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':practiceid',
        name: 'admin_practice_detail',
        component: PracticeForm,
        meta: {
          title: 'Practice Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_practice'],
        },
      },
      {
        path: '',
        name: 'admin_practice',
        component: PracticeList,
        meta: {
          title: 'Practice Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // Extended Data
  // ======================================== \\
  {
    path: 'extended/data',
    component: ExtendedData,
    meta: {
      title: 'Extended Data Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':controldatatypeid',
        name: 'admin_extendeddata_detail',
        component: ExtendedData,
        meta: {
          title: 'Extended Data Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_extendeddata'],
        },
      },
      {
        path: '',
        name: 'admin_extendeddata',
        component: ExtendedData,
        meta: {
          title: 'Extended Data Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // Landing Page Maintenance
  // ======================================== \\
  {
    path: 'landing/page',
    component: LandingPageTemplate,
    meta: {
      title: 'Landing Page Template Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':landingpagetemplateid',
        name: 'admin_landingpagetemplate_detail',
        component: LandingPageTemplateForm,
        meta: {
          title: 'Landing Page Template Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_landingpagetemplate'],
        },
      },
      {
        path: ':landingpagetemplateid/layout',
        name: 'admin_landingpagetemplatelayout',
        component: LandingPageLayout,
        meta: {
          title: 'Landing Page Template Layout Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_landingpagetemplateupdate'],
        },
      },
      {
        path: '',
        name: 'admin_landingpagetemplate',
        component: LandingPageTemplateList,
        meta: {
          title: 'Landing Page Template Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },
  {
    path: 'landing/page/:landingpagetemplateid/:dashboarditemid',
    component: LandingPageFilterEdit,
    meta: {
      title: 'Landing Page Widget Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: 'filters',
        name: 'admin_landingpage_widget_filters',
        component: LandingPageFilter,
        meta: {
          title: 'Landing Page Widget Filter Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: [''],
        },
      },
      {
        path: '',
        component: LandingPageFilterSummary,
        name: 'admin_landingpage_widget',
        meta: {
          title: 'Landing Page Widget Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: [''],
        },
      },
    ],
  },

  // ======================================== \\
  // Alerts
  // ======================================== \\
  {
    path: 'alert',
    component: GenericRoute,
    meta: {
      title: 'Alert Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':alertid',
        name: 'admin_alert_detail',
        component: AlertDetail,
        meta: {
          title: 'Alert Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_alerts'],
        },
      },
      {
        path: '',
        name: 'admin_alerts',
        component: AlertList,
        meta: {
          title: 'Alert Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },
];
