<template>
  <div id="app" :class="'theme-' + themeNo">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
const default_layout = 'application';

export default {
  name: 'app',
  components: {},
  computed: {
    themeNo: function () {
      return this.$store.getters.getThemeNo;
    },
    layout: function () {
      return (this.$route?.meta?.layout ?? default_layout) + '-layout';
    },
  },
};
</script>
<style lang="less">
@import url('./assets/css/bootstrap-inc.less');
@import url('./assets/css/themes.less');
body {
  position: relative;
}
#app {
  font-family: 'ubuntulight', Century Gothic, CenturyGothic, AppleGothic, sans-serif;
  // font-family: 'Avenir', Helvetica, Arial, sans-serif;

  // @media screen and (max-width: 996px) {
  //   font-size: 0.52em;
  // }
}
</style>
