//import { createModule } from 'vuex-toast'
//import 'vuex-toast/dist/vuex-toast.css'

import Vue from 'vue';
import Vuex from 'vuex';

import untruncateJson from 'untruncate-json';
import { JwtFunctions } from '/src/mixins/JwtFunctions';

import { AQUAProductService } from '/src/services/aquaProduct';
import { UserService } from '/src/services/user';
import { PropertyService } from '/src/services/property';
import { PersonnelService } from '/src/services/personnel';
import { ErrorService } from '/src/services/error';
import { router } from './router';

const crypto = require('crypto');
const MultiTabStore = require('/src/plugins/multiTabStore');

const debugLog = (...args) => router.app.$options.methods.debugLog(...args);

Vue.use(Vuex);

export const store = new Vuex.Store({
  plugins: [MultiTabStore()],
  state: {
    inMaintenanceMode: false,

    // Authentication Level
    authenticationLevel: null,

    // MENU
    appMenuList: null,
    appMenuOpen: false,
    // ACCOUNT MENU
    accountMenuOpen: false,
    // SUB MENU
    subMenuItems: [],

    isMobile: false,

    currentDashboardTabs: [],
    dashboardQuickFilters: [],

    // APP MESSAGES
    appMessages: [],
    badgeMessages: [],

    // SYSTEM MESSAGES
    showMessagesFlag: false,

    // CURRENT USER DATA
    currentUser: null, // REQUIRED TO KEEP REACTIVE!!
    currentAreas: null, // REQUIRED TO KEEP REACTIVE!!
    loggedInUsers: null, // REQUIRED TO KEEP REACTIVE!!
    usersPersonnel: null,

    selectableAreas: null,

    // NOTES
    appNotes: {
      addNote: false,
      noteTable: '',
      noteID: null,
    },

    // APP CONTENT COVER
    appCover: 0,

    // APP LOADER
    appLoading: false,

    // Password Policy
    passwordPolicy: null,

    //==========================================================================================
    // API call counter.
    // This is an array that keeps a record of the total number of outstanding API calls.
    // Each array element is an object that consists of a unique window/tab identifier
    // and the current count of outstanding API calls. See /mixins/axios.js for implementation.
    //==========================================================================================
    apiCallCount: [],
    //==========================================================================================
    // Abort Controllers
    // Used to allow API calls to be aborted.
    // Contains: [
    //  {
    //    UID: '',
    //    endpoint: '',
    //    abortController: AbortController instance
    //  },
    //  ...
    // ]
    //==========================================================================================
    apiAbortControllers: [],
    //==========================================================================================

    // THEME
    themeNo: 1,

    // APP SCREEN FILTERS
    session_filter: null,

    userHistory: [],

    selectedAdminCategory: null,
    functionLockOverride: false,
    locationStore: [],

    aquaProductList: [],

    isGettingProperties: false,
    globalProperties: [],
    areaProperties: [],
    userProperties: [],
    roleProperties: [],

    userRoles: [],

    webLinks: [],

    sessionStore: [],
    sessionSummaryWeeksCount: 4,

    appFormData: [],

    userListData: [],
    userListCustomColumns: [],

    // Used by route/session/plans for calculating color values
    // Moved to the store to ensure consistent color display after filtered and changing screens
    // Accessing the data object directly should never be done, use the getData function instead!
    sessionPlanMetaData: {
      data: {},
      nextColorIndex: 0,
      getData: function (planUID) {
        // If the provided string isn't already in the data object >
        // add it and give it the next available color.
        if (!this.data[planUID]) {
          if (!Array.isArray(this.colors)) this.colors = this.colors.split(',');

          if (this.nextColorIndex > this.colors.length - 1) this.nextColorIndex = 0;

          this.data[planUID] = {
            color: this.colors[this.nextColorIndex++],
            isHovered: false,
          };
        }

        // return the data object
        return this.data[planUID];
      },
      clearData: function () {
        this.data = {};
        this.nextColorIndex = 0;
      },
      setIsHovered: function (planUID) {
        for (const [key, value] of Object.entries(this.data)) {
          value.isHovered = planUID ? key === planUID ?? '' : false;
        }
      },
      colors:
        '#64b5f6,#ffb74d,#bcaaa4,#b2dfdb,#9fa8da,#9575cd,#a5d6a7,#c5cae9,#e1bee7,#ff7043,#ffd54f,#29b6f6,#26c6da,#f8bbd0,#80cbc4,#ffcc80,#f0f4c3,#4dd0e1,#ffee58,#dcedc8,' +
        '#d4e157,#81c784,#ffab91,#f48fb1,#ffca28,#ffe082,#ff8a65,#ba68c8,#bbdefb,#c8e6c9,#80deea,#b2ebf2,#fff9c4,#ffe0b2,#66bb6a,#e6ee9c,#c5e1a5,#fff59d,#b39ddb,#90caf9,' +
        '#7986cb,#ffccbc,#42a5f5,#ef9a9a,#81d4fa,#26a69a,#4fc3f7,#f06292,#a1887f,#b3e5fc,#dce775,#4db6ac,#ce93d8,#aed581,#ffa726,#9ccc65,#d1c4e9,#e57373,#ffecb3,#ffcdd2,#fff176',
      // /\ The above array is a randomized list of the below color codes \/ \\
      // "#ffcdd2","#ef9a9a","#e57373", // RED
      // "#f8bbd0","#f48fb1","#f06292", // PINK
      // "#e1bee7","#ce93d8","#ba68c8", // PURPLE
      // "#d1c4e9","#b39ddb","#9575cd", // DEEP PURPLE
      // "#c5cae9","#9fa8da","#7986cb", // INDIGO
      // "#bbdefb","#90caf9","#64b5f6","#42a5f5", // BLUE
      // "#b3e5fc","#81d4fa","#4fc3f7","#29b6f6", // LIGHT BLUE
      // "#b2ebf2","#80deea","#4dd0e1","#26c6da", // CYAN
      // "#b2dfdb","#80cbc4","#4db6ac","#26a69a", // TEAL
      // "#c8e6c9","#a5d6a7","#81c784","#66bb6a", // GREEN
      // "#dcedc8","#c5e1a5","#aed581","#9ccc65", // LIGHT GREEN
      // "#f0f4c3","#e6ee9c","#dce775","#d4e157", // LIME
      // "#fff9c4","#fff59d","#fff176","#ffee58", // YELLOW
      // "#ffecb3","#ffe082","#ffd54f","#ffca28", // AMBER
      // "#ffe0b2","#ffcc80","#ffb74d","#ffa726", // ORANGE
      // "#ffccbc","#ffab91","#ff8a65","#ff7043", // DEEP ORANGE
      // "#bcaaa4","#a1887f", // BROWN
    },

    searchText: [],
    searchStopWords: null,

    reportParameters_unsaved: null,
  },
  getters: {
    getMaintenanceMode: (state) => {
      return state.inMaintenanceMode;
    },

    // Authentication Level
    getAuthenticationLevel: (state) => {
      return state.authenticationLevel != null ? state.authenticationLevel.AuthenticationLevelID : null;
    },

    // MENU
    allowedAppMenuList: (state) => {
      return state.appMenuList;
    },
    getAppMenuOpen: (state) => {
      return state.appMenuOpen;
    },
    // ACCOUNT MENU
    getAccountMenuOpen: (state) => {
      return state.accountMenuOpen;
    },
    // SUB MENU
    getSubMenuItems: (state) => {
      return state.subMenuItems;
    },

    getCurrentDashboardTab: (state) => (UID) => {
      return UID ? state.currentDashboardTabs.find((entry) => entry.UID === UID)?.currentTab : null;
    },
    dashboardQuickFilters: (state) => (UID) => {
      return UID ? state.dashboardQuickFilters.find((entry) => entry.UID === UID)?.filters : null;
    },

    // APP MESSAGES
    getAppMessages: (state) => {
      return state.appMessages;
    },
    lastAppMessage: (state) => {
      return state.appMessages[state.appMessages.length - 1];
    },

    // APP MESSAGES
    getBadges: (state) => {
      return state.badgeMessages;
    },

    // SYSTEM MESSAGES
    getShowMessages: (state) => {
      return state.showMessagesFlag;
    },
    getWebLinks: (state) => {
      return state.webLinks;
    },
    // NOTES
    // APP CONTENT COVER
    getAppCover: (state) => {
      return state.appCover > 0;
    },
    // APP LOADER
    getAppLoading: (state) => {
      return state.appLoading;
    },

    // Password Policy
    getPasswordPolicy: (state) => {
      return state.passwordPolicy;
    },

    // API call counter for the current window/tab
    currentAPICallCount: (state, getters) => {
      const windowUID = store.$browserStorage.aquaWindowUID;
      if (windowUID) {
        return getters.getAPICallCount(windowUID)?.count ?? 0;
      }
      return 0;
    },
    // API call counter by UID
    getAPICallCount: (state) => (UID) => {
      let callCount = state.apiCallCount.find((entry) => entry.UID === UID) ?? { UID, count: 0, URLs: [] };
      if (!callCount.URLs) callCount.URLs = [];
      return callCount;
    },
    // All API call counters
    getAPICallCounts: (state) => {
      return state.apiCallCount;
    },

    // API Abort Controllers
    abortControllers: (state) => {
      return state.apiAbortControllers;
    },
    // Get endpoint AbortController signal for specific tab/window
    getEndpointAbortController: (state) => (abortIdentifier) => {
      const abortInstance = state.apiAbortControllers.find((aac) => aac.UID === abortIdentifier.UID && aac.endpoint === abortIdentifier.endpoint);
      return abortInstance?.abortController ?? null;
    },
    // Get endpoint AbortController signal for specific tab/window
    getEndpointAbortSignal: (state) => (abortIdentifier) => {
      const abortInstance = state.apiAbortControllers.find((aac) => aac.UID === abortIdentifier.UID && aac.endpoint === abortIdentifier.endpoint);
      return abortInstance?.abortController?.signal ?? null;
    },

    // APP MESSAGES
    getIsMobile: (state) => {
      return state.isMobile;
    },

    // THEME
    getThemeNo: (state) => {
      return state.themeNo;
    },

    // APP SCREEN FILTERS
    getSessionFilter: (state) => {
      return state.session_filter;
    },

    fullSessionStore: (state) => {
      return state.sessionStore;
    },
    sessionStore: (state, getters) => {
      const currentArea = getters.currentArea;
      return state.sessionStore.filter(
        (s) =>
          s.AreaID == currentArea?.ID ||
          (currentArea?.Children?.length &&
            currentArea.Children.filter((c) => c.ChildEnabled)
              .map((c) => c.ChildAreaID)
              .includes(s.AreaID)) ||
          s.AutoEmergency
      );
    },

    getSessionSummaryWeeksCount: (state) => {
      return state.sessionSummaryWeeksCount;
    },

    userListData: (state) => {
      return state.userListData;
    },
    userListCustomColumns: (state) => (UID, listID) => {
      return UID ? state.userListCustomColumns.find((entry) => entry.UID === UID && entry.listID === listID)?.columns ?? [] : [];
    },

    getSelectedAdminCategory: (state) => {
      return state.selectedAdminCategory;
    },

    getFunctionLockOverride: (state) => {
      return state.functionLockOverride;
    },
    getAQUAProductList: (state) => {
      return new Promise(function (resolve) {
        if (state.aquaProductList == null || state.aquaProductList.length == 0) {
          AQUAProductService.getAQUAProduct({
            ProductEnum: null,
            Enabled: null,
          }).then((response) => {
            state.aquaProductList = response;
            resolve(state.aquaProductList);
          });
        } else {
          resolve(state.aquaProductList);
        }
      });
    },
    getCurrentUsersAccessibleAQUAProductList: (state, getters) => {
      let accessibleAQUAProductList = [];

      const user = state.currentUser;
      if (user) {
        state.aquaProductList.forEach((product) => {
          if (user.ProductEnum & product.ProductEnum) {
            accessibleAQUAProductList.push(product);
          }
        });
      }

      return accessibleAQUAProductList;
    },

    usersPersonnel: (state, getters) => {
      const user = state.currentUser;
      if (user && !state.usersPersonnel) {
        PersonnelService.getPersonnel({ PersonnelID: user.PersonnelID }).then((response) => {
          state.usersPersonnel = response;
          return state.usersPersonnel;
        });
      } else {
        return state.usersPersonnel;
      }
    },

    parseBool: (state) => (value) => {
      return router.app.$options.methods.parseBool(value);
    },

    // PROPERTIES
    getGlobalProperties:
      (state, getters) =>
      (forceUpdate = false) => {
        return new Promise(function (resolve) {
          if (state.isGettingProperties) {
            // added to limit the number of repeat calls made to getAllGlobalProperties
            const awaitProperties = () => {
              if (state.isGettingProperties) {
                setTimeout(awaitProperties, 500);
              } else {
                resolve(state.globalProperties);
              }
            };
            awaitProperties();
          } else {
            if (forceUpdate) {
              state.globalProperties = [];
            }
            if (!state.globalProperties || !state.globalProperties.length) {
              state.isGettingProperties = true;
              PropertyService.getAllGlobalProperties(!isLoggedIn()).then((response) => {
                const results = prepGlobalProperties(response, state.globalProperties);

                state.isGettingProperties = false;

                store.commit('UPDATE_GLOBAL_PROPERTIES', results);

                resolve(results);
              });
            } else {
              resolve(state.globalProperties);
            }
          }
        });
      },

    getGlobalProperty:
      (state, getters) =>
      (propertyID = null, propertyName = null) => {
        return new Promise(function (resolve) {
          getters.getGlobalProperties().then((properties) => {
            resolve(
              !propertyID && !propertyName
                ? properties
                : properties && properties.length
                  ? properties.find((p) => (propertyID != null && p.PropertyID == propertyID) || (propertyName != null && p.PropertyName == propertyName)) ?? null
                  : null
            );
          });
        });
      },

    currentUser: (state) => {
      // EXISTS IN STORE TO REMAIN REACTIVE
      return state.currentUser;
    },
    currentAreas: (state) => {
      // EXISTS IN STORE TO REMAIN REACTIVE
      return state.currentAreas;
    },
    currentArea: (state) => {
      // EXISTS IN STORE TO REMAIN REACTIVE
      return state.currentAreas?.find((v) => v.userID == state.currentUser?.ID && v.tabID == store.$browserStorage.aquaWindowUID)?.area;
    },
    loggedInUsers: (state) => {
      // EXISTS IN STORE TO REMAIN REACTIVE
      return state.loggedInUsers;
    },

    selectableAreas: (state) => {
      return state.selectableAreas;
    },

    getAreaProperties: (state, getters) => (areaID) => {
      return new Promise(function (resolve) {
        if (areaID) {
          let existingProps = null;
          if (state.areaProperties && state.areaProperties.length) {
            existingProps = state.areaProperties.find((area) => area.areaID === areaID);
          }

          if (!existingProps) {
            PropertyService.getAreaProperties(areaID).then((response) => {
              state.areaProperties.push({ areaID: areaID, properties: response });

              resolve(response);
            });
          } else {
            resolve(existingProps.properties);
          }
        } else {
          resolve([]);
        }
      });
    },

    getAreaProperty:
      (state, getters) =>
      (areaID, propertyID = null, propertyName = null) => {
        return new Promise(function (resolve) {
          if (areaID) {
            getters.getAreaProperties(areaID).then((properties) => {
              resolve(
                !propertyID && !propertyName
                  ? properties
                  : properties && properties.length
                    ? properties.find((p) => (propertyID != null && p.PropertyID == propertyID) || (propertyName != null && p.PropertyName == propertyName)) ?? null
                    : null
              );
            });
          } else {
            resolve([]);
          }
        });
      },

    getRoleProperties: (state) => (roleID) => {
      return new Promise(function (resolve) {
        if (roleID != null) {
          let existingProps = null;
          if (state.roleProperties && state.roleProperties.length) {
            existingProps = state.roleProperties.find((role) => role.roleID === roleID);
          }

          if (!existingProps) {
            PropertyService.getRoleProperties(roleID).then((response) => {
              state.roleProperties.push({ roleID: roleID, properties: response });

              resolve(response);
            });
          } else {
            resolve(existingProps.properties);
          }
        } else {
          resolve([]);
        }
      });
    },

    getRoleProperty:
      (state, getters) =>
      (roleID, propertyID = null, propertyName = null) => {
        return new Promise(function (resolve) {
          getters.getRoleProperties(roleID).then((properties) => {
            resolve(
              !propertyID && !propertyName
                ? properties
                : properties && properties.length
                  ? properties.find((p) => (propertyID != null && p.PropertyID == propertyID) || (propertyName != null && p.PropertyName == propertyName)) ?? null
                  : null
            );
          });
        });
      },

    getUserProperties: (state, getters) => (userID) => {
      return new Promise(function (resolve) {
        if (!userID) {
          const user = state.currentUser;
          if (user) {
            userID = user.ID;
          }
        }

        if (userID) {
          let existingProps = null;
          if (state.userProperties && state.userProperties.length) {
            existingProps = state.userProperties.find((up) => up.userID === userID);
          }

          if (!existingProps) {
            PropertyService.getUserProperties(userID).then((response) => {
              state.userProperties.push({ userID: userID, properties: response });

              resolve(response);
            });
          } else {
            resolve(existingProps.properties);
          }
        } else {
          resolve([]);
        }
      });
    },

    getUserProperty:
      (state, getters) =>
      (userID, propertyID = null, propertyName = null) => {
        return new Promise(function (resolve) {
          getters.getUserProperties(userID).then((properties) => {
            resolve(
              !propertyID && !propertyName
                ? properties
                : properties && properties.length
                  ? properties.find((p) => (propertyID != null && p.PropertyID == propertyID) || (propertyName != null && p.PropertyName == propertyName)) ?? null
                  : null
            );
          });
        });
      },

    userRoles: (state, getters) => (userID) => {
      return new Promise(function (resolve) {
        if (!userID) {
          const user = state.currentUser;
          if (user) {
            userID = user.ID;
          }
        }

        if (userID) {
          let existingRoles = null;
          if (state.userProperties && state.userProperties.length) {
            existingRoles = state.userRoles.find((ur) => ur.userID === userID);
          }

          if (!existingRoles) {
            UserService.getUserRolesDirect(userID).then((response) => {
              state.userRoles.push({ userID: userID, roles: response });

              resolve(response);
            });
          } else {
            resolve(existingRoles.roles);
          }
        } else {
          resolve([]);
        }
      });
    },

    // ====================================================================================================
    // Determines whether a user has rights to a property by examining the property in the following order:
    //  User -> Role -> Global || Area -> Global
    // ====================================================================================================
    hasBooleanPropertyAccessRights:
      (state, getters) =>
      (propertyID = null, propertyName = null) => {
        return new Promise(async (resolve) => {
          propertyID = propertyID ? parseInt(propertyID) : null;

          const user = getters.currentUser;
          const area = getters.currentArea;
          const userRoles = await getters.userRoles(user.ID);

          let rolePropertyResults = [];
          if (userRoles?.length) {
            userRoles.forEach(async (userRole) => {
              rolePropertyResults.push(await getters.getRoleProperty(userRole.RoleID, propertyID, propertyName));
            });
          }

          Promise.all([getters.getGlobalProperty(propertyID, propertyName), area ? getters.getAreaProperty(area.ID, propertyID, propertyName) : null, user ? getters.getUserProperty(user.ID, propertyID, propertyName) : null]).then(
            (results) => {
              if (results && results.length) {
                const globalAccess = results[0] ? getters.parseBool(results[0].Value) : false;
                const areaAccess = results[1] ? getters.parseBool(results[1].Value) : false;
                const userAccess = results[2] ? getters.parseBool(results[2].Value) : false;

                let roleAccess = false;
                rolePropertyResults.every((roleProp) => {
                  roleAccess = roleProp ? getters.parseBool(roleProp.Value) : false;
                  return !roleAccess; // Continue looping until roleAccess == true
                });

                resolve(userAccess || roleAccess || areaAccess || globalAccess);
              } else {
                resolve(false);
              }
            }
          );
        });
      },

    getSearchText: (state) => (UID) => {
      const result = state.searchText.find((entry) => entry.UID === UID);
      return result && result.value ? result.value : '';
    },

    getSearchStopWords: (state) => {
      return state.searchStopWords;
    },

    reportParameters_unsaved: (state) => {
      return state.reportParameters_unsaved;
    },
  },
  mutations: {
    CLEAN_STORE: (state) => {
      state.usersPersonnel = null;
      state.locationStore = [];
      state.appMessages = [];
      state.globalProperties = [];
      state.areaProperties = [];
      state.userProperties = [];
      state.roleProperties = [];
      state.userRoles = [];
      state.webLinks = [];
      state.sessionStore = [];
      state.appFormData = [];
      state.userListData = [];
      state.appMenuList = null;
    },

    SET_MAINTENANCE_MODE: (state, payload) => {
      state.inMaintenanceMode = payload;
    },

    // Authentication Level
    SET_AUTHENTICATION_LEVEL(state, payload) {
      state.authenticationLevel = payload;
    },

    // MENU
    UPDATE_ISMOBILE(state, payload) {
      state.isMobile = payload;
    },
    // MENU
    UPDATE_ALLOWED_APP_MENU_LIST(state, payload) {
      state.appMenuList = payload;
    },
    UPDATE_APP_MENU_OPEN(state, payload) {
      state.appMenuOpen = payload;
      state.appCover = payload ? state.appCover + 1 : state.appCover - 1;
      state.accountMenuOpen = false;
      if (state.appCover < 0) state.appCover = 0;
    },
    // ACCOUNT MENU
    UPDATE_ACCOUNT_MENU(state, payload) {
      state.accountMenuOpen = payload;
      state.appCover = payload ? state.appCover + 1 : state.appCover - 1;
      state.appMenuOpen = false;
      if (state.appCover < 0) state.appCover = 0;
    },
    // SUB MENU
    UPDATE_SUBMENU_ITEMS(state, payload) {
      state.subMenuItems = payload.subMenuItems;
    },

    UPDATE_CURRENTDASHBOARDTAB(state, payload) {
      let currentDashboardTab = state.currentDashboardTabs.find((entry) => entry.UID === payload.UID);
      if (currentDashboardTab) {
        // Update existing
        currentDashboardTab.currentTab = payload.currentTab;
      } else {
        // Add new
        state.currentDashboardTabs.push(payload);
      }
    },
    UPDATE_DASHBOARD_QUICKFILTERS(state, payload) {
      let windowFilters = state.dashboardQuickFilters.find((entry) => entry.UID === payload.UID);
      if (windowFilters) {
        // Update existing
        windowFilters.filters = { ...payload.filters };
      } else {
        // Add new
        state.dashboardQuickFilters.push(payload);
      }
    },

    UPDATE_CURRENT_USER(state, payload) {
      // EXISTS IN STORE TO REMAIN REACTIVE
      state.currentUser = payload;
      store.$browserStorage.currentUser = payload;
    },
    UPDATE_CURRENT_AREAS(state, payload) {
      // EXISTS IN STORE TO REMAIN REACTIVE
      state.currentAreas = payload;
      store.$browserStorage.currentAreas = payload;
    },
    UPDATE_USER_ACCESS(state, payload) {
      let user = state.currentUser;
      if (user) {
        user.accessRights = payload;
        store.$browserStorage.currentUser = user;
      }
    },

    UPDATE_LOGGED_IN_USERS(state, payload) {
      state.loggedInUsers = payload;
      store.$browserStorage.loggedInUsers = payload;
    },

    SET_SELECTABLE_AREAS(state, payload) {
      state.selectableAreas = payload;
    },

    // APP MESSAGES
    async ADD_APP_MESSAGE(state, payload) {
      if (payload) {
        const isNullOrEmpty = router.app.$options.methods.isNullOrEmpty;

        let isPersistentMessage = payload.isPersistentMessage ?? false;

        let _title = payload.title || '';
        let _description = payload.description || '';
        let _errorDetail = null;
        let isErrorMessage = false;
        let isNetworkError = false;
        let isSQLError = false;
        let jsonErrorData = '';

        if (payload.error) {
          let errorResponse = payload.error.response || payload.error.data;
          isErrorMessage = true;

          if (errorResponse) {
            let errorData = errorResponse.data || errorResponse;

            if (typeof errorData === 'string') {
              if (errorData.startsWith('{')) {
                errorData = JSON.parse(untruncateJson(errorData));
              } else {
                errorData = { ID: 500, error: errorData };
              }
            } else if (errorData instanceof Blob && errorData.type && errorData.type.toLowerCase().indexOf('json') != -1) {
              let blobError = await errorData.text();
              errorData = JSON.parse(blobError);
            }

            jsonErrorData = JSON.stringify(errorData).toLowerCase();
            isSQLError = jsonErrorData.includes('a network-related or instance-specific error occurred');
            if (!isNullOrEmpty(errorData?.description) && errorData.description.toLowerCase().includes('invalid credentials')) {
              _title = 'Login Failure';
            } else {
              _title = isSQLError || !errorData.title ? 'Error' : errorData.title;
            }

            _description = isSQLError ? 'The application failed to connect to the database, please contact your system administrator.' : errorData.description ?? 'Unrecognised error (please contact support)';

            isPersistentMessage = true;
            _errorDetail = errorData;

            // Check for large error data and truncate
            if (errorData.result) {
              // create a recursive depth limiter function
              const limitDepth = (jsonData, depth) => {
                let limitedJSON = {};

                if (depth < 5) {
                  let jsonKeys = Object.keys(jsonData);

                  jsonKeys.forEach((key) => {
                    let value = jsonData[key];
                    if (value !== null) {
                      if (Array.isArray(value)) {
                        let limitedValue = [];

                        value.forEach((json) => {
                          let result = limitDepth(json, depth + 1);
                          if (result) limitedValue.push(result);
                        });

                        limitedJSON[key] = limitedValue;
                      } else if (typeof value === 'object' && Object.keys(value)) {
                        limitedJSON[key] = limitDepth(value, depth);
                      } else {
                        limitedJSON[key] = value;
                      }
                    }
                  });
                } else {
                  limitedJSON = null;
                }
                return limitedJSON;
              };
              // execute depth limiter
              _errorDetail = limitDepth(errorData, 0);
            }
          } else {
            _title = !isNullOrEmpty(payload.error.title) ? payload.error.title : 'Error';
            _description = payload.error.message ?? payload.error.statusText ?? 'Unknown network error';

            if (typeof _description === 'object') {
              _description = _description.message;
            }

            isNetworkError = !isNullOrEmpty(_description) && _description.toLowerCase().includes('network error');
            if (isNullOrEmpty(_description) || isNetworkError) {
              _description = `${isNetworkError ? 'Unable to contact AQUA due to a network error.' : 'An unknown error has been recorded.'} Please ensure you are online, retry in a few minutes, or contact support if the problem persists.`;
              payload.doNotFireCreateError = isNetworkError;
            } else {
              _description += ' (please contact support)';
            }

            if (payload.error.toJSON) {
              _errorDetail = payload.error.toJSON();
            } else {
              _errorDetail = payload.error.stack ?? payload.error;
            }
            isPersistentMessage = true;
          }

          // Record error to the database
          if (!isSQLError && !jsonErrorData.includes('SqlClient Data Provider') && !isNetworkError && !payload.doNotFireCreateError) {
            let errorResponse = typeof _errorDetail === 'string' || _errorDetail instanceof String ? _errorDetail : JSON.stringify(_errorDetail);

            ErrorService.createError(state.currentUser, {
              ErrorSource: 'UI',
              ErrorNumber: 0,
              ErrorMessage: `(${_title}) ${_description}`,
              ErrorResponse: errorResponse,
            });
          }

          store.$AQUA_Timer?.cancelTimersOnError({ route_name: router.currentRoute.name });
        }

        if (!isNullOrEmpty(_title + _description)) {
          let iMessage = {
            uid: crypto ? crypto.randomBytes(32).toString('base64') : Math.floor(10000 + Math.random() * 100000 + 1),
            status: payload.status || 'warning',
            icon: payload.icon || 'warning',
            title: _title,
            description: _description,
            errorDetail: _errorDetail,
            isPersistentMessage: isPersistentMessage,
            canNotBeClosed: payload.canNotBeClosed,
            timeout: payload.timeout || 4000,
            hidden: false,
            timerID: null,
            isOnHold: isErrorMessage,
            onHoldSince: new Date(),
            isErrorMessage: isErrorMessage,
          };

          // Add new message to the message array unless it is a duplicate
          if (state.appMessages.length === 0 || !state.appMessages.some((m) => m.title == iMessage.title && m.description == iMessage.description)) {
            state.appMessages.push(iMessage);
          }

          // Removes message after timeout
          if (!isErrorMessage && !isPersistentMessage && iMessage.timeout > 0) {
            setTimeout(function () {
              if (state.appMessages.find((m) => m.uid == iMessage.uid)) {
                store.commit('REMOVE_APP_MESSAGE', iMessage);
              }
            }, iMessage.timeout);
          }
        }
      }
    },
    UPDATE_APP_MESSAGE(state, payload) {
      state.appMessages.splice(payload.index, 1, payload.appMessages);
    },
    REMOVE_APP_MESSAGE(state, payload) {
      if (!isNaN(payload)) {
        state.appMessages.splice(payload, 1);
      } else {
        const msg = isNaN(payload) ? state.appMessages.find((m) => m.uid == payload.uid) : null;
        if (msg) {
          state.appMessages.splice(state.appMessages.indexOf(msg), 1);
        }
      }
    },
    CLEAR_APP_MESSAGES(state) {
      state.appMessages = [];
    },
    REPLACE_APP_MESSAGES(state, payload) {
      state.appMessages = payload;
    },

    // LEADERBOARD BADGES
    ADD_BADGE_MESSAGE(state, payload) {
      // Stops duplicated message using the same title & description
      if (state.badgeMessages.length === 0) {
        state.badgeMessages.push(payload);
      } else {
        state.badgeMessages.map((m) => {
          if (m.title != payload.title && m.description != payload.description) {
            state.badgeMessages.splice(state.badgeMessages.length - 1, 1);
          }
        });
      }

      // Removes message after timeout
      if (payload.timeout > 0) {
        setTimeout(function () {
          state.badgeMessages.splice(state.badgeMessages.length - 1, 1);
        }, payload.timeout);
      }
    },
    REMOVE_BADGE_MESSAGE(state, payload) {
      state.badgeMessages.splice(isNaN(payload) ? state.appMessages.indexOf(payload) : payload, 1);
    },

    // SYSTEM MESSAGES
    UPDATE_SHOW_MESSAGES(state, payload) {
      state.showMessagesFlag = payload;
    },
    // NOTES
    TOGGLE_ADD_NOTE(state, payload) {
      state.appNotes = payload;
    },
    // APP CONTENT COVER
    UPDATE_APP_COVER(state, payload) {
      state.appCover = payload.appCover ? state.appCover + 1 : state.appCover - 1; //  payload.appCover;
      if (state.appCover < 0) state.appCover = 0;

      // .css('top', -(document.documentElement.scrollTop) + 'px')
      //    .addClass('noscroll');
    },
    // APP LOADER
    UPDATE_APP_LOADING(state, payload) {
      state.appLoading = payload.appLoading;
    },

    // Password Policy
    UPDATE_PASSWORD_POLICY(state, payload) {
      state.passwordPolicy = payload;
    },

    // API call counter
    UPDATE_API_CALL_COUNT(state, payload) {
      if (Array.isArray(payload)) {
        // Replace all counts
        state.apiCallCount = payload;
      } else if (payload.UID) {
        if (state.apiCallCount.find((entry) => entry.UID === payload.UID)) {
          // Update existing count
          state.apiCallCount.every((entry) => {
            if (entry.UID === payload.UID) {
              entry.count = payload.count;
              return false; // Break out of the loop
            }
            return true; // Continue looping
          });
        } else {
          // Add new count
          state.apiCallCount.push(payload);
        }
      }
    },

    // Add API Abort Controller
    ADD_API_ABORT_CONTROLLER(state, payload) {
      if (payload.UID) {
        let apiAbortEntry = state.apiAbortControllers.find((entry) => entry.UID === payload.UID && entry.endpoint === payload.endpoint);
        if (apiAbortEntry) {
          // Reset existing endpoint AbortController
          apiAbortEntry = new AbortController();
        } else {
          // Add new AbortController
          payload.abortController = new AbortController();
          state.apiAbortControllers.push(payload);
        }
      }
    },

    // Add API Abort Controller
    REMOVE_API_ABORT_CONTROLLER(state, payload) {
      if (payload.UID) {
        const apiAbortIndex = state.apiAbortControllers.findIndex((entry) => entry.UID === payload.UID && entry.endpoint === payload.endpoint);
        if (apiAbortIndex >= 0) {
          // Reset existing endpoint AbortController
          state.apiAbortControllers.splice(state.apiAbortControllers.indexOf(apiAbortIndex), 1);
        }
      }
    },
    // Abort all endpoints for current tab/window
    API_ABORT_ALL(state) {
      const windowUID = store.$browserStorage.aquaWindowUID;
      state.apiAbortControllers
        .filter((entry) => entry.UID == windowUID && entry.abortController)
        .forEach((ac) => {
          ac.abortController.abort();
        });

      state.apiAbortControllers = state.apiAbortControllers.filter((entry) => entry.UID != windowUID);
    },

    // THEME
    UPDATE_THEME_NO(state, payload) {
      state.themeNo = payload.themeNo;
    },

    // APP SCREEN FILTERS
    UPDATE_SESSION_FILTER(state, payload) {
      state.session_filter = payload;
    },

    UPDATE_SESSION_STORE(state, iSessionList) {
      // ideally find and update each sessionstore from sessionlist, or add if not available
      // !!! consideration of delete of data

      /*
      let totalSessions = 0;
      let newSessions = 0;
      let newPlans = 0;
      let updatedSessions = 0;
      let updatedPlans = 0;
      */

      if (iSessionList.sessions) {
        iSessionList.sessions.forEach((updatedsession) => {
          // console.table(updatedsession)
          if (updatedsession) {
            // totalSessions++;

            // ADHOC SESSION:
            //
            // IS THERE A SUPPLIED PLAN ID?
            if (!updatedsession.SessionPlanID) {
              // IF NO THEN ADHOC SESSION
              // CREATE/REPLACE STORE SESSION BY SESSIONID
              let adhocIndex = state.sessionStore.findIndex(
                (s) =>
                  (updatedsession.SessionRecordID && s.SessionRecordID == updatedsession.SessionRecordID) ||
                  (!updatedsession.SessionRecordID && updatedsession.LocationMaintenanceID && s.LocationMaintenanceID == updatedsession.LocationMaintenanceID && s.StartDate == updatedsession.StartDate)
              );

              if (adhocIndex < 0) {
                state.sessionStore.push(updatedsession);
                // newSessions++;
              } else {
                state.sessionStore.splice(adhocIndex, 1, { ...state.sessionStore[adhocIndex], ...updatedsession });
                // updatedSessions++;
              }
            } else {
              // PLAN ID SUPPLIED
              //
              // IS THERE A SUPPLIED SESSION ID?
              //if (!updatedsession.SessionRecordID) {
              //
              // STILL A PLAN:
              // IF NO CREATE/REPLACE STORE PLAN BY PLANID AND START DATE
              let planIndex = state.sessionStore.findIndex((s) => s.SessionPlanID == updatedsession.SessionPlanID && s.StartDate == updatedsession.StartDate);
              if (planIndex < 0) {
                state.sessionStore.push(updatedsession);
                // newSessions++;
              } else {
                state.sessionStore.splice(planIndex, 1, { ...state.sessionStore[planIndex], ...updatedsession });
                // updatedSessions++;
              }
              //} else {

              // IF YES WAS IT A PLAN?
              // FIND STORE SESSION BY PLANID AND STARTDATE
              // IF STORE PLAN AVAILABLE REPLACE
              // IF NO STORE PLAN CREATE STORE SESSION

              //}
            }

            // if (updatedsession.SessionRecordID) {
            //   // IS SESSION

            //   // FIND ITSELF
            //   let planIndex = state.sessionStore.findIndex(s => s.SessionRecordID == updatedsession.SessionRecordID && s.SessionPlanID == updatedsession.SessionPlanID && s.StartDate == updatedsession.StartDate);
            //   if (planIndex != -1) {

            //     console.log('FOUND:', updatedsession.SessionRecordID + '==' + state.sessionStore[planIndex].SessionRecordID + '-' + state.sessionStore[planIndex].SessionPlanID + '-' + state.sessionStore[planIndex].StartDate);

            //     // IF SESSION WAS A PLAN/EXISTING ADHOC SESSION
            //     let updatedSession = { ...state.sessionStore[planIndex], ...updatedsession }
            //     state.sessionStore.splice(planIndex, 1, updatedSession);
            //     updatedSessions++;
            //   } else {

            //     let planIndex = state.sessionStore.findIndex(s => !s.SessionRecordID && s.SessionPlanID == updatedsession.SessionPlanID && s.StartDate == updatedsession.StartDate);
            //     if (planIndex != -1) {
            //       // FOUND PREVIOUS PLAN INFORMATION
            //       let updatedSession = { ...state.sessionStore[planIndex], ...updatedsession }
            //       state.sessionStore.splice(planIndex, 1, updatedSession);
            //       updatedSessions++;

            //     } else {
            //       // NEW SESSION
            //       state.sessionStore.push(updatedsession);
            //       newSessions++;
            //     }

            //   }
            // } else {
            //   // PLAN
            //   let planIndex = state.sessionStore.findIndex(s => s.SessionPlanID == updatedsession.SessionPlanID && s.StartDate == updatedsession.StartDate);
            //   if (planIndex != -1) {
            //     // EXISTING PLAN
            //     let updatedSession = { ...state.sessionStore[planIndex], ...updatedsession }
            //     state.sessionStore.splice(planIndex, 1, updatedSession);
            //     updatedPlans++;
            //   } else {
            //     // NEW PLAN
            //     state.sessionStore.push(updatedsession);
            //     newPlans++;
            //   }
            // }
          }
        });
        state.sessionStore.sort((a, b) => (a.PlannedSessionStartTime > b.PlannedSessionStartTime ? 1 : -1));
      }
      /* 
      // console.log('===================================');
      if (newSessions) // console.log('new sessions', newSessions);
      if (newPlans) // console.log('new plans', newPlans);
      if (updatedSessions) // console.log('refound sessions', updatedSessions);
      if (updatedPlans) // console.log('refound plans', updatedPlans);
      // console.log('TOTAL STORE UPDATES', totalSessions);
      // console.log('==================================='); */
    },
    DELETE_SESSION_STORE(state, iSessionList) {
      iSessionList.forEach((sl) => {
        const sIndex = sl.SessionRecordID
          ? state.sessionStore.findIndex((s) => s.SessionRecordID == sl.SessionRecordID)
          : sl.SessionPlanID && sl.StartDate
            ? state.sessionStore.findIndex((s) => s.SessionPlanID == sl.SessionPlanID && s.StartDate == sl.StartDate)
            : sl.LocationMaintenanceID && sl.StartDate
              ? state.sessionStore.findIndex((s) => s.LocationMaintenanceID == sl.LocationMaintenanceID && s.StartDate == sl.StartDate)
              : -1;

        if (sIndex != -1) {
          state.sessionStore.splice(sIndex, 1);
        }
      });
    },
    DELETE_SESSION_PLAN_STORE(state, sessionPlanIDs) {
      state.sessionStore = state.sessionStore.filter((s) => !sessionPlanIDs.includes(s.SessionPlanID));
    },
    DELETE_MAINTENANCE_PLAN_STORE(state, maintenancePlanIDs) {
      state.sessionStore = state.sessionStore.filter((s) => !maintenancePlanIDs.includes(s.LocationMaintenanceID));
    },
    CLEAR_SESSION_STORE(state) {
      state.sessionStore = [];
    },

    UPDATE_SESSIONSUMMARYWEEKSCOUNT(state, payload) {
      state.sessionSummaryWeeksCount = payload;
    },

    UPDATE_USERLISTDATA(state, payload) {
      // console.log(payload.userlists);

      let foundLstIdx = state.userListData.findIndex((l) => l.listid == payload.listid);
      if (foundLstIdx < 0) {
        state.userListData.push(payload);
      } else {
        state.userListData.splice(foundLstIdx, 1, payload);
      }
    },
    UPDATE_USERLISTCUSTOMCOLUMNS(state, payload) {
      let windowColumns = state.userListCustomColumns.find((entry) => entry.UID === payload.UID && entry.listID === payload.listID);
      if (windowColumns) {
        // Update existing
        windowColumns.columns = [ ...payload.columns ];
      } else {
        // Add new
        state.userListCustomColumns.push(payload);
      }
    },

    UPDATE_APPFORMDATA_STORE(state, iFormData) {
      let stateAppFormIndex = state.appFormData.findIndex((fd) => fd.Form == iFormData.Form);
      if (stateAppFormIndex != -1) {
        let updatedAppForm = { ...state.appFormData[stateAppFormIndex], ...iFormData };
        Vue.set(state.appFormData, stateAppFormIndex, updatedAppForm);
      } else {
        state.appFormData.push(iFormData);
      }
    },

    UPDATE_USER_HISTORY(state, iSession) {},

    UPDATE_SELECTEDADMINCATEGORY_STORE(state, payload) {
      state.selectedAdminCategory = payload;
    },

    UPDATE_FUNCTIONLOCKOVERRIDE_STORE(state, payload) {
      state.functionLockOverride = payload;
    },
    UPDATE_LOCATION_STORE(state, iLocations) {
      state.locationStore = iLocations;
    },
    UPDATE_AQUA_PRODUCT_LIST(state, payload) {
      state.aquaProductList = payload;
    },
    UPDATE_GLOBAL_PROPERTIES_WITH_PREP(state, payload) {
      state.globalProperties = prepGlobalProperties(payload);
    },
    UPDATE_GLOBAL_PROPERTIES(state, payload) {
      state.globalProperties = payload;
    },
    UPDATE_USER_PROPERTIES(state, payload) {
      payload.forEach((prop) => {
        if (prop && prop.PropertyID) {
          // Convert Boolean types to actual Booleans
          if (prop.ValueType == 'Boolean') {
            prop.Value = store.getters.parseBool(prop.Value);
          }

          let userProps = store.getters.getUserProperties(prop.UserID);

          if (userProps && userProps.length) {
            let propIndx = userProps.properties.findIndex((up) => up.PropertyID == prop.PropertyID);

            if (propIndx < 0) {
              userProps.properties.push(prop);
            } else {
              let updatedProp = { ...userProps.properties[propIndx], ...prop };
              userProps.properties.splice(propIndx, 1, updatedProp);
            }
          }
        }
      });
    },
    UPDATE_WEBLINKS(state, payload) {
      if (!payload) return;
      if (!Array.isArray(payload)) payload = [payload];
      payload.forEach((p) => {
        let foundRec = state.webLinks.find((gp) => gp.WebLinkID == p.WebLinkID);
        if (!foundRec) state.webLinks.push(p);
      });
    },

    UPDATE_SEARCHTEXT(state, payload) {
      if (Array.isArray(payload)) {
        state.searchText = payload;
      } else if (payload.UID) {
        let existing = state.searchText.find((entry) => entry.UID === payload.UID);
        if (existing) {
          // Update existing
          existing.value = payload.value;
        } else {
          // Add new count
          state.searchText.push(payload);
        }
      }
    },

    UPDATE_SEARCH_STOPWORDS: (state, payload) => {
      state.searchStopWords = payload;
    },

    UPDATE_REPORT_PARAMETERS: (state, payload) => {
      state.reportParameters_unsaved = payload;
    },
  },
  actions: {
    cleanStore: (state) => {
      state.commit('CLEAN_STORE');
    },

    setMaintenanceMode: (state, payload) => {
      state.commit('SET_MAINTENANCE_MODE', payload);
    },

    // Authentication Level
    setAuthenticationLevel(state, payload) {
      state.commit('SET_AUTHENTICATION_LEVEL', payload);
    },

    // MENU
    updateIsMobile(state, payload) {
      state.commit('UPDATE_ISMOBILE', payload);
    },
    // MENU
    updateAllowedAppMenuList(state, payload) {
      state.commit('UPDATE_ALLOWED_APP_MENU_LIST', payload);
    },
    updateAppMenuOpen(state, payload) {
      state.commit('UPDATE_APP_MENU_OPEN', payload);
    },
    // ACCOUNT MENU
    updateAccountMenu(state, payload) {
      state.commit('UPDATE_ACCOUNT_MENU', payload);
    },
    // SUB MENU
    updateSubMenuItems(state, payload) {
      state.commit('UPDATE_SUBMENU_ITEMS', payload);
    },

    updateCurrentDashboardTab(state, payload) {
      const windowUID = store.$browserStorage.aquaWindowUID;
      if (windowUID) {
        state.commit('UPDATE_CURRENTDASHBOARDTAB', { UID: windowUID, currentTab: payload });
      }
    },
    updateDashboardQuickFilters(state, payload) {
      const windowUID = store.$browserStorage.aquaWindowUID;
      if (windowUID) {
        state.commit('UPDATE_DASHBOARD_QUICKFILTERS', { UID: windowUID, filters: { ...payload } });
      }
    },

    // APP MESSAGES
    addAppMessage(state, payload) {
      state.commit('ADD_APP_MESSAGE', payload);
    },
    updateAppMessage(state, payload) {
      state.commit('UPDATE_APP_MESSAGE', payload);
    },
    removeAppMessage(state, payload) {
      state.commit('REMOVE_APP_MESSAGE', payload);
    },
    clearAppMessages(state) {
      state.commit('CLEAR_APP_MESSAGES');
    },
    replaceAppMessages(state, payload) {
      state.commit('REPLACE_APP_MESSAGES', payload);
    },

    updateCurrentUser(state, payload) {
      // EXISTS IN STORE TO REMAIN REACTIVE
      state.commit('UPDATE_CURRENT_USER', payload);
    },
    updateCurrentAreas(state, payload) {
      // EXISTS IN STORE TO REMAIN REACTIVE
      state.commit('UPDATE_CURRENT_AREAS', payload);
    },
    updateUserAccess(state, payload) {
      state.commit('UPDATE_USER_ACCESS', payload);
    },
    updateLoggedInUsers(state, payload) {
      // EXISTS IN STORE TO REMAIN REACTIVE
      state.commit('UPDATE_LOGGED_IN_USERS', payload);
    },

    setSelectableAreas(state, payload) {
      state.commit('SET_SELECTABLE_AREAS', payload);
    },

    // BADGES

    addBadgeMessage(state, payload) {
      let _title = payload.title || '';
      let _description = payload.description || '';

      if (payload.error) {
        if (payload.error.response) {
          _title = payload.error.response.data.title;
          _description = payload.error.response.data.description;
        } else {
          _title = 'Error';
          _description = payload.error.message + ' (please contact support)';
        }
      }

      let iMessage = {
        status: payload.status || 'warning',
        icon: payload.icon || 'warning',
        title: _title,
        description: _description,
        timeout: payload.timeout || 3000,
      };
      state.commit('ADD_BADGE_MESSAGE', iMessage);
    },
    removeBadgeMessage(state, payload) {
      state.commit('REMOVE_BADGE_MESSAGE', payload);
    },

    // SYSTEM MESSAGES
    updateShowMessages(state, payload) {
      state.commit('UPDATE_SHOW_MESSAGES', payload);
    },
    // NOTES
    toggleAddNote(state, payload) {
      state.commit('TOGGLE_ADD_NOTE', payload);
      state.commit('UPDATE_APP_COVER', payload?.addNote ?? false);
    },
    // APP CONTENT COVER
    updateAppCover(state, payload) {
      state.commit('UPDATE_APP_COVER', payload);
    },
    // APP LOADER
    updateAppLoading(state, payload) {
      state.commit('UPDATE_APP_LOADING', payload);
    },

    // Password Policy
    updatePasswordPolicy(state, payload) {
      state.commit('UPDATE_PASSWORD_POLICY', payload);
    },

    // API call counter - reset to zero
    resetAPICallCount(state) {
      const windowUID = store.$browserStorage.aquaWindowUID;
      if (windowUID) {
        state.commit('UPDATE_API_CALL_COUNT', { UID: windowUID, count: 0 });
      }
    },
    // API call counter - increment
    incrementAPICallCount({ commit, getters }, url) {
      const windowUID = store.$browserStorage.aquaWindowUID;
      if (windowUID) {
        let apiCallCount = getters.getAPICallCount(windowUID);

        // debugLog(`increment count: ${apiCallCount.count} -> ${apiCallCount.count + 1} [ ${url} ]`);

        apiCallCount.count++;
        apiCallCount.URLs.push(url);

        commit('UPDATE_API_CALL_COUNT', apiCallCount);
      }
    },
    // API call counter - decrement
    decrementAPICallCount({ commit, getters }, url) {
      const windowUID = store.$browserStorage.aquaWindowUID;
      if (windowUID) {
        let apiCallCount = getters.getAPICallCount(windowUID);

        const urlIndex = apiCallCount.URLs.lastIndexOf(url);
        if (urlIndex >= 0) {
          // debugLog(`decrement count: ${apiCallCount.count} -> ${apiCallCount.count - 1} [ ${url} ]`);

          apiCallCount.count--;
          apiCallCount.URLs.splice(urlIndex, 1);

          commit('UPDATE_API_CALL_COUNT', apiCallCount);
        }
      }
    },

    // Add Abort Controller for specific endpoint
    addAbortController(state, payload) {
      state.commit('ADD_API_ABORT_CONTROLLER', payload);
    },
    // Add Abort Controller for specific endpoint
    removeAbortController(state, payload) {
      state.commit('REMOVE_API_ABORT_CONTROLLER', payload);
    },
    // Abort specific endpoint for current tab/window
    abortEndpoint({ getters }, endpoint) {
      let abortController = getters.getEndpointAbortController({
        UID: store.$browserStorage.aquaWindowUID,
        endpoint: endpoint,
      });
      if (abortController) {
        // console.log('AQUA: 🛒 Abort endpoint - ' + ac.endpoint);
        abortController.abort();
      }
    },
    // Abort all endpoints
    abortAllEndpoints({ commit }) {
      commit('API_ABORT_ALL');
    },

    // THEME
    updateTheme(state, payload) {
      state.commit('UPDATE_THEME_NO', payload);
    },

    updateUserListData(state, payload) {
      state.commit('UPDATE_USERLISTDATA', payload);
    },
    updateUserListCustomColumns(state, payload) {
      const windowUID = store.$browserStorage.aquaWindowUID;
      if (windowUID) {
        state.commit('UPDATE_USERLISTCUSTOMCOLUMNS', { UID: windowUID, listID: payload.listID, columns: [ ...payload.columns ] });
      }
    },


    // APP SCREEN FILTERS
    updateSessionFilter(state, payload) {
      state.commit('UPDATE_SESSION_FILTER', payload);
    },

    updateSessionStore(state, iSessions) {
      state.commit('UPDATE_SESSION_STORE', iSessions);
    },
    deleteSessionStore(state, iSessions) {
      //console.log('deleteSessionStore', iSessions);
      state.commit('DELETE_SESSION_STORE', iSessions);
    },
    deleteSessionPlanStore(state, sessionPlans) {
      state.commit('DELETE_SESSION_PLAN_STORE', sessionPlans);
    },
    deleteMaintenancePlanStore(state, maintenancePlans) {
      state.commit('DELETE_MAINTENANCE_PLAN_STORE', maintenancePlans);
    },
    clearSessionStore(state) {
      state.commit('CLEAR_SESSION_STORE');
    },

    setSessionSummaryWeeksCount(state, payload) {
      state.commit('UPDATE_SESSIONSUMMARYWEEKSCOUNT', payload);
    },

    updateAppFormStore(state, iFormData) {
      state.commit('UPDATE_APPFORMDATA_STORE', iFormData);
    },

    updateSelectedAdminCategory(state, payload) {
      state.commit('UPDATE_SELECTEDADMINCATEGORY_STORE', payload);
    },

    updateFunctionLockOverride(state, payload) {
      state.commit('UPDATE_FUNCTIONLOCKOVERRIDE_STORE', payload);
    },
    // LOCATIONS
    updateLocationStore(state, iLocations) {
      state.commit('UPDATE_LOCATION_STORE', iLocations);
    },
    // AQUA PRODUCT LIST
    updateAQUAProductList(state, payload) {
      state.commit('UPDATE_AQUA_PRODUCT_LIST', payload);
    },
    // GLOBAL PROPERTIES
    updateGlobalProperties(state, payload) {
      state.commit('UPDATE_GLOBAL_PROPERTIES_WITH_PREP', payload);
    },
    updateWebLinks(state, payload) {
      state.commit('UPDATE_WEBLINKS', payload);
    },
    // USER PROPERTIES
    updateUserProperties(state, payload) {
      state.commit('UPDATE_USER_PROPERTIES', payload);
    },

    setSearchText(state, payload) {
      state.commit('UPDATE_SEARCHTEXT', payload);
    },

    clearSearchText(state) {
      state.commit('UPDATE_SEARCHTEXT', []);
    },

    updateSearchStopWords(state, payload) {
      state.commit('UPDATE_SEARCH_STOPWORDS', payload);
    },

    updateReportParameters: (state, payload) => {
      state.commit('UPDATE_REPORT_PARAMETERS', payload);
    },
  },
});

const prepGlobalProperties = (payload, globalProperties = []) => {
  let results = globalProperties.slice();

  payload.forEach((prop) => {
    if (prop && prop.PropertyID) {
      // Convert Boolean types to actual Booleans
      if (prop.ValueType == 'Boolean') {
        prop.Value = store.getters.parseBool(prop.Value);
      }

      const propIndx = results.findIndex((gp) => gp.PropertyID == prop.PropertyID);
      if (propIndx < 0) {
        results.push(prop);
      } else {
        const updatedProp = { ...results[propIndx], ...prop };
        results.splice(propIndx, 1, updatedProp);
      }
    }
  });

  return results;
};

const isLoggedIn = () => {
  const user = store.$browserStorage.currentUser;
  if (user) {
    // Decode the access token
    const jwt = JwtFunctions.decodeAccessToken(user);
    if (jwt) {
      // Check to see if the current users access token has or is due to expire
      let expiryDate = new Date(0);
      expiryDate.setUTCSeconds(jwt.exp);

      return expiryDate > new Date();
    }
  }

  return false;
};
