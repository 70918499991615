const axios = require('axios');

export const PersonnelService = {
  PersonnelList: [],

  //PERSONNEL
  getPersonnel: function (
    data = {
      Filter: null,
      PersonnelID: null,
      PersonnelRoleID: null,
      PersonnelCode: null,
      GMCCode: null,
      PersonnelTypeEnum: null,
      Enabled: null,
    }
  ) {
    //const user = this.currentUser

    return axios
      .post(
        // URL
        'Personnel',
        data
      )
      .then((response) => {
        return response.data.result;
      });
  },

  deletePersonnelRec: function (iPersonnelID) {
    return axios.delete('Personnel/delete', { data: { PersonnelID: iPersonnelID } }).then((response) => {
      return response.data.result;
    });
  },

  getPersonnelSchema: function (iPersonnelID, iPersonnelCode) {
    //const user = this.currentUser

    return axios
      .post(
        // URL
        'Personnel/schema',
        {
          PersonnelID: iPersonnelID,
          PersonnelCode: iPersonnelCode,
        }
      )
      .then((response) => {
        return response.data.result;
      });
  },

  getPersonnelByFilter: function (iFilter) {
    return axios.post('Personnel', { Filter: iFilter }).then((res) => {
      return res;
    });
  },

  getPersonnelByID: function (iPersonnelID) {
    //const user = this.currentUser

    if (iPersonnelID) {
      return axios
        .post(
          // URL
          'Personnel/find/byIDs',
          {
            PersonnelIDs: iPersonnelID.toString().split(',').map(Number),
          }
        )
        .then((res) => {
          return res.data.result;
        });
    }
  },

  getPersonnelRec: function (iPersonnelID) {
    //const user = this.currentUser

    // console.log('getPersonnelByID', iPersonnelID);

    if (iPersonnelID) {
      return axios
        .post(
          // URL
          'Personnel/find',
          {
            PersonnelID: iPersonnelID,
          }
        )
        .then((res) => {
          return res.data.result;
        });
    }
  },

  updatePersonnelSchema: function (iPersonnelObj) {
    return axios
      .put(
        // URL
        'Personnel/schema/update',
        iPersonnelObj
      )
      .then((res) => {
        return res.data.result;
      });
  },

  getPersonnelByRole: function (
    data = {
      Filter: null,
      PersonnelRoleID: null,
      PersonnelTypeEnum: null,
    }
  ) {
    return axios
      .post(
        // URL
        'Personnel/find/byrole',
        data
      )
      .then((res) => {
        return res;
      });
  },

  updatePersonnel: function (iPersonnel) {
    if (iPersonnel) {
      return axios.put('personnel/update', iPersonnel).then((response) => {
        return response.data.result;
      });
    }
  },

  deletePersonnel: function (iGradeID) {
    return axios
      .delete('Personnel/grade/delete', {
        data: {
          PersonnelGradeID: iGradeID,
        },
      })
      .then((res) => {
        return {
          resultType: 'success',
          resultText: 'Deleted Successfully',
          resultID: iGradeID,
        };
      });
  },

  getConsultants: function () {
    return this.getPersonnel({ IsConsultant: true }).then((response) => {
      return response;
    });
  },

  //TEMPLATE
  getTemplate: function (data = { Enabled: null }) {
    return axios.post('Personnel/template', data).then((res) => {
      return res.data.result;
    });
  },
  getPersonnelTemplate: function (data = { Enabled: true }) {
    return axios.post('Personnel/template', data).then((res) => {
      return res.data.result;
    });
  },

  getTemplateRoles: function (iPersonnelID) {
    return axios.post('Personnel/template/role', { PersonnelTemplateID: iPersonnelID }).then((res) => {
      return res.data.result;
    });
  },

  deleteTemplate: function (PersonnelTemplateID) {
    return axios
      .delete('Personnel/template/delete', { data: { PersonnelTemplateID: PersonnelTemplateID } })
      .then((res) => {
        return res.data.result;
      });
  },

  updatePersonnelTemplateRole: function (iTemplateRec) {
    return axios.put('Personnel/template/role/update', { ...iTemplateRec }).then((response) => {
      return response.data.result;
    });
  },

  bulkUpdatePersonnelTemplateRole: function (iUpdateObj) {
    return axios.put('Personnel/template/role/bulk/update', iUpdateObj).then((response) => {
      return response.data.result;
    });
  },
  // createPersonnelTemplate: function (iTemplateRec) {
  //   return axios.post('Personnel/template/create', iTemplateRec).then((response) => {
  //     return response.data.result;
  //   });
  // },

  updatePersonnelTemplate: function (iTemplateRec) {
    return axios.put('Personnel/template/update', iTemplateRec).then((response) => {
      return response.data.result;
    });
  },

  getPersonnelTemplateRole: function (iPersonnelTemplateID) {
    return axios.post('Personnel/template/role', { PersonnelTemplateID: iPersonnelTemplateID }).then((res) => {
      return res.data.result;
    });
  },

  //DESIGNATIONS
  getDesignation: function (data = { Enabled: null }) {
    return axios
      .post('Personnel/designation', data)
      .then((res) => {
        return res.data.result;
      })
      .catch(function (error) {
        return error;
      });
  },
  updateDesignation: function (iDesignationRec) {
    return axios.put('Personnel/designation/update', iDesignationRec).then((response) => {
      return response.data.result;
    });
  },

  deleteDesignation: function (iPersonnelDesignationID) {
    return axios
      .delete('Personnel/designation/delete', {
        data: {
          PersonnelDesignationID: iPersonnelDesignationID,
        },
      })
      .then((response) => {
        return response.data.result;
      });
  },

  //GRADES
  getGrade: function (data = { Enabled: null }) {
    return axios.post('Personnel/grade', data).then((response) => {
      return response.data.result;
    });
  },

  updatePersonnelGrade: function (iGradeRec) {
    return axios.put('Personnel/grade/update', iGradeRec).then((response) => {
      return response.data.result;
    });
  },

  deletePersonnelGrade: function (iPersonnelGradeID) {
    return axios
      .delete('Personnel/grade/delete', { data: { PersonnelGradeID: iPersonnelGradeID } })
      .then((response) => {
        return response.data.result;
      });
  },

  //BANDS
  personnelBand: function (data = { Enabled: null }) {
    return axios.post('Personnel/band', data).then((res) => {
      return res.data.result;
    });
  },

  updatePersonnelBand: function (iBandRec) {
    return axios.put('Personnel/band/schema/update', iBandRec).then((response) => {
      return response.data.result;
    });
  },

  bandRateUpdate: function (rates) {
    return axios.put('Personnel/band/rate/update', { ...rates }).then((res) => {
      return res;
    });
  },

  getBandRate: function (data = { Enabled: null }) {
    return axios.post('Personnel/band/rate', data).then((response) => {
      return response.data.result;
    });
  },

  getBandSchemaRates: function (iBandID) {
    return axios.post('Personnel/band/schema', { BandID: iBandID }).then((res) => {
      return res.data.result;
    });
  },

  deleteBandRate: function (iBandRateID) {
    return axios.delete('Personnel/band/rate/delete', { data: { BandRateID: parseInt(iBandRateID) } }).then((res) => {
      return res.data.result;
    });
  },

  deleteBand: function (iBandID) {
    return axios.delete('Personnel/band/delete', { data: { BandID: parseInt(iBandID) } }).then((res) => {
      return res.data.result;
    });
  },

  updateBand: function (iBandRec) {
    return axios.put('Personnel/band/update', iBandRec).then((response) => {
      return response.data.result;
    });
  },

  //ROLES
  getRoleDesignations: function (iRoleID) {
    return axios.post('Personnel/role/schema', { PersonnelRoleID: iRoleID }).then((res) => {
      return res.data.result;
    });
  },

  personnelRoleUpdate: function (iRole) {
    return axios
      .put('Personnel/role/schema/update', {
        ...iRole,
      })
      .then((response) => {
        return response.data.result;
      });
  },

  deletePersonnelRole: function (iRoleId) {
    return axios
      .delete('Personnel/role/delete', {
        data: {
          PersonnelRoleID: iRoleId,
        },
      })
      .then((res) => {
        return {
          resultType: 'success',
          resultText: 'Deleted Successfully',
          resultID: iRoleId,
        };
      });
  },

  getRole: function (data = { Enabled: null }) {
    return axios.post('Personnel/role', data).then((res) => {
      return res.data.result;
    });
  },

  //Specialty
  getSpecialty: function (data) {
    return axios.post('Personnel/specialty', data).then((response) => {
      return response.data.result;
    });
  },

  //
  // PERSONNEL MOVEMENT
  //

  personnelLeave: function (
    iObject = {
      ActualPersonnelID: null,
      LeaveTime: null,
    }
  ) {
    return axios
      .put(
        // URL
        'Session/actual/personnel/left',
        iObject
      )
      .then((res) => {
        return res.data.result;
      });
  },
  personnelArrive: function (
    iObject = {
      ActualPersonnelID: null,
      LeaveTime: null,
    }
  ) {
    return axios
      .put(
        // URL
        'Session/actual/personnel/arrived',
        iObject
      )
      .then((res) => {
        return res.data.result;
      });
  },

  //
  // PATIENT TEAMS
  //

  getPersonnelTeams: function (
    iObject = {
      TeamID: null,
      OperationRecordID: null,
      PatientActualID: null,
    }
  ) {
    return axios
      .post(
        // URL
        'Patient/actual/team',
        iObject
      )
      .then((res) => {
        return res.data.result;
      });
  },
  updatePersonnelTeam: function (iObject = {}) {
    return axios
      .put(
        // URL
        'Patient/actual/team/update',
        iObject
      )
      .then((res) => {
        return res.data.result;
      });
  },
  deletePersonnelTeam: function (iTeamID = null) {
    return axios
      .delete('Patient/actual/team/delete', {
        data: {
          TeamID: iTeamID,
        },
      })
      .then((res) => {
        return res.data.result;
      });
  },
  personnelProcedureTime: function (
    sendData = {
      PersonnelID: null,
      ProcedureCodes: null,
    }
  ) {
    return axios.post('Personnel/procedureTime', sendData).then((res) => {
      return res.data.result;
    });
  },

  personnelProcedures: function (sendData = {}) {
    return axios.post('Personnel/procedures', sendData).then((res) => {
      return res.data.result;
    });
  },

  getPersonnelTypes: function (iData) {
    let sendData = { PersonnelTypeEnum: iData };
    return axios.post('Personnel/type', sendData).then((response) => {
      return response.data.result;
    });
  },
};
