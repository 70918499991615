<template>
  <div id="SessionTimeTemplate">
    <div class="tile">
      <ContentLoading displaystyle="boxed" />
      <div class="tilebody">
        <div class="tileheaderdescription">
          <div class="icon timespan"></div>
          <h2>Session Time Details</h2>
          <h4>Select the session duration and identifier</h4>
        </div>
      </div>
      <div class="tilebody" v-if="SessionRecord">
        <div class="form">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group isrequired" :class="SessionRecord.TimeTemplateID && SessionRecord.TimeTemplateID > 0 ? 'hasvalue' : ''">
                <label for="SessionRecord.TimeTemplateID">Time Template</label>
                <select
                  class="styleselect"
                  ref="SessionRecord.TimeTemplateID"
                  name="SessionRecord.TimeTemplateID"
                  id="SessionRecord.TimeTemplateID"
                  v-model="SessionRecord.TimeTemplateID"
                  :disabled="action && ['time', 'edit'].includes(action) && isactualsession"
                  @change="changeTimeTemplate()"
                >
                  <optgroup v-for="(time, tIndex) in timeTemplateListFilter" :key="tIndex" :label="time.StartTime">
                    <option :value="timetemplate.TimeTemplateID" v-for="(timetemplate, ttIndex) in time.Templates" :key="ttIndex">
                      {{ timetemplate.StartTime + '~' + timetemplate.FinishTime + ' | ' }}
                      <strong
                        >{{ timetemplate.TimeTemplateDescription }}

                        {{ timetemplate.SessionIdentifier ? ' (' + timetemplate.SessionIdentifier + ')' : '' }}
                      </strong>
                    </option>
                  </optgroup>
                </select>

                <div class="errormessage" v-if="submitted && !$v.SessionRecord.TimeTemplateID.required">Time Template is required</div>
              </div>
            </div>

            <div class="col-sm-3" v-if="!alternateview">
              <div class="form-group">
                <label>Start Date</label>
                <div class="searchselect disabled" v-if="!enablestartdate">
                  {{ SessionRecord.StartDate | moment('DD/MM/YYYY') }}
                </div>

                <v-date-picker
                  is-expanded
                  :first-day-of-week="2"
                  locale="en-GB"
                  class="datepickerinput"
                  ref="displayStartDate"
                  v-model="displayStartDate"
                  v-if="enablestartdate"
                  @input="changeTimeTemplate()"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input :value="inputValue" v-on="inputEvents" aria-label="Start Date" />
                  </template>
                </v-date-picker>
                <div class="errormessage" v-if="submitted && !$v.displayStartDate.required">Start date required</div>
              </div>
            </div>
            <div :class="alternateview ? 'col-sm-4' : 'col-sm-3'">
              <div class="form-group isrequired" :class="SessionRecord.PlannedSessionStartTime && !SessionRecord.PlannedSessionStartTime.includes('NaN') ? 'hasvalue' : ''">
                <label for="SessionRecord.PlannedSessionStartTime">Start Time</label>
                <TimeInput
                  v-model="SessionRecord.PlannedSessionStartTime"
                  @input="calculateSlotInfo()"
                  :defaultdate="SessionRecord.StartDate"
                  :disabled="(selectedSessionProfile && !selectedSessionProfile.TimeTemplateAvailable) || (action && ['time', 'edit'].includes(action) && isactualsession)"
                />
                <!-- <input
                  type="time"
                  min="00:00"
                  max="23:59"
                  class="form-control"
                  v-model="displayStartTime"
                /> -->

                <div class="errormessage" v-if="submitted && !$v.SessionRecord.PlannedSessionStartTime.required">Start Time is required</div>
              </div>
            </div>
            <div :class="alternateview ? 'col-sm-4' : 'col-sm-3'">
              <div class="form-group isrequired" :class="$v.SessionRecord.PlannedSessionFinishTime.required && $v.SessionRecord.PlannedSessionFinishTime.minValue ? 'hasvalue' : ''">
                <label for="SessionRecord.PlannedSessionFinishTime">End Time</label>
                <TimeInput
                  v-model="SessionRecord.PlannedSessionFinishTime"
                  @input="calculateSlotInfo()"
                  :defaultdate="SessionRecord.StartDate"
                  :disabled="(selectedSessionProfile && !selectedSessionProfile.TimeTemplateAvailable) || (action && ['time', 'edit'].includes(action) && isactualsession)"
                />
                <!-- <input
                  type="time"
                  min="00:00"
                  max="23:59"
                  class="form-control"
                  v-model="displayFinishTime"
                /> -->

                <div class="errormessage" v-if="submitted && !$v.SessionRecord.PlannedSessionFinishTime.required">End Time is required</div>
                <div class="errormessage" v-if="submitted && !$v.SessionRecord.PlannedSessionFinishTime.minValue">End Time must be after Start Time</div>
              </div>
            </div>
            <div :class="alternateview ? 'col-sm-4' : 'col-sm-3'">
              <div class="form-group isrequired" :class="SessionRecord.SessionIdentifier && $v.SessionRecord.SessionIdentifier.mustMatchTimeTemplate ? 'hasvalue' : ''">
                <label for="SessionRecord.SessionIdentifier">Identifier</label>
                <input
                  autocomplete="off"
                  type="text"
                  class="form-control"
                  ref="SessionRecord.SessionIdentifier"
                  maxlength="10"
                  id="SessionRecord.SessionIdentifier"
                  name="SessionRecord.SessionIdentifier"
                  v-lengthcheck
                  v-model="SessionRecord.SessionIdentifier"
                  @keydown="filterSpaces($event)"
                  @input="removeClipboardSpaces($event)"
                  :disabled="action && ['time', 'edit'].includes(action) && isactualsession"
                />

                <div class="errormessage" v-if="!$v.SessionRecord.SessionIdentifier.mustMatchTimeTemplate">Session identifier must match a time template</div>
                <div class="errormessage" v-if="submitted && !$v.SessionRecord.SessionIdentifier.required">Session Identifier is required</div>
              </div>
            </div>
            <div :class="alternateview ? 'col-sm-4' : 'col-sm-3'" v-if="allowsBreakRecording">
              <div class="form-group">
                <label for="SessionRecord.BreakLength">Break Length</label>
                <NumberCounter
                  :id="SessionRecord.BreakLength"
                  :ref="SessionRecord.BreakLength"
                  v-model.number="SessionRecord.BreakLength"
                  min="0"
                  :max="maxBreakLength"
                  step="1"
                  decimal="0"
                  :updateonblur="false"
                  :hidebuttons="true"
                />
              </div>
            </div>
          </div>
          <div class="row" v-if="isSlotBased">
            <div :class="alternateview ? 'col-sm-5' : 'col-sm-3'">
              <div
                class="form-group"
                :class="[
                  selectedSessionProfile && selectedSessionProfile.IsSlotBased && selectedSessionProfile.SlotConfigurationEnabled ? 'isrequired' : '',
                  selectedSessionProfile && selectedSessionProfile.IsSlotBased && selectedSessionProfile.SlotConfigurationEnabled && SessionRecord.SlotQuantity ? 'hasvalue' : '',
                ]"
              >
                <label for="SessionRecord.BreakLength">Slot Quantity</label>
                <NumberCounter
                  id="slotQuantity"
                  ref="slotQuantity"
                  v-model="SessionRecord.SlotQuantity"
                  :min="Math.floor(plannedSessionTime ? plannedSessionTime / 60 : 0)"
                  :max="Math.floor(plannedSessionTime ? plannedSessionTime / minimumSlotMinutes : 288)"
                  step="1"
                  decimal="0"
                  :readonly="!$v.SessionRecord.TimeTemplateID.required || SessionRecord.SlotLength == null || (selectedSessionProfile && !selectedSessionProfile.SlotConfigurationEnabled)"
                  :updateonblur="true"
                  :hidebuttons="true"
                />
                <div class="errormessage" v-if="submitted && !$v.SessionRecord.SlotQuantity.required">Slot Quantity is required</div>
              </div>
            </div>
            <div :class="alternateview ? 'col-sm-5' : 'col-sm-3'">
              <div
                class="form-group"
                :class="[
                  selectedSessionProfile && selectedSessionProfile.IsSlotBased && selectedSessionProfile.SlotConfigurationEnabled ? 'isrequired' : '',
                  selectedSessionProfile && selectedSessionProfile.IsSlotBased && selectedSessionProfile.SlotConfigurationEnabled && SessionRecord.SlotLength ? 'hasvalue' : '',
                ]"
              >
                <label for="SessionRecord.slotLength">Slot Length</label>
                <NumberCounter
                  id="slotLength"
                  ref="slotLength"
                  v-model="SessionRecord.SlotLength"
                  :min="minimumSlotMinutes"
                  max="60"
                  step="5"
                  decimal="0"
                  :validationtext="'Minimum ' + minimumSlotMinutes"
                  :readonly="!$v.SessionRecord.TimeTemplateID.required || (selectedSessionProfile && !selectedSessionProfile.SlotConfigurationEnabled)"
                  :updateonblur="true"
                  :hidebuttons="true"
                  @countupdate="(SessionRecord.SlotQuantity = autoSlotQuantity), (SessionRecord.SlotLength = autoSlotLength)"
                />
                <div class="errormessage" v-if="submitted && !$v.SessionRecord.SlotLength.required">Slot Length is required</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="alert" :class="(!canUserOverbook && newTimeGreaterThanOriginal) || !canOverbookTime ? 'danger' : 'warning'" v-if="isOverbookingTime && newTimeDifferentThanOriginal">
                <h3>Session Available Time Exceeded</h3>
                <!-- <p>The session will be overbooked by {{ patientBookedTime - sessionTimeCalculation }} minutes</p> -->
                <p>
                  Session will be overbooked by <strong>{{ getOverbookValue() }} </strong> minutes.
                  {{ overbookMessage }}
                </p>

                <div class="form-group p0" style="margin-top: 10px" v-if="canOverbookTime">
                  <div class="checkboxitem">
                    <input name="overbookoverride" id="overbookoverride" type="checkbox" class="form-check-input" v-model="overbookOverride" @change="changeOverride()" />
                    <label for="overbookoverride"> <span></span>Continue Anyway</label>
                  </div>
                </div>
              </div>
              <div class="alert danger" v-if="sessionConflictError && !supressconflicts">
                <h3>Session Conflict</h3>
                <p>Creation of session not allowed due to session conflict</p>
              </div>

              <div class="alert" :class="sessionBlocks ? 'danger' : 'warning'" v-if="sessionConflicts && sessionConflicts.length && !supressconflicts">
                <h3>{{ sessionBlocks ? 'Session times can not overlap for this location' : 'WARNING:' }}</h3>
                <p v-if="!sessionBlocks">Session times overlap the following sessions</p>

                <div v-for="(conflict, cIdx) in sessionConflicts" :key="cIdx">
                  <p>
                    <strong>{{ conflict.StartTime | moment('HH:mm') }} - {{ conflict.FinishTime | moment('HH:mm') }}</strong>
                    {{ conflict.SessionIdentifier }}
                  </p>
                </div>

                <div class="form-group p0" style="margin-top: 10px" v-if="!sessionBlocks">
                  <div class="checkboxitem">
                    <input :name="'overrideconflict_' + UID" :id="'overrideconflict_' + UID" type="checkbox" class="form-check-input" v-model="conflictOverride" @change="changeConflict()" />
                    <label :for="'overrideconflict_' + UID"> <span></span>Continue Anyway</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { required, requiredIf } from 'vuelidate/lib/validators';

import { SessionService } from '/src/services/session';

export default {
  props: ['value', 'submitted', 'enablestartdate', 'supressconflicts', 'alternateview', 'splitsessionid', 'splitplanid', 'sessionprofileid', 'ignoresessionconflict', 'action', 'isactualsession'],
  name: 'SessionTimeTemplate',
  data() {
    return {
      originalSessionTime: null,
      loadingContent: false,
      SessionRecord: null,
      timeTemplateList: [],
      allowSessionIDSpaces: null,
      allowSessionIDSpacesGlobal: null,

      enableStartDate: true,
      displayStartDate: new Date(),
      displayStartTime: null,
      displayFinishTime: null,

      sessionConflicts: null,
      sessionBlocks: false,
      conflictOverride: false,
      sessionConflictError: false,

      sessionProfileList: null,
      spaceArray: [32, 160, 5760, 8192, 8192, 8194, 8195, 8196, 8197, 8198, 8199, 8200, 8201, 8202, 8232, 8233, 8239, 8287, 12288],
      plannedSessionTime: null,
      ignoreSessionConflict: null,
      canUserOverbook: false,
      allowUserUnlimitedOverbook: false,
      overbookOverride: false,
    };
  },
  computed: {
    ...mapGetters({
      getAreaProperty: 'getAreaProperty',
      hasBooleanPropertyAccessRights: 'hasBooleanPropertyAccessRights',
    }),
    newTimeGreaterThanOriginal: function () {
      return this.action != 'create' ? this.sessionTimeCalculation > this.originalSessionTime : true;
    },
    newTimeDifferentThanOriginal: function () {
      return this.action != 'create' ? this.sessionTimeCalculation != this.originalSessionTime : true;
    },
    maxOverbookMinutes: function () {
      return this.timeOverbookValue ? this.timeOverbookValue : this.timeOverbookPercentage ? this.SessionRecord.PlannedSessionTime * (this.timeOverbookPercentage / 100) : null;
    },
    emergencySession: function () {
      if (!this.SessionRecord) {
        return false;
      }
      return this.SessionRecord.AutoEmergency;
    },
    overbookMessage: function () {
      return (!this.emergencySession && this.isOverbookingTime && !this.canUserOverbook && (this.timeOverbookPercentage || this.timeOverbookValue)) ||
        (!this.emergencySession && this.isOverbookingTime && !this.timeOverbookPercentage && !this.timeOverbookValue && this.newTimeGreaterThanOriginal)
        ? ' The session available time cannot be exceeded.'
        : !this.emergencySession &&
          this.isOverbookingTime &&
          this.canUserOverbook &&
          !this.canOverbookTime &&
          (this.timeOverbookPercentage || this.timeOverbookValue) &&
          this.newTimeDifferentThanOriginal
        ? this.maxOverbookMinutes
          ? ' The maximum this session can be overbooked is ' + this.maxOverbookMinutes + ' minutes.'
          : ' The session available time overbook limit cannot be exceeded.'
        : null;
    },
    maxBreakLength: function () {
      if (this.sessionTimeCalculation) {
        return parseInt(this.sessionTimeCalculation) < 120 ? parseInt(this.sessionTimeCalculation) : 120;
      } else {
        return 120;
      }
    },
    UID: function () {
      return this._uid;
    },
    patientBookedTime: function () {
      return this.SessionRecord && this.SessionRecord.Patients && this.SessionRecord.Patients.length
        ? this.SessionRecord.Patients.filter((pa) => pa.OperationRecordID && pa.BookingStatus && ![2, 5, 4].includes(pa.BookingStatus))
            .map((p) => p.CalculatedDuration)
            .reduce((prev, next) => prev + next, 0)
        : 0;
    },
    sessionTimeCalculation: function () {
      // Check selected times are enough to cover patient times
      return this.DateFunctions.timeBetween(this.SessionRecord.PlannedSessionStartTime, this.SessionRecord.PlannedSessionFinishTime);
    },
    allowProfileUnlimitedOverbook: function () {
      return Boolean(this.SessionRecord && Boolean(this.SessionRecord.UnlimitedOverbookEnabled) == true);
    },
    timeOverbookValue: function () {
      return this.SessionRecord ? this.SessionRecord.TimeOverbookValue : 0;
    },
    timeOverbookPercentage: function () {
      return this.SessionRecord ? this.SessionRecord.TimeOverbookPercentage : 0;
    },
    canOverbookTime: function () {
      return Boolean(
        (this.canUserOverbook || !this.newTimeGreaterThanOriginal) &&
          this.SessionRecord &&
          ((this.allowUserUnlimitedOverbook && this.allowProfileUnlimitedOverbook) ||
            (this.timeOverbookValue != null && this.sessionTimeCalculation + this.timeOverbookValue >= this.patientBookedTime) ||
            (this.timeOverbookPercentage != null && this.sessionTimeCalculation + (this.SessionRecord.PlannedSessionTime / 100) * this.timeOverbookPercentage >= this.patientBookedTime))
      );
    },
    isOverbookingTime: function () {
      return this.patientBookedTime != 0 && this.patientBookedTime != null && this.SessionRecord && !this.isSlotBased && this.sessionTimeCalculation < this.patientBookedTime;
    },
    selectedSessionProfile: function () {
      return this.sessionprofileid && this.sessionProfileList && this.sessionProfileList.length > 0 ? this.sessionProfileList.find((sp) => sp.SessionProfileID === this.sessionprofileid) : null;
    },

    timeTemplateListFilter: function () {
      let timeArray = [];

      if (this.timeTemplateList) {
        this.timeTemplateList.forEach((ttl) => {
          if (timeArray.findIndex((tt) => tt.StartTime === ttl.StartTime) == -1) {
            timeArray.push({ StartTime: ttl.StartTime, Templates: [ttl] });
          } else {
            timeArray[timeArray.findIndex((tt) => tt.StartTime === ttl.StartTime)].Templates.push(ttl);
          }
        });
      }

      timeArray.forEach((t) => {
        t.Templates.sort((a, b) => (a.FinishTime > b.FinishTime ? 1 : -1));
      });

      let sortArray = timeArray.sort((a, b) => (a.StartTime > b.StartTime ? 1 : -1));

      return sortArray;
    },
    isSlotBased: function () {
      return this.selectedSessionProfile && this.selectedSessionProfile.IsSlotBased;
    },
    allowsBreakRecording: function () {
      return this.selectedSessionProfile && this.selectedSessionProfile.RecordBreakTime;
    },
    autoSlotQuantity: function () {
      if (this.plannedSessionTime && this.SessionRecord.SlotLength) {
        return Math.floor(this.plannedSessionTime / this.SessionRecord.SlotLength);
      } else {
        return null;
      }
    },
    autoSlotLength: function () {
      if (this.plannedSessionTime && this.SessionRecord.SlotLength) {
        return Math.ceil(this.SessionRecord.SlotLength / 5) * 5;
      } else {
        return null;
      }
    },
    minimumSlotMinutes: function () {
      return this.selectedSessionProfile && this.selectedSessionProfile.MinimumSlotMinutes ? this.selectedSessionProfile.MinimumSlotMinutes : 5;
    },
    timeTemplateIdentityRequired: function () {
      if (!this.sessionProfileList || !this.sessionProfileList.length || !this.SessionRecord || !this.SessionRecord.SessionProfileID) {
        return false;
      }
      let sessionProfile = this.sessionProfileList.find((spl) => spl.SessionProfileID == this.SessionRecord.SessionProfileID);
      return sessionProfile && sessionProfile.TimeTemplateIdentityRequired ? sessionProfile.TimeTemplateIdentityRequired : false;
    },
    finishIsAfterStart: function () {
      return this.SessionRecord && this.SessionRecord.PlannedSessionFinishTime && this.SessionRecord.PlannedSessionStartTime
        ? new Date(this.SessionRecord.PlannedSessionFinishTime) > new Date(this.SessionRecord.PlannedSessionStartTime)
        : true;
    },
  },
  components: {
    TimeInput: () => import('/src/components/application/TimeInput'),

    NumberCounter: () => import('/src/components/application/NumberCounter'),
  },
  watch: {
    displayStartDate: function () {
      this.checkSessionConflict();
    },
    'SessionRecord.StartDate': function () {
      this.displayStartDate = new Date(this.SessionRecord.StartDate);
      this.checkSessionConflict();
    },
    'SessionRecord.SessionIdentifier': function () {
      this.checkSessionConflict();
    },
    'SessionRecord.LocationID': function () {
      this.checkSessionConflict();
    },
    'SessionRecord.PlannedSessionStartTime': function () {
      this.checkSessionConflict();
    },
    'SessionRecord.PlannedSessionFinishTime': function () {
      this.checkSessionConflict();
    },
    'SessionRecord.TimeTemplateID': function () {
      this.checkSessionConflict();
    },
    'SessionRecord.SessionProfileID': function () {
      this.checkSessionConflict();

      this.calculateSlotInfo();
    },
    'selectedSessionProfile.IsSlotBased': function (newVal, oldVal) {
      if (newVal == false) {
        this.SessionRecord.SlotLength = 0;
        this.SessionRecord.SlotQuantity = 0;
      } else if (newVal == true) {
        this.calculateSlotInfo();
      }
    },
  },
  created() {
    Promise.all([this.hasBooleanPropertyAccessRights(511), this.hasBooleanPropertyAccessRights(512)]).then((results) => {
      this.canUserOverbook = results[0];
      this.allowUserUnlimitedOverbook = results[1];

      this.loadingContent = true;
      this.enableStartDate = this.enablestartdate || true;
      this.SessionRecord = this.value;
      this.originalSessionTime = this.DateFunctions.timeBetween(this.SessionRecord.PlannedSessionStartTime, this.SessionRecord.PlannedSessionFinishTime);
      this.displayStartDate = new Date(this.SessionRecord.StartDate);
      this.ignoreSessionConflict = parseInt(this.ignoresessionconflict);
      SessionService.getTimeTemplates().then((timetemplates) => {
        this.timeTemplateList = timetemplates;
        this.loadingContent = false;
      });

      this.getSessionProfileData();

      this.getAreaProperty(this.currentArea.ID, 505).then((result) => {
        this.allowSessionIDSpaces = result ? result.Value : false;
        if (this.SessionRecord.SessionIdentifier && !this.allowSessionIDSpaces) {
          this.SessionRecord.SessionIdentifier = this.removeSpaces(this.SessionRecord.SessionIdentifier);
        }
      });
    });
  },
  methods: {
    ...mapActions(['addAppMessage']),
    getOverbookValue: function () {
      let overbookVal = 0;
      if (this.SessionRecord) {
        overbookVal = (this.SessionRecord.PlannedSessionTimeAvailable - this.patientBookedTime) * -1;
      }
      return overbookVal;
    },
    changeConflict: function () {
      this.$emit('emitconflict', {
        conflicterror: this.sessionConflictError,
        conflicts: this.sessionConflicts,
        override: this.conflictOverride,
        patienttimeerror: this.patientBookedTime > this.sessionTimeCalculation && this.newTimeDifferentThanOriginal,
      });
      this.loadingContent = false;
    },
    changeOverride: function () {
      this.$emit('emitoverride', this.overbookOverride);
    },
    filterSpaces: function (e) {
      if (!this.allowSessionIDSpaces && this.spaceArray.includes(e.keyCode)) {
        e.preventDefault();
      }
    },
    removeSpaces: function (val) {
      if (!this.allowSessionIDSpaces && (/\s/.test(val) || / /g.test(val))) {
        return val.replace(/ /g, '').replace(/\s/g, '').replace(' ', '').trim();
      } else {
        return val;
      }
    },
    removeClipboardSpaces: function (e) {
      if (!this.allowSessionIDSpaces && (/\s/.test(e.target.value) || / /g.test(e.target.value))) {
        e.target.value = e.target.value.replace(/ /g, '').replace(/\s/g, '').trim();
        e.target.dispatchEvent(new CustomEvent('input'));
      }
    },
    changeTimeTemplate: function () {
      this.$v.$touch();

      this.SessionRecord.StartDate = this.DateFunctions.apiDate(new Date(this.displayStartDate));
      let timeTemplateRec = this.timeTemplateList.find((tt) => tt.TimeTemplateID == this.SessionRecord.TimeTemplateID);
      if (timeTemplateRec) {
        if (timeTemplateRec.SessionIdentifier && timeTemplateRec.SessionIdentifier != '' && (this.SessionRecord.SessionIdentifier == null || this.SessionRecord.SessionIdentifier == '')) {
          this.SessionRecord.SessionIdentifier = !this.allowSessionIDSpaces ? this.removeSpaces(timeTemplateRec.SessionIdentifier) : timeTemplateRec.SessionIdentifier;
        }
        this.SessionRecord.PlannedSessionStartTime = this.DateFunctions.apiDate(new Date(this.displayStartDate)) + 'T' + timeTemplateRec.StartTime;
        this.SessionRecord.PlannedSessionFinishTime = this.DateFunctions.apiDate(new Date(this.displayStartDate)) + 'T' + timeTemplateRec.FinishTime;
        if (timeTemplateRec.DefaultBreakLength != 0) {
          this.SessionRecord.BreakLength = timeTemplateRec.DefaultBreakLength;
        }

        this.calculateSlotInfo();
      }
    },

    getSessionProfileData: function () {
      SessionService.getSessionProfile({ iSessionProfileID: null, Enabled: true }).then((profile) => {
        this.sessionProfileList = profile;
      });
    },

    calculateSlotInfo: function () {
      if (this.SessionRecord.PlannedSessionStartTime && this.SessionRecord.PlannedSessionFinishTime && this.isSlotBased) {
        var absDiff = Math.abs(new Date(this.SessionRecord.PlannedSessionFinishTime) - new Date(this.SessionRecord.PlannedSessionStartTime));
        var diffMins = Math.floor(absDiff / 1000 / 60);
        this.plannedSessionTime = diffMins;
        // this.SessionRecord.SlotLength = this.minimumSlotMinutes;
        // this.SessionRecord.SlotQuantity = this.autoSlotQuantity;
        //per bug #10743, if there's already values, then use them. otherwise, use the calculated values
        this.SessionRecord.SlotLength = this.SessionRecord.SlotLength && this.SessionRecord.SlotLength > 0 ? this.SessionRecord.SlotLength : this.minimumSlotMinutes;
        this.SessionRecord.SlotQuantity = Math.floor(diffMins / this.SessionRecord.SlotLength);
      }
    },

    checkSessionConflict: debounce(function () {
      if (this.supressconflicts) {
        this.sessionConflictError = false;
        this.sessionConflicts = null;
        this.changeConflict();
      }

      if (
        !this.SessionRecord.LocationID ||
        !this.SessionRecord.StartDate ||
        !this.SessionRecord.PlannedSessionStartTime ||
        (this.SessionRecord.PlannedSessionStartTime && this.SessionRecord.PlannedSessionStartTime.includes('NaN')) ||
        !this.SessionRecord.PlannedSessionFinishTime ||
        (this.SessionRecord.PlannedSessionFinishTime && this.SessionRecord.PlannedSessionFinishTime.includes('NaN'))
      )
        return;

      this.loadingContent = true;

      SessionService.checkSessionConflict({
        SessionRecordID: this.splitsessionid || this.SessionRecord.SessionRecordID,
        SessionPlanID: this.splitplanid || this.SessionRecord.SessionPlanID,
        StartDate: this.DateFunctions.apiDate(this.SessionRecord.StartDate),
        LocationID: this.SessionRecord.LocationID,
        StartTime: this.SessionRecord.PlannedSessionStartTime,
        FinishTime: this.SessionRecord.PlannedSessionFinishTime,
        SessionIdentifier: this.SessionRecord.SessionIdentifier,
        SessionProfileID: this.SessionRecord.SessionProfileID,
        ExcludeSessionRecordID: this.ignoreSessionConflict,
      })
        .then((result) => {
          this.sessionConflictError = false;
          if (!result || result.length > 0) {
            // SOFT ERROR - ALLOW OVERRIDE
            this.conflictOverride = false;
            this.sessionConflicts = result.filter((sc) => {
              return sc.SessionRecordID != this.ignoreSessionConflict;
            });
            this.sessionBlocks = result.some((s) => {
              return s.BlockSession == 1;
            });
          } else {
            // ALL OK
            this.sessionConflicts = null;
          }

          this.changeConflict();
        })
        .catch((error) => {
          // HARD ERROR - CANNOT CREATE+
          this.sessionConflictError = true;
          this.sessionConflicts = null;
          this.sessionBlocks = false;

          this.changeConflict();
        });
    }, 1000),

    getTimeTemplate: function (timeTemplateID) {
      return this.timeTemplateList.find((tt) => tt.TimeTemplateID == timeTemplateID);
    },
  },

  validations: {
    displayStartDate: {
      required,
    },
    displayStartTime: {
      required,
    },
    displayFinishTime: {
      required,
    },

    SessionRecord: {
      TimeTemplateID: {
        required,
      },
      SlotLength: {
        required: requiredIf(function () {
          return this.selectedSessionProfile && this.selectedSessionProfile.IsSlotBased;
        }),
      },
      SlotQuantity: {
        required: requiredIf(function () {
          return this.selectedSessionProfile && this.selectedSessionProfile.IsSlotBased;
        }),
      },
      StartDate: {
        required,
      },
      PlannedSessionStartTime: {
        required,
      },
      PlannedSessionFinishTime: {
        required,
        minValue: function () {
          return this.finishIsAfterStart;
        },
      },
      SessionIdentifier: {
        required,
        mustMatchTimeTemplate(value) {
          // if (value && this.timeTemplateList && this.selectedSessionProfile && this.selectedSessionProfile.TimeTemplateIdentityRequired) {
          if (value && this.timeTemplateList && this.timeTemplateIdentityRequired) {
            let timeTemplateRec = this.timeTemplateList.find(
              (tt) =>
                tt.SessionIdentifier &&
                (!this.allowSessionIDSpaces
                  ? tt.SessionIdentifier.replace(/ /g, '').replace(/\s/g, '').trim().toUpperCase() === value.toUpperCase()
                  : tt.SessionIdentifier.toUpperCase() === value.toUpperCase())
            );

            if (timeTemplateRec) {
              return true;
            }
            return false;
          }
          return true;
        },
      },
    },
  },
};
</script>
