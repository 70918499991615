const GenericRoute = () => import('/src/components/application/_GenericRoute.vue');

const Dashboard = () => import('/src/components/dashboard/Dashboard.vue');
const DashboardFullscreen = () => import('/src/components/dashboard/DashboardFullScreen.vue');
const DashboardTabConfig = () => import('/src/components/dashboard/tabs/config/DashboardTabConfig.vue');

// const LeaderBoards = () => import('/src/components/user/LeaderBoards.vue');

export default [
  {
    path: '/dashboard',
    component: GenericRoute,
    meta: {
      title: 'Dashboard',
    },
    children: [
      {
        path: 'fullscreen/:tabid/:quickfilters',
        name: 'dashboard_fullscreen',
        component: DashboardFullscreen,
        meta: {
          title: 'Dashboard Carousel',
          layout: 'fullscreen',
          transitionName: 'slide',
          functionCode: 'DASHBOARD',
          backRoutes: ['dashboard'],
        },
      },
      {
        path: 'config',
        name: 'dashboard_tab_config',
        component: DashboardTabConfig,
        meta: {
          title: 'Dashboard Tab Config',
          functionCode: 'DASHBOARD',
          backRoutes: ['dashboard'],
        },
      },
      {
        path: '',
        name: 'dashboard',
        component: Dashboard,
        meta: {
          title: 'Dashboard',
          tags: ['Landing Page'],
          functionCode: 'DASHBOARD',
          hideBackButton: true,
          isLandingRoute: true,
        },
      },
    ],
  },

  // {
  //   path: '/leaderboards',
  //   name: 'leaderboards',
  //   meta: {
  //     title: 'AQUA Leaderboards',
  //     functionCode: 'SECURITYADMIN',
  //   },
  //   component: LeaderBoards,
  // },
];
