// ====================================================================== \\
// == Browser Storage: Assistant functions for getting and setting the == \\
// ==                  browsers localStorage/sessionStorage data.      == \\
// ====================================================================== \\
// !!  All data entered into the localStorage/sessionStorage will be   !! \\
// !!  encrypted and can only be decrypted using this assistant.       !! \\
// ====================================================================== \\
const { blake2b, box, compress, inflate, kdf, keygen, unbox } = require('./storageHelpers/fortification');

const { MemoryStorage } = require('./storageHelpers/memoryStorage');
const memoryStorage = new MemoryStorage();

export const browserStorage = {
  // ====================================================================== \\
  // == Public function pointers                                         == \\
  // ====================================================================== \\
  getItem: _getItem,
  setItem: _setItem,
  removeItem: _removeItem,
  clear: _clear,
  clearMemoryStorage: _clearMemoryStorage,

  setUserPostLoginRoute: _setUserPostLoginRoute,

  // ====================================================================== \\
  // == mixin initalisation                                              == \\
  // ====================================================================== \\
  install(Vue, options) {
    if (browserStorage.install.isInitialized) {
      return; // previously initialized
    }

    // ====================================================================== \\
    // == Initialize the Browser Storage assistant                         == \\
    // ====================================================================== \\
    // !!  This must be the last thing done by the install(Vue) function   !! \\
    // ====================================================================== \\
    vue_prototype = Vue.prototype;
    addProperties();
    addAccessorProperties(options.accessors);

    const properties = Object.getOwnPropertyNames(browserStorage);

    // storage events are fired in non-focused same-origin tabs
    window.addEventListener('storage', (event) => {
      new Promise((resolve) => setTimeout(resolve, 0)).then(() => {
        if (properties.includes(event.key)) {
          memoryStorage.setItem(event.key, decodeValue(event.key, event.newValue));
        }
      });
    });

    browserStorage.install.isInitialized = true;

    Object.seal(browserStorage);
  },
};

// Vue instance
let vue_prototype = null;

// ====================================================================== \\
// == Property insertion function                                      == \\
// ====================================================================== \\
function addProperties() {
  // ====================================================================== \\
  // == Add localStorage properties to the Browser Storage assistant     == \\
  // ====================================================================== \\
  Object.defineProperty(browserStorage, 'aquaUID', { get: get_aquaUID });
  Object.defineProperty(browserStorage, 'aquaDeviceID', { get: get_aquaDeviceID, set: set_aquaDeviceID });
  Object.defineProperty(browserStorage, 'previousActiveWindowUID', { get: get_previousActiveWindowUID, set: set_previousActiveWindowUID });
  Object.defineProperty(browserStorage, 'lockSession', { get: get_lockSession, set: set_lockSession });
  Object.defineProperty(browserStorage, 'isAddingUser', { get: get_isAddingUser, set: set_isAddingUser });
  Object.defineProperty(browserStorage, 'loggedInUsers', { get: get_loggedInUsers, set: set_loggedInUsers });
  Object.defineProperty(browserStorage, 'currentUser', { get: get_currentUser, set: set_currentUser });
  Object.defineProperty(browserStorage, 'currentAreas', { get: get_currentAreas, set: set_currentAreas });
  Object.defineProperty(browserStorage, 'appTickerData', { get: get_appTickerData, set: set_appTickerData });
  Object.defineProperty(browserStorage, 'isLoggingIn', { get: get_isLoggingIn, set: set_isLoggingIn });
  Object.defineProperty(browserStorage, 'isLoggingOut', { get: get_isLoggingOut, set: set_isLoggingOut });
  Object.defineProperty(browserStorage, 'suspendApplicationTicker', { get: get_suspendApplicationTicker, set: set_suspendApplicationTicker });
  Object.defineProperty(browserStorage, 'isRefreshingToken', { get: get_isRefreshingToken, set: set_isRefreshingToken });

  // ====================================================================== \\
  // == Add sessionStorage properties to the Browser Storage assistant   == \\
  // ====================================================================== \\
  Object.defineProperty(browserStorage, 'aquaWindowUID', { get: get_aquaWindowUID, set: set_aquaWindowUID });
  Object.defineProperty(browserStorage, 'routeHistory', { get: get_routeHistory, set: set_routeHistory });
  Object.defineProperty(browserStorage, 'postLoginRoute', { get: get_postLoginRoute, set: set_postLoginRoute });
  Object.defineProperty(browserStorage, 'lockResult', { get: get_lockResult, set: set_lockResult });
  Object.defineProperty(browserStorage, 'originLocationID', { get: get_originLocationID, set: set_originLocationID });
}

// ====================================================================== \\
// == localStorage getters and setters                                 == \\
// ====================================================================== \\
function get_aquaUID() {
  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! \\
  // !! DO NOT MODIFY OR USE THIS CODE AS AN EXAMPLE !! \\
  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! \\
  let value = localStorage.getItem('aquaUID');
  if (!value || value == '') {
    value = keygen().toString('base64');
    localStorage.setItem('aquaUID', value);
  }
  return Buffer.from(value, 'base64');
  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! \\
}

function get_aquaDeviceID() {
  let value = browserStorage.getItem('aquaDeviceID');
  if (!value || value == '') {
    value = browserStorage.app.getGUID();
    set_aquaDeviceID(value);
  }
  return value;
}
function set_aquaDeviceID(value) {
  if (value == null) {
    browserStorage.removeItem('aquaDeviceID');
  } else {
    browserStorage.setItem('aquaDeviceID', value);
  }
}
function get_previousActiveWindowUID() {
  return browserStorage.getItem('previousActiveWindowUID');
}
function set_previousActiveWindowUID(value) {
  if (value == null) {
    browserStorage.removeItem('previousActiveWindowUID');
  } else {
    browserStorage.setItem('previousActiveWindowUID', value);
  }
}

function get_lockSession() {
  return browserStorage.app.$options.methods.parseBool(browserStorage.getItem('lockSession'));
}
function set_lockSession(value) {
  if (value == null) {
    browserStorage.removeItem('lockSession');
  } else {
    browserStorage.setItem('lockSession', value);
  }
}

function get_isAddingUser() {
  return browserStorage.app.$options.methods.parseBool(browserStorage.getItem('isAddingUser'));
}
function set_isAddingUser(value) {
  if (value == null) {
    browserStorage.removeItem('isAddingUser');
  } else {
    browserStorage.setItem('isAddingUser', value);
  }
}

function get_loggedInUsers() {
  return browserStorage.getItem('loggedInUsers');
}
function set_loggedInUsers(value) {
  if (value == null) {
    browserStorage.removeItem('loggedInUsers');
  } else {
    browserStorage.setItem('loggedInUsers', value);
  }
}

function get_currentUser() {
  return browserStorage.getItem('currentUser');
}
function set_currentUser(value) {
  if (value == null) {
    browserStorage.removeItem('currentUser');
  } else {
    browserStorage.setItem('currentUser', value);
  }
}

function get_currentAreas() {
  return browserStorage.getItem('currentAreas');
}
function set_currentAreas(value) {
  if (value == null) {
    browserStorage.removeItem('currentAreas');
  } else {
    browserStorage.setItem('currentAreas', value);
  }
}

function get_appTickerData() {
  return browserStorage.getItem('appTickerData');
}
function set_appTickerData(value) {
  if (value == null) {
    browserStorage.removeItem('appTickerData');
  } else {
    browserStorage.setItem('appTickerData', value);
  }
}

function get_isLoggingIn() {
  return browserStorage.app.$options.methods.parseBool(browserStorage.getItem('isLoggingIn'));
}
function set_isLoggingIn(value) {
  if (value == null) {
    browserStorage.removeItem('isLoggingIn');
  } else {
    browserStorage.setItem('isLoggingIn', value);
  }
}

function get_isLoggingOut() {
  return browserStorage.app.$options.methods.parseBool(browserStorage.getItem('isLoggingOut'));
}
function set_isLoggingOut(value) {
  if (value == null) {
    browserStorage.removeItem('isLoggingOut');
  } else {
    browserStorage.setItem('isLoggingOut', value);
  }
}

function get_suspendApplicationTicker() {
  const value = browserStorage.getItem('suspendApplicationTicker');
  return value != null ? new Date(value) : null;
}
function set_suspendApplicationTicker(value) {
  if (value == null) {
    browserStorage.removeItem('suspendApplicationTicker');
  } else {
    browserStorage.setItem('suspendApplicationTicker', value);
  }
}

function get_isRefreshingToken() {
  return browserStorage.app.$options.methods.parseBool(browserStorage.getItem('isRefreshingToken'));
}
function set_isRefreshingToken(value) {
  if (value == null) {
    browserStorage.removeItem('isRefreshingToken');
  } else {
    browserStorage.setItem('isRefreshingToken', value);
  }
}

// ====================================================================== \\
// == sessionStorage getters and setters                               == \\
// ====================================================================== \\
function get_aquaWindowUID() {
  let windowUID = browserStorage.getItem('aquaWindowUID');
  if (vue_prototype.crypto && (!windowUID || windowUID == '')) {
    windowUID = vue_prototype.crypto.randomBytes(32).toString('base64');
    set_aquaWindowUID(windowUID);
  }
  return windowUID;
}
function set_aquaWindowUID(value) {
  if (value == null) {
    browserStorage.removeItem('aquaWindowUID');
  } else {
    browserStorage.setItem('aquaWindowUID', value, true);
  }
}

function get_postLoginRoutes() {
  return browserStorage.getItem('postLoginRoutes') ?? [];
}

const dummyUser = { ID: -999 };
function get_postLoginRoute() {
  let user = get_currentUser();
  if (!user && !get_isLoggingIn()) {
    user = dummyUser;
  }

  // get postLoginRoute
  if (user) {
    const values = get_postLoginRoutes();
    const postLoginRoute = values?.find((v) => v.userID == user.ID);
    return postLoginRoute?.route || null;
  }
}
function set_postLoginRoute(value) {
  _setUserPostLoginRoute(get_currentUser() ?? dummyUser, value);
}
function _setUserPostLoginRoute(user, route) {
  let postLoginRoutes = get_postLoginRoutes();

  if (user && (!route || route?.name != 'clear_cache')) {
    let update = true;
    // get current users postLoginRoute
    let postLoginRoute = postLoginRoutes?.find((v) => v.userID == user.ID);
    if (postLoginRoute) {
      if (route) {
        postLoginRoute.route = browserStorage.app.$routeAssistant.getReducedRouteObject(route);
      } else {
        postLoginRoutes = postLoginRoutes.filter((v) => v.userID != user.ID);
      }
    } else if (route != null) {
      postLoginRoutes.push({
        userID: user.ID,
        route: browserStorage.app.$routeAssistant.getReducedRouteObject(route),
      });
    } else {
      update = false;
    }

    if (update) {
      browserStorage.setItem('postLoginRoutes', postLoginRoutes, true);
    }
  }
}

function get_routeHistory() {
  const user = get_currentUser();
  const routeHistory = browserStorage.getItem('routeHistory');

  // get routeHistory for current user
  return !user ? [] : routeHistory?.find((v) => v.userID == user.ID && v.tabID == browserStorage.aquaWindowUID)?.history ?? [];
}
function set_routeHistory(value) {
  const user = get_currentUser();
  let routeHistory = browserStorage.getItem('routeHistory');
  routeHistory = routeHistory ?? [];

  // get current users postLoginRoute
  if (user) {
    let userRoutes = routeHistory?.find((v) => v.userID == user.ID && v.tabID == browserStorage.aquaWindowUID);
    if (userRoutes) {
      userRoutes.history = value ?? [];
    } else if (value != null) {
      routeHistory.push({
        userID: user.ID,
        tabID: browserStorage.aquaWindowUID,
        history: value ?? [],
      });
    }
  }

  browserStorage.setItem('routeHistory', routeHistory, true);
}
function get_lockResult() {
  return browserStorage.getItem('lockResult');
}
function set_lockResult(value) {
  if (value == null) {
    browserStorage.removeItem('lockResult');
  } else {
    browserStorage.setItem('lockResult', value, true);
  }
}

function get_originLocationID() {
  return browserStorage.getItem('originLocationID');
}
function set_originLocationID(value) {
  if (value == null) {
    browserStorage.removeItem('originLocationID');
  } else {
    browserStorage.setItem('originLocationID', value, true);
  }
}

// ====================================================================== \\
// == Public functions                                                 == \\
// ====================================================================== \\
function _getItem(key) {
  if (key) {
    const memStoreValue = memoryStorage.getItem(key);

    if (!memStoreValue) {
      return decodeFromBrowserStorage(key);
    } else {
      try {
        return JSON.parse(memStoreValue).value || null;
      } catch (_) {
        return null;
      }
    }
  }
  return null;
}

function _setItem(key, value, intoSessionStorage = false) {
  if (key && value) {
    let storage = intoSessionStorage ? sessionStorage : localStorage;

    memoryStorage.setItem(key, JSON.stringify({ value }));
    encodeToBrowserStorage(storage, key, value);
  } else if (key && value == null) {
    browserStorage.removeItem(key);
  }
}

function _removeItem(key) {
  if (key) {
    if (memoryStorage.getItem(key)) {
      memoryStorage.removeItem(key);
    }
    if (localStorage.getItem(key)) {
      localStorage.removeItem(key);
    }
    if (sessionStorage.getItem(key)) {
      sessionStorage.removeItem(key);
    }
  }
}

function _clear() {
  return new Promise((resolve) => {
    // Clear the browser storage
    localStorage.clear();
    sessionStorage.clear();
    memoryStorage.clear();

    browserStorage.isLoggingOut = browserStorage.aquaWindowUID;

    if ('serviceWorker' in navigator) {
      // This should clear the browser cache for AQUA
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          registration.unregister();
        }
        resolve();
      });
    } else {
      resolve();
    }
  });
}
function _clearMemoryStorage() {
  memoryStorage.clear();
}

// ====================================================================== \\
// == Private functions                                                == \\
// ====================================================================== \\
const decodeFromBrowserStorage = (key) => {
  // new Promise((resolve) => setTimeout(resolve, 0)).then(() => {
  const encoded = localStorage.getItem(key) ?? sessionStorage.getItem(key) ?? null;

  if (encoded != null) {
    let value = null;
    const decoded = decodeValue(key, encoded);

    if (decoded != null) {
      try {
        value = JSON.parse(decoded)?.value || null;
      } catch (_) {
        value = null;
      }

      if (decoded != null) {
        memoryStorage.setItem(key, decoded);
      }
    } else {
      browserStorage.removeItem(key);
    }

    return value;
  }
  return null;
  // });
};
const decodeValue = (key, encoded) => {
  try {
    const storageKey = kdf(key, browserStorage.aquaUID);
    const inflated = inflate(encoded);
    const nonce = blake2b(key, browserStorage.aquaUID, 24);
    const unboxed = unbox(storageKey, nonce, inflated);
    const decoded = unboxed.toString();

    return decoded;
  } catch (_) {
    return null;
  }
};
const encodeToBrowserStorage = (storage, key, value) => {
  new Promise((resolve) => setTimeout(resolve, 0)).then(() => {
    const valueToEncode = JSON.stringify({ value: value });

    const storageKey = kdf(key, browserStorage.aquaUID);
    const buffer = Buffer.from(valueToEncode);
    const nonce = blake2b(key, browserStorage.aquaUID, 24);
    const boxed = box(storageKey, nonce, buffer);
    const compressed = compress(boxed);

    storage.setItem(key, compressed);
  });
};

// ====================================================================== \\
// == Application wide property insertion functions                    == \\
// ====================================================================== \\
function addAccessorProperties(accessors) {
  const router = accessors.find((accessor) => accessor.key == 'VueRouter')?.value;
  if (router) {
    Object.defineProperty(browserStorage, 'app', {
      get: () => {
        return router.app;
      },
    });
    Object.defineProperty(browserStorage, '$router', {
      get: () => {
        return router;
      },
    });
  }

  addAppWideAccessorProperties(accessors);
}
function addAppWideAccessorProperties(accessors) {
  accessors.forEach((accessor) => {
    Object.defineProperty(accessor.value, '$browserStorage', {
      get: () => {
        return browserStorage;
      },
    });
  });
}
