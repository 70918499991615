const GenericRoute = () => import('/src/components/application/_GenericRoute');

const NotesList = () => import('/src/components/application/NotesList');

export default [
  {
    path: '/notes',
    component: GenericRoute,
    meta: {
      title: 'Notes',
    },
    children: [
      {
        path: 'session/:sessionid',
        name: 'notes_session',
        meta: {
          title: 'Session Notes List',
          functionCode: 'SESSION',
          backRoutes: ['session_view'],
        },
        component: NotesList,
      },
      {
        path: 'episode/:operationrecordid',
        name: 'notes_episode',
        meta: {
          title: 'Episode Notes List',
          functionCode: 'PATIENT',
          backRoutes: ['episode_view'],
        },
        component: NotesList,
      },
      {
        path: '',
        name: 'notes',
        meta: {
          title: 'Notes List',
        },
        component: NotesList,
      },
    ],
  },
];
