const axios = require('axios');

import { store } from '/src/store';

export const UserService = {
  UserList: [],
  UserRecord: {},
  UserRoleList: [],

  getUser: function (userID) {
    if (userID) {
      return axios
        .get(
          // URL
          'user/' + userID,
          {}
        )
        .then((response) => {
          return response.data.result;
        });
    }
  },

  getUserRoles: function (userID) {
    return new Promise((resolve) => {
      store.getters.userRoles(userID).then((userRoles) => {
        resolve(userRoles);
      });
    });
  },

  getUserRolesDirect: function (userID) {
    return axios
      .post(
        // URL
        'user/role',
        // DATA
        { UserID: userID }
      )
      .then((response) => {
        return response.data.result;
      });
  },

  getUsers: function (
    sendData = {
      // SearchText: ""
      // Enabled: true,
      Page: 0,
      PageSize: 10,
      OrderColumn: 'FullName',
      OrderDirection: 0,
      IncludeRoles: true,
    }
  ) {
    return axios
      .post(
        // URL
        'User/list',
        // DATA
        sendData
      )
      .then((response) => {
        return response.data.result;
      });
  },

  getRegistrationRequests: function () {
    return axios
      .get(
        // URL
        'user/requests/list'
      )
      .then((response) => {
        return response.data.result;
      });
  },

  getLockedUsers: function () {
    return axios
      .get(
        // URL
        'user/locked/list'
      )
      .then((response) => {
        return response.data.result;
      });
  },

  getPasswordRequests: function () {
    return axios
      .get(
        // URL
        'user/passwordRequests/list'
      )
      .then((response) => {
        return response.data.result;
      });
  },

  getPinRequests: function () {
    return axios
      .get(
        // URL
        'user/pinRequests/list'
      )
      .then((response) => {
        return response.data.result;
      });
  },

  getRetiredUsers: function () {
    return axios
      .get(
        // URL
        'user/retired/list'
      )
      .then((response) => {
        return response.data.result;
      });
  },

  getInactiveUsers: function (inactiveDate = null) {
    return axios
      .post(
        // URL
        'user/inactive/list',
        // DATA
        { InactiveSince: inactiveDate }
      )
      .then((response) => {
        return response.data.result;
      });
  },

  getUserCounts: function () {
    return axios
      .get(
        // URL
        'User/counts'
      )
      .then((response) => {
        return response.data.result;
      });
  },

  getUserRoleList: function (userID) {
    return axios.all([axios.post('Role', { Enabled: true }), axios.post('User/role', { UserID: parseInt(userID) })]).then((responses) => {
      let roles = responses[0].data.result;
      let userRoles = responses[1].data.result;

      // loop through roles
      roles.forEach((func) => {
        // set function as active if the user has the role assigned to them
        func.Active = userRoles.some((funcRole) => userID != null && userID > 0 && func.ID === funcRole.RoleID);
      });

      return { roles, userRoles };
    });
  },

  updateUser: function (iUser) {
    if (!iUser.ID || iUser.ID === 0) {
      return axios
        .post(
          // URL
          'User/create',
          // DATA
          iUser
        )
        .then((response) => {
          return response.data.result;
        });
    } else {
      return axios
        .put(
          // URL
          'User/update',
          // DATA
          iUser
        )
        .then((response) => {
          return response.data.result;
        });
    }
  },
  updateUsersBulk: function (payload) {
    if (payload) {
      return axios
        .put(
          // URL
          'User/bulk/update',
          // DATA
          payload
        )
        .then((response) => {
          return response.data.result;
        });
    }
  },

  updateSelf: function (payload) {
    if (payload) {
      return axios
        .put(
          // URL
          'User/update/self',
          // DATA
          payload
        )
        .then((response) => {
          return response.data.result;
        });
    }
  },

  deleteUser: function (userID) {
    return axios
      .delete('User/delete', {
        data: {
          ID: userID,
        },
      })
      .then((response) => {
        return response.data.result;
      });
  },

  retireUser: function (userID) {
    return axios
      .put(
        // URL
        'User/retire',
        // DATA
        {
          ID: userID,
        }
      )
      .then((response) => {
        return response.data.result;
      });
  },

  reInstateUser: function (userID) {
    return axios
      .put(
        // URL
        'User/reinstate',
        // DATA
        {
          ID: userID,
        }
      )
      .then((response) => {
        return response.data.result;
      });
  },

  getMessages: function (userID, iShowAll = false) {
    return axios
      .post(
        // URL
        'User/messages',
        // DATA
        {
          UserID: userID,
          ShowAll: iShowAll,
        }
      )
      .then((response) => {
        return response.data.result;
      });
  },
  getUserSystemMessages: function (userID, bIncludeHidden = false) {
    return axios
      .post(
        // URL
        'User/messages/system',
        // DATA
        {
          UserID: userID,
          IncludeHidden: bIncludeHidden,
        }
      )
      .then((response) => {
        return response.data.result;
      });
  },
  hideMessage: function (userID, iMessageID) {
    return axios
      .put(
        // URL
        'User/messages/system/update',
        // DATA
        {
          UserID: userID,
          SystemMessageID: iMessageID,
          HideMessage: true,
        }
      )
      .then((response) => {
        return response.data.result;
      });
  },
  userFunctions: function (iGroupCode) {
    return axios
      .post(
        // URL
        'User/function/access',
        // DATA
        {
          GroupCode: iGroupCode,
        }
      )
      .then((response) => {
        return response.data.result;
      });
  },
  dashboardItems: function (userID) {
    return axios
      .post(
        // URL
        'User/dashboarditem',
        // DATA
        {
          UserID: userID,
        }
      )
      .then((response) => {
        return response.data.result;
      });
  },
  getUserAreas: function (userID) {
    return axios
      .post(
        // URL
        'User/area',
        // DATA
        { UserID: parseInt(userID) }
      )
      .then((response) => {
        return response.data.result;
      });
  },

  resetDedicatedLogin: function (userID) {
    return axios
      .put(
        // URL
        'User/dedicated/reset',
        // DATA
        { UserID: userID }
      )
      .then((response) => {
        return response.data.result;
      });
  },

  recentActions: function (userID) {
    return axios
      .post(
        // URL
        'User/recentActions',
        // DATA
        { ActionedByUserID: userID }
      )
      .then((response) => {
        return response.data.result;
      });
  },

  getGlobalFilter: function (
    iObject = {
      GlobalFilterID: null,
      UserID: null,
      Enabled: null,
    }
  ) {
    return axios
      .post(
        // URL
        'GlobalFilter',
        // DATA
        iObject
      )
      .then((response) => {
        return response.data.result;
      });
  },

  updateGlobalFilter: function (
    iObject = {
      GlobalFilterID: null,
      UserID: null,
      GlobalFilterDescription: null,
      IsDefault: null,
      Enabled: null,
      FilterAreaID: null,
      FilterSpecialty: null,
      FilterPersonnel: null,
      FilterLocation: null,
      FilterSessionType: null,
      IsDeepDive: null,
    }
  ) {
    return axios
      .put(
        // URL
        'GlobalFilter/update',
        // DATA
        iObject
      )
      .then((response) => {
        return response.data.result;
      });
  },
  getUserHistory: function (UserID = null) {
    return axios
      .post('User/history', {
        ID: UserID,
      })
      .then((response) => {
        return response.data.result;
      });
  },

  linkExternalAuth: function (payload = { userID: null, authCode: null }) {
    return axios
      .post(
        // URL
        'User/link_external',
        // DATA
        {
          UserID: payload.userID,
          provider: 'google',
          code: payload.authCode,
          redirect_uri: process.env.VUE_APP_URL,
        }
      )
      .then((response) => {
        return response.data.result;
      });
  },
};
