const GenericRoute = () => import('/src/components/application/_GenericRoute');

// Reports
const ReportDefinition = () => import('/src/components/admin/report/ReportDefinition');
const ReportDefinitionList = () => import('/src/components/admin/report/views/ReportDefinitionList');
const ReportEnablingForm = () => import('/src/components/admin/report/forms/ReportEnablingForm');
const ReportDefinitionForm = () => import('/src/components/admin/report/forms/ReportDefinitionForm');

const ReportRoleList = () => import('/src/components/admin/report/views/ReportRoleList');
const ReportRoleForm = () => import('/src/components/admin/report/forms/ReportRoleForm');
const ReportUserList = () => import('/src/components/admin/report/views/ReportUserList');
const ReportUserForm = () => import('/src/components/admin/report/forms/ReportUserForm');

// Report Categories
const ReportCategory = () => import('/src/components/admin/report/ReportCategory');
const ReportCategoryList = () => import('/src/components/admin/report/views/ReportCategoryList');
const ReportCategoryForm = () => import('/src/components/admin/report/forms/ReportCategoryForm');

// Template Report
const TemplateReportList = () => import('/src/components/admin/templateReport/templateReportList');
const TemplateReportEdit = () => import('/src/components/admin/templateReport/templateReportEdit');

// Template Report Output
const TemplateReportOutput = () => import('/src/components/admin/templateReportOutput/templateReportOutput');
const TemplateReportOutputList = () =>
  import('/src/components/admin/templateReportOutput/views/templateReportOutputList');
const TemplateReportOutputForm = () =>
  import('/src/components/admin/templateReportOutput/views/templateReportOutputForm');

export default [
  // ======================================== \\
  // Reports
  // ======================================== \\
  {
    path: 'report',
    component: ReportDefinition,
    meta: {
      title: 'Report Definition Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: 'definition',
        name: 'admin_reportdefinitionlist',
        component: ReportDefinitionList,
        meta: {
          title: 'Report Definition Maintenance',
          tags: ['Administration'],
          functionCode: 'REPORTADMIN',
          backRoutes: ['admin_home'],
        },
      },
      {
        path: 'user',
        name: 'admin_reportuserlist',
        component: ReportUserList,
        meta: {
          title: 'Report User Enabling',
          tags: ['Administration'],
          functionCode: 'REPORTADMIN',
          backRoutes: ['admin_home'],
        },
      },
      {
        path: 'role',
        name: 'admin_reportrolelist',
        component: ReportRoleList,
        meta: {
          title: 'Report Role Enabling',
          tags: ['Administration'],
          functionCode: 'REPORTADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  {
    path: 'report/definition/:reportdefinitionid',
    name: 'admin_reportdefinition',
    component: ReportDefinitionForm,
    props: true,
    meta: {
      title: 'Report Definition Maintenance',
      tags: ['Administration'],
      functionCode: 'REPORTADMIN',
      backRoutes: ['admin_reportdefinitionlist'],
    },
  },
  {
    path: 'report/user/:userid',
    name: 'admin_reportuser',
    component: ReportUserForm,
    props: true,
    meta: {
      title: 'Report Definition User Maintenance',
      tags: ['Administration'],
      functionCode: 'REPORTADMIN',
      backRoutes: ['admin_reportuserlist'],
    },
  },
  {
    path: 'report/role/:roleid',
    name: 'admin_reportrole',
    component: ReportRoleForm,
    props: true,
    meta: {
      title: 'Report Definition Role Maintenance',
      tags: ['Administration'],
      functionCode: 'REPORTADMIN',
      backRoutes: ['admin_reportrolelist'],
    },
  },
  {
    path: 'report/enablement',
    name: 'admin_reportenablement',
    component: ReportEnablingForm,
    props: true,
    meta: {
      title: 'Report Definition Enablement Maintenance',
      tags: ['Administration'],
      functionCode: 'REPORTADMIN',
      backRoutes: ['admin_reportdefinitionlist','admin_reportuser', 'admin_reportrole'],
    },
  },

  // ======================================== \\
  // Report Categories
  // ======================================== \\
  {
    path: 'report/category',
    component: ReportCategory,
    meta: {
      title: 'Report Category Maintenance',
      tags: ['Administration'],
      functionCode: 'REPORTADMIN',
      backRoutes: ['admin_home'],
    },
    children: [
      {
        path: ':reportcategoryid',
        name: 'admin_reportcategory_detail',
        component: ReportCategoryForm,
        props: true,
        meta: {
          title: 'Report Category Maintenance',
          tags: ['Administration'],
          functionCode: 'REPORTADMIN',
          backRoutes: ['admin_reportcategorylist'],
        },
      },
      {
        path: '',
        name: 'admin_reportcategorylist',
        component: ReportCategoryList,
        meta: {
          title: 'Report Category Maintenance',
          tags: ['Administration'],
          functionCode: 'REPORTADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // Template Report
  // ======================================== \\
  {
    path: 'template/report',
    component: GenericRoute,
    meta: {
      title: 'Template Report Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':templatereportid',
        name: 'admin_templatereport_detail',
        component: TemplateReportEdit,
        meta: {
          title: 'Template Report Maintenance',
          tags: ['Administration'],
          functionCode: 'REPORTADMIN',
          backRoutes: ['admin_templatereport'],
        },
      },
      {
        path: '',
        name: 'admin_templatereport',
        component: TemplateReportList,
        meta: {
          title: 'Template Report Maintenance',
          tags: ['Administration'],
          functionCode: 'REPORTADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // Template Report Output
  // ======================================== \\
  {
    path: 'template/report/output',
    component: TemplateReportOutput,
    meta: {
      title: 'Template Report Output Maintenance',
      tags: ['Administration'],
      functionCode: 'REPORTADMIN',
      backRoutes: ['admin_home'],
    },
    children: [
      {
        path: ':templatereportoutputid',
        name: 'admin_templatereportoutput_detail',
        component: TemplateReportOutputForm,
        meta: {
          title: 'Template Report Output Maintenance',
          tags: ['Administration'],
          functionCode: 'REPORTADMIN',
          backRoutes: ['admin_templatereportoutput'],
        },
      },
      {
        path: '',
        name: 'admin_templatereportoutput',
        component: TemplateReportOutputList,
        meta: {
          title: 'Template Report Output Maintenance',
          tags: ['Administration'],
          functionCode: 'REPORTADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },
];
