/* ===================================================================================================
 *  DESCRIPTION:
 * ===================================================================================================
 *  Due to the wide range of barcode scanners on the market we need handle as many capture inputs as
 *  posible.
 *
 *  This plugin caters for diffences in scanner speeds, that can lead to noisy and innacurate results.
 *  This plugin is NOT a barcode scanner, it is an input capture throttler that listens for key entry
 *  events. This means that it should be compatible with thrid-party plugins that are avaliable for
 *  camera or image proccess barcode scanning.
 *
 *  When it comes to us adding support for camera scanning, look at following plugin:
 *    https://www.npmjs.com/package/vue-barcode-reader
 *
 * ===================================================================================================
 *  OPTIONS:
 * ===================================================================================================
 *  This plugin has several customisable options that can be passed to the initializer. If obmittied
 *  the default values will be used.
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *  sound                 -:  when set to "true", the sound specified in "soundSrc" will be played
 *                            once the barcode scan is complete.
 * ---------------------------------------------------------------------------------------------------
 *  soundSrc              -:  path to an audio file or a 'data:audio/wav;base64' string
 * ---------------------------------------------------------------------------------------------------
 *  captureSensitivity    -:  number of miliseconds to wait after scan (see "callbackAfterTimeout")
 * ---------------------------------------------------------------------------------------------------
 *  useInputElement       -:  if set to "true" you need to add the "data-barcode" attribute to you
 *                            input field, then only this input will responsed to a scanner.
 * ---------------------------------------------------------------------------------------------------
 *  prefix                -:  a character (or group of characters) expected to be found at the start
 *                            of the barcode. When supplied barcodes returned from a scanner without
 *                            this will be ignored.
 * ---------------------------------------------------------------------------------------------------
 *  suffix                -:  a character (or group of characters) expected to be found at the end of
 *                            the barcode. When supplied barcodes returned from a scanner without
 *                            this will be ignored.
 * ---------------------------------------------------------------------------------------------------
 *  GSReplacementChar     -:  a character (or group of characters) that will be used to replace any
 *                            GS1/FNC1 seperation charaters found in the barcode.
 * ---------------------------------------------------------------------------------------------------
 *  controlSequenceKeys   -:  when a control key in this list is encountered in a scan sequence, it
 *                            will be replaced with tags for easy string replacement.
 *                            Example:
 *                              "controlSequenceKeys" = ['NumLock']
 *                              Barcode scanned:        NumLock 0013 NumLock
 *                              result:                 <ControlSequence>0013</ControlSequence>
 * ---------------------------------------------------------------------------------------------------
 *  callbackAfterTimeout  -:  this will fire the callback defined in the component once the
 *                            captureSensitivity has elapsed, following the last character in the
 *                            barcode sequence. This is useful for scanners that don't end their
 *                            sequences with ENTER and is backwards compatible with scanners that do.
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *  created() {
 *    // Add barcode capture listener, pass the callback function and options object
 *    let options = {
 *      useInputElement:      true,                 // default is false
 *      captureSensitivity:   300,                  // default is 100 - in miliseconds
 *      callbackAfterTimeout: true,                 // default is true
 *      prefix:               '<',                  // default is blank
 *      suffix:               '>',                  // default is blank
 *      sound:                true,                 // default is false
 *      soundSrc:             '/static/sound.wav',  // default is blank
 *      controlSequenceKeys:  ['NumLock', 'Clear'], // default is null
 *    }
 *    this.$barcodeCapture.init(this.onBarcodeCaptured, options);
 *  },
 *
 * ===================================================================================================
 *  USAGE:
 * ===================================================================================================
 *  In your component file (.vue) where you need to listen for barcodes include the following:
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *  created() {
 *    // Add barcode capture listener and pass the callback function
 *    this.$barcodeCapture.init(this.onBarcodeCaptured);
 *  },
 *  destroyed() {
 *    // Remove barcode capture listener when component is destroyed (!IMPORTANT!)
 *    this.$barcodeCapture.destroy();
 *  },
 *  methods: {
 *    // Create callback function to receive barcode when the scanner is done
 *    onBarcodeCaptured: function (barcode) {
 *      // Your code to handle the barcode result goes here
 *      // console.log('barcode: ' + barcode);
 *    },
 *  }
 *
 * ===================================================================================================
 *  ADVANCED USAGE:
 * ===================================================================================================
 *  Using an eventBus you can trap when a scanner starts and finishes processing a barcode:
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *  data() {
 *    return {
 *      isScanning: false,
 *    };
 *  },
 *  created() {
 *    // Pass an options object with `eventBus: true` to receive an eventBus back
 *    // which emits `start` and `finish` events
 *    const eventBus = this.$barcodeCapture.init(this.onBarcodeCaptured, { eventBus: true })
 *    if (eventBus) {
 *      eventBus.$on('start', () => { this.isScanning = true })
 *      eventBus.$on('finish', () => { this.isScanning = false })
 *    }
 *  },
 *  destroyed() {
 *    // Remove listener when component is destroyed (!IMPORTANT!)
 *    this.$barcodeCapture.destroy();
 *  },
 *  methods: {
 *    // Create callback function to receive barcode when the scanner is done
 *    onBarcodeCaptured: function (barcode) {
 *      // You code to handle the barcode result goes here
 *      // console.log('barcode: ' + barcode);
 *    },
 *  }
 *
 * ===================================================================================================
 */

export const BarcodeCapture = {
  install(Vue, options) {
    // default plugin settings
    let attributes = {
      previousCode: '',
      barcode: '',
      settings: {
        prefix: '',
        suffix: '',
        sound: false,
        soundSrc: '',
        captureSensitivity: 50,
        GSReplacementChar: '|',
        useInputElement: false,
        /*
         * `controlSequenceKeys` should be an array of Strings ([String])
         * that will be joined in a regex string for identifying
         * control sequences they will be replaced in the return string by tags
         * this allows easy string replacement
         *
         * Example:
         *   "controlSequenceKeys" = ['NumLock']
         *   Barcode scanned:        NumLock 0013 NumLock
         *   result:                 <ControlSequence>0013</ControlSequence>
         */
        controlSequenceKeys: null,
        /*
         * Some scanners do not end their sequence with the ENTER key.
         * This option allows "finishing" the sequence without an ENTER key
         * after the number of ms defined in `settings.captureSensitivity`
         * elapses after the last character in the sequence.
         * Example:
         * (without timeout, sequence ends with ENTER):
         *   1. Scan barcode
         *   2. Scanner sends sequence of characters to device, ending with ENTER (13) key
         *   3. `callback` passed in `init()` is called
         * (without timeout, sequence ends without ENTER):
         *   1. Scan barcode
         *   2. Scanner sends sequence of characters to device. Final character is not ENTER
         *   3. `callback` is not called until the ENTER key is pressed
         * (with timeout, sequence ends without ENTER):
         *   1. Scan barcode
         *   2. Scanner sends sequence of characters to device. Final character is not ENTER
         *   3. After `settings.captureSensitivity` ms elapses, `callback` is called
         */
        callbackAfterTimeout: true,
      },
      callback: null,
      hasListener: false,
      pressedTime: [],
      // This is used for scanners which do not send
      // ENTER (13) as the final key code
      // in a barcode sequence.
      timeout: null,
      // Used to handle control sequences
      isInControlSequence: false,
      // Used to emit messages
      eventBus: null,
      // Used for determing whether or not to emit a `start` event
      isProcessing: false,
      // GS1/FNC1 speration character
      GSChar: String.fromCharCode(29),

      clearBufferTimerID: null,
    };

    setOptions(options);

    Vue.prototype.$barcodeCapture = {};

    Vue.prototype.$barcodeCapture.init = (callback, options = {}) => {
      setOptions(options);

      // add listenter for scanner
      // use keypress to separate lower/upper case character from scanner
      addListener('keypress');
      // use keydown only to detect Tab event (Tab cannot be detected using keypress)
      addListener('keydown');
      attributes.callback = callback;

      /*
       * Allow an event bus to be passed back to the caller. This is an `init` option because
       * we do not want to create additional Vue instances on every component, but would like
       * to have access to a bus under some circumstances.
       *
       * The importance of this is greater when scanning 2D barcodes, which take significantly
       * longer (>=4 seconds) than 1D barcodes and some kind of indication of what the plugin
       * is doing should be made avaliable to the component.
       */
      if (options.eventBus) {
        attributes.eventBus = new Vue();
        return attributes.eventBus;
      }
    };

    Vue.prototype.$barcodeCapture.destroy = () => {
      // remove listener
      removeListener('keypress');
      removeListener('keydown');
    };

    Vue.prototype.$barcodeCapture.hasListener = () => {
      return attributes.hasListener;
    };

    Vue.prototype.$barcodeCapture.getPreviousCode = () => {
      return attributes.previousCode;
    };

    Vue.prototype.$barcodeCapture.setSensitivity = (sensitivity) => {
      attributes.settings.captureSensitivity = sensitivity;
    };

    function setOptions(options) {
      // initial plugin settings
      if (options) {
        attributes.settings.prefix = options.prefix ?? attributes.settings.prefix;
        attributes.settings.suffix = options.suffix ?? attributes.settings.suffix;
        attributes.settings.sound = options.sound ?? attributes.settings.sound;
        attributes.settings.soundSrc = options.soundSrc ?? attributes.settings.soundSrc;
        attributes.settings.useInputElement = options.useInputElement ?? attributes.settings.useInputElement;
        attributes.settings.captureSensitivity = options.sensitivity ?? attributes.settings.captureSensitivity;
        attributes.settings.controlSequenceKeys = options.controlSequenceKeys ?? attributes.settings.controlSequenceKeys;
        attributes.settings.callbackAfterTimeout = options.callbackAfterTimeout ?? attributes.settings.callbackAfterTimeout;
      }
    }

    function addListener(type) {
      if (attributes.hasListener) {
        removeListener(type);
      }
      window.addEventListener(type, onInputScanned);
      attributes.hasListener = true;
    }

    function removeListener(type) {
      if (attributes.hasListener) {
        window.removeEventListener(type, onInputScanned);
        attributes.hasListener = false;
      }
    }

    // This is called when either an ENTER key (13) is received or when the `attributes.timeout` fires,
    // following a scan sequence.
    function finishScanSequence() {
      //console.log('raw barcode captured: ',attributes.barcode);
      // clear and null the timeout
      if (attributes.timeout) {
        clearTimeout(attributes.timeout);
      }
      attributes.timeout = null;

      // check existence of prefix and suffix
      let isValidBarcode = !attributes.settings.prefix && !attributes.settings.suffix;
      if (attributes.settings.prefix && attributes.barcode.indexOf(attributes.settings.prefix) == 0) {
        attributes.barcode = attributes.barcode.replace(attributes.settings.prefix, '');
        isValidBarcode = true;
      }
      if (attributes.settings.suffix && attributes.barcode.indexOf(attributes.settings.suffix) > 0) {
        attributes.barcode = attributes.barcode.replace(attributes.settings.suffix, '');
        isValidBarcode = true;
      }

      // only provide the barcode to the callback function if valid
      if (isValidBarcode) {
        // Replace GS1/FNC1 seperation character with "GSReplacementChar"
        if (attributes.barcode.includes(attributes.GSChar)) {
          if (attributes.barcode.startsWith(']')) {
            attributes.barcode = attributes.barcode.substring(1);
          }
          attributes.barcode = attributes.barcode.replaceAll(attributes.GSChar, attributes.settings.GSReplacementChar);
        }

        // scanner is done and trigger Enter/Tab
        attributes.callback(attributes.barcode);

        // trigger sound if it's set as true
        if (attributes.settings.sound) {
          triggerSound();
        }
      }

      // backup the barcode
      attributes.previousCode = attributes.barcode;
      // clear barcode
      attributes.barcode = '';
      // clear pressedTime
      attributes.pressedTime = [];

      emitEvent('finish');
      attributes.isProcessing = false;
    }

    // If entering a control sequence, add `<ControlSequence>` to the buffer
    // If exiting a control sequence, add `</ControlSequence>` to the buffer
    // Toggle control sequence flag
    function handleControlBoundaryKeydown() {
      attributes.barcode += attributes.isInControlSequence ? '</ControlSequence>' : '<ControlSequence>';

      attributes.isInControlSequence = !attributes.isInControlSequence;
    }

    // Returns a regex for control sequence matching or null
    function controlSequenceRegex() {
      return attributes.settings.controlSequenceKeys ? new RegExp(attributes.settings.controlSequenceKeys.join('|')) : null;
    }

    function emitEvent(type, payload) {
      if (attributes.eventBus) {
        attributes.eventBus.$emit(type, payload);
      }
    }

    function onInputScanned(event) {
      const controlRegex = controlSequenceRegex();

      // ignore other keydown event that is not a TAB, so there are no duplicate keys
      if (event.type === 'keydown' && event.keyCode !== 9) {
        // Return early if no control keys should be observed
        if (!controlRegex) return;
        // Return early if this is not a control key that should be observed
        if (controlRegex && !controlRegex.test(event.key)) return;
      }

      // handle control boundary keydown
      if (event.type === 'keydown' && controlRegex && controlRegex.test(event.key)) {
        return handleControlBoundaryKeydown();
      }

      if (checkInputElapsedTime(event.key, Date.now())) {
        if (!attributes.isProcessing) {
          emitEvent('start', event);
          attributes.isProcessing = true;
        }

        // Get input element if required that has the 'data-barcode' attribute
        let barcodeIdentifier = true;
        if (attributes.settings.useInputElement) {
          barcodeIdentifier = event.target.attributes.getNamedItem('data-barcode');
        }

        if (barcodeIdentifier && (event.keyCode === 13 || event.keyCode === 9) && attributes.barcode !== '') {
          finishScanSequence();

          // prevent TAB navigation for scanner
          if (event.keyCode === 9 || event.keyCode === 13) {
            event.preventDefault();
          }
        } else {
          // reset the finish sequence timer and add the key to the buffer
          if (attributes.timeout) {
            clearTimeout(attributes.timeout);
          }

          // console.log({
          //   captureSensitivity: attributes.settings.captureSensitivity,
          //   callbackAfterTimeout: attributes.settings.callbackAfterTimeout,
          // });

          // ensure there are characters in the buffer
          // otherwise, the callback will always fire
          if (attributes.settings.callbackAfterTimeout && attributes.barcode.length >= 4) {
            attributes.timeout = setTimeout(finishScanSequence, attributes.settings.captureSensitivity);
          }

          // scan and validate each character
          attributes.barcode += event.key;

          // clear the barcode buffer after a second of no input
          if (attributes.clearBufferTimerID) clearTimeout(attributes.clearBufferTimerID);
          attributes.clearBufferTimerID = setTimeout(() => {
            // backup the barcode
            attributes.previousCode = attributes.barcode;
            // clear barcode
            attributes.barcode = '';
            // clear pressedTime
            attributes.pressedTime = [];
          }, 1000);
        }
      }
    }

    // check whether the keystrokes are considered as scanner or human
    function checkInputElapsedTime(eventKey, timestamp) {
      // push current timestamp to the register
      attributes.pressedTime.push(timestamp);
      // when register is full (ready to compare)
      if (attributes.pressedTime.length === 2) {
        // compute elapsed time between 2 keystrokes
        let timeElapsed = attributes.pressedTime[1] - attributes.pressedTime[0];

        // too slow (assume as human)
        if (timeElapsed >= attributes.settings.captureSensitivity) {
          // put latest key char into barcode
          attributes.barcode = eventKey;
          // remove(shift) first timestamp in register
          attributes.pressedTime.shift();
          // not fast enough
          return false;
        } else {
          // fast enough (assume as scanner)
          // reset the register
          attributes.pressedTime = [];
        }
      }

      // not able to check (register is empty before pushing) or assumed as scanner
      return true;
      // return attributes.pressedTime.length >= 4;
    }

    // init audio and play
    function triggerSound() {
      let audio = new Audio(attributes.settings.soundSrc);
      audio.play();
    }
  },
};
