<template>
  <div class="popover-mask" v-scroll-lock="true">
    <div class="popover">
      <div class="popoverheader">
        <slot name="popoverclose">
          <button type="button" class="close" @click.stop.prevent="closePopOver()" ref="popoverclosebtn">CLOSE</button>
        </slot>

        <slot name="popovertitle">
          <h2>Action</h2>
        </slot>

        <slot name="popoverbuttons"></slot>
      </div>
      <div class="popovercontent" v-scroll-lock="true">
        <slot name="popovercontent">
          <div class="container-fluid mt30"><h1>NO CONTENT TO SHOW</h1></div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopOver',
  props: ['refocusref', 'focusonclose', 'setfocuson'],
  data() {
    return {};
  },
  created() {
    document.addEventListener('keyup', this.escKeyPress);

    if (this.setfocuson) {
      this.setFocusByClass(this.setfocuson);
    } else {
      this.$nextTick(function () {
        this.setFocusByRef('popoverclosebtn');
      });
    }
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.escKeyPress);
  },
  destroyed() {
    if (this.refocusref !== '') {
      this.setFocusByParentRef(this.refocusref);
    }
  },
  methods: {
    closePopOver: function () {
      this.$emit('closePopOver');
    },
    escKeyPress: function (event) {
      if (event.key === 'Escape') {
        this.$emit('closePopOver');
      }
    },
  },
};
</script>
