// Resource
const Resources = () => import('/src/components/admin/resources/Resources');
const ResourcesList = () => import('/src/components/admin/resources/views/ResourcesList');
const ResourcesView = () => import('/src/components/admin/resources/views/ResourcesView');

// Resource Group
const ResourcesGroupList = () => import('/src/components/admin/resources/views/ResourceGroupList');
const ResourcesGroupView = () => import('/src/components/admin/resources/views/ResourceGroupView');

// Stock Group
const StockGroup = () => import('/src/components/admin/resources/StockGroup');
const StockGroupList = () => import('/src/components/admin/resources/views/StockGroupList');
const StockGroupView = () => import('/src/components/admin/resources/views/StockGroupView');

// Preference Card
const PreferenceCard = () => import('/src/components/admin/preferencecard/PreferenceCard');
const PreferenceCardProcedureList = () =>
  import('/src/components/admin/preferencecard/views/PreferenceCardProcedureList');
const PreferenceCardList = () => import('/src/components/admin/preferencecard/views/PreferenceCardList');
const PreferenceCardResources = () => import('/src/components/admin/preferencecard/forms/PreferenceCardResources');

// Supplier/Manufacturer
const SupplierAdmin = () => import('/src/components/admin/resources/SupplierAdmin');
const SupplierAdminList = () => import('/src/components/admin/resources/views/SupplierAdminList');
const SupplierAdminForm = () => import('/src/components/admin/resources/views/SupplierAdminForm');

// Supplier/Manufacturer Type
const SupplierType = () => import('/src/components/admin/resources/SupplierType');
const SupplierTypeList = () => import('/src/components/admin/resources/views/SupplierTypeList');
const SupplierTypeForm = () => import('/src/components/admin/resources/views/SupplierTypeForm');

export default [
  // ======================================== \\
  // Resource & Resource Group
  // ======================================== \\
  {
    path: 'resource',
    name: 'admin_resources',
    component: Resources,
    meta: {
      title: 'Resources',
      tags: ['Administration'],
      functionCode: 'RESOURCEADMIN',
      backRoutes: ['admin_home'],
    },
    children: [
      {
        path: 'group/:resourcegroupid',
        name: 'admin_resourcegroup',
        component: ResourcesGroupView,
        meta: {
          title: 'Resource Group View',
          tags: ['Administration'],
          functionCode: 'RESOURCEADMIN',
          backRoutes: ['admin_resourcegroups'],
        },
      },
      {
        path: 'group',
        name: 'admin_resourcegroups',
        component: ResourcesGroupList,
        meta: {
          title: 'Resource Group Listing',
          tags: ['Administration'],
          functionCode: 'RESOURCEADMIN',
          backRoutes: ['admin_home'],
        },
      },      
      {
        path: 'item/:resourceid',
        name: 'admin_resourceitem',
        component: ResourcesView,
        meta: {
          title: 'Resource View',
          tags: ['Administration'],
          functionCode: 'RESOURCEADMIN',
          backRoutes: ['admin_resourceitems'],
        },
      },
      {
        path: 'items/:resourcegroupid?',
        name: 'admin_resourceitems',
        component: ResourcesList,
        meta: {
          title: 'Resource Listing',
          tags: ['Administration'],
          functionCode: 'RESOURCEADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // Preference Card
  // ======================================== \\
  {
    path: 'resource/preference/card',
    component: PreferenceCard,
    meta: {
      title: 'Preference Card Resources',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':procedureid/:preferencecardid',
        name: 'admin_preferencecard_resources',
        component: PreferenceCardResources,
        meta: {
          title: 'Preference Card Resources',
          tags: ['Administration'],
          functionCode: 'RESOURCEADMIN',
          backRoutes: ['admin_preferencecards_for_procedure', 'admin_preferencecard_procedures'],
        },
      },
      {
        path: ':procedureid',
        name: 'admin_preferencecards_for_procedure',
        component: PreferenceCardList,
        meta: {
          title: 'Preference Cards',
          tags: ['Administration'],
          functionCode: 'RESOURCEADMIN',
          backRoutes: ['admin_preferencecard_procedures'],
        },
      },
      {
        path: '',
        name: 'admin_preferencecard_procedures',
        component: PreferenceCardProcedureList,
        meta: {
          title: 'Preference Cards',
          tags: ['Administration'],
          functionCode: 'RESOURCEADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // Supplier/Manufacturer
  // ======================================== \\
  {
    path: 'resource/supplier',
    component: SupplierAdmin,
    meta: {
      title: 'Supplier / Manufacturer Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':supplierid',
        name: 'admin_supplierlist_detail',
        component: SupplierAdminForm,
        meta: {
          title: 'Supplier / Manufacturer Maintenance',
          tags: ['Administration'],
          functionCode: 'RESOURCEADMIN',
          backRoutes: ['admin_supplierlist'],
        },
      },
      {
        path: '',
        name: 'admin_supplierlist',
        component: SupplierAdminList,
        meta: {
          title: 'Supplier / Manufacturer Maintenance',
          tags: ['Administration'],
          functionCode: 'RESOURCEADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // Supplier/Manufacturer Type
  // ======================================== \\
  {
    path: 'resource/supplier/type',
    component: SupplierType,
    meta: {
      title: 'Supplier / Manufacturer Type Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':suppliertypeid',
        name: 'admin_suppliertype_detail',
        component: SupplierTypeForm,
        meta: {
          title: 'Supplier / Manufacturer Type Maintenance',
          tags: ['Administration'],
          functionCode: 'RESOURCEADMIN',
          backRoutes: ['admin_suppliertypelist'],
        },
      },
      {
        path: '',
        name: 'admin_suppliertypelist',
        component: SupplierTypeList,
        meta: {
          title: 'Supplier / Manufacturer Type Maintenance',
          tags: ['Administration'],
          functionCode: 'RESOURCEADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

   // ======================================== \\
  // Stock Group
  // ======================================== \\
  {
    path: 'resource/stock/group',
    component: StockGroup,
    meta: {
      title: 'Stock Group Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':stockgroupid',
        name: 'admin_stockgroup_detail',
        component: StockGroupView,
        meta: {
          title: 'Stock Group Maintenance',
          tags: ['Administration'],
          functionCode: 'RESOURCEADMIN',
          backRoutes: ['admin_stockgroups'],
        },
      },
      {
        path: '',
        name: 'admin_stockgroups',
        component: StockGroupList,
        meta: {
          title: 'Stock Group Maintenance',
          tags: ['Administration'],
          functionCode: 'RESOURCEADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },
];
