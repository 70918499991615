const axios = require('axios');

import { JwtFunctions } from '/src/mixins/JwtFunctions';

export const ErrorService = {
  createError: function (user, payload) {
    const jwt = JwtFunctions.decodeAccessToken(user);
    if (jwt && !payload.SessionID) {
      payload.SessionID = jwt.TMSessionId;
    }

    return axios
      .put(
        // URL
        'Error/create',
        // DATA
        payload
      )
      .then((response) => {
        return response.data.result;
      });
  },
};
