const axios = require('axios');

import { store } from '/src/store';

export const ActionService = {
  actionRequestLockSession: function (
    iAction = {
      SessionRecordID: null, // *REQUIRED*
      ActionDateTime: null, // [OPTIONAL] Default = NULL,
      ActionedByPersonnelID: null, // [OPTIONAL] Default = NULL,
      ActionedByUserID: null, // [OPTIONAL] Default = NULL,
      ReasonID: null, // [OPTIONAL] Default = NULL,
      ReasonFreeText: null, // [OPTIONAL] Default = NULL,
      ReasonDetailID: null, // [OPTIONAL] Default = NULL,
      ValidatedByPersonnelID: null, // [OPTIONAL] Default = NULL,
      DestinationURL: null, // [OPTIONAL] Default = NULL,
      Comments: null, // [OPTIONAL] Default = NULL,
    }
  ) {
    if (iAction) {
      return axios
        .put(
          // URL
          'Session/planned/submit/lock',
          // HEADERS
          iAction
        )
        .then((res) => {
          return res;
        });
    }
  },

  actionLockSession: function (
    iAction = {
      SessionRecordID: null, //  *REQUIRED*
      ActionDateTime: null, // [OPTIONAL] Default = NULL,
      ActionedByPersonnelID: null, // [OPTIONAL] Default = NULL,
      ActionedByUserID: null, // [OPTIONAL] Default = NULL,
      ReasonID: null, // [OPTIONAL] Default = NULL,
      ReasonFreeText: null, // [OPTIONAL] Default = NULL,
      ReasonDetailID: null, // [OPTIONAL] Default = NULL,
      ValidatedByPersonnelID: null, // [OPTIONAL] Default = NULL,
      Comments: null, // [OPTIONAL] Default = NULL,
    }
  ) {
    if (iAction) {
      return axios
        .put(
          // URL
          'Session/planned/lock',
          // HEADERS
          iAction
        )
        .then((res) => {
          return res;
        });
    }
  },
  actionUnLockSession: function (
    iAction = {
      SessionRecordID: null, // *REQUIRED*/[OPTIONAL] Default = NULL, - Required if SessionPlanID and StartDate not supplied
      SessionPlanID: null, // *REQUIRED*/[OPTIONAL] Default = NULL, - Required if SessionRecordID not supplied
      StartDate: null, // *REQUIRED*/[OPTIONAL] Default = NULL, - Required if SessionRecordID not supplied
      ActionDateTime: null, // [OPTIONAL] Default = NULL,
      ActionedByPersonnelID: null, // [OPTIONAL] Default = NULL,
      ActionedByUserID: null, // [OPTIONAL] Default = NULL,
      ReasonID: null, // [OPTIONAL] Default = NULL,
      ReasonFreeText: null, // [OPTIONAL] Default = NULL,
      ReasonDetailID: null, // [OPTIONAL] Default = NULL,
      ValidatedByPersonnelID: null, // [OPTIONAL] Default = NULL,
      Comments: null, // [OPTIONAL] Default = NULL,
    }
  ) {
    if (iAction) {
      return axios
        .put(
          // URL
          'Session/planned/unlock',
          // HEADERS
          iAction
        )
        .then((res) => {
          return res;
        });
    }
  },
  actionRequestUnLockSession: function (
    iAction = {
      SessionRecordID: null, // *REQUIRED*/[OPTIONAL] Default = NULL, - Required if SessionPlanID and StartDate not supplied
      SessionPlanID: null, // *REQUIRED*/[OPTIONAL] Default = NULL, - Required if SessionRecordID not supplied
      StartDate: null, // *REQUIRED*/[OPTIONAL] Default = NULL, - Required if SessionRecordID not supplied
      ActionDateTime: null, // [OPTIONAL] Default = NULL,
      ActionedByPersonnelID: null, // [OPTIONAL] Default = NULL,
      ActionedByUserID: null, // [OPTIONAL] Default = NULL,
      ReasonID: null, // [OPTIONAL] Default = NULL,
      ReasonFreeText: null, // [OPTIONAL] Default = NULL,
      ReasonDetailID: null, // [OPTIONAL] Default = NULL,
      ValidatedByPersonnelID: null, // [OPTIONAL] Default = NULL,
      DestinationURL: null, // [OPTIONAL] Default = NULL,
      Comments: null, // [OPTIONAL] Default = NULL,
    }
  ) {
    if (iAction) {
      return axios
        .put(
          // URL
          'Session/planned/unlock/request',
          // HEADERS
          iAction
        )
        .then((res) => {
          return res;
        });
    }
  },

  actionAnaesthetistSignoffSession: function (
    iAction = {
      SessionRecordID: null, // *REQUIRED*
      ActionDateTime: null, // [OPTIONAL] Default = NULL,
      ActionedByPersonnelID: null, // [OPTIONAL] Default = NULL,
      ActionedByUserID: null, // [OPTIONAL] Default = NULL,
      ReasonID: null, // [OPTIONAL] Default = NULL,
      ReasonFreeText: null, // [OPTIONAL] Default = NULL,
      ReasonDetailID: null, // [OPTIONAL] Default = NULL,
      ValidatedByPersonnelID: null, // [OPTIONAL] Default = NULL,
      Comments: null, // [OPTIONAL] Default = NULL,
    }
  ) {
    if (iAction) {
      return axios
        .put(
          // URL
          'Session/planned/signoff/anaesthetist',
          // HEADERS
          iAction
        )
        .then((res) => {
          return res;
        });
    }
  },
  actionConsultantSignoffSession: function (
    iAction = {
      SessionRecordID: null, // *REQUIRED*
      ActionDateTime: null, // [OPTIONAL] Default = NULL,
      ActionedByPersonnelID: null, // [OPTIONAL] Default = NULL,
      ActionedByUserID: null, // [OPTIONAL] Default = NULL,
      ReasonID: null, // [OPTIONAL] Default = NULL,
      ReasonFreeText: null, // [OPTIONAL] Default = NULL,
      ReasonDetailID: null, // [OPTIONAL] Default = NULL,
      ValidatedByPersonnelID: null, // [OPTIONAL] Default = NULL,
      Comments: null, // [OPTIONAL] Default = NULL,
    }
  ) {
    if (iAction) {
      return axios
        .put(
          // URL
          'Session/planned/signoff/consultant',
          // HEADERS
          iAction
        )
        .then((res) => {
          return res;
        });
    }
  },

  actionRejectLockSession: function (
    iAction = {
      SessionRecordID: null, // *REQUIRED*
      ActionDateTime: null, // [OPTIONAL] Default = NULL,
      ActionedByPersonnelID: null, // [OPTIONAL] Default = NULL,
      ActionedByUserID: null, // [OPTIONAL] Default = NULL,
      ReasonID: null, // [OPTIONAL] Default = NULL,
      ReasonFreeText: null, // [OPTIONAL] Default = NULL,
      ReasonDetailID: null, // [OPTIONAL] Default = NULL,
      ValidatedByPersonnelID: null, // [OPTIONAL] Default = NULL,
      Comments: null, // [OPTIONAL] Default = NULL,
    }
  ) {
    if (iAction) {
      return axios
        .put(
          // URL
          'Session/planned/lock/reject',
          // HEADERS
          iAction
        )
        .then((res) => {
          return res;
        });
    }
  },

  actionRejectUnlockSession: function (
    iAction = {
      SessionRecordID: null, // *REQUIRED*
      ActionDateTime: null, // [OPTIONAL] Default = NULL,
      ActionedByPersonnelID: null, // [OPTIONAL] Default = NULL,
      ActionedByUserID: null, // [OPTIONAL] Default = NULL,
      ReasonID: null, // [OPTIONAL] Default = NULL,
      ReasonFreeText: null, // [OPTIONAL] Default = NULL,
      ReasonDetailID: null, // [OPTIONAL] Default = NULL,
      ValidatedByPersonnelID: null, // [OPTIONAL] Default = NULL,
      Comments: null, // [OPTIONAL] Default = NULL,
    }
  ) {
    if (iAction) {
      return axios
        .put(
          // URL
          'Session/planned/unlock/reject',
          // HEADERS
          iAction
        )
        .then((res) => {
          return res;
        });
    }
  },

  getPatientAction: function (iActionTypeID, iAreaID) {
    // PATIENT ACTIONS
    // 1	Cancelled patient*
    // 2	Uncancelled patient
    // 3	Postponed patient
    // 4	Cancelled patient postponement
    // 5	Abandoned patient
    // 6	Cancelled patient abandonment
    // 7	Transferred patient*
    // 8	Reordered patient
    // 9	Sent patient back to theatre
    // 10	Cancelled back to theatre
    // 11	Patient Outcome
    // 12	Patient booked
    // 21 Golden Patient
    // 23 Red Patient
    // 24	Retrospective Patient Mode
    // 25	Cancel Retrospective Patient Mode

    return axios
      .post(
        // URL
        'Patient/actionType',
        // HEADERS
        {
          Enabled: true,
          ActionTypeID: iActionTypeID,
          AreaID: iAreaID,
        }
      )
      .then((res) => {
        return res;
      });
  },
  getPatientActionProperties: function (iActionTypeID) {
    return axios
      .post(
        // URL
        'Patient/actionType/properties',
        // HEADERS
        {
          Enabled: true,
          ActionTypeID: iActionTypeID,
        }
      )
      .then((res) => {
        return res;
      });
  },
  updatePatientActionType: function (iActionType) {
    if (iActionType) {
      return axios.put('Patient/actionType/update', iActionType).then((response) => {
        return response.data.result;
      });
    }
  },
  getPatientActionTypeAreaProp: function (iActionTypeID) {
    if (iActionTypeID) {
      return axios
        .post(
          // URL
          'Patient/actionType/areaProp',
          // HEADERS
          {
            Enabled: true,
            ActionTypeID: iActionTypeID,
          }
        )
        .then((res) => {
          return res.data.result;
        });
    }
  },

  updatePatientActionTypeAreaProp: function (iActionTypeAreaProp) {
    if (iActionTypeAreaProp) {
      return axios.put('Patient/actionType/areaProp/update', iActionTypeAreaProp).then((response) => {
        return response.data.result;
      });
    }
  },
  deletePatientActionTypeAreaProp: function (iActionTypeAreaProp) {
    if (iActionTypeAreaProp) {
      return axios
        .delete('Patient/actionType/areaProp/delete', {
          data: iActionTypeAreaProp,
        })
        .then((response) => {
          return response.data.result;
        });
    }
  },
  updatePatientActionTypeSchema: function (iActionTypeSchema) {
    if (iActionTypeSchema) {
      return axios.put('Patient/actionType/schema/update', iActionTypeSchema).then((response) => {
        return response.data.result;
      });
    }
  },

  getReasons: function (iReasonGroup) {
    if (iReasonGroup) {
      return axios
        .post(
          // URL
          'reason/GroupLink',
          // HEADERS
          {
            ReasonGroupID: iReasonGroup,
          }
        )
        .then((res) => {
          return res;
        });
    }
  },

  getPatientActualActions: function (iOperationRecordID, iPatientActualID, iActionID) {
    return axios
      .post(
        // URL
        'Patient/actual/action',
        // HEADERS
        {
          OperationRecordID: iOperationRecordID,
          PatientActualID: iPatientActualID,
          PatientActionTypeID: iActionID,
        }
      )
      .then((res) => {
        return res;
      });
  },
  actionDeathPatient: function (iAction) {
    return axios.put('Patient/actual/action/death', iAction).then((response) => {
      return response.data.result;
    });
  },

  actionCancelPatient: function (iAction) {
    return axios.put('Patient/actual/action/cancel', iAction).then((response) => {
      return response.data.result;
    });
  },

  actionUnCancelPatient: function (iAction) {
    return axios.put('Patient/actual/action/uncancel', iAction).then((response) => {
      return response.data.result;
    });
  },

  actionTransferPatient: function (iAction) {
    return axios.put('Patient/actual/action/transfer', iAction).then((response) => {
      return response.data.result;
    });
  },

  actionTransferPatientBulk: function (iAction) {
    return axios.put('Patient/actual/action/bulk/transfer', iAction).then((response) => {
      return response.data.result;
    });
  },

  actionPostponePatient: function (iAction) {
    return axios.put('Patient/actual/action/postpone', iAction).then((response) => {
      return response.data.result;
    });
  },

  actionUnPostponePatient: function (iAction) {
    return axios.put('Patient/actual/action/unpostpone', iAction).then((response) => {
      return response.data.result;
    });
  },

  actionClockStart: function (iAction) {
    return axios.put('Patient/actual/action/clockStart', iAction).then((response) => {
      return response.data.result;
    });
  },

  actionClockStop: function (iAction) {
    return axios.put('Patient/actual/action/clockStop', iAction).then((response) => {
      return response.data.result;
    });
  },

  actionRecordDelay: function (iAction) {
    return axios.put('Patient/actual/action/delay', iAction).then((response) => {
      return response.data.result;
    });
  },

  actionChangePriority: function (iAction) {
    return axios.put('Patient/actual/action/priority', iAction).then((response) => {
      return response.data.result;
    });
  },
  preAssessmentAction: function (iAction) {
    return axios.post('Patient/preAssessment/action', iAction).then((response) => {
      return response.data.result;
    });
  },

  actionPreAssessmentOutcome: function (iAction) {
    return axios.put('Patient/preAssessment/action/outcome', iAction).then((response) => {
      return response.data.result;
    });
  },

  actionReorderPatient: function (resortArray = []) {
    if (resortArray.length > 0) {
      return axios
        .put(
          // URL
          'Patient/actual/action/bulk/reorder',
          // HEADERS
          { Data: resortArray }
        )
        .then((res) => {
          return res;
        });
    }
  },
  getSessionAction: function (iActionTypeID, iAreaID) {
    // SESSION ACTIONS
    //
    // 1	Cancelled session
    // 2	Uncancelled session
    // 3	Placed session on offer
    // 4	Taken offered session
    // 5	Amended session
    // 6	Split session
    // 7	Undone split session
    // 8	Submitted session for locking
    // 9	Rejected session lock
    // 10	Locked session
    // 11	Unlocked Session
    // 12	Deleted Session
    // 13	Created Session
    // 14	Transfer Session
    // 15	Session Personnel

    return axios
      .post(
        // URL
        'session/actionType',
        // HEADERS
        {
          Enabled: true,
          ActionTypeID: iActionTypeID,
          AreaID: iAreaID,
        }
      )
      .then((res) => {
        return res.data.result;
      });
  },

  actionDeleteSession: function (
    iAction = {
      SessionRecordID: null, // *REQUIRED*/[OPTIONAL] Default = NULL, - Required if SessionPlanID and StartDate not supplied
      SessionPlanID: null, // *REQUIRED*/[OPTIONAL] Default = NULL, - Required if SessionRecordID not supplied
      StartDate: null, // *REQUIRED*/[OPTIONAL] Default = NULL, - Required if SessionRecordID not supplied
      ActionDateTime: null, // [OPTIONAL] Default = NULL,
      ActionedByPersonnelID: null, // [OPTIONAL] Default = NULL,
      ActionedByUserID: null, // [OPTIONAL] Default = NULL,
      ReasonID: null, // [OPTIONAL] Default = NULL,
      ReasonFreeText: null, // [OPTIONAL] Default = NULL,
      ReasonDetailID: null, // [OPTIONAL] Default = NULL,
      ValidatedByPersonnelID: null, // [OPTIONAL] Default = NULL,
      Comments: null, // [OPTIONAL] Default = NULL,
    }
  ) {
    if (iAction) {
      return axios
        .delete(
          // URL
          'Session/planned/delete',
          // HEADERS
          { data: iAction }
        )
        .then((res) => {
          // console.log('actionDeleteSession', res);

          return res.data.result;
        });
    }
  },

  actionCancelSession: function (
    iAction = {
      SessionRecordID: null, // *REQUIRED*/[OPTIONAL] Default = NULL, - Required if SessionPlanID and StartDate not supplied
      SessionPlanID: null, // *REQUIRED*/[OPTIONAL] Default = NULL, - Required if SessionRecordID not supplied
      StartDate: null, // *REQUIRED*/[OPTIONAL] Default = NULL, - Required if SessionRecordID not supplied
      ActionDateTime: null, // [OPTIONAL] Default = NULL,
      ActionedByPersonnelID: null, // [OPTIONAL] Default = NULL,
      ActionedByUserID: null, // [OPTIONAL] Default = NULL,
      ReasonID: null, // [OPTIONAL] Default = NULL,
      ReasonFreeText: null, // [OPTIONAL] Default = NULL,
      ReasonDetailID: null, // [OPTIONAL] Default = NULL,
      ValidatedByPersonnelID: null, // [OPTIONAL] Default = NULL,
      Comments: null, // [OPTIONAL] Default = NULL,
    }
  ) {
    if (iAction) {
      return axios
        .put(
          // URL
          'Session/planned/cancel',
          // HEADERS
          iAction
        )
        .then((res) => {
          return res;
        });
    }
  },
  actionSlotReserve: function (
    iAction = {
      SessionRecordID: null, // *REQUIRED*/[OPTIONAL] Default = NULL, - Required if SessionPlanID and StartDate not supplied
      SessionPlanID: null, // *REQUIRED*/[OPTIONAL] Default = NULL, - Required if SessionRecordID not supplied
      StartDate: null, // *REQUIRED*/[OPTIONAL] Default = NULL, - Required if SessionRecordID not supplied
      ActionDateTime: null, // [OPTIONAL] Default = NULL,
      ActionedByPersonnelID: null, // [OPTIONAL] Default = NULL,
      ActionedByUserID: null, // [OPTIONAL] Default = NULL,
      ReasonID: null, // [OPTIONAL] Default = NULL,
      ReasonFreeText: null, // [OPTIONAL] Default = NULL,
      ReasonDetailID: null, // [OPTIONAL] Default = NULL,
      ValidatedByPersonnelID: null, // [OPTIONAL] Default = NULL,
      Comments: null, // [OPTIONAL] Default = NULL,
    }
  ) {
    if (iAction) {
      return axios
        .put(
          // URL
          'Session/planned/slotReserve',
          // HEADERS
          iAction
        )
        .then((res) => {
          return res;
        });
    }
  },
  actionSlotReserveRemove: function (
    iAction = {
      SessionRecordID: null, // *REQUIRED*/[OPTIONAL] Default = NULL, - Required if SessionPlanID and StartDate not supplied
      SessionPlanID: null, // *REQUIRED*/[OPTIONAL] Default = NULL, - Required if SessionRecordID not supplied
      StartDate: null, // *REQUIRED*/[OPTIONAL] Default = NULL, - Required if SessionRecordID not supplied
      ActionDateTime: null, // [OPTIONAL] Default = NULL,
      ActionedByPersonnelID: null, // [OPTIONAL] Default = NULL,
      ActionedByUserID: null, // [OPTIONAL] Default = NULL,
      ReasonID: null, // [OPTIONAL] Default = NULL,
      ReasonFreeText: null, // [OPTIONAL] Default = NULL,
      ReasonDetailID: null, // [OPTIONAL] Default = NULL,
      ValidatedByPersonnelID: null, // [OPTIONAL] Default = NULL,
      Comments: null, // [OPTIONAL] Default = NULL,
    }
  ) {
    if (iAction) {
      return axios
        .put(
          // URL
          'Session/planned/slotReserve/remove',
          // HEADERS
          iAction
        )
        .then((res) => {
          return res;
        });
    }
  },
  actionUndoConfirmAllPatients: function (
    iAction = {
      SessionRecordID: null, // *REQUIRED*/[OPTIONAL] Default = NULL, - Required if SessionPlanID and StartDate not supplied
      SessionPlanID: null, // *REQUIRED*/[OPTIONAL] Default = NULL, - Required if SessionRecordID not supplied
      StartDate: null, // *REQUIRED*/[OPTIONAL] Default = NULL, - Required if SessionRecordID not supplied
      ActionDateTime: null, // [OPTIONAL] Default = NULL,
      ActionedByPersonnelID: null, // [OPTIONAL] Default = NULL,
      ActionedByUserID: null, // [OPTIONAL] Default = NULL,
      ReasonID: null, // [OPTIONAL] Default = NULL,
      ReasonFreeText: null, // [OPTIONAL] Default = NULL,
      ReasonDetailID: null, // [OPTIONAL] Default = NULL,
      ValidatedByPersonnelID: null, // [OPTIONAL] Default = NULL,
      Comments: null, // [OPTIONAL] Default = NULL,
    }
  ) {
    if (iAction) {
      return axios
        .put(
          // URL
          'Session/planned/undo/confirmAllPatients',
          // HEADERS
          iAction
        )
        .then((res) => {
          return res;
        });
    }
  },
  actionConfirmAllPatients: function (
    iAction = {
      SessionRecordID: null, // *REQUIRED*/[OPTIONAL] Default = NULL, - Required if SessionPlanID and StartDate not supplied
      SessionPlanID: null, // *REQUIRED*/[OPTIONAL] Default = NULL, - Required if SessionRecordID not supplied
      StartDate: null, // *REQUIRED*/[OPTIONAL] Default = NULL, - Required if SessionRecordID not supplied
      ActionDateTime: null, // [OPTIONAL] Default = NULL,
      ActionedByPersonnelID: null, // [OPTIONAL] Default = NULL,
      ActionedByUserID: null, // [OPTIONAL] Default = NULL,
      ReasonID: null, // [OPTIONAL] Default = NULL,
      ReasonFreeText: null, // [OPTIONAL] Default = NULL,
      ReasonDetailID: null, // [OPTIONAL] Default = NULL,
      ValidatedByPersonnelID: null, // [OPTIONAL] Default = NULL,
      Comments: null, // [OPTIONAL] Default = NULL,
    }
  ) {
    if (iAction) {
      return axios
        .put(
          // URL
          'Session/planned/confirmAllPatients',
          // HEADERS
          iAction
        )
        .then((res) => {
          return res;
        });
    }
  },

  actionUnCancelSession: function (
    iAction = {
      SessionRecordID: null, // *REQUIRED*/[OPTIONAL] Default = NULL, - Required if SessionPlanID and StartDate not supplied
      SessionPlanID: null, // *REQUIRED*/[OPTIONAL] Default = NULL, - Required if SessionRecordID not supplied
      StartDate: null, // *REQUIRED*/[OPTIONAL] Default = NULL, - Required if SessionRecordID not supplied
      ActionDateTime: null, // [OPTIONAL] Default = NULL,
      ActionedByPersonnelID: null, // [OPTIONAL] Default = NULL,
      ActionedByUserID: null, // [OPTIONAL] Default = NULL,
      ReasonID: null, // [OPTIONAL] Default = NULL,
      ReasonFreeText: null, // [OPTIONAL] Default = NULL,
      ReasonDetailID: null, // [OPTIONAL] Default = NULL,
      ValidatedByPersonnelID: null, // [OPTIONAL] Default = NULL,
      Comments: null, // [OPTIONAL] Default = NULL,
    }
  ) {
    if (iAction) {
      return axios
        .put(
          // URL
          'Session/planned/uncancel',
          // HEADERS
          iAction
        )
        .then((res) => {
          return res;
        });
    }
  },

  actionOfferSession: function (
    iAction = {
      SessionRecordID: null, // *REQUIRED*/[OPTIONAL] Default = NULL, - Required if SessionPlanID and StartDate not supplied
      SessionPlanID: null, // *REQUIRED*/[OPTIONAL] Default = NULL, - Required if SessionRecordID not supplied
      StartDate: null, // *REQUIRED*/[OPTIONAL] Default = NULL, - Required if SessionRecordID not supplied
      ActionDateTime: null, // [OPTIONAL] Default = NULL,
      ActionedByPersonnelID: null, // [OPTIONAL] Default = NULL,
      ActionedByUserID: null, // [OPTIONAL] Default = NULL,
      ReasonID: null, // [OPTIONAL] Default = NULL,
      ReasonFreeText: null, // [OPTIONAL] Default = NULL,
      ReasonDetailID: null, // [OPTIONAL] Default = NULL,
      ValidatedByPersonnelID: null, // [OPTIONAL] Default = NULL,
      Comments: null, // [OPTIONAL] Default = NULL,
    }
  ) {
    if (iAction) {
      return axios
        .put(
          // URL
          'Session/planned/offer',
          // HEADERS
          iAction
        )
        .then((res) => {
          return res;
        });
    }
  },

  actionTakeSession: function (iAction) {
    if (iAction) {
      // iAction.UpdateSession.PlannedSessionStartTime = iAction.UpdateSession.PlannedSessionStartTime.substr(0,5);
      // iAction.UpdateSession.PlannedSessionFinishTime = iAction.UpdateSession.PlannedSessionFinishTime.substr(0,5);

      return axios
        .put(
          // URL
          'Session/planned/take',
          // HEADERS
          iAction
        )
        .then((response) => {
          return response.data.result;
        });
    }
  },

  actionUpdateSession: function (iAction) {
    if (iAction) {
      // iAction.UpdateSession.PlannedSessionStartTime = iAction.UpdateSession.PlannedSessionStartTime.substr(0,5);
      // iAction.UpdateSession.PlannedSessionFinishTime = iAction.UpdateSession.PlannedSessionFinishTime.substr(0,5);

      return axios
        .put(
          // URL
          'Session/planned/amend',
          // HEADERS
          iAction
        )
        .then((response) => {
          return response.data.result;
        });
    }
  },
  actionUpdateSessionTimes: function (iAction) {
    if (iAction) {
      return axios
        .put(
          // URL
          'Session/planned/times/amend',
          // HEADERS
          iAction
        )
        .then((response) => {
          return response.data.result;
        });
    }
  },
  actionUpdateSessionPersonnel: function (iAction) {
    if (iAction) {
      return axios
        .put(
          // URL
          'Session/planned/personnel/amend',
          // HEADERS
          iAction
        )
        .then((response) => {
          return response.data.result;
        });
    }
  },
  actionUpdateSessionResources: function (iAction) {
    if (iAction) {
      return axios
        .put(
          // URL
          'Session/planned/resources/amend',
          // HEADERS
          iAction
        )
        .then((response) => {
          return response.data.result;
        });
    }
  },
  actionTransferSession: function (iAction) {
    if (iAction) {
      // store.dispatch("clearSessionStore", {});

      return axios
        .put(
          // URL
          'Session/planned/move',
          // HEADERS
          iAction
        )
        .then((res) => {
          return res;
        });
    }
  },

  actionSplitSession: function (iAction) {
    if (iAction) {
      store.dispatch('clearSessionStore', {});

      return axios
        .put(
          // URL
          'Session/planned/split',
          // HEADERS
          iAction
        )
        .then((res) => {
          // CLEAR EXISTING SESSION

          // console.log('actionSplitSession RESPONSE', res.data.result.UpdateSession.SessionRecordID)
          // store.dispatch("deleteSessionStore", [iAction.UpdateSession]);

          return res;
        });
    }
  },

  actionUnsplitSession: function (iAction) {
    if (iAction) {
      store.dispatch('clearSessionStore', {});

      return axios
        .put(
          // URL
          'Session/planned/unsplit',
          // HEADERS
          iAction
        )
        .then((res) => {
          return res;
        });
    }
  },
  actionSignoffSession: function (iAction) {
    if (iAction) {
      return axios
        .put(
          // URL
          'Session/planned/signoff',
          // HEADERS
          iAction
        )
        .then((res) => {
          return res;
        });
    }
  },

  getSessionPlannedActions: function (
    iAction = {
      SessionRecordID: null, // [OPTIONAL] Default = NULL,
      ActionTypeID: null, // [OPTIONAL] Default = NULL,
    }
  ) {
    if (iAction) {
      return axios
        .post(
          // URL
          'Session/Planned/action',
          // HEADERS
          { ...iAction }
        )
        .then((res) => {
          return res.data.result;
        });
    }
  },

  getPatientTourniquet: function (TourniquetRequest) {
    return axios
      .post(
        // URL
        'Patient/actual/tourniquet',
        // DATA
        TourniquetRequest
      )
      .then((response) => {
        return response.data.result;
      });
  },

  inflatePatientTourniquet: function (TourniquetInflate) {
    return axios
      .put(
        // URL
        'Patient/actual/tourniquet/inflate',
        // DATA
        TourniquetInflate
      )
      .then((response) => {
        return response.data.result;
      });
  },
  deflatePatientTourniquet: function (TourniquetDeflate) {
    return axios
      .put(
        // URL
        'Patient/actual/tourniquet/deflate',
        // DATA
        TourniquetDeflate
      )
      .then((response) => {
        return {
          resultID: response.id,
          resultText: response.title,
          resultType: 'success',
        };
      });
  },
  reinflatePatientTourniquet: function (TourniquetReinflate) {
    return axios
      .put(
        // URL
        'Patient/actual/tourniquet/reinflate',
        // DATA
        TourniquetReinflate
      )
      .then((response) => {
        return {
          resultID: response.id,
          resultText: response.title,
          resultType: 'success',
        };
      });
  },
  removePatientTourniquet: function (TourniquetRemove) {
    return axios
      .put(
        // URL
        'Patient/actual/tourniquet/remove',
        // DATA
        TourniquetRemove
      )
      .then((response) => {
        return {
          resultID: response.id,
          resultText: response.title,
          resultType: 'success',
        };
      });
  },
  deletePatientTourniquet: function (TourniquetDelete) {
    return axios
      .delete(
        // URL
        'Patient/actual/tourniquet/delete',
        // DATA
        { data: TourniquetDelete }
      )
      .then((response) => {
        return {
          resultID: response.id,
          resultText: response.title,
          resultType: 'success',
        };
      });
  },
  getPatientTourniquetHistory: function (TourniquetRequest) {
    return axios
      .post(
        // URL
        'Patient/actual/tourniquet/history',
        // DATA
        TourniquetRequest
      )
      .then((response) => {
        return response.data.result;
      });
  },
  validatePatientTourniquet: function (TourniquetRequest) {
    return axios
      .post(
        // URL
        'Patient/actual/tourniquet/validate',
        // DATA
        TourniquetRequest
      )
      .then((response) => {
        return response.data.result;
      });
  },
  getPatientAnaestheticSchema: function (AnaestheticsRequest) {
    return axios.post('Patient/actual/anaesthetic/schema', AnaestheticsRequest).then((res) => {
      return res.data.result;
    });
  },
  updatePatientAnaestheticSchema: function (iAnaesthetic) {
    if (iAnaesthetic) {
      return axios.put('Patient/actual/anaesthetic/schema/amend', iAnaesthetic).then((response) => {
        return response.data.result;
      });
    }
  },
  getSignoff: function (iSignoffID) {
    return axios.post('Signoff', { SignOffID: iSignoffID }).then((res) => {
      return res.data.result;
    });
  },
  createSignoff: function (iSignoff) {
    if (iSignoff) {
      return axios.post('Signoff/create', iSignoff).then((response) => {
        return response.data.result;
      });
    }
  },

  validatePatientAnaesthetic: function (AnaestheticRequest) {
    return axios
      .post(
        // URL
        'Patient/actual/anaesthetic/validate',
        // DATA
        AnaestheticRequest
      )
      .then((response) => {
        return response.data.result;
      });
  },
  getPatientFunctionSchema: function (FunctionRequest) {
    if (FunctionRequest && FunctionRequest.PatientActualID) {
      return axios.post('Patient/actual/function/schema', FunctionRequest).then((res) => {
        return res.data.result;
      });
    }
    return Promise.resolve();
  },
  updatePatientFunctionForm: function (iFunctionForm) {
    if (iFunctionForm) {
      return axios.put('Patient/actual/function/form/update', iFunctionForm).then((response) => {
        return response.data.result;
      });
    }
  },
  actionRecordGold: function (iAction) {
    return axios.put('Patient/actual/action/golden', iAction).then((response) => {
      return response.data.result;
    });
  },
  actionRemoveGold: function (iAction) {
    return axios.put('Patient/actual/action/golden/remove', iAction).then((response) => {
      return response.data.result;
    });
  },
  actionRecordRetro: function (iAction) {
    return axios.put('Patient/actual/action/retro', iAction).then((response) => {
      return response.data.result;
    });
  },
  actionRemoveRetro: function (iAction) {
    return axios.put('Patient/actual/action/retro/remove', iAction).then((response) => {
      return response.data.result;
    });
  },
  actionResetEpisode: function (iPatientActualID) {
    return axios
      .delete(
        // URL
        'Patient/actual/delete',
        // DATA
        { data: { PatientActualID: parseInt(iPatientActualID) } }
      )
      .then((response) => {
        return {
          resultID: response.id,
          resultText: response.title,
          resultType: 'success',
        };
      });
  },
  getSessionActionTypeAreaProp: function (iActionTypeID) {
    if (iActionTypeID) {
      return axios
        .post(
          // URL
          'Session/actionType/areaProp',
          // HEADERS
          {
            Enabled: true,
            ActionTypeID: iActionTypeID,
          }
        )
        .then((res) => {
          return res.data.result;
        });
    }
  },

  updateSessionActionTypeAreaProp: function (iActionTypeAreaProp) {
    if (iActionTypeAreaProp) {
      return axios.put('Session/actionType/areaProp/update', iActionTypeAreaProp).then((response) => {
        return response.data.result;
      });
    }
  },
  deleteSessionActionTypeAreaProp: function (iActionTypeAreaProp) {
    if (iActionTypeAreaProp) {
      return axios
        .delete('Session/actionType/areaProp/delete', {
          data: iActionTypeAreaProp,
        })
        .then((response) => {
          return response.data.result;
        });
    }
  },
  updateSessionActionTypeSchema: function (iActionTypeSchema) {
    if (iActionTypeSchema) {
      return axios.put('Session/actionType/schema/update', iActionTypeSchema).then((response) => {
        return response.data.result;
      });
    }
  },
  patientActualActionChangeForm: function (iAction) {
    return axios.put('Patient/actual/action/change/form', iAction).then((response) => {
      return response.data.result;
    });
  },
  actionConfirmSession: function (iAction) {
    if (iAction) {
      // store.dispatch("clearSessionStore", {});

      return axios
        .put(
          // URL
          'Session/planned/confirm',
          // HEADERS
          iAction
        )
        .then((res) => {
          return res;
        });
    }
  },
  actionConfirmSessionUndo: function (iAction) {
    if (iAction) {
      // store.dispatch("clearSessionStore", {});

      return axios
        .put(
          // URL
          'Session/planned/confirm/undo',
          // HEADERS
          iAction
        )
        .then((res) => {
          return res;
        });
    }
  },
  actionFinaliseSessionUndo: function (iAction) {
    if (iAction) {
      // store.dispatch("clearSessionStore", {});

      return axios
        .put(
          // URL
          'Session/planned/finalise/undo',
          // HEADERS
          iAction
        )
        .then((res) => {
          return res;
        });
    }
  },
};
