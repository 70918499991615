const GenericRoute = () => import('/src/components/application/_GenericRoute');

const LocationList = () => import('/src/components/locations/LocationList');
const LocationView = () => import('/src/components/locations/LocationView');

export default [
  {
    path: '/locations',
    component: GenericRoute,
    meta: {
      title: 'Locations',
    },
    children: [
      {
        path: ':locationid',
        name: 'location_view',
        component: LocationView,
        meta: {
          title: 'Live Location',
          functionCode: 'LIVELOCATIONS',
          areaDisabled: true,
          backRoutes: ['live_locations'],
        },
      },
      {
        path: '',
        name: 'live_locations',
        component: LocationList,
        meta: {
          title: 'Live Locations',
          tags: ['Landing Page'],
          functionCode: 'LIVELOCATIONS',
          hideBackButton: true,
          isLandingRoute: true,
        },
      },
    ],
  },
];
