<template>
  <div id="fullscreenLayout">
    <div id="appContentCover" @click="showContent()" :class="[showCover ? 'active' : '']" role="complementary"></div>
    <router-view></router-view>
    <AppMessages />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

const AppMessages = () => import('/src/components/application/AppMessages');

export default {
  name: 'fullscreen-layout',
  data() {
    return {};
  },
  components: {
    AppMessages,
  },
  computed: {
    showCover: {
      get() {
        return this.$store.state.appCover > 0;
      },
    },
  },
  methods: {
    ...mapActions(['updateAppCover']),

    showContent() {
      this.updateAppCover(false);
    },
  },
};
</script>

<style lang="less"></style>
