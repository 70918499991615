import { browserStorage } from '/src/mixins/browserStorage';

const SiteMap = () => import('/src/components/_sitemap/sitemap');
const AQUAIcons = () => import('/src/components/_concepts/AQUAIcons');

export default [
  {
    path: '/clear_cache',
    name: 'clear_cache',
    meta: {
      siteMapHidden: true,
    },
    beforeEnter: () => {
      browserStorage.clear().then(() => {
        handleHardReload(window.location.origin);
      });
    },
  },
  {
    path: '/sitemap',
    name: 'sitemap',
    component: SiteMap,
    meta: {
      title: 'AQUA Site Map',
      hideBackButton: true,
      siteMapHidden: true,
    },
  },
  {
    path: '/aquaicons',
    name: 'aquaicons',
    component: AQUAIcons,
    meta: {
      title: 'AQUA Icons',
      hideBackButton: true,
      siteMapHidden: true,
    },
  },
];

async function handleHardReload(url) {
  await fetch(url, {
    headers: {
      Pragma: 'no-cache',
      Expires: '-1',
      'Cache-Control': 'no-cache',
    },
  });
  window.location.href = url;
  window.location.reload();
}
