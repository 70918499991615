import security from '/src/routes/admin/01-security';
import location from '/src/routes/admin/02-location';
import personnel from '/src/routes/admin/03-personnel';
import resource from '/src/routes/admin/04-resource';
import general from '/src/routes/admin/05-general';
import procedure from '/src/routes/admin/06-procedure';
import form from '/src/routes/admin/07-form';
import pathway from '/src/routes/admin/08-pathway';
import session from '/src/routes/admin/09-session';
import report from '/src/routes/admin/10-report';

const GenericRoute = () => import('/src/components/application/_GenericRoute');

const AdminHome = () => import('/src/components/admin/AdminHome');

export default [
  {
    path: '/administration',
    component: GenericRoute,
    meta: {
      title: 'Administration',
      tags: ['Landing Page'],
    },
    children: [
      // ======================================== \\
      // Administration Routes by Category
      // ======================================== \\
      ...security,
      ...location,
      ...personnel,
      ...resource,
      ...general,
      ...procedure,
      ...form,
      ...pathway,
      ...session,
      ...report,

      // ======================================== \\
      // Administration Home
      // ======================================== \\
      {
        path: '',
        name: 'admin_home',
        component: AdminHome,
        meta: {
          title: 'Administration',
          tags: ['Landing Page'],
          functionCode: 'ADMIN',
          hideBackButton: true,
          isLandingRoute: true,
        },
      },
    ],
  },
];
