<template>
  <div id="sessionActionForm">
    <ContentLoading displaystyle="boxed" />

    <div class="tile" v-if="actionOptions && canEdit">
      <div class="form">
        <div class="tilebody">
          <div class="tileheaderdescription">
            <div class="icon action"></div>
            <h2>{{ formatActionDesc(actionOptions.ActionTypeDescription) }}</h2>
            <h4 class="m0">Complete the action</h4>
          </div>
        </div>

        <div class="tilebody">
          <div class="row" v-if="actionOptions.EnterReason">
            <div class="col-sm-12">
              <ReasonCaptureComponent
                v-model="actionForm"
                :reasongroup="actionOptions.DefaultReasonGroupID"
                :showdetailedreason="actionOptions.EnterReasonDetail"
                :submitted="submitted"
                :hidetitle="true"
                :hidetile="true"
                :allowcopypatientdelay="false"
                :donotfocus="true"
                @reasonList="reasons = $event"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6" v-if="actionOptions.EnterActionedBy">
              <div
                class="form-group"
                :class="[!$v.actionForm.ActionedByPersonnelID.required ? 'isrequired' : '', actionOptions.EnterActionedBy == true && actionForm.ActionedByPersonnelID ? 'hasvalue' : '']"
              >
                <label for="actionForm.ActionedByPersonnelID">Authorised By</label>
                <div class="withinputbtn">
                  <button
                    type="button"
                    ref="actionForm.ActionedByPersonnelID"
                    id="actionForm.ActionedByPersonnelID"
                    class="inputbtn cancel"
                    @click.stop.prevent="
                      selectedPersonnelRec = null;
                      actionForm.ActionedByPersonnelID = null;
                    "
                    v-if="selectedPersonnelRec"
                  ></button>
                  <button type="button" class="inputbtn search" v-if="!selectedPersonnelRec" @click="personnelSearchPopOver = true"></button>
                  <button type="button" class="searchselect" @click.stop.prevent="personnelSearchPopOver = true">
                    {{ selectedPersonnelRec ? selectedPersonnelRec.PersonnelName : 'Click to select personnel' }}
                  </button>
                </div>
                <div class="errormessage" v-if="submitted && !$v.actionForm.ActionedByPersonnelID.required">Actioned by is required</div>
              </div>
            </div>
            <div class="col-sm-6" v-if="actionOptions.EnterValidatedBy">
              <div
                class="form-group"
                :class="[!$v.actionForm.ValidatedByPersonnelID.required ? 'isrequired' : '', actionOptions.EnterValidatedBy == true && actionForm.ValidatedByPersonnelID ? 'hasvalue' : '']"
              >
                <label for="actionForm.ValidatedByPersonnelID">Validated By</label>
                <div class="withinputbtn">
                  <button
                    type="button"
                    id="actionForm.ValidatedByPersonnelID"
                    ref="actionForm.ValidatedByPersonnelID"
                    class="inputbtn cancel"
                    @click.stop.prevent="
                      selectedValidatedPersonnelRec = null;
                      actionForm.ValidatedByPersonnelID = null;
                    "
                    v-if="selectedValidatedPersonnelRec"
                  ></button>
                  <button type="button" class="inputbtn search" v-if="!selectedValidatedPersonnelRec" @click="personnelValidatedSearchPopOver = true"></button>
                  <button type="button" class="searchselect" @click="personnelValidatedSearchPopOver = true">
                    {{ selectedValidatedPersonnelRec ? selectedValidatedPersonnelRec.PersonnelName : 'Click to select personnel' }}
                  </button>
                </div>
                <div class="errormessage" v-if="submitted && !$v.actionForm.ValidatedByPersonnelID.required">Validated by is required</div>
              </div>
            </div>
          </div>
          <div class="row" v-if="actionOptions.EditActionDateTime">
            <div :class="actionOptions.EditActionDateTime ? 'col-sm-6' : 'col-sm-4'">
              <div class="form-group isrequired" :class="[displayActionDateTime && actionForm.ActionTime && actionForm.ActionDateTime ? 'hasvalue' : '']">
                <label for="actionForm.ActionDateTime">Actioned At</label>
                <label for="actionForm.ActionTime" style="display: none">Action Time</label>
                <div class="searchselect action disabled" v-if="actionOptions.EditActionDateTime == false" style="margin-right: 3px; margin-bottom: 3px">
                  {{ displayActionDateTime | moment('DD/MM/YYYY') }}
                </div>
                <v-date-picker
                  id="actionForm.ActionDateTime"
                  ref="actionForm.ActionDateTime"
                  v-model="actionForm.ActionDateTime"
                  v-else
                  :max-date="new Date()"
                  is-expanded
                  :attributes="calattributes"
                  :first-day-of-week="2"
                  locale="en-GB"
                  class="datepickerinput"
                  style="margin-right: 3px"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input :value="inputValue" v-on="inputEvents" aria-label="Actioned Date" />
                  </template>
                </v-date-picker>

                <input
                  id="actionForm.ActionTime"
                  ref="actionForm.ActionTime"
                  type="time"
                  min="00:00"
                  max="23:59"
                  class="searchselect action"
                  :class="actionOptions.EditActionDateTime == false ? 'disabled' : ''"
                  :disabled="actionOptions.EditActionDateTime == false"
                  v-model="actionForm.ActionTime"
                  aria-label="Actioned Time"
                />
                <a href="#" v-if="actionOptions.EditActionDateTime" class="inlineedit refresh" style="margin-left: 15px" @click.stop.prevent="actionForm.ActionTime = DateFunctions.getTimeFromDate()">
                  Refresh time
                </a>
              </div>
              <div class="errormessage" v-if="submitted && !$v.actionForm.ActionTime.required">Action time is required</div>
              <div class="errormessage" v-if="submitted && !$v.actionForm.ActionDateTime.required">Action date is required</div>
            </div>
          </div>

          <div class="row" v-if="actionOptions.EnterComment">
            <div class="col-sm-12">
              <div class="form-group" :class="[!$v.actionForm.Comments.required ? 'isrequired' : '', this.actionOptions.EnterComment == true && actionForm.Comments ? 'hasvalue' : '']">
                <label for="actionForm.Comments">Comments</label>
                <textarea
                  id="actionForm.Comments"
                  ref="actionForm.Comments"
                  :rows="actiontypeid == 23 ? 1 : 4"
                  :maxlength="actiontypeid == 23 ? 100 : 1000"
                  v-lengthcheck
                  class="form-control"
                  v-model="actionForm.Comments"
                ></textarea>

                <div class="errormessage" v-if="submitted && !$v.actionForm.Comments.required">Comments are required</div>
                <div class="errormessage" v-if="submitted && !$v.actionForm.Comments.maxLength">Comments cannot exceed 1000 characters</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <transition name="popup">
      <PopOver @closePopOver="personnelSearchPopOver = null" v-if="personnelSearchPopOver" :refocusref="'actionForm.ActionedByPersonnelID'">
        <template v-slot:popovertitle>
          <h2>Personnel</h2>
        </template>
        <template v-slot:popovercontent>
          <PersonnelSearch
            @confirmPersonnelSearch="confirmPersonnelSearch($event)"
            :ignoreids="actionForm && actionForm.ValidatedByPersonnelID ? [actionForm.ValidatedByPersonnelID] : null"
            @cancelPersonnelSearch="personnelSearchPopOver = false"
          />
        </template>
      </PopOver>
      <PopOver @closePopOver="personnelValidatedSearchPopOver = null" v-if="personnelValidatedSearchPopOver" :refocusref="'actionForm.ValidatedByPersonnelID'">
        <template v-slot:popovertitle>
          <h2>Personnel</h2>
        </template>
        <template v-slot:popovercontent>
          <PersonnelSearch
            @confirmPersonnelSearch="confirmValidatedPersonnelSearch($event)"
            :ignoreids="actionForm && actionForm.ActionedByPersonnelID ? [actionForm.ActionedByPersonnelID] : null"
            @cancelPersonnelSearch="personnelValidatedSearchPopOver = false"
          />
        </template>
      </PopOver>
    </transition>
  </div>
</template>

<script>
import { required, maxLength, requiredIf } from 'vuelidate/lib/validators';

import { ActionService } from '/src/services/actions';

export default {
  props: ['value', 'actiontypeid', 'submitted', 'areaid'],
  name: 'session_actionForm',
  data() {
    return {
      actionOptions: null,
      reasons: [],
      personnelSearchPopOver: false,
      personnelValidatedSearchPopOver: false,
      selectedPersonnelRec: null,
      selectedValidatedPersonnelRec: null,
      displayActionDateTime: new Date(),

      actionForm: {
        OperationRecordID: null,
        ActionDateTime: new Date(),
        ActionTime: null,
        ActionedByPersonnelID: null,
        ActionedByUserID: null,
        ReasonID: null,
        ReasonFreeText: null,
        ReasonDetailID: null,
        ValidatedByPersonnelID: null,
        Comments: null,
      },
      calattributes: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#66b3cc', // Turquoise
            borderColor: '#53a9c6',
            borderWidth: '2px',
            borderRadius: '5px',
          },
          contentStyle: {
            color: 'white',
          },
          //dot: true,
          dates: new Date(),
        },
      ],
    };
  },
  watch: {
    areaid: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.loadActionOptions();
        }
      },
    },
  },
  created() {
    this.actionForm = this.value;

    if (!this.actionForm.ActionTime) {
      this.actionForm.ActionTime = this.DateFunctions.getTimeFromDate();
    }

    this.loadActionOptions();
  },
  computed: {
    canEdit: function () {
      return (
        this.actionOptions.EnterActionedBy ||
        this.actionOptions.EnterValidatedBy ||
        this.actionOptions.EditActionDateTime ||
        this.actionOptions.EnterComment ||
        this.actionOptions.EnterReason ||
        this.actionOptions.EnterReasonDetail
      );
    },

    selectedReason: function () {
      if (this.actionForm && this.actionForm.ReasonID && this.reasons && this.reasons.length) {
        return this.reasons.find((r) => r.ReasonID === this.actionForm.ReasonID);
      } else {
        return null;
      }
    },
  },
  components: {
    PersonnelSearch: () => import('/src/components/personnel/PersonnelSearch'),
    ReasonCaptureComponent: () => import('/src/components/route/episode/function/functions/ReasonCaptureComponent'),
  },

  validations: {
    actionForm: {
      ReasonID: {
        required: requiredIf(function () {
          return this.actionOptions.EnterReason == true && this.reasons && this.reasons.length;
        }),
      },
      ReasonFreeText: {
        maxLength: maxLength(1000),
        required: requiredIf(function () {
          return this.actionOptions.EnterReason == true && this.actionForm.ReasonID && this.selectedReason && this.selectedReason.CommentsRequired == true;
        }),
      },
      ReasonDetailID: {
        required: requiredIf(function () {
          return this.actionOptions.EnterReasonDetail == true && this.actionForm.ReasonID && this.selectedReason && this.selectedReason.DetailExists != 0;
        }),
      },
      ActionedByPersonnelID: {
        required: requiredIf(function () {
          return this.actionOptions.EnterActionedBy == true;
        }),
      },
      ValidatedByPersonnelID: {
        required: requiredIf(function () {
          return this.actionOptions.EnterValidatedBy == true;
        }),
      },
      Comments: {
        required: requiredIf(function () {
          return this.actionOptions.EnterComment == true;
        }),
        maxLength: maxLength(1000),
      },
      ActionDateTime: {
        required,
      },
      ActionTime: {
        required,
      },
    },
  },
  methods: {
    confirmPersonnelSearch: function (iPersonnel) {
      this.selectedPersonnelRec = iPersonnel;
      this.actionForm.ActionedByPersonnelID = iPersonnel.PersonnelID;
      this.personnelSearchPopOver = false;
    },
    confirmValidatedPersonnelSearch: function (iPersonnel) {
      this.selectedValidatedPersonnelRec = iPersonnel;
      this.actionForm.ValidatedByPersonnelID = iPersonnel.PersonnelID;
      this.personnelValidatedSearchPopOver = false;
    },
    confirmAction() {
      this.submitted = true;
      this.$v.$touch();
      this.$emit('confirmactionForm', this.actionForm);
    },

    formatActionDesc: function (actionDesc) {
      return actionDesc ? actionDesc.charAt(0).toUpperCase() + actionDesc.toLowerCase().slice(1) : null;
    },
    loadActionOptions: function () {
      ActionService.getSessionAction(this.actiontypeid, this.areaid).then((response) => {
        this.actionOptions = response;
      });
    },
  },
};
</script>
<style lang="less">
#sessionActionForm {
  .icon {
    left: 10px;
    width: 35px;
    height: 40px;
    top: 5px;
  }
}
</style>
