const GenericRoute = () => import('/src/components/application/_GenericRoute');

const LivePatients = () => import('/src/components/patient/LivePatients');
const PatientMerge = () => import('/src/components/patient/views/PatientMerge');
const PatientCasenoteEdit = () => import('/src/components/patient/views/PatientCasenoteEdit');
const PatientView = () => import('/src/components/patient/views/PatientSummary');
const PatientForms = () => import('/src/components/patient/views/PatientForms');
const PatientPreAssessment = () => import('/src/components/patient/views/PreAssessment');
// const PatientFormEntry = () => import('/src/components/patient/views/PatientFormEntry');
const PatientSearch = () => import('/src/components/patient/PatientSearchBeta');
const PatientPreAssessmentForm = () => import('/src/components/patient/views/PatientPreAssessmentForm');
const PatientPreAssessmentView = () => import('/src/components/patient/views/PatientPreAssessmentView');

const PatientsBySession = () => import('/src/components/patient/PatientsBySession');

const FormDisplay = () => import('/src/components/forms/FormDisplay');

const WaitingListLanding = () => import('/src/components/waitinglist/views/WaitingListLanding');
const WaitingListView = () => import('/src/components/waitinglist/views/WaitingListView');
const WaitingListPAS = () => import('/src/components/waitinglist/views/WaitingPAS');

export default [
  {
    path: '/patient',
    component: GenericRoute,
    meta: {
      title: 'Patients',
    },
    children: [
      {
        path: 'bysession',
        name: 'patients_by_session',
        component: PatientsBySession,
        meta: {
          title: 'Patients By Session',
          functionCode: 'PATIENT',
        },
      },
      {
        path: 'live',
        name: 'live_patients',
        component: LivePatients,
        meta: {
          title: 'Live Patients',
          tags: ['Landing Page'],
          functionCode: 'LIVEPATIENTS',
          hideBackButton: true,
          isLandingRoute: true,
        },
      },
      {
        path: '/patient/planning',
        component: GenericRoute,
        meta: {
          title: 'Waiting Lists',
        },
        children: [
          {
            path: ':listid/:specialtyid?/:consultantid?/:sessionrecordid?/:sessionplanid?/:y?/:m?/:d?',
            component: GenericRoute,
            meta: {
              title: 'Waiting Lists',
            },
            children: [
              {
                path: 'pasepisode/:pasepisodeid/:patientid',
                name: 'waiting_list_pas_episode',
                component: WaitingListPAS,
                meta: {
                  title: 'Waiting List - Patient - PAS Episode',
                  functionCode: 'PATIENTPLANNING',
                  backRoutes: ['waiting_list_view'],
                },
              },
              {
                path: '',
                name: 'waiting_list_view',
                component: WaitingListView,
                meta: {
                  title: 'Waiting List',
                  functionCode: 'PATIENTPLANNING',
                  backRoutes: ['waiting_list','session_scheduler_list'],
                },
              },
            ],
          },
          {
            path: '',
            name: 'waiting_list',
            component: WaitingListLanding,
            meta: {
              title: 'Waiting Lists',
              tags: ['Landing Page'],
              functionCode: 'PATIENTPLANNING',
              hideBackButton: true,
              isLandingRoute: true,
            },
          },
        ],
      },
      {
        path: ':patientid',
        component: GenericRoute,
        meta: {
          title: 'Patient',
        },
        children: [
          {
            path: 'casenoteedit',
            name: 'patient_casenote_edit',
            component: PatientCasenoteEdit,
            meta: {
              title: 'Patient Casenote Edit',
              functionCode: 'PATIENTCASENOTE',
              backRoutes: ['patient_view'],
            },
          },
          {
            path: 'merge',
            name: 'patient_merge',
            component: PatientMerge,
            meta: {
              title: 'Patient Merge',
              functionCode: 'PATIENTMERGE',
              backRoutes: ['patient_view'],
            },
          },
          {
            path: 'pasepisode/:pasepisodeid',
            name: 'patientpasepisode',
            component: PatientView,
            meta: {
              title: 'Patient Episode',
              functionCode: 'PATIENT',
              backRoutes: ['patient_view'],
            },
          },
          {
            path: 'preassessment',
            component: GenericRoute,
            meta: {
              title: 'Patient Pre-Assessment',
            },
            children: [
              {
                path: 'select',
                name: 'patient_preassessment_select',
                component: PatientForms,
                meta: {
                  title: 'Patient Pre-Assessment',
                  functionCode: 'PREASSESSMENT',
                },
              },
              {
                path: ':preassessmentid/:operationrecordid?/:pasepisodeid?',
                component: GenericRoute,
                meta: {
                  title: 'Patient Pre-Assessment Form',
                },
                children: [
                  {
                    path: 'formentry/:formid',
                    component: GenericRoute,
                    meta: {
                      title: 'Patient Pre-Assessment Form',
                    },
                    children: [
                      {
                        path: ':revisionid?',
                        name: 'patient_preassessment_form_with_revision',
                        component: PatientPreAssessmentForm,
                        meta: {
                          title: 'Patient Pre-Assessment Form',
                          functionCode: 'PREASSESSMENT',
                          backRoutes: ['patient_preassessment_view'],
                        },
                      } /* 
                      {
                        path: '',
                        name: 'patient_preassessment_form',
                        component: PatientFormEntry,
                        meta: {
                          title: 'Patient Pre-Assessment Forms',
                          functionCode: 'PREASSESSMENT',
                          backRoutes: ['patient_preassessment_view'],
                        },
                      }, */,
                    ],
                  },
                  {
                    path: 'formdisplay/:formid/:actualformid',
                    name: 'patient_preassessment_form_display',
                    component: FormDisplay,
                    meta: {
                      title: 'Patient Pre-Assessment',
                      functionCode: 'PREASSESSMENT',
                      backRoutes: ['patient_preassessment_view'],
                    },
                  },
                  {
                    path: 'display',
                    name: 'patient_preassessment_display',
                    component: FormDisplay,
                    meta: {
                      title: 'Patient Pre-Assessment Display',
                      functionCode: 'PREASSESSMENT',
                      backRoutes: ['patient_preassessment_view'],
                    },
                  },
                  {
                    path: '',
                    name: 'patient_preassessment_view',
                    component: PatientPreAssessment,
                    meta: {
                      title: 'Patient Pre-Assessment',
                      functionCode: 'PREASSESSMENT',
                      backRoutes: ['patient_view', 'waiting_list_pas_episode', 'session_view','working_list_view','session_scheduler_list'],
                    },
                  },
                ],
              },
              {
                path: '',
                name: 'patient_preassessment_list',
                component: PatientPreAssessmentView,
                meta: {
                  title: 'Patient Pre-Assessments',
                  functionCode: 'PREASSESSMENT',
                },
              },
            ],
          },
          {
            path: '',
            name: 'patient_view',
            component: PatientView,
            meta: {
              title: 'Patient View',
              functionCode: 'PATIENT',
              backRoutes: ['patient_search'],
            },
          },
        ],
      },
      {
        path: '',
        name: 'patient_search',
        component: PatientSearch,
        meta: {
          title: 'Patient Search',
          tags: ['Landing Page'],
          functionCode: 'PATIENT',
          hideBackButton: true,
          isLandingRoute: true,
        },
      },
    ],
  },
];
