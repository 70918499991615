// ====================================================================== \\
// == Route Assistant: Functions for accessing route data              == \\
// ====================================================================== \\
export const routeAssistant = {
  // ====================================================================== \\
  // == Public function pointers                                         == \\
  // ====================================================================== \\
  getRoute: _getRoute,
  getRouteFunctionCode: _getRouteFunctionCode,
  addRouteToHistory: _addRouteToHistory,
  removeTabRouteHistory: _removeTabRouteHistory,
  spliceTabRouteHistory: _spliceTabRouteHistory,
  getReducedRouteObject: _getReducedRouteObject,
  dateToRouteParams: _dateToRouteParams,
  dateFromRouteParams: _dateFromRouteParams,

  // ====================================================================== \\
  // == mixin initalisation                                              == \\
  // ====================================================================== \\
  install(Vue, options) {
    if (routeAssistant.install.isInitialized) {
      return; // previously initialized
    }

    // ====================================================================== \\
    // == Initialize the Route Assistant                                   == \\
    // ====================================================================== \\
    // !!  This must be the last thing done by the install(Vue) function   !! \\
    // ====================================================================== \\
    addProperties();
    addAccessorProperties(options.accessors);

    routeAssistant.install.isInitialized = true;

    Object.seal(routeAssistant);
  },
};

// ====================================================================== \\
// == Property insertion function                                      == \\
// ====================================================================== \\
function addProperties() {
  Object.defineProperty(routeAssistant, 'app', { get: getVueInstance });
  Object.defineProperty(routeAssistant, 'routeHistory', { get: get_routeHistory, set: set_routeHistory });

  Object.defineProperty(routeAssistant, 'firstLandingRoute', { get: get_firstLandingRoute });
  Object.defineProperty(routeAssistant, 'landingRoutes', { get: get_landingRoutes });
  Object.defineProperty(routeAssistant, 'loginRoutes', { get: get_loginRoutes });
  Object.defineProperty(routeAssistant, 'multiUserRoutes', { get: get_multiUserRoutes });
}

// ====================================================================== \\
// == getter and setter functions                                      == \\
// ====================================================================== \\
function getVueInstance() {
  return routeAssistant.$router.app;
}

function get_routeHistory() {
  return routeAssistant.app.$browserStorage.routeHistory ?? [];
}
function set_routeHistory(value) {
  routeAssistant.app.$browserStorage.routeHistory = value;
}

function get_firstLandingRoute() {
  const firstRoute = routeAssistant.landingRoutes.find((route) =>
    routeAssistant.app.$options.methods.checkAccessRights(route.meta.functionCode)
  );
  return firstRoute;
}
// ====================================================================== \\
// get_landingRoutes: Retrives a list of routes that will be used to send
//                    the user to after initial login. This function uses
//                    the AppMenuList to ensure that the landing routes
//                    are in the same order they appear on the main menu.
// ====================================================================== \\
import AppMenuList from '/src/components/application/AppMenuList.js';
function get_landingRoutes() {
  let landingRoutes = [];

  const routes = routeAssistant.$routes_flat.filter((r) => r.meta?.isLandingRoute);
  AppMenuList.get().forEach((m) => {
    const mRoute = routes.find((r) => r.name === m.routeName);
    if (mRoute != null) landingRoutes.push(mRoute);

    if (m.menuItems && m.menuItems.length) {
      m.menuItems.forEach((mi) => {
        const miRoute = routes.find((r) => r.name === mi.routeName);
        if (miRoute != null) landingRoutes.push(miRoute);
      });
    }
  });

  return landingRoutes;
}
// ====================================================================== \\
// get_loginRoutes: Retrives a list of login routes
// ====================================================================== \\
function get_loginRoutes() {
  return routeAssistant.$routes_flat.filter((r) => r.meta?.isLoginRoute).map((r) => r.name.toLowerCase());
}
// ====================================================================== \\
// get_multiUserRoutes: Retrives a list of multi user routes
// ====================================================================== \\
function get_multiUserRoutes() {
  return routeAssistant.$routes_flat.filter((r) => r.meta?.isMultiUserRoute).map((r) => r.name.toLowerCase());
}

// ====================================================================== \\
// == Public functions                                                 == \\
// ====================================================================== \\
function _getRoute(name) {
  return recursiveRouteFind(name, routeAssistant.$routes);
}
function _getRouteFunctionCode(name) {
  return routeAssistant.getRoute(name)?.meta?.functionCode;
}
function _addRouteToHistory(route) {
  if (route?.name && !routeAssistant.loginRoutes.includes(route.name.toLowerCase())) {
    let routeHistory = routeAssistant.routeHistory;

    if (routeHistory.length === 40) {
      // remove the oldest route for the history
      routeHistory.splice(0, 1);
    }

    // only add the route to the history if it's not a duplicate of the last entry
    const reducedRoute = _getReducedRouteObject(route);
    let lastRoute = routeHistory[routeHistory.length - 1];
    if (!lastRoute || JSON.stringify(_getReducedRouteObject(lastRoute)) !== JSON.stringify(reducedRoute)) {
      if (lastRoute && (window.scrollX != 0 || window.scrollY != 0)) {
        // update the scroll position of the previous route
        lastRoute.scrollPosition = { x: window.scrollX, y: window.scrollY };
        // Vue 3 = lastRoute.scrollPosition = { top: window.scrollY, left: window.scrollX };
      }

      routeHistory.push(reducedRoute);
    }

    routeAssistant.routeHistory = routeHistory;
  }
}
function _removeTabRouteHistory(count) {
  const routeHistory = routeAssistant.routeHistory;

  if (routeHistory && routeHistory.length > count) {
    _spliceTabRouteHistory(routeHistory.length - count);
  }
}
function _spliceTabRouteHistory(index) {
  let routeHistory = routeAssistant.routeHistory;

  if (routeHistory.length > index) {
    routeHistory.splice(index, routeHistory.length - index);

    routeAssistant.routeHistory = routeHistory;
  }
}

function _getReducedRouteObject(route) {
  let reducedRoute = null;

  if (route) {
    reducedRoute = {
      name: route.name,
      params: {},
    };

    if (route.params) {
      const paramEntries = Object.entries(route.params);
      if (paramEntries && paramEntries.length) {
        const ignoredParameters = route.meta?.historyIgnoredParameters ?? [];

        reducedRoute.params = !ignoredParameters.length
          ? { ...route.params }
          : Object.fromEntries(paramEntries.filter((p) => !ignoredParameters.includes(p[0])));
      }
    }
  }

  return reducedRoute;
}

function _dateToRouteParams(date) {
  if (date) {
    date = routeAssistant.app.DateFunctions.apiDate(date, '/');
    if (date) {
      const dateParts = date.split('/');
      return {
        y: dateParts[0],
        m: dateParts[1],
        d: dateParts[2],
      };
    }
  }
  return null;
}
function _dateFromRouteParams(route = routeAssistant.$router.currentRoute) {
  const params = route.params;
  return params.y && params.m && params.d ? new Date(`${params.y}-${params.m}-${params.d}T00:00:00`) : null;
}

// ====================================================================== \\
// == Private functions                                                == \\
// ====================================================================== \\
function recursiveRouteFind(name, routes = null) {
  if (!routes) {
    return null;
  }

  let route = routes.find((r) => r.name === name);
  if (!route) {
    routes.every((r) => {
      if (r?.children?.length) {
        route = recursiveRouteFind(name, r.children);
      }
      return route == null; // Break the loop if route is found
    });
  }

  return route;
}

function flattenRoutes() {
  return flattenRoutesRecursive(routeAssistant.$routes);
}

function flattenRoutesRecursive(routes) {
  let flatRoutes = [];

  routes.forEach((r) => {
    flatRoutes.push(r);
    if (r?.children?.length) {
      flatRoutes.push(...flattenRoutesRecursive(r.children));
    }
  });

  return flatRoutes;
}

// ====================================================================== \\
// == Application wide property insertion functions                    == \\
// ====================================================================== \\
function addAccessorProperties(accessors) {
  addAppWideAccessorProperties(accessors);

  const router = accessors.find((accessor) => accessor.key == 'VueRouter')?.value;
  if (router) {
    Object.defineProperty(routeAssistant, '$router', {
      get: () => {
        return router;
      },
    });
    Object.defineProperty(routeAssistant, '$routes', {
      get: () => {
        return router.options.routes;
      },
    });

    Object.defineProperty(routeAssistant, '$routes_flat', { get: flattenRoutes });
  }
}

function addAppWideAccessorProperties(accessors) {
  accessors.forEach((accessor) => {
    Object.defineProperty(accessor.value, '$routeAssistant', {
      get: () => {
        return routeAssistant;
      },
    });
  });
}
