<template>
  <div id="FixedHeaderBar" :class="['fixedHeader', pageScrolled ? 'active' : '']">
    <slot name="headerContent"></slot>
  </div>
</template>

<script>
export default {
  name: 'FixedHeaderBar',
  props: ['tolerance'],
  data() {
    return {
      headerTolerance: 0,
      pageScrolled: false,
    };
  },
  created() {
    this.headerTolerance = this.tolerance ?? 50;

    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.pageScrolled = window.scrollY > this.headerTolerance;
    },
  },
};
</script>

<style lang="less" scoped>
</style>