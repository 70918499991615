// ====================================================================== \\
// Route Standard:
// ====================================================================== \\
//    {
//      path: '',                    - route path
//      name: '',                    - route name
//      component: ,                 - vue component
//      meta: {
//        title: '',                 - route title -> used for browser title
//        tags: [''],                - [optional] string array of tags -> used by the sitemap
//        layout: '',                - [optional] tells the app which layout file to use (default = 'application')
//        backRoutes: [''],          - string array of route names this route should return to (must contain at least one, unless isLandingRoute = true)
//                                     If
//        functionCode: '',          - security function code
//        hideBackButton: false,     - [optional] if true will hide the AQUA back button in main header
//        areaDisabled: false,       - [optional] if true will disable Area selection in main header
//        isLandingRoute: false,     - [optional] if true the route will be considered as a landing page after login
//        isLoginRoute: false,       - [optional] identifies routes that are outside the security
//        isMultiUserRoute: false,   - [optional] identifies multi user feature routes
//        transitionName: '',        - [optional] animation used between routes, 'slide' / 'fade' (default = 'fade')
//        transitionMode: '',        - [optional] animation mode, 'out-in' / 'in-out' (default = 'out-in')
//      },
//    },
// ====================================================================== \\

import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

import { store } from '/src/store';

import dashboardRoutes from '/src/routes/dashboard';
import sessionRoutes from '/src/routes/session';
import episodeRoutes from '/src/routes/episode';
import patientRoutes from '/src/routes/patient';
import locationRoutes from '/src/routes/locations';
import resourcesRoutes from '/src/routes/resources';
import worklistRoutes from '/src/routes/worklist';
import reportRoutes from '/src/routes/reports';
import adminRoutes from '/src/routes/admin/admin_home';
import userRoutes from '/src/routes/user';
import notesRoutes from '/src/routes/notes';

import sysRoutes from '/src/routes/_sys';
// import conceptRoutes from '/src/routes/_concepts';

import { AccountService } from '/src/services/account';

const debugLog = (...args) => router.app.$options.methods.debugLog(...args);

export const router = new VueRouter({
  routes: [
    ...dashboardRoutes,
    ...sessionRoutes,
    ...episodeRoutes,
    ...patientRoutes,
    ...locationRoutes,
    ...worklistRoutes,
    ...resourcesRoutes,
    ...reportRoutes,
    ...adminRoutes,
    ...userRoutes,
    ...notesRoutes,

    ...sysRoutes,
    // ...conceptRoutes,

    {
      path: '*',
      name: 'catch-all',
      redirect: (to) => {
        const redirectTo =
          router.app.$options.computed.isLoggedIn.get() || router.$browserStorage.isLoggingIn
            ? router.$browserStorage.postLoginRoute ?? router.$routeAssistant.firstLandingRoute ?? to
            : { name: 'login' };

        debugLog(
          `AQUA: ♾️ 'catch-all' -> `,
          `isLoggingIn = ${router.app.$options.computed.isLoggedIn.get()}`,
          `isLoggingIn = ${router.$browserStorage.isLoggingIn}`,
          'postLoginRoute = ',
          router.$browserStorage.postLoginRoute,
          'firstLandingRoute = ',
          router.$routeAssistant.firstLandingRoute,
          'caught(to) = ',
          to
        );

        debugLog(`AQUA: ♾️ 'catch-all' -> redirect to -> ${redirectTo?.name}`);

        if (redirectTo) {
          return redirectTo;
        }
      },
      meta: {
        siteMapHidden: true,
      },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (to.hash) {
          resolve({ selector: to.hash, behavior: 'smooth' });
          // Vue 3 = resolve({ el: to.hash, behavior: 'smooth' });
        } else if (savedPosition) {
          resolve(savedPosition);
        } else {
          const historicalRoute = getRouteFromHistory(to.name);
          if (
            historicalRoute?.scrollPosition &&
            (historicalRoute.scrollPosition.x != 0 || historicalRoute.scrollPosition.y != 0)
          ) {
            resolve({ ...historicalRoute.scrollPosition, behavior: 'smooth' });
          } else {
            resolve({ x: 0, y: 0, behavior: 'smooth' });
            // Vue 3 = resolve({ top: 0, left: 0, behavior: 'smooth' });
          }
        }
      }, 1000);
    });
  },
});

router.beforeEach((to, from, next) => {
  store.dispatch('resetAPICallCount');

  const isLoggedIn = router.app.$options.computed.isLoggedIn.get();
  const isLocked = router.$browserStorage.lockSession || false;

  const loginRoutes = router.$routeAssistant.loginRoutes;
  const multiUserRoutes = router.$routeAssistant.multiUserRoutes;

  const isGoingToLoginRoute = loginRoutes.includes(to.name?.toLowerCase());
  const isComingFromLoginRoute = loginRoutes.includes(from.name?.toLowerCase());

  const isAddingUser = router.$browserStorage.isAddingUser;

  // Redirect to the login if there is no access token
  if (!isAddingUser && !isLocked && (to.name === 'locked' || !isGoingToLoginRoute) && !isLoggedIn) {
    debugLog(
      'AQUA: ♾️ redirect to login -> ',
      !isAddingUser && !isLocked && (to.name === 'locked' || !isGoingToLoginRoute) && !isLoggedIn
    );

    if (!isGoingToLoginRoute) {
      debugLog(`AQUA: ♾️ setting to postLoginRoute -> ${to.name}`, to);
      router.$browserStorage.postLoginRoute = to;
    }

    return next({ name: 'login' });
  }

  if (isAddingUser && !['add_login', 'register'].includes(to.name)) {
    return next({ name: 'add_login' });
  } else if (['add_login', 'register'].includes(to.name)) {
    router.$browserStorage.isAddingUser = true;
    return next();
  }

  if (isLocked && !isGoingToLoginRoute) {
    debugLog(`AQUA: ♾️ setting to postLoginRoute -> ${to.name}`, to);
    router.$browserStorage.postLoginRoute = to;

    debugLog('AQUA: ♾️ isLocked -> /locked');
    return next({ name: 'locked' });
  } else if (!isLocked && isLoggedIn) {
    // If we are coming from a login route and a previous route is recorded, redirect to the previous route
    const postLoginRoute = router.$browserStorage.postLoginRoute;
    if (
      postLoginRoute &&
      !isGoingToLoginRoute &&
      isComingFromLoginRoute &&
      to.name != postLoginRoute.name &&
      !loginRoutes.includes(postLoginRoute.name)
    ) {
      debugLog(`AQUA: ♾️ clearing postLoginRoute`);
      router.$browserStorage.postLoginRoute = null;

      debugLog(`AQUA: ♾️ redirect to postLoginRoute -> ${postLoginRoute.name}`, postLoginRoute);

      return next(postLoginRoute);
    } else if (!isGoingToLoginRoute) {
      debugLog(`AQUA: ♾️ setting to postLoginRoute -> ${to.name}`, to);
      router.$browserStorage.postLoginRoute = to;
    }

    if (isGoingToLoginRoute) {
      return next({ name: 'catch-all' });
    } else {
      const authPromise = new Promise((resolve, reject) => {
        // Redirect to login page if not logged in and trying to access a restricted page
        const loggedInUsers = router.$browserStorage.loggedInUsers;
        const authRequired = !isGoingToLoginRoute;

        if (authRequired) {
          const user = router.$browserStorage.currentUser;

          try {
            // Is someone trying access a route but not logged in?
            if (authRequired && !user) {
              // If someone was logged in, route to /locked
              if (loggedInUsers.length > 0 && !multiUserRoutes.includes(to.name)) {
                debugLog('AQUA: ♾️ authRequired && !user -> /locked');

                reject({ name: 'locked' });
              } else if (to.name != 'login') {
                debugLog('AQUA: ♾️ authRequired && !user -> /login');

                reject({ name: 'login' });
              } else {
                debugLog(`AQUA: ♾️ authRequired && !user -> routing to -> ${to?.name}`, to);

                resolve(true);
              }
            } else if (authRequired) {
              // Check to ensure the current user has access to the route and cancel navigation if not
              if (to.meta.functionCode && !router.app.$options.methods.checkAccessRights(to.meta.functionCode)) {
                if ((from.name == null || isComingFromLoginRoute) && !isGoingToLoginRoute) {
                  store.dispatch('clearAppMessages');
                  AccountService.logout(user, false)
                    .then(() => {
                      store.dispatch('addAppMessage', {
                        status: 'warning',
                        title: 'Access Denied',
                        description: 'User has insufficient access rights. Please contact your system administrator.',
                        isPersistentMessage: true,
                      });

                      const rejectTo =
                        loggedInUsers.length > 1
                          ? { name: 'switch_user' }
                          : from.name !== 'login'
                            ? { name: 'login' }
                            : false;

                      debugLog(`AQUA: ♾️ access denied -> rejected to -> ${rejectTo.name}`);

                      reject(rejectTo);
                    })
                    .catch(() => {
                      debugLog('AQUA: ♾️ access denied -> rejected');

                      reject(false);
                    });
                }
                // else if we are NOT coming "from" a login route and we're NOT "going" to a login route
                else if (!isComingFromLoginRoute && !isGoingToLoginRoute) {
                  store.dispatch('clearAppMessages');
                  store.dispatch('addAppMessage', {
                    status: 'warning',
                    title: 'Access Denied',
                    description: 'User has insufficient access rights. Please contact your system administrator.',
                    isPersistentMessage: true,
                  });

                  debugLog('AQUA: ♾️ access denied -> rejected');

                  reject(false);
                } else {
                  debugLog(`AQUA: ♾️ access denied -> routing to -> ${to?.name}`, to);

                  resolve(true);
                }
              } else {
                debugLog(`AQUA: ♾️ authRequired and access granted -> routing to -> ${to?.name}`, to);

                resolve(true);
              }
            }
          } catch (e) {
            const rejectTo = loggedInUsers.length > 1 ? { name: 'switch_user' } : { name: 'login' };

            debugLog(`AQUA: ♾️ ERROR -> rejected to -> ${rejectTo.name}`);

            reject(rejectTo);
          }
        } else if (to.name == 'login' && loggedInUsers.length > 1 && to.name != 'add_login') {
          debugLog('AQUA: ♾️ redirect to -> switch_user');

          reject({ name: 'switch_user' });
        } else {
          debugLog(`AQUA: ♾️ No authRequired -> routing to -> ${to?.name}`, to);

          resolve(true);
        }
      });

      authPromise
        .catch((rejectBackTo) => {
          return next(rejectBackTo);
        })
        .then((value) => {
          if (value) {
            return next();
          }
        });
    }
  } else {
    return next();
  }
});

router.afterEach((to, from) => {
  router.$AQUA_Timer.cancelRouteTimers({ route_name: from.name });

  router.$routeAssistant.addRouteToHistory(to);

  store.dispatch('updateAppCover', false);
  store.dispatch('updateAppMenuOpen', false);

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const routeTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title && r.meta.title != '')?.meta.title;

  // If a route with a title was found, set the document (page) title to that value.
  document.title = (routeTitle && routeTitle != '' ? routeTitle + ' - ' : '') + 'AQUA theatreman';
});

// Add go back route function
VueRouter.prototype.goBack = () => {
  const goBackTo = getBackRoute_TidyHistory(router.currentRoute.meta.backRoutes ?? []);

  router.replace(goBackTo ?? '/');
};
// Add go back to specific route function
VueRouter.prototype.goBackTo = (destination) => {
  const goBackTo = getBackRoute_TidyHistory([destination.name || destination], destination.params);

  router.replace(goBackTo ?? destination ?? '/');
};

const getRouteFromHistory = (name) => {
  const routeHistory = router.$routeAssistant.routeHistory;
  if (routeHistory?.length) {
    if (!name) {
      return routeHistory[routeHistory.length - 2];
    } else {
      // Has history
      return routeHistory
        .slice()
        .reverse()
        .find((r) => r.name === name);
    }
  }
};

const getBackRoute_TidyHistory = (backRoutes = [], params = null) => {
  let goBackTo = null;
  const routeHistory = router.$routeAssistant.routeHistory;
  if (routeHistory?.length) {
    if (backRoutes.length === 0) {
      goBackTo = routeHistory[routeHistory.length - 2];
    } else {
      // Has history
      goBackTo = routeHistory
        .slice()
        .reverse()
        .find((r) => backRoutes.includes(r.name));
    }

    if (goBackTo) {
      router.$routeAssistant.spliceTabRouteHistory(routeHistory.indexOf(goBackTo) + 1);
    }

    if (params && goBackTo) {
      goBackTo.params = { ...goBackTo.params, ...params };
    }
  } else if (backRoutes.length == 1) {
    goBackTo = {
      name: backRoutes[0],
      params: { ...router.currentRoute?.params, ...params },
    };
  }

  return goBackTo;
};

const substituteNullParams = (to) => {
  if (to?.params && Object.values(to.params).filter((v) => v == null).length) {
    // Ensure null params are replaced with a string
    const keys = Object.keys(to.params);
    keys.forEach((key) => {
      if (to.params[key] == null) to.params[key] = '~';
    });
  }
  return to;
};

// Added to silence "Uncaught (in promise) Error: Redirected when going from "???" to "???" via a navigation guard.
const origPush = VueRouter.prototype.push;
const origReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  aquaPushReplace(this, origPush, location, onResolve, onReject);
};
VueRouter.prototype.replace = function replace(location, onResolve, onReject) {
  aquaPushReplace(this, origReplace, location, onResolve, onReject);
};
const aquaPushReplace = (_this, origFunction, location, onResolve, onReject) => {
  location = substituteNullParams(location);

  if (onResolve || onReject) return origFunction.call(_this, location, onResolve, onReject);

  return origFunction.call(_this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err;
    }
    // rethrow error
    return Promise.reject(err);
  });
};

const orig_resolve = VueRouter.prototype.resolve;
VueRouter.prototype.resolve = function resolve(to, current, append) {
  return orig_resolve.call(this, substituteNullParams(to), current, append);
};

router.onError((error) => {
  if (/.*loading chunk.*failed.*/i.test(error.message) && navigator.onLine) {
    debugLog('AQUA: ♾️ ERROR -> loading chunk failure');
    window.location.reload();
  }
});

// Added this in preperation for Vue 3 upgrade
if (typeof VueRouter.prototype.app === 'undefined') {
  Object.defineProperty(VueRouter.prototype, 'app', {
    get: () => {
      return router.apps[0];
    },
  });
}
