/**
 * MultiTabStore syncs Vuex state across tabs
 *
 * @returns {Function<void>}
 */
function MultiTabStore() {
  const storeKey = 'aqua-multitab';
  const ignoredMutations = [
    'UPDATE_APP_MENU',
    'UPDATE_ACCOUNT_MENU',
    'UPDATE_APP_COVER',
    'UPDATE_APP_LOADING',
    'UPDATE_SELECTEDADMINCATEGORY_STORE',
    'UPDATE_CURRENT_AREA',
    'CLEAR_SESSION_STORE',
    'CLEAR_APP_MESSAGES',
  ];

  let ignoreNext = false;
  let ignoreIdentifier = null;
  let identifier = 0;

  /**
   * Store plugin
   *
   * @param {Object} store
   */
  return function (store) {
    // storage events are fired in non-focused same-origin tabs
    window.addEventListener('storage', (event) => {
      if (event.key === storeKey) {
        const mutation = JSON.parse(event.newValue);

        if (ignoreIdentifier !== mutation.ignoreIdentifier) {
          ignoreNext = true;
          store.commit(mutation.type, mutation.payload);
        }
      }
    });

    store.subscribe((mutation) => {
      // Ignore this mutation if it's being sync'd form another tab/window or it's in our list of ignored mutations
      if (ignoreNext || ignoredMutations.includes(mutation.type)) {
        ignoreNext = false;
        return;
      }

      mutation.ignoreIdentifier = Date.now() + '' + identifier++;

      // Mark app message so we can avoid firing the createError API
      if (mutation.type == 'ADD_APP_MESSAGE') {
        if (mutation.payload.doNotSync) {
          return;
        }
        mutation.payload.doNotFireCreateError = true;
      }

      // Post this mutation into local storage for other tabs/windows to pickup
      localStorage[storeKey] = JSON.stringify(mutation);
    });
  };
}

module.exports = MultiTabStore;
