import axios from 'axios';
import { router } from '/src/router';
import { store } from '/src/store';

import { JwtFunctions } from '/src/mixins/JwtFunctions';
import { DateFunctions } from '/src/mixins/datefunctions';

import { MaintenanceService } from '/src/services/maintenance';
import { AreaService } from '/src/services/area';

const debugLog = (...args) => router.app.$options.methods.debugLog(...args);

const app_computed = () => router.app.$options.computed;

export const AccountService = {
  getBadgePositions: function (username = null) {
    return axios
      .post(
        // URL
        'Account/badge/positions',
        // DATA
        {
          Username: username,
        }
      )
      .then((response) => {
        return response.data.result;
      });
  },

  login: function (payload) {
    return this.handleLogin(payload, 'Account/signin');
  },
  loginPin: function (payload) {
    return this.handleLogin(payload, 'Account/signin/pin');
  },
  handleLogin: function (payload, endpoint) {
    // {
    //   grant_type: 'password',
    //   username: '',
    //   password: '',
    //   return_user: true,
    //   deviceid: router.app.$options.computed.aquaDeviceID,
    // }
    return new Promise((resolve, reject) => {
      if (!router.$browserStorage.isLoggingIn) {
        router.$browserStorage.isLoggingIn = router.$browserStorage.aquaWindowUID;

        return axios
          .post(
            // URL
            endpoint,
            // DATA
            payload
          )
          .then((response) => {
            let result = response.data.result;

            if (result?.accessToken) {
              // get logged in users from local storage
              let loggedInUsers = app_computed().loggedInUsers.get();

              // set up local storage user
              let user = result.user;
              user.accessToken = result.accessToken;
              user.refreshToken = result.refreshToken;
              app_computed().currentUser.set(user);

              this.getUserRoleAccess(user.ID).then((userRoles) => {
                user.roles = userRoles;
                app_computed().currentUser.set(user);

                // Decode the access token
                const jwt = JwtFunctions.decodeAccessToken(user);
                user.loginProvider = jwt.LoginProvider;

                // check for users that have been inactive for 7 days and remove them
                if (loggedInUsers?.length) {
                  loggedInUsers = loggedInUsers.filter(
                    (u) => u.LastSuccessfulSignIn == null || new Date(u.LastSuccessfulSignIn) > new Date().removeDays(7)
                  );

                  // Update currentUser in loggedInUsers array
                  const userIndex = loggedInUsers.findIndex((u) => u.ID === user.ID);
                  if (userIndex >= 0) {
                    loggedInUsers[userIndex] = user;
                  } else {
                    loggedInUsers.push(user);
                  }
                } else {
                  loggedInUsers = [user];
                }

                app_computed().loggedInUsers.set(loggedInUsers);

                resolve(user);
              });
            } else {
              resolve(response.data);
            }
          })
          .catch((error) => {
            router.$browserStorage.isLoggingIn = null;
            reject(error);
          });
      }
    });
  },

  logout: function (user = null, pushRoute = true, callback = null, otherUser = false) {
    return this.logoutExtended(user, pushRoute, false, callback, otherUser);
  },
  logoutExtended: function (user = null, pushRoute = true, isLocking = false, callback = null, otherUser = false) {
    if (router.$browserStorage.lockResult) {
      app_computed().GlobalEventBus().$emit('unlockFunction', () => {
        return this.actualLogout(user, pushRoute, isLocking, callback, otherUser);
      });
    } else {
      return this.actualLogout(user, pushRoute, isLocking, callback, otherUser);
    }
  },
  actualLogout: function (
    user = null,
    pushRoute = true,
    isLocking = false,
    callback = null,
    otherUser = false,
    invalidToken = false
  ) {
    const currentUser = app_computed().currentUser.get();

    if (!user) {
      user = currentUser;
    } else if (otherUser) {
      this.clearUserData(user, otherUser ? currentUser.ID : null, pushRoute, isLocking, callback);
    }

    if (user) {
      store.$browserStorage.isLoggingOut = store.$browserStorage.aquaWindowUID;
      if (!invalidToken && user.accessToken) {
        return axios
          .post(
            // URL
            'Account/signout',
            // DATA
            {}
          )
          .then((response) => {
            this.clearUserData(user, otherUser ? currentUser.ID : null, pushRoute, isLocking, callback);
          })
          .catch((error) => {
            this.clearUserData(user, otherUser ? currentUser.ID : null, false, false, callback);
          });
      } else {
        this.clearUserData(user, otherUser ? currentUser.ID : null, pushRoute, isLocking, callback);
      }
    }
  },
  clearUserData: function (user = null, originalUserID = null, pushRoute = true, isLocking = false, callback = null) {
    let loggedInUsers = app_computed().loggedInUsers.get();

    if (user && loggedInUsers) {
      if (!isLocking) {
        router.$browserStorage.routeHistory = [];

        // remove user being logged out
        loggedInUsers = loggedInUsers.filter((u) => u.ID != user.ID);

        debugLog(`AQUA: 🔐 clearing postLoginRoute for: ${user.UserName}`);
        router.$browserStorage.setUserPostLoginRoute(user, null);
      }

      // Clear other users jwt tokens
      loggedInUsers.forEach((u, index) => {
        if (!originalUserID || originalUserID !== u.ID) {
          u.accessToken = null;
          u.refreshToken = null;
        }
      });

      app_computed().loggedInUsers.set(loggedInUsers);
      store.dispatch('clearAppMessages');
    }

    if (!user || !loggedInUsers || loggedInUsers.length === 0) {
      app_computed().loggedInUsers.set(null);
      store.dispatch('cleanStore');
    }

    if (!originalUserID) {
      // clear up any other user data from local storage
      app_computed().currentUser.set(null);
      router.$browserStorage.originLocationID = null;

      router.$browserStorage.isLoggingIn = null;
      router.$browserStorage.isLoggingOut = null;

      if (!isLocking || !loggedInUsers || loggedInUsers.length === 0) {
        router.$browserStorage.lockSession = null;
      }

      if (!isLocking) {
        debugLog(`AQUA: 🔐 clearing all postLoginRoute's`);
        router.$browserStorage.postLoginRoute = null;
      }
    }

    // Cancel all timers and workers
    router.$AQUA_Timer.cancelAll();

    // Force browserStorage to reload it's values
    // router.$browserStorage.clearMemoryStorage();

    // Execute the callback function if supplied
    if (callback) callback();

    // route to switch user if other users have logged in, else return to login
    if (pushRoute) router.push({ name: loggedInUsers.length > 0 ? 'switch_user' : 'login' });
  },

  register: function (user) {
    return axios
      .post(
        // URL
        'Account/register',
        // DATA
        user
      )
      .then((response) => {
        return response.data.result;
      });
  },

  getUserAccess: function (iUserID) {
    return axios
      .post(
        // URL
        'User/function',
        // DATA
        { UserID: iUserID }
      )
      .then((response) => {
        return response.data.result;
      });
  },

  getUserRoleAccess: function (userID) {
    return axios.post('User/role', { UserID: parseInt(userID) }).then((response) => {
      return response.data.result;
    });
  },

  getFromPersonnelID: function (payload) {
    return axios
      .post(
        // URL
        'Account/from_personnel_id',
        // DATA
        payload
      )
      .then((response) => {
        return response.data.result;
      });
  },

  forgotPassword: function (username) {
    return axios
      .post(
        // URL
        'Account/Forgot/Password',
        // DATA
        { Username: username }
      )
      .then((response) => {
        return response.data;
      });
  },

  changePassword: function (
    payload = {
      CurrentPassword: null,
      NewPassword: null,
      ResetForceChange: null,
    }
  ) {
    return axios
      .put(
        // URL
        'Account/change/password',
        // DATA
        payload
      )
      .then((response) => {
        return response.data.result;
      });
  },

  forgotPin: function (username) {
    return axios
      .post(
        // URL
        'Account/Forgot/Pin',
        // DATA
        { Username: username }
      )
      .then((response) => {
        return response.data;
      });
  },

  changePIN: function (
    payload = {
      CurrentPin: null,
      NewPin: null,
      ResetForceChange: null,
    }
  ) {
    return axios
      .put(
        // URL
        'Account/change/pin',
        // DATA
        payload
      )
      .then((response) => {
        return response.data.result;
      });
  },

  checkUsername: function (
    sendData = {
      UserName: null,
      FullName: null,
    }
  ) {
    return axios
      .post(
        // URL
        'User/check/username',
        // DATA
        sendData
      )
      .then((response) => {
        return response.data.result;
      });
  },

  checkUserBadge: function (
    sendData = {
      BadgeID: null,
    }
  ) {
    return axios
      .post(
        // URL
        'User/check/badgeid',
        // DATA
        sendData
      )
      .then((response) => {
        return response.data.result;
      });
  },

  refreshToken: function (user) {
    return new Promise(async (resolve, reject) => {
      if (!router.$browserStorage.isRefreshingToken && user && JwtFunctions.isAccessTokenAboutToExpire(user)) {
        // If there are outstanding API calls, wait for them to finish before refreshing the token
        let waitCount = 0;
        while (store.getters.currentAPICallCount > 0) {
          router.$AQUA_Timer.sleep().then(() => waitCount++);
        }
        if (waitCount > 0) {
          debugLog(`AQUA: 🛜 [refreshToken] waited ${waitCount} seconds for API calls to complete`);
        }

        // The access token has or is due to expire, so we need to refresh the tokens
        store.dispatch('abortAllEndpoints');

        router.$browserStorage.isRefreshingToken = true;

        axios
          .post('Account/refresh', {
            accessToken: user.accessToken,
            refreshToken: user.refreshToken,
          })
          .then((response) => {
            const result = response.data.result;

            if (result && result.accessToken) {
              // Update the browserStorage user object
              user.accessToken = result.accessToken;
              user.refreshToken = result.refreshToken;
              app_computed().currentUser.set(user);

              // get logged in users from browserStorage
              let loggedInUsers = app_computed().loggedInUsers.get() ?? [];

              // add user to list if missing
              if (!loggedInUsers.find((lUser) => lUser.ID === user.ID)) {
                loggedInUsers.push(user);
              }
              // update user list
              loggedInUsers.forEach((lUser) => {
                const userMatch = lUser.ID === user.ID;
                lUser.accessToken = userMatch ? user.accessToken : null;
                lUser.refreshToken = userMatch ? user.refreshToken : null;
              });
              // update browserStorage logged in users
              app_computed().loggedInUsers.set(loggedInUsers);
            }

            router.$browserStorage.isRefreshingToken = null;

            resolve(result);
          })
          .catch((error) => {
            router.$browserStorage.isRefreshingToken = null;

            store.dispatch('clearAppMessages');
            store.dispatch('addAppMessage', {
              status: 'danger',
              icon: 'error',
              error: error,
            });

            reject(error);
          });
      } else {
        resolve(false);
      }
    });
  },

  validateUsername: function (username = null) {
    return axios
      .post(
        // URL
        'account/validate/username',
        // DATA
        { Username: username }
      )
      .then((response) => {
        return response.data.result;
      });
  },

  validatePassword: function (password) {
    return axios
      .post(
        // URL
        'account/validate/password',
        // DATA
        { Password: password }
      )
      .then((response) => {
        return response.data.result;
      });
  },

  loginSuccess: function (userResponse) {
    return new Promise((resolve, reject) => {
      router.$browserStorage.lockSession = null;

      // goodbye NaN 👻
      let failedAttempts =
        (userResponse.SignInFails != null ? parseInt(userResponse.SignInFails) : 0) +
        (userResponse.PinFails != null ? parseInt(userResponse.PinFails) : 0);
      let loginResponse = '';

      if (failedAttempts === 0) {
        loginResponse =
          'Last successful login: ' +
          (userResponse.LastSuccessfulSignIn
            ? DateFunctions.humanReadableDateAndTime(userResponse.LastSuccessfulSignIn)
            : 'Never');
      } else {
        loginResponse =
          'Last unsuccessful login: ' +
          (userResponse.LastUnsuccessfulSignIn
            ? DateFunctions.humanReadableDateAndTime(userResponse.LastUnsuccessfulSignIn)
            : 'Never') +
          (userResponse.SignInFails || userResponse.PinFails
            ? ' - ' + failedAttempts + ' failed attempt' + (failedAttempts > 1 ? 's' : '')
            : '');
      }

      // In order to keep the very first login quick (after a release) we can't use Promise.all here due to the API refreshing it's Endpoint IDs to the DB.
      // If Promise.all is used, the calls will run alongside eachother causing the API to refresh the Endpoint IDs multiple times.
      store.getters
        .getGlobalProperties(true)
        .then(() => {
          MaintenanceService.getWebLinks({ Enabled: true })
            .then(() => {
              AreaService.getArea({ ID: userResponse.DefaultAreaID })
                .then((areaResult) => {
                  AccountService.getUserAccess(userResponse.ID)
                    .then((accessResult) => {
                      // get users access rights
                      let user = app_computed().currentUser.get();
                      user.accessRights = accessResult;
                      app_computed().currentArea.set(areaResult);
                      app_computed().currentUser.set(user);

                      store.dispatch('updateAllowedAppMenuList', null);
                      store.dispatch('updateShowMessages', true);

                      router.$browserStorage.isLoggingIn = null;
                      router.$browserStorage.isAddingUser = null;

                      store.dispatch('addAppMessage', {
                        status: 'success',
                        icon: 'success',
                        title: 'Login Success',
                        description: loginResponse,
                      });

                      resolve(true);
                    })
                    .catch(() => {
                      reject();
                    });
                })
                .catch(() => {
                  reject();
                });
            })
            .catch(() => {
              reject();
            });
        })
        .catch(() => {
          reject();
        });
    });
  },
  loginFailure: function (error) {
    const APIError = error ? (error.errorResponse ? error.errorResponse.data : error) : error;

    this.loginPromptData = {
      title: 'Login Failure',
      detail: APIError?.description,
      type: 1,
      payload: this.loginPayload,
    };
    // store.dispatch('removeAppMessage', error.message);
  },

  getUserRegisterRequests: function (iUserRegisterID) {
    return axios
      .post(
        // URL
        'User/register/requests',
        // DATA
        { UserRegisterID: iUserRegisterID }
      )
      .then((response) => {
        return response.data.result;
      });
  },
  userRegisterUpdate: function (userRegister) {
    return axios
      .post(
        // URL
        'User/register/update',
        // DATA
        userRegister
      )
      .then((response) => {
        return response.data.result;
      });
  },
  userRegisterDelete: function (iUserRegisterID) {
    return axios
      .delete(
        // URL
        'User/register/delete',
        // DATA
        { data: { UserRegisterID: iUserRegisterID } }
      )
      .then((response) => {
        return response.data.result;
      });
  },

  keepAlive: function () {
    return axios.post('account/keep-alive').then((response) => {
      return response.data.result;
    });
  },
};
