// Forms
const FormList = () => import('/src/components/admin/forms/FormList');
const FormAdmin = () => import('/src/components/admin/forms/FormAdmin');
const FormPreview = () => import('/src/components/admin/forms/FormPreview');

// Form Allocation Groups
const FormAllocation = () => import('/src/components/admin/formAlloc/FormAllocation');
const FormAllocationForm = () => import('/src/components/admin/formAlloc/forms/FormAllocationForm');

// Pre-Assessment Forms
const PreAssessment = () => import('/src/components/admin/forms/PreAssessment');
const PreAssessmentList = () => import('/src/components/admin/forms/views/PreAssessmentList');
const PreAssessmentUpdate = () => import('/src/components/admin/forms/views/PreAssessmentUpdate');

// Observation Sets
const Observations = () => import('/src/components/admin/forms/Observations');
const ObservationsList = () => import('/src/components/admin/forms/views/ObservationsList');
const ObservationsUpdate = () => import('/src/components/admin/forms/views/ObservationsUpdate');

// Observation Types
const ObservationTypesList = () => import('/src/components/admin/forms/views/ObservationTypesList');

// Observation Warning Scores
const EarlyWarning = () => import('/src/components/admin/earlywarning/EarlyWarning');
const EarlyWarningList = () => import('/src/components/admin/earlywarning/views/EarlyWarningList');
const EarlyWarningView = () => import('/src/components/admin/earlywarning/views/EarlyWarningView');

export default [
  // ======================================== \\
  // Forms
  // ======================================== \\
  {
    path: 'form',
    name: 'admin_forms',
    component: FormList,
    meta: {
      title: 'Form Maintenance',
      tags: ['Administration'],
      functionCode: 'FORMSADMIN',
      backRoutes: ['admin_home'],
    },
  },
  {
    path: 'form/:id',
    name: 'admin_form_detail',
    component: FormAdmin,
    meta: {
      title: 'Form Management',
      tags: ['Administration'],
      functionCode: 'FORMSADMIN',
      backRoutes: ['admin_forms'],
    },
  },
  {
    path: 'form/:id/preview/:revision',
    name: 'admin_formpreview',
    component: FormPreview,
    meta: {
      title: 'Form Preview',
      tags: ['Administration'],
      functionCode: 'FORMSADMIN',
      backRoutes: ['admin_form_detail'],
    },
  },

  // ======================================== \\
  // Form Allocation Groups
  // ======================================== \\
  {
    path: 'form/allocation',
    name: 'admin_formalloc',
    component: FormAllocation,
    meta: {
      title: 'Form Allocation Maintenance',
      tags: ['Administration'],
      functionCode: 'FORMSADMIN',
      backRoutes: ['admin_home'],
    },
  },
  {
    path: 'form/allocation/:allocationid',
    name: 'admin_formalloc_detail',
    component: FormAllocationForm,
    meta: {
      title: 'Form Allocation Detail',
      tags: ['Administration'],
      functionCode: 'FORMSADMIN',
      backRoutes: ['admin_formalloc'],
    },
  },

  // ======================================== \\
  // Pre-Assessment Forms
  // ======================================== \\
  {
    path: 'pre-assessment',
    component: PreAssessment,
    meta: {
      title: 'Pre-assessment',
      tags: ['Administration'],
      functionCode: 'FORMSADMIN',
      backRoutes: ['admin_home'],
    },
    children: [
      {
        path: ':preassessmentid',
        name: 'admin_preassessment_detail',
        component: PreAssessmentUpdate,
        meta: {
          title: 'Pre-assessment',
          tags: ['Administration'],
          functionCode: 'FORMSADMIN',
          backRoutes: ['admin_formpreassessment'],
        },
      },
      {
        path: '',
        name: 'admin_formpreassessment',
        component: PreAssessmentList,
        meta: {
          title: 'Pre-assessment',
          tags: ['Administration'],
          functionCode: 'FORMSADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // Observations
  // ======================================== \\
  {
    path: 'observation',
    component: Observations,
    meta: {
      title: 'Observation Maintenance',
      tags: ['Administration'],
      functionCode: 'FORMSADMIN',
      backRoutes: ['admin_home'],
    },
    children: [
      // ======================================== \\
      // Observation Types
      // ======================================== \\
      {
        path: 'type',
        name: 'admin_observation_types',
        component: ObservationTypesList,
        meta: {
          title: 'Observation Type Maintenance',
          tags: ['Administration'],
          functionCode: 'FORMSADMIN',
          backRoutes: ['admin_home'],
        },
      },

      // ======================================== \\
      // Observation Sets
      // ======================================== \\
      {
        path: ':observationid',
        name: 'admin_observations_detail',
        component: ObservationsUpdate,
        meta: {
          title: 'Observation Maintenance',
          tags: ['Administration'],
          functionCode: 'FORMSADMIN',
          backRoutes: ['admin_observations'],
        },
      },
      {
        path: '',
        name: 'admin_observations',
        component: ObservationsList,
        meta: {
          title: 'Observation Maintenance',
          tags: ['Administration'],
          functionCode: 'FORMSADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // Observation Warning Score
  // ======================================== \\
  {
    path: 'early/warning',
    component: EarlyWarning,
    meta: {
      title: 'Early Warning Maintenance',
      tags: ['Administration'],
      functionCode: 'FORMSADMIN',
      backRoutes: ['admin_home'],
    },
    children: [
      {
        path: ':ewsid',
        name: 'admin_earlywarning_view',
        component: EarlyWarningView,
        meta: {
          title: 'Early Warning Maintenance',
          tags: ['Administration'],
          functionCode: 'FORMSADMIN',
          backRoutes: ['admin_earlywarning'],
        },
      },
      {
        path: '',
        name: 'admin_earlywarning',
        component: EarlyWarningList,
        meta: {
          title: 'Early Warning Maintenance',
          tags: ['Administration'],
          functionCode: 'FORMSADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },
];
