// Procedure
const Procedure = () => import('/src/components/admin/procedure/Procedure');
const ProcedureList = () => import('/src/components/admin/procedure/views/ProcedureList');
const ProcedureForm = () => import('/src/components/admin/procedure/forms/ProcedureForm');

// Procedure Group
const ProcedureGroupList = () => import('/src/components/admin/procedure/views/ProcedureGroupList');
const ProcedureGroupForm = () => import('/src/components/admin/procedure/forms/ProcedureGroupForm');

// Procedure Site
const ProcedureSiteList = () => import('/src/components/admin/procedure/views/ProcedureSiteList');
const ProcedureSiteForm = () => import('/src/components/admin/procedure/forms/ProcedureSiteForm');

// Procedure Laterality
const ProcedureLateralityList = () => import('/src/components/admin/procedure/views/ProcedureLateralityList');
const ProcedureLateralityForm = () => import('/src/components/admin/procedure/forms/ProcedureLateralityForm');

export default [
  // ======================================== \\
  // Procedure Maintenance
  // ======================================== \\
  {
    path: 'procedure',
    component: Procedure,
    meta: {
      title: 'Procedure Maintenance',
      tags: ['Administration'],
      functionCode: 'GENERALADMIN',
      backRoutes: ['admin_home'],
    },
    children: [
      // ======================================== \\
      // Procedure Group
      // ======================================== \\
      {
        path: 'group/:proceduregroupid',
        name: 'admin_proceduregroup_detail',
        component: ProcedureGroupForm,
        meta: {
          title: 'Procedure Group Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_proceduregroup'],
        },
      },
      {
        path: 'group',
        name: 'admin_proceduregroup',
        component: ProcedureGroupList,
        meta: {
          title: 'Procedure Group Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_home'],
        },
      },

      // ======================================== \\
      // Procedure Site
      // ======================================== \\
      {
        path: 'site/:procedureid',
        name: 'admin_procedureSite_detail',
        component: ProcedureSiteForm,
        meta: {
          title: 'Procedure Site Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_procedureSite'],
        },
      },
      {
        path: 'site',
        name: 'admin_procedureSite',
        component: ProcedureSiteList,
        meta: {
          title: 'Procedure Site Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_home'],
        },
      },

      // ======================================== \\
      // Procedure Laterality
      // ======================================== \\
      {
        path: 'laterality/:procedureid',
        name: 'admin_procedureLaterality_detail',
        component: ProcedureLateralityForm,
        meta: {
          title: 'Procedure Laterality Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_procedureLaterality'],
        },
      },
      {
        path: 'laterality',
        name: 'admin_procedureLaterality',
        component: ProcedureLateralityList,
        meta: {
          title: 'Procedure Laterality Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_home'],
        },
      },

      // ======================================== \\
      // Procedure
      // ======================================== \\
      {
        path: ':procedureid',
        name: 'admin_procedure_detail',
        component: ProcedureForm,
        meta: {
          title: 'Procedure Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_procedure'],
        },
      },
      {
        path: '',
        name: 'admin_procedure',
        component: ProcedureList,
        meta: {
          title: 'Procedure Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },
];
