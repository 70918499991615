const GenericRoute = () => import('/src/components/application/_GenericRoute');

const ReportList = () => import('/src/components/report/ReportList');
const ReportParameters = () => import('/src/components/report/parameter/ReportParameters');
const ReportViewer_HTML = () => import('/src/components/report/ReportViewer_HTML');

export default [
  {
    path: '/report',
    component: GenericRoute,
    meta: {
      title: 'Reports',
    },
    children: [
      {
        path: 'list',
        name: 'report_list',
        component: ReportList,
        meta: {
          title: 'Report List',
          tags: ['Landing Page'],
          functionCode: ['ACCESSREPORTS'],
          hideBackButton: true,
          isLandingRoute: true,
        },
      },
      {
        path: ':reportDefinitionID?/:sessionRecordIDs?',
        component: GenericRoute,
        props: true,
        meta: {
          title: 'Reports',
        },
        children: [
          {
            path: 'parameters',
            name: 'report_parameters',
            component: ReportParameters,
            props: true,
            meta: {
              title: 'Report Parameters',
              functionCode: ['ACCESSREPORTS', 'REPORT', 'ADHOCREPORT'],
              historyIgnoredParameters: ['reportDefinition', 'reportParameters'],
              backRoutes: ['report_list', 'report_viewer'],
            },
          },
          {
            path: 'viewer',
            name: 'report_viewer',
            component: ReportViewer_HTML,
            props: true,
            meta: {
              title: 'Report Viewer',
              functionCode: ['ACCESSREPORTS', 'REPORT', 'ADHOCREPORT'],
              historyIgnoredParameters: ['reportDefinition', 'reportParameters'],
              backRoutes: ['report_list', 'report_parameters', 'report_session'],
            },
          },
          {
            path: 'session/:isBulkRequest?',
            name: 'report_session',
            component: ReportList,
            props: true,
            meta: {
              title: 'Session Reports List',
              functionCode: ['ACCESSREPORTS', 'REPORT', 'ADHOCREPORT'],
              backRoutes: ['session_list', 'session_view'],
            },
          },
        ],
      },
    ],
  },
];
