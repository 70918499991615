<template>
  <div class="loadingwrapper" :class="[displaystyle || 'fullscreen', isLoading ? 'showloader' : '']">
    <div class="loader" />
    <div class="alert warning tight taking-longer" v-if="isTakingLonger" v-html="msgIsTakingLonger" />
    <div class="alert danger tight taking-longer taking-to-long" v-if="isTakingToLong" v-html="msgIsTakingToLong" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ContentLoading',
  props: ['loading', 'displaystyle'],
  data() {
    return {
      displayStyles: ['fullscreen', 'boxed', 'subtle', 'subtle_boxed', 'subtle_left'],

      externalLoadingState: false,
      internalLoadingState: false,

      timeoutDuration: 60, // Seconds
      timeoutToLongDuration: 120, // Seconds
      timeoutCutoff: null,
      isTakingLonger: false,
      timeoutToLongCutoff: null,
      isTakingToLong: false,

      // AQUA_Timer options
      timeoutOptions: { uid: `loader_timeout_${this._uid}`, delay: 2 * 1000 },

      // Long process messages
      defaultIsTakingLonger: 'The current process is taking longer than expected.<br/>Please wait...',
      defaultIsTakingToLong: 'The current process is taking much longer than we would expect.<br />Please wait...<br/><br/>If the problem persists, refresh your browser and try again.',
      reportIsTakingLonger: 'Gathering reporting data, please wait...',
      reportIsTakingToLong: 'Gathering reporting data...<br/>Large datasets can take time to generate, please wait...',
    };
  },
  computed: {
    ...mapGetters(['currentAPICallCount']),

    isLoading: function () {
      return this.loadingFlagSupplied ? this.externalLoadingState : this.internalLoadingState;
    },
    loadingFlagSupplied: function () {
      return this.loading != null;
    },
    msgIsTakingLonger: function () {
      return this.$route?.name.startsWith('report_') ? this.reportIsTakingLonger : this.defaultIsTakingLonger;
    },
    msgIsTakingToLong: function () {
      return this.$route?.name.startsWith('report_') ? this.reportIsTakingToLong : this.defaultIsTakingToLong;
    },
  },
  watch: {
    isLoading: function (newVal, oldVal) {
      if (newVal != oldVal && newVal == true) {
        this.setCutoffs();
      }
    },
    loading: function (newVal) {
      this.externalLoadingState = newVal;
    },
    currentAPICallCount: function (newVal, oldVal) {
      if (!this.loadingFlagSupplied && newVal != oldVal) {
        this.internalLoadingState = newVal > 0;
        this.setCutoffs();
      }
    },
  },
  created() {},
  mounted() {
    this.externalLoadingState = this.loading;
    this.isTakingLonger = this.isTakingToLong = false;

    if (!this.loadingFlagSupplied) {
      this.setCutoffs();
    }
  },
  beforeDestroy() {
    this.$AQUA_Timer.cancelTimer(this.timeoutOptions);
  },
  methods: {
    setCutoffs: function () {
      this.timeoutCutoff = this.DateFunctions.addSeconds(new Date(), this.timeoutDuration);
      this.timeoutToLongCutoff = this.DateFunctions.addSeconds(new Date(), this.timeoutToLongDuration);

      if (!this.$AQUA_Timer.isActive(this.timeoutOptions)) {
        this.$AQUA_Timer.startTimer(this.checkTimeoutCutoff, this.timeoutOptions).then((options) => {
          this.timeoutOptions = options;
        });
      }
    },

    checkTimeoutCutoff: function () {
      let reset = false;

      if (this.isLoading) {
        // Process is taking longer than expected
        if (new Date() > this.timeoutCutoff && !this.isTakingLonger && !this.isTakingToLong) {
          if (!this.loadingFlagSupplied && !this.displaystyle?.toLowerCase().startsWith('subtle')) {
            this.isTakingLonger = true;
          } else {
            reset = true;
          }
        }
        // Process is taking MUCH longer than expected
        else if (new Date() > this.timeoutToLongCutoff && this.isTakingLonger && !this.isTakingToLong) {
          this.isTakingToLong = true;
          this.isTakingLonger = false;
        }
        // reset all flags
        else if (!this.loadingFlagSupplied && this.currentAPICallCount <= 0) {
          reset = true;
        }
      }

      if (reset || !this.isLoading || (!this.loadingFlagSupplied && this.currentAPICallCount <= 0)) {
        this.externalLoadingState = this.internalLoadingState = this.isTakingLonger = this.isTakingToLong = false;
        this.$AQUA_Timer.cancelTimer(this.timeoutOptions);
      }
    },
  },
};
</script>

<style lang="less"></style>
