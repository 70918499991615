// User maintenance
const Users = () => import('/src/components/admin/user/Users');
const UserList = () => import('/src/components/admin/user/views/UserList');
const UserLockedList = () => import('/src/components/admin/user/views/UserLockedList');
const UserPasswordPINList = () => import('/src/components/admin/user/views/UserPasswordPINList');
const UserRegistrationList = () => import('/src/components/admin/user/views/UserRegistrationList');
const UserRetiredList = () => import('/src/components/admin/user/views/UserRetiredList');
const UserInactiveList = () => import('/src/components/admin/user/views/UserInactiveList');
const UserDetail = () => import('/src/components/admin/user/forms/UserDetail');
const UserBulkUpdate = () => import('/src/components/admin/user/forms/UserBulkUpdate');

// Role maintenance
const RoleList = () => import('/src/components/admin/role/RoleList');
const RoleDetail = () => import('/src/components/admin/role/RoleDetail');

// Trust maintenance
const Trust = () => import('/src/components/admin/trust/Trust');

// System Message maintenance
const SystemMessage = () => import('/src/components/admin/systemmessage/SystemMessage');
const SystemMessageList = () => import('/src/components/admin/systemmessage/views/SystemMessageList');
const SystemMessageForm = () => import('/src/components/admin/systemmessage/views/SystemMessageForm');

// Multi Factor configuration
const MultiFactor = () => import('/src/components/admin/multifactor/multiFactor');
const MultiFactorForm = () => import('/src/components/admin/multifactor/views/multiFactorForm');

// Global Property maintenance
const GlobalProperties = () => import('/src/components/admin/global/GlobalProperties');

// Locked functions
const LockList = () => import('/src/components/admin/locking/LockList');

// Interface management
const Interface = () => import('/src/components/admin/interface/Interface');
const InterfaceList = () => import('/src/components/admin/interface/views/InterfaceList');
const InterfaceErrors = () => import('/src/components/admin/interface/forms/InterfaceErrors');

// WebLink
const WebLink = () => import('/src/components/admin/webLink/WebLink');
const WebLinkList = () => import('/src/components/admin/webLink/views/WebLinkList');
const WebLinkForm = () => import('/src/components/admin/webLink/views/WebLinkForm');

export default [
  // ======================================== \\
  // User maintainace
  // ======================================== \\
  {
    path: 'user',
    name: 'admin_users',
    component: Users,
    meta: {
      title: 'User Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: 'list',
        name: 'admin_userlist',
        component: UserList,
        meta: {
          title: 'User Maintenance',
          tags: ['Administration'],
          functionCode: 'USER',
          backRoutes: ['admin_home'],
        },
      },
      {
        path: 'locked',
        name: 'admin_userlockedlist',
        component: UserLockedList,
        meta: {
          title: 'Locked User Maintenance',
          tags: ['Administration'],
          functionCode: 'USER',
          backRoutes: ['admin_home'],
        },
      },
      {
        path: 'registration',
        name: 'admin_userregistrationlist',
        component: UserRegistrationList,
        meta: {
          title: 'Registration Request Maintenance',
          tags: ['Administration'],
          functionCode: 'USER',
          backRoutes: ['admin_home'],
        },
      },
      {
        path: 'passwordpin',
        name: 'admin_userpasswordpinlist',
        component: UserPasswordPINList,
        meta: {
          title: 'Password / PIN Request Maintenance',
          tags: ['Administration'],
          functionCode: 'USER',
          backRoutes: ['admin_home'],
        },
      },
      {
        path: 'retired',
        name: 'admin_userretiredlist',
        component: UserRetiredList,
        meta: {
          title: 'Retired User Maintenance',
          tags: ['Administration'],
          functionCode: 'USER',
          backRoutes: ['admin_home'],
        },
      },
      {
        path: 'inactive',
        name: 'admin_userinactivelist',
        component: UserInactiveList,
        meta: {
          title: 'Inactive User Maintenance',
          tags: ['Administration'],
          functionCode: 'USER',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  {
    path: 'user/:userid',
    name: 'admin_user_detail',
    component: UserDetail,
    meta: {
      title: 'User Detail',
      tags: ['Administration'],
      functionCode: 'USER',
      backRoutes: [
        'admin_userlist',
        'admin_userlockedlist',
        'admin_userpasswordpinlist',
        'admin_userretiredlist',
        'admin_userinactivelist',
        'working_list_view',
        'working_list_dashboard',
      ],
    },
  },
  {
    path: 'user/register/:registeruserid',
    name: 'admin_userregistration_detail',
    component: UserDetail,
    meta: {
      title: 'Registration Request Detail',
      tags: ['Administration'],
      functionCode: 'USER',
      backRoutes: ['admin_userregistrationlist', 'working_list_view', 'working_list_dashboard'],
    },
  },
  {
    path: 'user/bulk/update',
    name: 'admin_bulk_update',
    component: UserBulkUpdate,
    meta: {
      title: 'User Bulk Update',
      tags: ['Administration'],
      functionCode: 'USERUPDATE',
      backRoutes: ['admin_home'],
    },
  },

  // ======================================== \\
  // Role maintainace
  // ======================================== \\
  {
    path: 'role',
    name: 'admin_roles',
    component: RoleList,
    meta: {
      title: 'Role Maintenance',
      tags: ['Administration'],
      functionCode: 'ROLE',
      backRoutes: ['admin_home'],
    },
  },
  {
    path: 'role/:id',
    name: 'admin_role_detail',
    component: RoleDetail,
    meta: {
      title: 'Role Detail',
      tags: ['Administration'],
      functionCode: 'ROLE',
      backRoutes: ['admin_roles'],
    },
  },

  // ======================================== \\
  // Global Property maintenance
  // ======================================== \\
  {
    path: 'global/properties',
    name: 'admin_global_properties',
    component: GlobalProperties,
    meta: {
      title: 'Global Properties',
      tags: ['Administration'],
      functionCode: 'GLOBALSETTINGS',
      backRoutes: ['admin_home'],
    },
  },

  // ======================================== \\
  // System Message maintenance
  // ======================================== \\
  {
    path: 'application/message',
    component: SystemMessage,
    meta: {
      title: 'Application Message Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':systemmessageid',
        name: 'admin_system_message_detail',
        component: SystemMessageForm,
        meta: {
          title: 'Application Message Maintenance',
          tags: ['Administration'],
          functionCode: 'GENERALADMIN',
          backRoutes: ['admin_system_messages'],
        },
      },
      {
        path: '',
        name: 'admin_system_messages',
        component: SystemMessageList,
        meta: {
          title: 'Application Message Maintenance',
          tags: ['Administration'],
          functionCode: 'SECURITYADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // Locked Functions
  // ======================================== \\
  {
    path: 'function/locks',
    name: 'admin_locklist',
    component: LockList,
    meta: {
      title: 'Locked Functions',
      tags: ['Administration'],
      functionCode: 'SECURITYADMIN',
      backRoutes: ['admin_home'],
    },
  },

  // ======================================== \\
  // Trust maintenance
  // ======================================== \\
  {
    path: 'trust',
    name: 'admin_trust',
    component: Trust,
    meta: {
      title: 'Trust Administration',
      tags: ['Administration'],
      functionCode: 'SECURITYADMIN',
      backRoutes: ['admin_home'],
    },
  },

  // ======================================== \\
  // Interface management
  // ======================================== \\
  {
    path: 'interface',
    component: Interface,
    meta: {
      title: 'Interface Management',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':interfaceid/:errortype',
        name: 'admin_interfaceerrors',
        component: InterfaceErrors,
        meta: {
          title: 'Interface Management',
          tags: ['Administration'],
          functionCode: 'SECURITYADMIN',
          backRoutes: ['admin_interface'],
        },
      },
      {
        path: '',
        name: 'admin_interface',
        component: InterfaceList,
        meta: {
          title: 'Interface Management',
          tags: ['Administration'],
          functionCode: 'SECURITYADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // 'Multi Factor Authentication
  // ======================================== \\
  {
    path: 'multifactor',
    component: MultiFactor,
    meta: {
      title: 'Multi Factor Authentication',
      tags: ['Administration'],
    },
    children: [
      {
        path: '',
        name: 'admin_multifactor_detail',
        component: MultiFactorForm,
        meta: {
          title: 'Multi Factor Authentication',
          tags: ['Administration'],
          functionCode: 'MFAADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // Web Link
  // ======================================== \\
  {
    path: 'webLink',
    component: WebLink,
    meta: {
      title: 'Web Link Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':weblinkid',
        name: 'admin_weblink_detail',
        component: WebLinkForm,
        meta: {
          title: 'Web Link Maintenance',
          tags: ['Administration'],
          functionCode: 'SECURITYADMIN',
          backRoutes: ['admin_weblink'],
        },
      },
      {
        path: '',
        name: 'admin_weblink',
        component: WebLinkList,
        meta: {
          title: 'Web Link Maintenance',
          tags: ['Administration'],
          functionCode: 'SECURITYADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },
];
