import { store } from '/src/store';
import { router } from '/src/router';

import { debounce } from 'lodash';

import { DateFunctions } from '/src/mixins/datefunctions';

import { AccountService } from '/src/services/account';
import { ErrorService } from '/src/services/error';
import { VersionService } from '/src/services/version';

export const applicationTicker = {
  install(Vue) {
    const vue = Vue.prototype;

    const DebugTimeout = false;
    const DebugInterval = 240;
    const DefaultInterval = 10;

    const debugLog = (...args) => router.app.$options.methods.debugLog(...args);

    const UserAction = {
      Maintenance: 0,
      ServiceResumed: 1,
      VersionUpdate: 2,
      RefreshToken: 3,
      ActivityCheck: 4,
    };
    const Messages = {
      Maintenance: {
        status: 'warning',
        title: 'System Maintenance',
        description: 'AQUA has entered into maintenance mode. All users have been logged out. Please wait...',
        isPersistentMessage: true,
        canNotBeClosed: true,
      },
      ServiceResumed: {
        status: 'success',
        title: 'Service Resumed',
        description:
          'Normal service has resumed and your browser has been refreshed to ensure you are running the latest version. You may now log back into the system.',
        isPersistentMessage: true,
      },
      VersionUpdate: {
        status: 'warning',
        title: 'System Update',
        description:
          'A new version of AQUA has been released. You have been logged out and your browser has been refreshed to ensure you are running the latest version.',
        isPersistentMessage: true,
      },
      InactivityLockout: {
        status: 'warning',
        title: 'Locked',
        description: 'Your login session has been locked due to inactivity.',
        isPersistentMessage: true,
      },
      MultiInstanceInvalidated: {
        status: 'warning',
        title: 'Browser instance invalidated',
        description:
          'This browser tab/window instance has been invalidated due to your login session having been logged out.',
        isPersistentMessage: true,
        doNotSync: true,
      },
    };

    let appTickerWorker = null;

    const tickRateSeconds = 1;
    let tickCount = 0;

    const attributesCheckInterval = 2.5; // Minutes
    let attributesCheckCount = 0;

    const versionCheckCount = 2; // Number of attributesCheckCount before checking for version change

    const keepAlive = 'isSuspended -> keep alive';

    const defaultTickerData = {
      liveWindows: [],
      isHandlingTick: false,
      loggedInUserCount: 0,
      inactivityTimeout: null,
      inactivityCutoff: null,
      lastAttributeCheck: null,
      attributes: null,
      showMessage: null,
    };
    let appTickerData = { ...defaultTickerData };

    let _visibilityChange = null;
    const visibilityChange = () => {
      // Get the name of the browsers visibility change event
      if (!_visibilityChange) {
        _visibilityChange = 'visibilitychange';
        if (typeof document.msHidden !== 'undefined') {
          _visibilityChange = 'msvisibilitychange';
        } else if (typeof document.webkitHidden !== 'undefined') {
          _visibilityChange = 'webkitvisibilitychange';
        }
      }
      return _visibilityChange;
    };

    let _documentHiddenProperty = null;
    const hiddenProperty = () => {
      // Get the name of the browsers hidden property
      if (!_documentHiddenProperty) {
        _documentHiddenProperty = 'hidden';
        if (typeof document.msHidden !== 'undefined') {
          _documentHiddenProperty = 'msHidden';
        } else if (typeof document.webkitHidden !== 'undefined') {
          _documentHiddenProperty = 'webkitHidden';
        }
      }
      return _documentHiddenProperty;
    };
    const isWindowVisible = () => {
      return !document[hiddenProperty()];
    };

    const currentRoute = () => {
      return router?.history?.current;
    };
    const currentRouteName = () => {
      return currentRoute()?.name?.toLowerCase() || '';
    };

    const storeTickerData = () => {
      vue.$browserStorage.appTickerData = appTickerData;
    };

    const isWindowInitialized = () => {
      return currentWindowData() != null && appTickerWorker;
    };
    const currentWindowData = () => {
      return appTickerData && appTickerData.liveWindows
        ? appTickerData.liveWindows.find((w) => w.UID === vue.$browserStorage.aquaWindowUID)
        : null;
    };

    const isLoggedIn = () => {
      return router.app.$options.computed.isLoggedIn.get();
    };

    const windowIsAlive = () => {
      if (!currentWindowData()) {
        if (!appTickerData.liveWindows) appTickerData.liveWindows = [];
        appTickerData.liveWindows.push({
          UID: vue.$browserStorage.aquaWindowUID,
          lastTick: new Date(),
          focused: false,
        });
      } else {
        currentWindowData().lastTick = new Date();
      }
      cleanDeadWindows();
    };

    const cleanDeadWindows = () => {
      let deathCutoff = DateFunctions.addMinutes(new Date(), -1);

      appTickerData.liveWindows = appTickerData.liveWindows.filter((w) => new Date(w.lastTick) > deathCutoff);
      storeTickerData();
    };

    const userActive = debounce(($event) => {
      if (!isSuspended()) debugLog('AQUA: ⏱ User active');

      // User is active, lets keep their database session alive
      if (hasBeenAMinute(appTickerData.lastKeepAlive) && processCall() && isLoggedIn()) {
        appTickerData.lastKeepAlive = new Date().removeSeconds(5);
        storeTickerData();

        AccountService.keepAlive();
      }

      // Ensure we handle any inactivity timeout, or forced logout, prior to recording new activity!
      // This should help catch cenarios where the ticker is paused by the browser or browser shutdown + restart
      if (processCall() && $event != keepAlive) nextTick();

      // Record user activity
      appTickerData.inactivityCutoff = DateFunctions.addMinutes(new Date(), appTickerData.inactivityTimeout);
      storeTickerData();

      if (!isSuspended() || $event != keepAlive) {
        // Ensure the current window/tab is recorded as active
        windowMadeActive(typeof $event === 'boolean' ? $event : true);
        // and the previousActiveWindowUID is updated
        vue.$browserStorage.previousActiveWindowUID = vue.$browserStorage.aquaWindowUID;
      }
    }, 500);

    const windowMadeActive = (focused) => {
      windowIsAlive();

      // Defocus other windows
      if (focused && currentWindowData()?.focused == false) {
        appTickerData.liveWindows
          .filter((w) => w.focused && w.UID !== vue.$browserStorage.aquaWindowUID)
          .forEach((w) => {
            w.focused = false;
          });

        // Set the current windows focused flag
        currentWindowData().focused = focused;
        storeTickerData();

        debugLog(`AQUA: ⏱ Active window changed to ${currentWindowData().focused}`);
      }

      nextTick();
    };

    const isActiveWindow = () => {
      return currentWindowData().focused;
    };

    const processCall = () => {
      return vue.$browserStorage.isLoggingIn == false && vue.$browserStorage.isLoggingOut == false;
    };

    const hasBeenAMinute = (checkDate, minutes = 1) => {
      return !checkDate || DateFunctions.secondsBetween(new Date(checkDate), new Date()) >= minutes * 60;
    };

    const isSuspended = () => {
      return vue.$browserStorage.suspendApplicationTicker
        ? vue.$browserStorage.suspendApplicationTicker.getTime() > new Date().getTime()
        : false;
    };

    const refreshToken = () => {
      // Check for expired access token and refresh if required
      handleUsers(UserAction.RefreshToken).then(() => {
        appTickerData.isHandlingTick = false;
        storeTickerData();
      });
    };

    const addEventListeners = () => {
      // Hook events to watch for user inactivity
      window.addEventListener('keyup', userActive);
      window.addEventListener('mouseup', userActive);
      window.addEventListener('wheel', userActive);

      // Hook events to watch for active Window
      window.addEventListener(visibilityChange(), () => {
        debugLog(`AQUA: ⏱ Window visibility changed to ${isWindowVisible()}`);
        userActive(isWindowVisible());
      });

      // Record that we have attached the events
      window.Trisoft_AQUA_TickerAttached = true;
      debugLog('AQUA: ⏱ Activity events attached');
    };

    const nextTick = () => {
      appTickerData = vue.$browserStorage.appTickerData || { ...defaultTickerData };
      windowIsAlive();

      // Make sure that the inactivity watch events are attached to the current window
      if (!window.Trisoft_AQUA_TickerAttached) {
        addEventListeners();

        appTickerData.isHandlingTick = false;
        storeTickerData();
      }

      // Only fire this tick if no other tickers are processing and if we're not getting access tokens
      if (processCall()) {
        tickCount++;

        if (!appTickerData.isHandlingTick) {
          appTickerData.isHandlingTick = true;
          storeTickerData();

          if (isSuspended() && tickCount >= 8) {
            tickCount = 0;
            userActive(keepAlive);
          }

          // Check for updated attributes file and handle accordingly (once a minute)
          if (hasBeenAMinute(appTickerData.lastAttributeCheck, attributesCheckInterval)) {
            debugLog('AQUA: ⏱ <TICK> getAttributes');
            getAttributes(attributesCallback);
          } else if (appTickerData.showMessage) {
            attributesCallback();
          }

          debugLog(
            `AQUA: ⏱ <${isSuspended() ? 'SUSPENDED' : 'TICK'}> [${!isActiveWindow() ? 'NOT ' : ''}visible] ` +
              `{ InactivityCutoff: ${DateFunctions.getDateTimeString(appTickerData.inactivityCutoff, true)}` +
              `, TimedOut: ${new Date(appTickerData.inactivityCutoff) < new Date()}}`
          );

          // Handle user inactivity
          handleUsers(UserAction.ActivityCheck).then((redirecting) => {
            if (redirecting) {
              appTickerData.isHandlingTick = false;
              storeTickerData();
            } else {
              refreshToken();
            }
          });
        } else if (tickCount >= 8) {
          tickCount = 0;

          appTickerData.isHandlingTick = false;
          storeTickerData();
        }
      }
    };

    //--------------------------------------------------------------------------------
    // Handle what happens during the current tick.
    // Uses router.replace() instead of push() to keep the browser history clean.
    //--------------------------------------------------------------------------------
    const handleUsers = (action) => {
      return new Promise((resolve) => {
        try {
          const routeName = currentRouteName();
          if (routeName && routeName != '' && processCall() && (!isSuspended() || action === UserAction.RefreshToken)) {
            const isLoginRoute = vue.$routeAssistant.loginRoutes.includes(routeName);
            const isLocked = vue.$browserStorage.lockSession;

            // Get logged in users from local storage
            const currentUser = vue.$browserStorage.currentUser;
            switch (action) {
              // Lock user(s) if they've been inactive
              case UserAction.ActivityCheck: {
                const onLockScreen = routeName === 'locked';
                const onRegister = routeName === 'register';
                const onAddLogin = routeName === 'add_login';
                const onPinEntry = routeName === 'pinentry';
                const isAddingUser = vue.$browserStorage.isAddingUser;

                if (isAddingUser && !onAddLogin && !onLockScreen && !onRegister && !onPinEntry) {
                  debugLog('AQUA: ⏱ Force redirect to /locked');
                  router.replace({ name: 'locked' });
                  resolve(true);
                } else if (!isAddingUser && !onRegister) {
                  appTickerData.loggedInUserCount = vue.$browserStorage.loggedInUsers.length;
                  storeTickerData();

                  const isLockedRedirectRequired = isLocked && !onLockScreen && !isLoginRoute;
                  const isToBeLocked =
                    !isLocked &&
                    !isLoginRoute &&
                    appTickerData.loggedInUserCount > 0 &&
                    new Date(appTickerData.inactivityCutoff) < new Date();
                  const hasBeenUnlocked =
                    !isLocked &&
                    (onLockScreen ||
                      (isLoginRoute && currentUser && currentUser.accessToken && currentUser.accessToken != ''));

                  if (isToBeLocked || isLockedRedirectRequired) {
                    // Lock system
                    if (isToBeLocked && !isLockedRedirectRequired) {
                      debugLog('AQUA: ⏱ UserAction.ActivityCheck isToBeLocked');

                      store.dispatch('abortAllEndpoints');

                      AccountService.logoutExtended(currentUser, false, true, () => {
                        store.dispatch('clearAppMessages');
                        store.dispatch('addAppMessage', Messages.InactivityLockout);

                        vue.$browserStorage.lockSession = true;
                        tickCount = 0;
                        router.replace({ name: 'locked' });
                        resolve(true);
                      });
                    } else if (!onLockScreen && isLockedRedirectRequired) {
                      debugLog('AQUA: ⏱ UserAction.ActivityCheck isLockedRedirectRequired');

                      store.dispatch('abortAllEndpoints');

                      router.replace({ name: 'locked' });
                      resolve(true);
                    }
                  }
                  // Force route to reload if unlocked from another tab/window
                  else if (hasBeenUnlocked && isLoggedIn() && !onPinEntry) {
                    debugLog('AQUA: ⏱ UserAction.ActivityCheck - Unlocked redirect');

                    store.dispatch('clearAppMessages');

                    const postLoginRoute = vue.$browserStorage.postLoginRoute;

                    debugLog(`AQUA: ⏱ clearing postLoginRoute`);
                    vue.$browserStorage.postLoginRoute = null;

                    debugLog(`AQUA: ⏱ redirect to postLoginRoute -> ${postLoginRoute.name}`, postLoginRoute);
                    router.replace(postLoginRoute ?? '/');

                    tickCount = 0;
                    resolve(true);
                  } else if (!isLocked && !isLoginRoute && (!currentUser || !currentUser.accessToken)) {
                    debugLog('AQUA: ⏱ UserAction.ActivityCheck - MultiInstanceInvalidated no currentUser');

                    store.dispatch('abortAllEndpoints');
                    tickCount = 0;

                    appTickerData.showMessage = UserAction.MultiInstanceInvalidated;
                    storeTickerData();

                    recordError('UserAction.ActivityCheck - MultiInstanceInvalidated no currentUser -> clear_cache');

                    router.replace({ name: 'clear_cache' });
                    resolve(true);
                  } else {
                    resolve(false);
                  }
                } else {
                  resolve(false);
                }
                break;
              }
              // Check for expired access token and refresh if required
              case UserAction.RefreshToken: {
                if (!isLocked && !isLoginRoute) {
                  AccountService.refreshToken(currentUser)
                    .then((result) => {
                      if (result) {
                        debugLog('AQUA: ⏱ UserAction.RefreshToken 🎉 Token refreshed');
                      }
                      tickCount = 0;
                      resolve(true);
                    })
                    .catch((error) => {
                      // throw new axios.Cancel(`Operation canceled due to token refresh error. {${config?.url ?? 'UNKOWN_URL'}}`);
                      debugLog('AQUA: ⏱ UserAction.RefreshToken 💥 TOKEN REFRESH ERROR', error);

                      tickCount = 0;
                      router.replace({ name: 'clear_cache' });
                      resolve(true);
                    });
                }
                break;
              }
            }
            // } else if (!isLoginRoute && action == UserAction.ActivityCheck) {
            //   debugLog('AQUA: ⏱ MultiInstanceInvalidated !isLoginRoute');
            //
            //   store.dispatch('clearAppMessages');
            //   store.dispatch('addAppMessage', Messages.MultiInstanceInvalidated);
            //
            //   store.dispatch('abortAllEndpoints');
            //   tickCount = 0;
            //
            //   router.replace({ name: 'clear_cache' });
            //   resolve(true);
            // } else {
            //   resolve(false);
            // }
          } else {
            resolve(false);
          }
        } catch (e) {
          resolve(false);
        }
      });
    };

    const getAttributes = (_attributesCallback, isInitializing = false) => {
      // Setup request to get the appAttributes
      let xhr = new XMLHttpRequest();
      xhr.overrideMimeType('application/json');
      xhr.open('GET', '/appAttributes.json', true);

      // Ensure the appAttributes.json is never pulled from cache!
      xhr.setRequestHeader('Cache-Control', 'no-cache, no-store, must-revalidate');
      xhr.setRequestHeader('Pragma', 'no-cache'); // Used only for backwards compatibility
      xhr.setRequestHeader('Expires', '0'); // Zero means it has already expired

      // Setup the downloaded callback
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status == '200') {
          // Execute the callback function and pass it the converted JSON object
          _attributesCallback(JSON.parse(xhr.responseText), isInitializing);
        }
      };

      // Retrieve latest copy of the appAttributes json file from the server
      xhr.send(null);
    };

    const attributesCallback = (latestAttributes, isInitializing = false) => {
      if (appTickerData.showMessage) {
        if (appTickerData.showMessage === UserAction.Maintenance) {
          store.dispatch('clearAppMessages');
          store.dispatch('addAppMessage', Messages.Maintenance);
          store.dispatch('setMaintenanceMode', true);
          vue.$browserStorage.lockSession = null;
        } else if (appTickerData.showMessage === UserAction.ServiceResumed) {
          store.dispatch('clearAppMessages');
          store.dispatch('addAppMessage', Messages.ServiceResumed);
          store.dispatch('setMaintenanceMode', false);
          appTickerData.showMessage = null;
          storeTickerData();
        } else {
          store.dispatch('clearAppMessages');
          store.dispatch('addAppMessage', Messages.VersionUpdate);
          appTickerData.showMessage = null;
          storeTickerData();
        }
      }

      if (latestAttributes) {
        const windowInitialized = isWindowInitialized();
        const enteredMaintenance = latestAttributes && latestAttributes.maintenance;
        const uiVersionChanged =
          appTickerData.attributes && appTickerData.attributes?.version !== latestAttributes?.version;
        attributesCheckCount++;

        if (!windowInitialized || !appTickerData.inactivityTimeout) {
          // Ensure inactivityTimeout is correct
          getInactivityTimeout();
        }

        if (!windowInitialized && enteredMaintenance) {
          store.dispatch('addAppMessage', Messages.Maintenance);
          store.dispatch('setMaintenanceMode', true);
          vue.$browserStorage.lockSession = null;
        }

        // Force the user(s) out if we've entered maintenance mode
        else if (appTickerData.attributes && !appTickerData.attributes.maintenance && enteredMaintenance) {
          handleMaintenanceOrVersionChange(UserAction.Maintenance).then((redirect) => {
            appTickerData.showMessage = UserAction.Maintenance;
            updatelastAttributeCheck();

            store.dispatch('setMaintenanceMode', true);

            if (redirect) {
              store.dispatch('clearAppMessages');
              store.dispatch('addAppMessage', Messages.Maintenance);

              router.replace({ name: '/login' });
            }
          });
        }

        // Inform the user that we've exited maintenance mode
        else if (appTickerData.attributes && appTickerData.attributes.maintenance && !enteredMaintenance) {
          appTickerData.isHandlingTick = false;
          appTickerData.showMessage = UserAction.ServiceResumed;
          storeTickerData();

          store.dispatch('setMaintenanceMode', false);

          recordError('Maintenance Mode ended -> clear_cache');
          router.replace({ name: 'clear_cache' });
        }

        // Check version number and log user(s) out if update detected.
        // Version change checked every attributesCheckInterval * versionCheckCount, unless a UI version change is detected first.
        else if (isInitializing || uiVersionChanged || versionCheckCount <= attributesCheckCount) {
          attributesCheckCount = 0;

          VersionService.getVersions().then((versionResult) => {
            const versions = {
              UI: latestAttributes?.version,
              API: versionResult.API_Version,
              DB: versionResult.DB_Version,
            };
            const jsonVersions = JSON.stringify(versions);

            if (
              appTickerData.attributes &&
              appTickerData.versions &&
              appTickerData.versions !== jsonVersions &&
              windowInitialized
            ) {
              const currentVersions = appTickerData.attributes ? JSON.parse(appTickerData.versions) : null;
              let versionMessages = [];
              if (currentVersions) {
                if (currentVersions.UI != versions.UI) {
                  versionMessages.push(`Outdated UI version - ${currentVersions.UI} + ' -> ${versions.UI}`);
                }
                if (currentVersions.API != versions.API) {
                  versionMessages.push(`Outdated API version - ${currentVersions.API} + ' -> ${versions.API}`);
                }
                if (currentVersions.DB != versions.DB) {
                  versionMessages.push(`Outdated DB version - ${currentVersions.DB} -> ${versions.DB}`);
                }
              }

              if (versionMessages.length) {
                debugLog('AQUA: ⏱ ', versionMessages);
              }

              // Log user(s) out and inform with persistent warning as to why they were logged out
              handleMaintenanceOrVersionChange(UserAction.VersionUpdate).then(() => {
                appTickerData.attributes = latestAttributes;
                appTickerData.versions = jsonVersions;
                appTickerData.showMessage = UserAction.VersionUpdate;
                updatelastAttributeCheck();

                recordError('Newer version detected -> clear_cache', versionMessages);

                router.replace({ name: 'clear_cache' });
              });
            } else {
              // Update latest attributes
              appTickerData.attributes = latestAttributes;
              appTickerData.versions = jsonVersions;
              updatelastAttributeCheck();
            }
          });
        } else {
          // Update latest attributes
          appTickerData.attributes = latestAttributes;
          updatelastAttributeCheck();
        }
      }
    };

    const updatelastAttributeCheck = () => {
      appTickerData.lastAttributeCheck = new Date().removeSeconds(5);
      appTickerData.isHandlingTick = false;
      storeTickerData();
    };

    const getInactivityTimeout = () => {
      const initTicking = (timeout) => {
        if (timeout != appTickerData.inactivityTimeout) {
          appTickerData.inactivityTimeout = timeout;
          storeTickerData();
        }

        userActive(isWindowVisible());

        if (!appTickerWorker) {
          debugLog('AQUA: ⏱ Initialized');

          const appTickerWorkerFunction = `setInterval(() => { postMessage(null) }, ${tickRateSeconds * 1000});`;
          appTickerWorker = new Worker(URL.createObjectURL(new Blob([appTickerWorkerFunction])));
          appTickerWorker.onmessage = nextTick;

          nextTick();
        }
      };

      if (DebugTimeout) {
        initTicking(DebugInterval);
      } else {
        store.getters.getGlobalProperty(7).then((prop) => {
          // Set the inactivity timeout or to the default value (DefaultInterval)
          initTicking(prop && prop.Value ? parseInt(prop.Value) : DefaultInterval);
        });
      }
    };

    const handleMaintenanceOrVersionChange = (action) => {
      return new Promise((resolve) => {
        try {
          debugLog('AQUA: ⏱ UserAction.' + (action === UserAction.Maintenance ? 'Maintenance' : 'VersionUpdate'));

          if (appTickerData.loggedInUserCount > 0) {
            store.dispatch('abortAllEndpoints');

            // Loop the currently logged in users
            vue.$browserStorage.loggedInUsers.forEach((user) => {
              // Log user out
              AccountService.logout(user, false, () => {
                appTickerData.loggedInUserCount--;
                storeTickerData();

                // Once the last user has been logged out, return to login page and inform why the were logged out
                if (appTickerData.loggedInUserCount <= 0) {
                  resolve(true);
                }
              });
            });
          } else {
            if (action === UserAction.Maintenance) {
              store.dispatch('clearAppMessages');
              store.dispatch('addAppMessage', Messages.Maintenance);
            }
            resolve(false);
          }
        } catch (e) {
          resolve(false);
        }
      });
    };

    const recordError = (message, error = null) => {
      ErrorService.createError(vue.$browserStorage.currentUser, {
        ErrorSource: 'UI',
        ErrorNumber: 0,
        ErrorMessage: `[APPLICATION TICKER] ${message}`,
        ErrorResponse: typeof error === 'object' ? JSON.stringify(error) : error ?? '',
      });
    };

    const reportError = (title, error = null) => {
      store.dispatch('addAppMessage', {
        status: 'danger',
        icon: 'error',
        title: `[APPLICATION TICKER] ${title}`,
        error: error,
      });
    };

    // ====================================================================== \\
    // ==  Initialize the Application Ticker                               == \\
    // ====================================================================== \\
    // !!  This must be the last thing done by the install(Vue) function   !! \\
    // ====================================================================== \\
    vue.$applicationTicker = this;
    vue.$applicationTicker.init = () => {
      appTickerData = vue.$browserStorage.appTickerData || { ...defaultTickerData };

      if (!isWindowInitialized() || !appTickerWorker) {
        // Get attributes on initialization
        getAttributes(attributesCallback, true);
      }
    };
    vue.$applicationTicker.init();
    // ====================================================================== \\
  },
};
