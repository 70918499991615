<template>
  <transition name="slide">
    <div class="modal-mask info" v-scroll-lock="true">
      <div class="modal-wrapper" v-click-outside="exitModal">
        <div :class="['modal-header', !allowClose ? 'noCloseButton' : '']">
          <button v-if="allowClose" class="closemodal" @click.stop.prevent="$emit('close')" aria-label="click to close" ref="modalclosebtn"></button>
          <slot name="header">
            <h2>Actions</h2>
          </slot>
        </div>
        <div class="modal-body" :style="!hasFooterSlot ? { marginBottom: 0 } : null">
          <slot name="body">default body</slot>
        </div>
        <div v-if="hasFooterSlot" class="modal-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: ['showmodal', 'refocusref', 'setfocuson', 'allowclose', 'allowclickoutside'],
  data() {
    return {
      showModal: false,
      hasFooterSlot: true,
    };
  },
  created() {
    this.showModal = this.showmodal;
    this.hasFooterSlot = !!this.$slots.footer;

    document.addEventListener('keyup', this.escKeyPress);

    if (this.setfocuson) {
      this.setFocusByClass(this.setfocuson);
    } else {
      this.setFocusByRef('modalclosebtn');
    }
  },
  updated() {
    this.$nextTick(function () {
      // Code that will run only after the re-render
      this.hasFooterSlot = !!this.$slots.footer;
    });
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.escKeyPress);
  },
  destroyed() {
    if (this.refocusref !== '' && this.$parent.$refs[this.refocusref]) {
      this.$nextTick(function () {
        if (this.refocusref !== '' && this.$parent.$refs[this.refocusref]) {
          if (Array.isArray(this.$parent.$refs[this.refocusref])) {
            try {
              this.$parent.$refs[this.refocusref][0].focus({ preventScroll: false });
            } catch {
              try {
                this.$parent.$refs[this.refocusref][0].$el.focus({ preventScroll: false });
              } catch {
                /* do nothing! */
              }
            }
          } else {
            try {
              this.$parent.$refs[this.refocusref].focus({ preventScroll: false });
            } catch {
              try {
                this.$parent.$refs[this.refocusref].$el.focus({ preventScroll: false });
              } catch {
                /* do nothing! */
              }
            }
          }
        }
      });
    }
  },
  computed: {
    allowClose() {
      return this.allowclose != null ? this.allowclose : true;
    },
    allowClickOutside: function () {
      return this.allowclickoutside != null ? this.allowclickoutside : true;
    },
  },
  watch: {
    refocusref: function (newVal, oldVal) {
      this.refocusref = newVal;
      //console.log('watch', this.refocusref);
    },
  },
  methods: {
    escKeyPress: function (event) {
      if (event.keyCode === 27) {
        this.$emit('close');
      }
    },
    exitModal: function () {
      if (this.allowClickOutside) {
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
