<template>
  <div class="standardheader" ref="sessionHeader" id="sessionDetailHeader" style="padding-bottom: 15px">
    <div class="sessiondetailmini" v-if="value">
      <div v-if="emergencySession">
        <h2>{{ emergencySessionName || 'UNSPECIFIED' }}</h2>
      </div>
      <div v-else class="sessioninfo">
        <div class="calendarday">
          <div class="dayname">
            {{ value.PlannedSessionStartTime | moment('ddd') }}
          </div>
          <div class="monthday">
            {{ value.PlannedSessionStartTime | moment('DD') }}
          </div>
          <div class="monthname">
            {{ value.PlannedSessionStartTime | moment("MMM 'YY") }}
          </div>
        </div>
        <div class="row">
          <div class="col-sm-5">
            <h2 class="mb10">
              {{ value.LocationDescription || 'No Location' }}
              <small class="specialty">
                <span class="highlight">{{ value.SpecialtyDescription || 'No Specialty' }}</span>
              </small>
              <div class="childcount" v-if="value.PatientsPaed" :title="value.PatientsPaed + ' paediatric patients'">
                <span class="icon"></span>
                <div class="count">{{ value.PatientsPaed }}</div>
              </div>
            </h2>
            <h3 class="mb10">
              <span class="personnel"
                ><div class="indicator consultant" title="Consultant">C</div>
                {{ value.ConsultantName || 'No Consultant' }}</span
              >
              <br />
              <span class="personnel" v-if="value.AnaesthetistName"
                ><div class="indicator" title="Anaesthetist">A</div>
                {{ value.AnaesthetistName || 'No Anaesthetist' }}</span
              >
              <br />
              <span class="personnel" v-if="value.SurgeonName"
                ><div class="indicator" title="Surgeon">S</div>
                {{ value.SurgeonName || 'No Surgeon' }}</span
              >
              <small>
                <a href="#" v-if="value.Personnel && !hidepersonnel && value.ActualSessionStartTime == null" class="inlineedit open" ref="viewPersonnel" @click.stop.prevent="viewPersonnel = true"
                  >View Personnel</a
                ></small
              >
            </h3>
            <div
              v-if="!emergencySession"
              class="sessionruntime"
              :class="[value.SessionSubmitted || value.IsAutoLocked ? 'lockedicon' : '', value.SessionLocked || value.IsAutoLocked ? 'confirmlocked' : '']"
            >
              <div class="sessionlocked"></div>

              <span>{{ value.SessionIdentifier }}</span>
              {{ value.PlannedSessionStartTime | moment('HH:mm') }}
              -
              {{ value.PlannedSessionFinishTime | moment('HH:mm') }}
              <span style="margin-left: 10px">{{ value.BreakLength ? 'Planned Break: ' + value.BreakLength + ' minutes' : '' }}</span>
            </div>
          </div>

          <div class="col-sm-2 subdetail">
            <div
              class="lighter"
              :class="
                value.SessionSubmitted ||
                value.IsAutoLocked ||
                canSignoffConsultantSession ||
                canSignoffAnaesthetistSession ||
                (showbrief && hasActivePatients && value.TeamBriefFormID && value.TeamBriefStatus != 0) ||
                (value.TeamDebriefFormID && value.TeamDebriefStatus != 0)
                  ? 'border'
                  : ''
              "
            >
              <div class="textline" v-if="value.SessionSubmitted || value.IsAutoLocked">
                <span>
                  {{
                    value.IsAutoLocked ? 'Auto Locked' : value.SessionLocked ? 'Locked' + (value.IsAutoUnlocked ? ' ( Auto Unlocked ) ' : '') : value.SessionSubmitted ? 'Submitted for Locking' : ''
                  }}
                </span>

                <span v-if="value.SessionRequestedUnlock"> | Unlock Requested</span>
              </div>
              <div class="textline" v-if="canSignoffConsultantSession">
                {{ value.SessionSignedoffByConsultant ? 'Signed off by Consultant' : 'Awaiting Consultant Sign off' }}
              </div>
              <div class="textline" v-if="canSignoffAnaesthetistSession">
                {{ value.SessionSignedoffByAnaesthetist ? 'Signed off by Anaesthetist' : 'Awaiting Anaesthetist Sign off' }}
              </div>
              <!-- <div class="moreinfo" v-if="!emergencySession">
    {{ getPatientsBooked() + ' patient' + (getPatientsBooked() == 1 ? '' : 's') + ' booked'
    }}{{ value.IsSlotBased ? '' : ' | Planned Utilisation: ' + (value.PlannedUtilisationPercent || 0) + '%' }} |
    {{ getTimeAvailable() }}
  </div> -->
              <div class="textline briefstatus" v-if="showbrief && hasActivePatients && value.TeamBriefFormID && value.TeamBriefStatus != 0">
                <span :class="[canTakeBrief || canViewBrief ? 'clickable' : '']">
                  <a href="#" class="brieficon" @click.stop.prevent="canTakeBrief || canViewBrief ? showBrief() : ''" ref="briefStatusLink"
                    >Brief {{ value.TeamBriefStatus != 1 ? ' - Incomplete' : ' - Complete' }}
                    <div class="icon" :class="value.TeamBriefStatus != 1 ? 'incomplete' : 'complete'"></div
                  ></a>
                </span>
              </div>
              <div class="textline briefstatus" v-if="value.TeamDebriefFormID && value.TeamDebriefStatus != 0">
                <span :class="[canTakeDebrief || canViewDebrief ? 'clickable' : '']">
                  <a href="#" class="brieficon" @click.stop.prevent="canTakeDebrief || canViewDebrief ? showDebrief() : ''" ref="debriefStatusLink"
                    >Debrief {{ value.TeamDebriefStatus != 1 ? ' - Incomplete' : ' - Complete' }}
                    <div class="icon" :class="value.TeamDebriefStatus != 1 ? 'incomplete' : 'complete'"></div
                  ></a>
                </span>
              </div>
            </div>
          </div>
          <div class="col-sm-5 sessionstats mt15">
            <h3 class="mb5" v-if="value.SessionStatus">
              {{ value.SessionStatus }}
            </h3>
            <p style="margin-bottom: 2px; opacity: 0.8">
              <span v-if="value.BookingRequiresConfirmation" class="highlight" style="margin-right: 4px; margin-bottom: 4px; display: inline-block">{{
                value.IsConfirmed ? 'Session Confirmed' : 'Session Not Confirmed'
              }}</span>

              <span v-if="isFuture || isFinalised" class="highlight" style="margin-right: 4px; margin-bottom: 4px; display: inline-block">{{
                bookedPatients + ' patient' + (bookedPatients == 1 ? '' : 's') + ' planned'
              }}</span>

              <span v-if="!value.IsSlotBased && (isFuture || isFinalised)" class="highlight" style="margin-right: 4px; margin-bottom: 4px; display: inline-block">{{
                'Planned utilisation: ' + (value.PlannedUtilisationPercent || 0) + '%'
              }}</span>

              <span v-if="isFuture" class="highlight" style="margin-right: 4px; margin-bottom: 4px; display: inline-block">{{ getTimeAvailable() }}</span>
              <span v-else-if="isFinalised" class="highlight" style="margin-right: 4px; margin-bottom: 4px; display: inline-block">{{
                'Planned patient time: ' + value.PlannedPatientTime + ' minutes'
              }}</span>
            </p>
            <p style="opacity: 0.8">
              <span v-if="isFinalised" class="highlight" style="margin-right: 4px; margin-bottom: 4px; display: inline-block">{{
                value.PatientsComplete + ' patient' + (value.PatientsComplete == 1 ? '' : 's') + ' complete'
              }}</span>

              <span v-if="!value.IsSlotBased && isFinalised" class="highlight" style="margin-right: 4px; margin-bottom: 4px; display: inline-block">{{
                'Actual utilisation: ' + (value.ActualUtilisationPercent || 0) + '%'
              }}</span>

              <span v-if="isFinalised" class="highlight" style="margin-right: 4px; margin-bottom: 4px; display: inline-block">{{
                'Actual patient time: ' + value.ActualPatientTime + ' minutes'
              }}</span>
            </p>
          </div>
        </div>
        <div title="Scheduled Emergency Session" class="scheduledemergency" v-if="value.IsEmergencySessionProfile"></div>
      </div>
    </div>
    <transition name="popup">
      <PopOver
        @closePopOver="
          viewPersonnel = false;
          setFocusByRef('viewPersonnel');
        "
        v-if="viewPersonnel"
      >
        <template v-slot:popovertitle>
          <h2>Personnel</h2>
        </template>

        <template v-slot:popovercontent>
          <div class="container">
            <SessionPersonnelLight v-if="value" v-model="value.Personnel" :sessionrecordid="value.SessionRecordID" :personneltemplate="value.PersonnelTemplateID" :setfocus="true" :viewonly="true" />
          </div>
        </template>
      </PopOver>
    </transition>
  </div>
</template>

<script>
export default {
  props: ['value', 'showbrief', 'sessionprofile', 'sessionactual', 'surgicalunscheduled', 'hidepersonnel'],
  data() {
    return {
      viewPersonnel: false,
    };
  },
  name: 'SessionDetailMini',
  computed: {
    isFinalised: function () {
      return this.sessionactual?.Completed;
    },
    isFuture: function () {
      return this.DateFunctions.isFuture(this.value.PlannedSessionStartTime);
    },
    emergencySessionName: function () {
      let name = '';
      if (this.emergencySession) {
        name = this.value.SessionName;
        if (this.surgicalunscheduled && Boolean(this.surgicalunscheduled)) {
          name += ' - surgical view';
        } else {
          name += ' - full view';
        }
      }
      return name.toUpperCase();
    },
    emergencySession: function () {
      return this.value && this.value.AutoEmergency;
    },
    availableSlots: function () {
      return this.value && this.value.Patients && this.value.Patients.length > 0 ? this.value.Patients.filter((s) => s.SlotState == 0) : null;
    },
    hasActivePatients: function () {
      return this.value && this.value.Patients && this.value.Patients.length > 0 ? this.value.Patients.filter((p) => p.PatientStatus && p.PatientStatusID != 6).length > 0 : false;
    },
    canTakeBrief: function () {
      return (!this.isRetrospectiveSession || this.includeBriefInRetro) && this.value && !this.emergencySession && this.value.SessionOptions
        ? this.value.SessionOptions.find((so) => so.FunctionCode == 'SESSIONTEAMBRIEF' && so.AccessGranted == 1 && so.Active == 1)
        : null;
    },
    canTakeDebrief: function () {
      return (!this.isRetrospectiveSession || this.includeDebriefInRetro) && this.value && !this.emergencySession && this.value.SessionOptions
        ? this.value.SessionOptions.find((so) => so.FunctionCode == 'SESSIONTEAMDEBRIEF' && so.AccessGranted == 1 && so.Active == 1)
        : null;
    },
    canViewBrief: function () {
      return !this.emergencySession && this.value && this.value.SessionOptions && this.value.SessionOptions.length
        ? this.value.SessionOptions.find((so) => so.FunctionCode == 'SESSIONTEAMBRIEF' && so.AccessGranted == 1) != null && this.value.TeamBriefStatus && this.value.TeamBriefStatus != 0
        : false;
    },
    canViewDebrief: function () {
      return !this.emergencySession && this.value && this.value.SessionOptions && this.value.SessionOptions.length
        ? this.value.SessionOptions.find((so) => so.FunctionCode == 'SESSIONTEAMDEBRIEF' && so.AccessGranted == 1) != null && this.value.TeamDebriefStatus && this.value.TeamDebriefStatus != 0
        : false;
    },
    includeBriefInRetro: function () {
      return this.sessionprofile && this.sessionprofile.OverrideBriefRetro;
    },
    isRetrospectiveSession: function () {
      return this.sessionprofile && this.sessionprofile.RetrospectiveMode;
    },
    canSignoffConsultantSession: function () {
      return this.value && this.value.SessionOptions ? this.value.SessionOptions.find((so) => so.FunctionCode == 'SESSIONCONSIGNOFF' && so.AccessGranted == 1 && so.Active == 1) : null;
    },
    canSignoffAnaesthetistSession: function () {
      return this.value && this.value.SessionOptions ? this.value.SessionOptions.find((so) => so.FunctionCode == 'SESSIONANASIGNOFF' && so.AccessGranted == 1 && so.Active == 1) : null;
    },
    bookedPatients: function () {
      let sessionPatients = null;

      if (this.value && this.value.IsSlotBased && this.value.Patients && this.value.Patients.length > 0) {
        sessionPatients = this.value.Patients.filter((p) => p.SlotState == 1 && p.PrimarySlot == 1);
        return sessionPatients && sessionPatients.length ? sessionPatients.length : 0;
      } else {
        return this.value.PatientsPlanned || 0;
      }
    },
  },
  mounted: function () {
    this.$emit('headerHeight', this.$refs.sessionHeader.clientHeight);
  },
  methods: {
    showBrief: function () {
      this.$emit('showBrief');
    },
    showDebrief: function () {
      this.$emit('showDebrief');
    },
    getTimeAvailable: function () {
      if (this.value && this.value.IsSlotBased) {
        return (this.availableSlots && this.availableSlots.length ? this.availableSlots.length : 0) + ' slots available';
      } else {
        return (this.value.PlannedSessionTimeAvailable ? this.value.PlannedSessionTimeAvailable : 0) + ' minutes available';
      }
    },
    // getPatientsBooked: function () {
    //   if (this.value && this.value.IsSlotBased) {
    //     let patients = this.value.Patients ? this.value.Patients.length : 0;
    //     return patients && patients.length ? patients.filter((p) => p.SlotState == 1 && p.PrimarySlot == 1).length : 0;
    //   } else {
    //     return this.value.PatientsBooked || 0;
    //   }
    // },
  },
  components: {
    SessionPersonnelLight: () => import('/src/components/route/session/views/SessionPersonnelLight'),
  },
};
</script>

<style lang="less" scoped>
// @import url('/src/assets/css/colors.less');
// #SessionDetailMini {
//   overflow: hidden;
//   padding: 15px 20px 15px 60px;
//   position: relative;

//   @media screen and (max-width: 768px) {
//     padding-right: 150px;
//   }

//   .sessioncontent {
//     position: relative;

//     @media screen and (max-width: 768px) {
//       // font-size: 0.8em;
//       margin-bottom: 15px;
//     }
//   }

//   h2 {
//     font-size: 2em;
//     line-height: 1em;

//     @media screen and (max-width: 768px) {
//     }
//   }

//   h3 {
//     margin-bottom: 15px;

//     span {
//       padding: 5px 10px;
//       padding-left: 0;

//       & + span {
//         padding-left: 10px;
//       }

//       &.highlight {
//         background: @primaryColor;
//         color: @textPrimaryColor;
//         padding: 5px 10px;
//         display: inline-block;
//       }
//     }

//     @media screen and (max-width: 768px) {
//       span {
//         display: block;
//         padding: 0;
//         margin: 0;

//         &:before {
//           display: none;
//         }
//       }
//     }
//   }

//   p {
//     color: @secondaryTextColor;
//   }
// }
</style>
