<template>
  <div id="sessionPersonnelLight">
    <ContentLoading displaystyle="boxed" />
    <div class="tile">
      <div class="tilebody">
        <div class="tileheaderdescription">
          <div class="icon personnel"></div>
          <h2>Personnel {{ !viewonly ? 'Selection' : '' }}</h2>
          <h4>{{ viewonly ? 'View' : 'Select' }} the members of staff for each role</h4>
        </div>
      </div>
      <div class="tilebody">
        <h3 v-if="(!value || !value.length) && viewonly">There are no personnel against this session</h3>
        <ul class="unstyled" v-for="(personneltemplaterole, ptindex) in personnelTemplateRoleList" :key="ptindex">
          <li v-if="!viewonly || (getRolePersonnel(personneltemplaterole.PersonnelRoleID) && getRolePersonnel(personneltemplaterole.PersonnelRoleID).length)">
            <h3>{{ personneltemplaterole.PersonnelRoleDescription }}</h3>

            <!-- REMAINING SLOTS -->

            <ul class="personnelselected">
              <li v-for="(personnel, personnelindex) in getRolePersonnel(personneltemplaterole.PersonnelRoleID)" :key="personnelindex">
                <button
                  class="personnelselection"
                  :disabled="sessioncomplete || viewonly"
                  :class="[allowUpdate(personneltemplaterole, personnel.PersonnelID) && !viewonly ? 'success' : '']"
                  @click.stop.prevent="
                    selectPersonnelReturnRef = 'selectPersonnel' + ptindex;
                    removePersonnel(personnel.PersonnelID, personnel.PersonnelRoleID, personnel.OrderNumber);
                  "
                  :ref="'selectPersonnel' + ptindex"
                  :id="'selectPersonnel' + ptindex"
                >
                  <div class="icon"></div>
                  <div class="personnelname">
                    {{ personnel.PersonnelName }} <small v-if="defaultLocumID == personnel.PersonnelID"><i>(locum / visitor)</i></small>
                  </div>

                  <div class="remove" v-if="allowUpdate(personneltemplaterole, personnel.PersonnelID) && !viewonly"></div>
                </button>
              </li>
            </ul>

            <button
              v-if="(!personneltemplaterole.MaximumAllowed || getRolePersonnelLength(personneltemplaterole.PersonnelRoleID) < personneltemplaterole.MaximumAllowed) && !viewonly"
              class="personnelselection nostyle"
              :disabled="sessioncomplete || viewonly"
              :class="[personneltemplaterole.MinimumRequired && getRolePersonnelLength(personneltemplaterole.PersonnelRoleID) < personneltemplaterole.MinimumRequired ? 'required' : '']"
              @click.stop.prevent="
                selectPersonnelReturnRef = 'selectPersonnel' + ptindex;
                selectPersonnelForRole(personneltemplaterole);
              "
              :ref="'selectPersonnel' + ptindex"
              :id="'selectPersonnel' + ptindex"
            >
              <div class="icon"></div>
              <div class="personnelname">
                <span>Click to search for {{ personneltemplaterole.PersonnelRoleDescription.toLowerCase() }}</span
                >&nbsp;
              </div>
            </button>
          </li>
        </ul>
      </div>
    </div>

    <transition name="popup">
      <PopOver
        @closePopOver="
          personnelSearchPopOver = false;
          setFocusByRef(selectPersonnelReturnRef);
        "
        v-if="personnelSearchPopOver"
      >
        <template v-slot:popovertitle>
          <h2>Personnel Search</h2>
        </template>
        <template v-slot:popovercontent>
          <PersonnelSearch
            class="mt30"
            :personnelroleid="selectedPersonnelRoleID"
            :sessionrecordid="sessionRecordID"
            :areaid="areaid"
            :allowlocum="allowLocum(selectedPersonnelRoleID)"
            :excludeids="excludeids"
            @confirmPersonnelSearch="confirmPersonnelSearch($event)"
            @cancelPersonnelSearch="
              personnelSearchPopOver = false;
              setFocusByRef(selectPersonnelReturnRef);
            "
          />
        </template>
      </PopOver>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { PersonnelService } from '/src/services/personnel';

export default {
  name: 'SessionPersonnelLight',
  props: ['value', 'personneltemplate', 'updatepersonnel', 'sessionfromplan', 'sessionrecordid', 'setfocus', 'excludeids', 'areaid', 'submitted', 'sessioncomplete', 'viewonly'],
  data() {
    return {
      selectPersonnelReturnRef: '',
      personnelTemplateRoleList: [],
      sessionPersonnelList: [],
      personnelSearchPopOver: false,
      selectedPersonnelRoleID: null,
      selectedPersonnelOrderNumber: null,
      selectedLeadTypeID: null,
      selectedPersonnelEnum: null,
      sessionRecordID: null,

      prevPersonnelIDs: [],
      defaultLocumID: null,
    };
  },
  components: {
    PersonnelSearch: () => import('/src/components/personnel/PersonnelSearch'),
  },
  computed: {
    ...mapGetters(['sessionStore', 'getGlobalProperty']),

    currentSessionRecord: function () {
      if (this.sessionRecordID) {
        return this.sessionStore.find((ss) => ss.SessionRecordID == this.sessionRecordID);
      } else {
        return null;
      }
    },
  },
  watch: {
    personneltemplate: function (newVal, oldVal) {
      this.buildPersonnelTemplate();
    },
    updatepersonnel: function (newVal, oldVal) {
      this.sessionPersonnelList = this.updatepersonnel;
    },
  },
  created() {
    this.sessionPersonnelList = this.value || [];
    this.prevPersonnelIDs = this.sessionPersonnelList.map((p) => p.PersonnelID);
    this.getGlobalProperty(1500).then((result) => {
      this.defaultLocumID = result && result.Value ? parseInt(result.Value) : null;
    });

    this.buildPersonnelTemplate();
    this.sessionRecordID = this.sessionrecordid ? parseInt(this.sessionrecordid) : null;
  },
  methods: {
    ...mapActions(['addAppMessage']),
    allowLocum: function (iPersonnelRoleID) {
      if (!this.personnelTemplateRoleList || !iPersonnelRoleID || this.sessionfromplan) return false;
      return this.personnelTemplateRoleList.find((r) => r.PersonnelRoleID == iPersonnelRoleID && r.AllowLocum == true);
    },
    /* this is now handled by the search itself 
    personnelAreaMismatch: function (personnel) {
      if (this.areaid == null || personnel.FixedAreaID == null) return false;
      return personnel.FixedAreaID != this.areaid;
    }, */
    buildPersonnelTemplate: function () {
      if (this.personneltemplate) {
        PersonnelService.getPersonnelTemplateRole(this.personneltemplate).then((result) => {
          this.personnelTemplateRoleList = result;

          if (this.setfocus) {
            this.$nextTick(function () {
              this.setFocusByClass('personnelselection');
            });
          }
        });
      } else {
        this.personnelTemplateRoleList = [];

        if (this.setfocus) {
          this.$nextTick(function () {
            this.setFocusByClass('personnelselection');
          });
        }
      }
    },

    allowUpdate: function (iPersonnelRole, iPersonnelID) {
      let allowUpdate = true;

      if (iPersonnelRole.LockUpdate) {
        allowUpdate = this.prevPersonnelIDs.includes(iPersonnelID) ? false : true;
      }

      if (!this.currentSessionRecord || !this.currentSessionRecord.SessionLocked) {
        allowUpdate = true;
      }

      return allowUpdate;
    },

    confirmPersonnelSearch: function (iPersonnel) {
      let foundPersonnelForRole = this.sessionPersonnelList.findIndex((pr) => pr.PersonnelRoleID == this.selectedPersonnelRoleID && pr.PersonnelID == iPersonnel.PersonnelID);
      let orderNumber = this.sessionPersonnelList.find((pr) => pr.PersonnelRoleID == this.selectedPersonnelRoleID && pr.PersonnelID != null)
        ? this.sessionPersonnelList.find((pr) => pr.PersonnelRoleID == this.selectedPersonnelRoleID && pr.PersonnelID != null).OrderNumber + 1
        : 1;
      if (foundPersonnelForRole == -1) {
        this.sessionPersonnelList.push({
          PersonnelRoleID: this.selectedPersonnelRoleID,
          OrderNumber: orderNumber,
          PersonnelID: iPersonnel.PersonnelID,
          PersonnelName: iPersonnel.PersonnelName,
          LocumName: iPersonnel.Locum ? iPersonnel.PersonnelName : null,
          PersonnelTemplateID: this.personneltemplate,
          FixedAreaID: iPersonnel.FixedAreaID,
          PersonnelTypeEnum: this.selectedPersonnelEnum,
          LeadPersonnelTypeID: this.selectedLeadTypeID,
        });

        // this.sessionPersonnelList.push(iPersonnel);

        this.$emit('input', this.sessionPersonnelList);
        this.personnelSearchPopOver = false;
        this.setFocusByRef(this.selectPersonnelReturnRef);
      } else {
        this.addAppMessage({
          status: 'danger',
          icon: 'error',
          title: 'Duplicate Personnel',
          description: 'Personnel member already added to this role',
        });
      }
    },

    selectPersonnelForRole: function (personneltemplaterole, orderNumber) {
      this.selectedPersonnelRoleID = personneltemplaterole.PersonnelRoleID;
      this.selectedLeadTypeID = personneltemplaterole.LeadPersonnelTypeID;
      this.selectedPersonnelEnum = personneltemplaterole.PersonnelTypeEnum;
      this.personnelSearchPopOver = true;
    },
    getRolePersonnel: function (iPersonnelRoleID) {
      if (this.sessionPersonnelList && this.sessionPersonnelList.length > 0) {
        return this.sessionPersonnelList.filter((cp) => cp.PersonnelRoleID === iPersonnelRoleID && cp.PersonnelID);
      } else {
        return null;
      }
    },
    getRolePersonnelLength: function (iPersonnelRoleID) {
      if (this.sessionPersonnelList && this.sessionPersonnelList.length > 0) {
        return this.sessionPersonnelList.filter((cp) => cp.PersonnelRoleID === iPersonnelRoleID && cp.PersonnelID)?.length || 0;
      } else {
        return 0;
      }
    },

    removePersonnel: function (iPersonnelID, iPersonnelRoleID, iOrderNumber) {
      let roleRec = this.personnelTemplateRoleList.find((pr) => pr.PersonnelRoleID == iPersonnelRoleID);
      if (roleRec) {
        if (!this.allowUpdate(roleRec, iPersonnelID)) return false;
      }

      let personnelRec = this.sessionPersonnelList.find((up) => up.PersonnelID === iPersonnelID && up.PersonnelRoleID == iPersonnelRoleID && up.OrderNumber == iOrderNumber);
      if (personnelRec) {
        if (personnelRec.PlannedPersonnelID != null || (personnelRec.PlanPersonnelID != null && !this.sessionfromplan)) {
          personnelRec.PersonnelID = null;
        } else {
          this.sessionPersonnelList = this.sessionPersonnelList.filter((p) => p !== personnelRec);
        }
      }
      this.$nextTick(() => {
        this.$emit('input', this.sessionPersonnelList);
        this.setFocusByRef(this.selectPersonnelReturnRef);
      });
    },
  },
};
</script>
<style lang="less">
@import url('/src/assets/css/colors');
@import url('/src/assets/css/functions.less');
@import url('/src/assets/css/icons.less');
#sessionPersonnelLight {
  .personnelselection {
    padding: 10px 35px 10px 45px;
    // background   : @primaryColor;
    color: @primaryTextColor; //@textPrimaryColor;
    border-radius: 20px;
    position: relative;
    margin-bottom: 5px;
    display: inline-block;
    cursor: pointer;

    .icon {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
      content: ' ';
      display: block;
      // .usericon(@textPrimaryColor);
      .usericon(@dividerColor);

      &.resource {
        .resourceicon(@dividerColor);
      }
    }
    &:disabled {
      cursor: default;
      color: @textPrimaryColor;
      background-color: @primaryColor;
    }

    &.required {
      background-color: @errorColor;
      color: @textPrimaryColor;

      .icon {
        .usericon(@textPrimaryColor);
      }
    }

    &.success {
      background-color: @successColor;
      color: @textPrimaryColor;

      .icon {
        .usericon(@textPrimaryColor);

        &.resource {
          .resourceicon(@textPrimaryColor);
        }
      }
    }

    &.restricted {
      background-color: @dividerColor;
      color: @secondaryTextColor;
      cursor: not-allowed;

      .icon {
        .usericon(@secondaryTextColor);

        &.resource {
          .resourceicon(@secondaryTextColor);
        }
      }
    }

    .remove {
      position: absolute;
      top: 50%;
      right: 12px;
      width: 15px;
      height: 14px;
      border-radius: 100%;
      transform: translateY(-50%);

      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 14px;
        height: 14px;
        display: block;
        content: ' ';
        .closeicon(#ffffff);
      }
    }

    .personnelname {
      font-size: 1.1em;
    }

    .personnelrole {
      font-size: 0.7em;
    }
  }
  .personnelselected {
    margin-left: -40px;
  }
  color: @primaryTextColor;
}
</style>
