import VueJwtDecode from 'vue-jwt-decode';

import { DateFunctions } from '/src/mixins/datefunctions';
import { browserStorage } from '/src/mixins/browserStorage';

export const JwtFunctions = {
  decodeAccessToken(user = null) {
    if (!user) {
      user = browserStorage.currentUser;
    }

    return user && user.accessToken ? VueJwtDecode.decode(user.accessToken) : null;
  },

  hasAccessTokenExpired(user = null) {
    const jwt = JwtFunctions.decodeAccessToken(user);
    if (jwt) {
      // Check to see if the current users access token has expired
      const now = new Date();
      const jwtExpires = new Date(new Date(0).setUTCSeconds(jwt.exp));

      // console.info(`AQUA: hasAccessTokenExpired -> `, jwtExpires <= now, jwtExpires, now);
      return Boolean(jwtExpires <= now);
    }
    return true;
  },

  isAccessTokenAboutToExpire(user = null) {
    const jwt = JwtFunctions.decodeAccessToken(user);

    // Check to see if the current users access token is due to expire (exp minus 60 seconds)
    return !jwt || Boolean(DateFunctions.removeSeconds(new Date(0).setUTCSeconds(jwt.exp), 60) < new Date());
  },
};
