<template>
  <div else id="appWrapper" :class="[appCover ? 'noscroll' : '']">
    <div v-if="!isLoggedIn">
      <ContentLoading :loading="true" />
      <!-- Added to show loader on initial page load -->
    </div>
    <div v-else>
      <div id="appContentCover" @click="showContent()" :class="[appCover ? 'active' : '']" role="complementary"></div>

      <AppMenu @menuitemfired="showContent()" />

      <div id="appContent">
        <AppHeader />

        <main id="appPage" :class="[appCover ? 'menushown' : '']" role="main">
          <div>
            <!-- <ContentLoading /> -->

            <transition :name="transitionName" :mode="transitionMode" :enter-active-class="transitionEnterActiveClass">
              <router-view></router-view>
            </transition>
          </div>
        </main>

        <AppMessages />
        <LeaderboardBadge />
        <SystemMessages />

        <ChangePassword />
        <ChangePIN v-if="currentUser && !currentUser.ForcePasswordChange && currentUser.ForcePinChange" />

        <AddNote :addingnote="addNote" @emitAddNote="emitAddNote($event)" />

        <ToTop />

        <!-- iframe container used for report printing (added to negate the use of popup windows) -->
        <ContentLoading :loading="isPrinting" />
        <div id="reportPrintContainer"></div>
      </div>
    </div>
  </div>
</template>

<script>
const DEFAULT_TRANSITION = 'fade';
const DEFAULT_TRANSITION_MODE = 'out-in';

import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      transitionName: DEFAULT_TRANSITION,
      transitionMode: DEFAULT_TRANSITION_MODE,
      transitionEnterActiveClass: '',

      isPrinting: false, // used to display content loader
      pdfTestSize: 0,
    };
  },
  components: {
    AppHeader: () => import('/src/components/application/AppHeader'),
    AppMenu: () => import('/src/components/application/AppMenu'),
    ToTop: () => import('/src/components/application/ToTop'),

    AppMessages: () => import('/src/components/application/AppMessages'),
    LeaderboardBadge: () => import('/src/components/application/LeaderboardBadge'),
    SystemMessages: () => import('/src/components/application/SystemMessages'),
    AddNote: () => import('/src/components/application/AddNote'),
    ChangePassword: () => import('/src/components/user/ChangePassword'),
    ChangePIN: () => import('/src/components/user/ChangePIN'),
  },
  computed: {
    ...mapGetters({
      appMenuOpen: 'getAppMenuOpen',
      appLoading: 'getAppLoading',
      appCover: 'getAppCover',
    }),
    addNote: function () {
      return this.$store.state.appNotes.addNote;
    },
  },
  created() {
    this.GlobalEventBus.$on('printReport', this.handlePrintReport);
  },
  watch: {
    $route(to, from) {
      if (this.isLoggedIn) {
        this.transitionName = this.getNonEmptyString([to.meta.transitionName, from.meta.transitionName, DEFAULT_TRANSITION]);

        if (this.transitionName === 'slide') {
          const toDepth = to.path.split('/').length;
          const fromDepth = from.path.split('/').length;

          this.transitionName += toDepth < fromDepth ? '-right' : '-left';
        }

        this.transitionMode = this.getNonEmptyString([to.meta.transitionMode, from.meta.transitionMode, DEFAULT_TRANSITION_MODE]);
        this.transitionEnterActiveClass = '${transitionName}-enter-active';
      }

      // clear out the print data
      document.getElementById('reportPrintContainer')?.replaceChildren(null);
    },
  },
  methods: {
    ...mapActions(['updateAppCover', 'updateAppMenuOpen', 'updateAccountMenu', 'toggleAddNote']),

    showContent() {
      this.updateAppMenuOpen(false);
      this.updateAppCover(false);
      this.updateAccountMenu(false);
      this.toggleAddNote({ addNote: false });
    },

    handlePrintReport: function ($event) {
      const hasHTML = Boolean($event?.html?.length > 0);
      const hasURL = Boolean($event?.url?.length > 0);
      let dataSize = $event?.size ?? 0;

      if (hasHTML || hasURL) {
        this.isPrinting = true;

        const reportPrintContainer = document.getElementById('reportPrintContainer');
        const printIframe = document.createElement('iframe');
        reportPrintContainer.replaceChildren(printIframe);

        printIframe.classList.add('reportPrint');
        if ($event.orientationClass) {
          printIframe.classList.add($event.orientationClass);
        } else {
          printIframe.classList.add('portrait');
        }

        const postPrint = () => {
          this.isPrinting = false;
          // AW - 2024-04-08: Removed print data clean-up from post print due to defect #17950
          //                  It's done in the $route watcher above, so no need to do it here as well.
          // reportPrintContainer.replaceChildren(null);

          if ($event.callback) {
            $event.callback();
          }
        };

        // this.debugLog('AQUA: 🖨️ set printIframe.onload:', new Date().toISOString());
        printIframe.onload = (event) => {
          // this.debugLog('AQUA: 🖨️ fired printIframe.onload:', new Date().toISOString());

          if (hasHTML) {
            // Half a second timeout used here to give the rendering of the HTML time to adjust the header position
            setTimeout(() => {
              // The browsers print function blocks code from continuing.
              // So anything below this line will not get called until the print dialog has closed.
              printIframe.contentWindow.print();

              postPrint();
            }, 500);
          } else {
            // PDF printing will use the browsers embedded PDF viewer to fire the print command.
            // These tend to override the browsers print function and this has the downside of causing the
            // print events to not fire and therefore the print function will not block code from continuing.
            printIframe.contentWindow.print();

            // So to get around this issue we calculate a delay for our post print function using the size of the
            // PDF file being printed. We also restrict the delay to be a minium of 1 second and a maximum of 30 seconds.
            // This has been tested with varying sizes of PDF files, up to 200MB.
            const postPrintDelay = Math.min(30_000, !dataSize ? 1_000 : Math.max(1_000, Math.ceil(dataSize / 6 / 1000)));
            setTimeout(() => postPrint(), postPrintDelay);
            this.debugLog('AQUA: 🖨️ postPrintDelay (secs):', postPrintDelay / 1000);
          }
        };

        if (hasHTML) {
          printIframe.contentDocument.write($event.html);
          printIframe.contentDocument.close();
        } else {
          switch (this.pdfTestSize) {
            case 10:
              dataSize = 10_705_702;
              printIframe.src = '/10MB-TESTFILE.ORG.pdf';
              this.debugLog('AQUA: 🖨️ 10MB-TESTFILE');
              break;
            case 20:
              dataSize = 21_408_647;
              printIframe.src = '/20MB-TESTFILE.ORG.pdf';
              this.debugLog('AQUA: 🖨️ 20MB-TESTFILE');
              break;
            case 30:
              dataSize = 32_116_471;
              printIframe.src = '/30MB-TESTFILE.ORG.pdf';
              this.debugLog('AQUA: 🖨️ 30MB-TESTFILE');
              break;
            case 40:
              dataSize = 42_822_485;
              printIframe.src = '/40MB-TESTFILE.ORG.pdf';
              this.debugLog('AQUA: 🖨️ 40MB-TESTFILE');
              break;
            case 50:
              dataSize = 53_528_491;
              printIframe.src = '/50MB-TESTFILE.ORG.pdf';
              this.debugLog('AQUA: 🖨️ 50MB-TESTFILE');
              break;
            case 100:
              dataSize = 107_058_724;
              printIframe.src = '/100MB-TESTFILE.ORG.pdf';
              this.debugLog('AQUA: 🖨️ 100MB-TESTFILE');
              break;
            case 200:
              dataSize = 214_119_654;
              printIframe.src = '/200MB-TESTFILE.ORG.pdf';
              this.debugLog('AQUA: 🖨️ 200MB-TESTFILE');
              break;

            default:
              printIframe.src = $event.url;
              break;
          }
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
#appPage {
  @media screen and (min-width: 650px) {
    padding-left: 70px;
  }
}

#appWrapper {
  min-width: 100%;
  width: 100%;
}

#appContent {
  padding-top: 50px;
  position: relative;
  min-height: 100vh;
}
</style>
