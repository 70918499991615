// =================================================================================================================================================================== \\
// ==                                                                                                                                                               == \\
// == AQUA Timer: Assistant functions for periodically refreshing data                                                                                              == \\
// ==                                                                                                                                                               == \\
// =================================================================================================================================================================== \\
// ==                                                                                                                                                               == \\
// == Public Properties:                                                                                                                                            == \\
// == ------------------------------------------------------------------------------------------------------------------------------------------------------------- == \\
// ==                                                                                                                                                               == \\
// ==   CancelMode        An Enum that should be used to set options.cancel_mode                                                                                    == \\
// ==   DefaultOptions    An Object that can be used as a base for defining timer options                                                                           == \\
// ==                                                                                                                                                               == \\
// =================================================================================================================================================================== \\
// ==                                                                                                                                                               == \\
// == Public Functions:                                                                                                                                             == \\
// == ------------------------------------------------------------------------------------------------------------------------------------------------------------- == \\
// ==                                                                                                                                                               == \\
// ==   sleep(interval)                   ->  Sleeps for a number of milliseconds                                                                                   == \\
// ==                                                                                                                                                               == \\
// ==     interval      <Number>,           [Optional] Default = 1000  ->  The number of milliseconds to sleep for                                                  == \\
// ==                                                                                                                                                               == \\
// ==     Returns:      <Promise>         ->  The promise will resolve once the interval has elapsed                                                                == \\
// ==                                                                                                                                                               == \\
// == ------------------------------------------------------------------------------------------------------------------------------------------------------------- == \\
// ==                                                                                                                                                               == \\
// ==   startTimer(callback, options)     ->  Schedules repeated execution of callback every <options.delay> milliseconds                                           == \\
// ==                                                                                                                                                               == \\
// ==     callback      <Function>        ->  The function to call when the timer interval is triggered                                                             == \\
// ==     options       <Object>          ->  Timer configuration options:                                                                                          == \\
// ==       {                                                                                                                                                       == \\
// ==         uid:              <String>,   *REQUIRED*                          ->  A unique identified for the timer                                               == \\
// ==         route_name:       <String>,   [Optional] Default = current route  ->  The name of the originating vue route                                           == \\
// ==         delay:            <Number>,   [Optional] Default = 1 minute       ->  The number of milliseconds to wait before calling the callback                  == \\
// ==         cancel_mode:      <Number>,   [Optional] Default = RouteChange    ->  How automatic cancelation should be handled                                     == \\
// ==         cancel_on_error:  <Boolean>,  [Optional] Default = false          ->  Whether to cancel the timer when an error occurs                                == \\
// ==         repetitions:      <Number>,   [Optional] Default = null           ->  The number of times to execute callback before the timer completes              == \\
// ==       }                                                                                                                                                       == \\
// ==                                                                                                                                                               == \\
// ==     Returns:      <Promise>         ->  The promise will resolve with the options object containing all values after defaults are injected (if required)      == \\
// ==                                                                                                                                                               == \\
// == ------------------------------------------------------------------------------------------------------------------------------------------------------------- == \\
// ==                                                                                                                                                               == \\
// ==   cancelTimer({ uid, route_name })  ->  Cancels a single active timer created by startTimer()                                                                 == \\
// ==                                                                                                                                                               == \\
// ==     {                                                                                                                                                         == \\
// ==       uid         <String>,         ->  The unique identified passed to startTimer()                                                                          == \\
// ==       route_name  <String>,         ->  The originating vue route name passed to startTimer()                                                                 == \\
// ==     }                                                                                                                                                         == \\
// ==                                                                                                                                                               == \\
// ==     Returns:      <Promise>                                                                                                                                   == \\
// ==                                                                                                                                                               == \\
// == ------------------------------------------------------------------------------------------------------------------------------------------------------------- == \\
// ==                                                                                                                                                               == \\
// ==   cancelAllTimers()                 ->  Cancels all active timers created by startTimer()                                                                     == \\
// ==                                                                                                                                                               == \\
// ==     Returns:      <Promise>                                                                                                                                   == \\
// ==                                                                                                                                                               == \\
// == ------------------------------------------------------------------------------------------------------------------------------------------------------------- == \\
// ==                                                                                                                                                               == \\
// ==   cancelRouteTimers(route_name)     ->  Cancels all active timers with the same route name, created by startTimer()                                           == \\
// ==                                                                                                                                                               == \\
// ==     route_name    <String>          ->  The originating vue route name passed to startTimer()                                                                 == \\
// ==                                                                                                                                                               == \\
// ==     Returns:      <Promise>                                                                                                                                   == \\
// ==                                                                                                                                                               == \\
// == ------------------------------------------------------------------------------------------------------------------------------------------------------------- == \\
// ==                                                                                                                                                               == \\
// ==   cancelTimersOnError([{ [uid], [route_name] }])  ->  Cancels all active timers created by startTimer() with cancel_on_error option                           == \\
// ==                                                   ->  Can be called with [Optional] parameter containing [Optional] identifiers                               == \\
// ==     [{                                                                                                                                                        == \\
// ==       uid         <String>,   [Optional]          ->  The unique identified passed to startTimer()                                                            == \\
// ==       route_name  <String>,   [Optional]          ->  The originating vue route name passed to startTimer()                                                   == \\
// ==     }]                                                                                                                                                        == \\
// ==                                                                                                                                                               == \\
// ==     Returns:      <Promise>                                                                                                                                   == \\
// ==                                                                                                                                                               == \\
// =================================================================================================================================================================== \\
// ==                                                                                                                                                               == \\
// == CancelMode:             Will execute the callback function...                                                                                                 == \\
// ==   Never:       -1,  ->  until user is logged out                                                                                                              == \\
// ==   RouteChange:  0,  ->  until the route changes from the designated route_name identifier                                                                     == \\
// ==   Repetition:   1,  ->  a set number of times according to the repetitions value                                                                              == \\
// ==                                                                                                                                                               == \\
// =================================================================================================================================================================== \\
// ==                                                                                                                                                               == \\
// == INTERNAL DATA:                                                                                                                                                == \\
// ==                                                                                                                                                               == \\
// ==   activeTimers: [{                                                                                                                                            == \\
// ==     uid:              <String>,                                                                                                                               == \\
// ==     route_name:       <String>,                           Default = $router.currentRoute.name                                                                 == \\
// ==     delay:            <Number>,                           Default = 1 minute                                                                                  == \\
// ==     cancel_mode:      <Number>,                           Default = CancelMode.RouteChange                                                                    == \\
// ==     cancel_on_error:  <Boolean>,                          Default = false                                                                                     == \\
// ==     repetitions:      <Number>,                           Default = 0                                                                                         == \\
// ==     timeout:          <Timeout> | <String> | <Number>,                                                                                                        == \\
// ==   }]                                                                                                                                                          == \\
// ==                                                                                                                                                               == \\
// =================================================================================================================================================================== \\

const DebugLogging = false;
const DebugShowActiveEvery = 5; // minutes

const debugLog = (...args) => {
  if (DebugLogging) console.info(...args);
};

let activeTimers = [];
let activeWorkers = [];

export const CancelMode = {
  Never: -1,
  RouteChange: 0,
  Repetition: 1,
};

const DefaultOptions = {
  delay: 60 * 1000, // Default = 1 minute
  uid: null,
  route_name: null,
  cancel_mode: CancelMode.RouteChange,
  cancel_on_error: false,
  repetitions: 0,
  isWorker: false,
};

export const AQUA_Timer = {
  // ====================================================================== \\
  // == Public function pointers                                         == \\
  // ====================================================================== \\
  isActive: _isActive,

  sleep: _sleep,

  startTimer: _startTimer,

  cancelTimer: _cancelTimer,
  cancelAllTimers: _cancelAllTimers,
  cancelRouteTimers: _cancelRouteTimers,
  cancelTimersOnError: _cancelTimersOnError,

  startWorker: _startWorker,

  cancelWorker: _cancelWorker,
  cancelAllWorkers: _cancelAllWorkers,
  cancelRouteWorkers: _cancelRouteWorkers,
  cancelWorkersOnError: _cancelWorkersOnError,

  cancelAll: _cancelAll,

  // ====================================================================== \\
  // == mixin initalisation                                              == \\
  // ====================================================================== \\
  install(Vue, options) {
    if (AQUA_Timer.install.isInitialized) {
      return; // previously initialized
    }

    debugLog('AQUA: ⌛ AQUA_Timer -> initializing');

    // ====================================================================== \\
    // == Initialize the AQUA Timer assistant                              == \\
    // ====================================================================== \\
    // !!  This must be the last thing done by the install(Vue) function   !! \\
    // ====================================================================== \\
    // vue_prototype = Vue.prototype;
    addProperties();
    addAccessorProperties(options.accessors);

    AQUA_Timer.install.isInitialized = true;

    if (DebugLogging) {
      _startTimer(
        () => {
          debugLog('AQUA: ⌛ AQUA_Timer -> activeTimers:', activeTimers);
          debugLog('AQUA: ⌛ AQUA_Timer -> activeWorkers:', activeWorkers);
        },
        { ...DefaultOptions, uid: 'debug_active_timers', delay: DebugShowActiveEvery * 60 * 1000 }
      );
    }

    Object.freeze(AQUA_Timer);
  },
};

// ====================================================================== \\
// == Public functions                                                 == \\
// ====================================================================== \\
// == Timer functions                                                  == \\
// ====================================================================== \\
function _sleep(interval = 1000){
  return new Promise((resolve) => setTimeout(() => resolve(), interval));
}

function _startTimer(callback, options = {}) {
  return new Promise((resolve, reject) => {
    if (isValidRequest(reject, options, true)) {
      // Initialize a new timer and activate it
      let tOptions = { ...DefaultOptions, ...options };

      if (!isDebugIgnored(tOptions)) {
        debugLog(
          `AQUA: ⌛ AQUA_Timer -> START timer: { uid: '${tOptions.uid}', route_name: '${tOptions.route_name}', delay: ${
            tOptions.delay / 1000
          } seconds }`
        );
      }

      tOptions.timeout = setInterval(() => {
        switch (tOptions.cancel_mode) {
          case CancelMode.Never:
          case CancelMode.RouteChange:
            executeCallback(callback, tOptions);
            break;
          case CancelMode.Repetition:
            executeRepetition(callback, tOptions);
            break;
        }
      }, tOptions.delay);

      activeTimers.push(tOptions);

      resolve(tOptions);
    }
  });
}

function _cancelTimer({ uid, route_name }) {
  return _cancel(uid, route_name, false);
}
function _cancelAllTimers() {
  return _cancelAllOfType(false);
}
function _cancelRouteTimers({ route_name }) {
  return _cancelRoute(route_name, false);
}
function _cancelTimersOnError({ uid, route_name }) {
  return _cancelOnErrors(uid, route_name, false);
}

// ====================================================================== \\
// == Timer functions                                                  == \\
// ====================================================================== \\
function _startWorker(callback, options = {}) {
  return new Promise((resolve, reject) => {
    if (isValidRequest(reject, options)) {
      // Initialize a new worker and activate it
      let wOptions = { ...DefaultOptions, ...options, isWorker: true };

      if (!isDebugIgnored(wOptions)) {
        debugLog(
          `AQUA: ⌛ AQUA_Timer -> START worker: { uid: '${wOptions.uid}', route_name: '${
            wOptions.route_name
          }', delay: ${wOptions.delay / 1000} seconds }`
        );
      }

      const workerFunction = `setInterval(() => { postMessage(null) }, ${wOptions.delay});`;
      wOptions.worker = new Worker(URL.createObjectURL(new Blob([workerFunction])));

      wOptions.worker.onmessage = () => {
        switch (wOptions.cancel_mode) {
          case CancelMode.Never:
          case CancelMode.RouteChange:
            executeCallback(callback, wOptions);
            break;
          case CancelMode.Repetition:
            executeRepetition(callback, wOptions);
            break;
        }
      };

      activeWorkers.push(wOptions);

      resolve(wOptions);
    }
  });
}

function _cancelWorker({ uid, route_name }) {
  return _cancel(uid, route_name, true);
}
function _cancelAllWorkers() {
  return _cancelAllOfType(true);
}
function _cancelRouteWorkers({ route_name }) {
  return _cancelRoute(route_name, true);
}
function _cancelWorkersOnError({ uid, route_name }) {
  return _cancelOnErrors(uid, route_name, true);
}

// ====================================================================== \\
// == Private functions                                                == \\
// ====================================================================== \\
function isNullOrEmpty(value) {
  return !value || value === '';
}

function isDebugIgnored(options) {
  return options.uid.startsWith('loader');
}

function debugIdentifier(isWorker = false) {
  return isWorker ? 'worker' : 'timer';
}

function hasUID(reject, options, throwError = false) {
  if (isNullOrEmpty(options?.uid)) {
    const error = new Error('Missing required unique identifier: uid');
    reject(error);
    if (throwError) {
      throw error;
    }
    return false;
  }
  return true;
}

function isValidRequest(reject, options) {
  options.route_name = checkRouteName(options.route_name);

  if (hasUID(reject, options) && _isActive(options)) {
    const error = `There is already a ${debugIdentifier(options.isWorker)} running: { uid: '${
      options.uid
    }', route_name: '${options.route_name}' }`;
    debugLog(`AQUA: ⌛ AQUA_Timer -> START ERROR: ${error}`);

    reject(new Error(error));
    return false;
  }

  return true;
}

function checkRouteName(route_name) {
  // Ensure we have a route name
  return isNullOrEmpty(route_name)
    ? AQUA_Timer.$router.currentRoute.name
    : route_name;
}

function _isActive(options) {
  return getActive(options) != null;
}
function getActive(options) {
  options.route_name = checkRouteName(options.route_name);
  return (
    (!options.isWorker ? activeTimers : activeWorkers)?.find(
      (t) => t.uid == options.uid && t.route_name == options.route_name
    ) || null
  );
}
function getActiveList(options) {
  options.route_name = checkRouteName(options.route_name);
  return (
    (!options.isWorker ? activeTimers : activeWorkers)?.filter(
      (t) =>
        ((!options.uid && !options.route_name) || // No identifier or route_name supplied
          ((!options.uid || t.uid == options.uid) && // No uid supplied or uid matches
            (!options.route_name || t.route_name == options.route_name))) && // No route_name supplied or route_name matches
        (!options.cancel_on_error || t.cancel_on_error) // Not looking for cancel_on_error or cancel_on_error == true
    ) || []
  );
}

function executeCallback(callback, options) {
  options.route_name = checkRouteName(options.route_name);
  if (!isDebugIgnored(options) && options.delay > 1 * 1000) {
    debugLog(
      `AQUA: ⌛ AQUA_Timer -> EXECUTE ${debugIdentifier(options.isWorker)}: { uid: '${options.uid}', route_name: '${
        options.route_name
      }' }`
    );
  }
  if (callback) callback();
}

function executeRepetition(callback, options) {
  options.route_name = checkRouteName(options.route_name);
  if (options.repetitions > 0) {
    debugLog(
      `AQUA: ⌛ AQUA_Timer -> EXECUTE ${debugIdentifier(options.isWorker)} repetition (${
        options.repetitions
      }): { uid: '${options.uid}', route_name: '${options.route_name}' }`
    );

    options.repetitions--;
    if (callback) callback();
  } else {
    debugLog(
      `AQUA: ⌛ AQUA_Timer -> ${debugIdentifier(options.isWorker)} repetitions complete: { uid: '${
        options.uid
      }', route_name: '${options.route_name}' }`
    );

    if (options.isWorker) {
      options.worker.terminate();
      activeWorkers.splice(activeWorkers.indexOf(options), 1);
    } else {
      clearInterval(options.timeout);
      activeTimers.splice(activeTimers.indexOf(options), 1);
    }
  }
}

function _cancel(uid, route_name, cancelWorker = false) {
  return new Promise((resolve, reject) => {
    route_name = checkRouteName(route_name);

    const toCancel = getActive({ uid, route_name, isWorker: cancelWorker });
    if (toCancel) {
      // Cancel the timer
      if (!isDebugIgnored(toCancel)) {
        debugLog(
          `AQUA: ⌛ AQUA_Timer -> CANCEL ${debugIdentifier(cancelWorker)}: { uid: '${toCancel.uid}', route_name: '${
            toCancel.route_name
          }' }`
        );
      }

      if (cancelWorker) {
        toCancel.worker.terminate();
        activeWorkers.splice(activeWorkers.indexOf(toCancel), 1);
      } else {
        clearInterval(toCancel.timeout);
        activeTimers.splice(activeTimers.indexOf(toCancel), 1);
      }

      resolve();
    } else {
      if (!isDebugIgnored({ uid })) {
        debugLog(
          `AQUA: ⌛ AQUA_Timer -> Unable to find an active ${debugIdentifier(
            cancelWorker
          )}: { uid: '${uid}', route_name: '${route_name}' }`
        );
      }
      resolve(
        new Error(
          `Unable to find an active ${debugIdentifier(cancelWorker)}: { uid: '${uid}', route_name: '${route_name}' }`
        )
      );
    }
  });
}
function _cancelAll() {
  _cancelAllOfType(false);
  _cancelAllOfType(true);
}
function _cancelAllOfType(cancelWorkers = false) {
  return new Promise((resolve) => {
    const activeArray = getActiveList({ isWorker: cancelWorkers });
    if (activeArray?.length) {
      // Cancel all active
      activeArray.forEach((toCancel) => {
        if (!isDebugIgnored(toCancel)) {
          debugLog(
            `AQUA: ⌛ AQUA_Timer -> CANCEL ${debugIdentifier(cancelWorkers)}: { uid: '${toCancel.uid}', route_name: '${
              toCancel.route_name
            }' }`
          );
        }

        if (cancelWorkers) {
          toCancel.worker.terminate();
        } else {
          clearInterval(toCancel.timeout);
        }
      });

      // Reset active array
      if (cancelWorkers) {
        activeWorkers = [];
      } else {
        activeTimers = [];
      }

      resolve();
    }
  });
}
function _cancelRoute(route_name, cancelWorkers = false) {
  return new Promise((resolve) => {
    route_name = checkRouteName(route_name);

    const activeArray = getActiveList({ route_name, isWorker: cancelWorkers });
    if (activeArray?.length) {
      // Cancel the active route timers
      activeArray.forEach((toCancel) => {
        if (!isDebugIgnored(toCancel)) {
          debugLog(
            `AQUA: ⌛ AQUA_Timer -> CANCEL route ${debugIdentifier(cancelWorkers)}: { uid: '${
              toCancel.uid
            }', route_name: '${toCancel.route_name}' }`
          );
        }

        if (cancelWorkers) {
          toCancel.worker.terminate();
          activeWorkers.splice(activeWorkers.indexOf(toCancel), 1);
        } else {
          clearInterval(toCancel.timeout);
          activeTimers.splice(activeTimers.indexOf(toCancel), 1);
        }
      });

      resolve();
    } else {
      resolve(
        new Error(`Unable to find any active route ${debugIdentifier(cancelWorkers)}s: { route_name: '${route_name}' }`)
      );
    }
  });
}
function _cancelOnErrors(uid, route_name, cancelWorkers = false) {
  return new Promise((resolve) => {
    const activeArray = getActiveList({ uid, route_name, cancel_on_error: true, isWorker: cancelWorkers });
    if (activeArray?.length) {
      // Cancel the active route timers
      activeArray.forEach((toCancel) => {
        if (!isDebugIgnored(toCancel)) {
          debugLog(
            `AQUA: ⌛ AQUA_Timer -> CANCEL ${debugIdentifier(cancelWorkers)} due to error: { uid: '${
              toCancel.uid
            }', route_name: '${toCancel.route_name}' }`
          );
        }

        if (cancelWorkers) {
          toCancel.worker.terminate();
          activeWorkers.splice(activeWorkers.indexOf(toCancel), 1);
        } else {
          clearInterval(toCancel.timeout);
          activeTimers.splice(activeTimers.indexOf(toCancel), 1);
        }
      });

      resolve();
    } else {
      resolve(
        new Error(
          `Unable to find any active 'cancel on error' ${debugIdentifier(
            cancelWorkers
          )}s: { uid: '${uid}', route_name: '${route_name}' }`
        )
      );
    }
  });
}

// ====================================================================== \\
// == Property insertion function                                      == \\
// ====================================================================== \\
function addProperties() {
  Object.defineProperty(AQUA_Timer, 'CancelMode', {
    get: () => {
      return CancelMode;
    },
  });
  Object.defineProperty(AQUA_Timer, 'DefaultOptions', {
    get: () => {
      return { ...DefaultOptions };
    },
  });

  Object.defineProperty(AQUA_Timer, 'activeTimers', {
    get: () => {
      return activeTimers;
    },
  });
}

// ====================================================================== \\
// == Application wide property insertion functions                    == \\
// ====================================================================== \\
function addAccessorProperties(accessors) {
  const router = accessors.find((accessor) => accessor.key == 'VueRouter')?.value;
  if (router) {
    Object.defineProperty(AQUA_Timer, 'app', {
      get: () => {
        return router.app;
      },
    });
    Object.defineProperty(AQUA_Timer, '$router', {
      get: () => {
        return router;
      },
    });
  }

  addAppWideAccessorProperties(accessors);
}
function addAppWideAccessorProperties(accessors) {
  accessors.forEach((accessor) => {
    Object.defineProperty(accessor.value, '$AQUA_Timer', {
      get: () => {
        return AQUA_Timer;
      },
    });
  });
}
