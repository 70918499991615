const GenericRoute = () => import('/src/components/application/_GenericRoute');

const EpisodeView = () => import('/src/components/route/episode/EpisodeView');
const EpisodeAction = () => import('/src/components/route/episode/action/EpisodeAction');
const EpisodeFunctions = () => import('/src/components/route/episode/function/EpisodeFunctions');

const FormEntry = () => import('/src/components/forms/FormEntry');

const PatientInTheatre = () => import('/src/components/route/episode/views/PatientInTheatre');

const Booking = () => import('/src/components/booking/Booking');

export default [
  {
    path: '/episode/:operationrecordid?/:fireOptionID?',
    component: GenericRoute,
    meta: {
      title: 'Episode',
    },
    children: [
      {
        path: 'function/observations/:optionid?/:patientactualid?',
        name: 'episode_function_observation',
        component: EpisodeFunctions,
        meta: {
          title: 'Episode Function',
          // AW 2022-06-21 - Access is checked via the episode functions page and unnecessary here
          // functionCode: 'PATIENT',
          isEpisodeFunction: true,
          transitionName: 'slide',
          areaDisabled: true,
          backRoutes: ['episode_view', 'patient_view', 'location_view', 'session_view'],
        },
      },
      {
        path: 'function/:optioncode/:optionid?/:patientactualid?',
        name: 'episode_function',
        component: EpisodeFunctions,
        meta: {
          title: 'Episode Function',
          // AW 2022-06-21 - Access is checked via the episode functions page and unnecessary here
          // functionCode: 'PATIENT',
          isEpisodeFunction: true,
          transitionName: 'slide',
          areaDisabled: true,
          backRoutes: ['episode_view', 'patient_view', 'location_view', 'session_view'],
        },
      },
      {
        path: 'action/:actiontype/:optionid?',
        component: GenericRoute,
        meta: {
          title: 'Episode Action',
        },
        children: [
          {
            path: 'preassessment/:patientpreassessmentid',
            name: 'episode_action_preassessment',
            component: EpisodeAction,
            meta: {
              title: 'Episode Action',
              functionCode: 'PREASSESSMENTOUTCOME',
              transitionName: 'slide',
              areaDisabled: true,
              backRoutes: ['episode_view', 'patient_view', 'patient_preassessment_view'],
            },
          },
          {
            path: '',
            name: 'episode_action',
            component: EpisodeAction,
            meta: {
              title: 'Episode Action',
              transitionName: 'slide',
              areaDisabled: true,
              backRoutes: ['episode_view', 'patient_view', 'location_view', 'session_view'],
            },
            beforeEnter: (to, from, next) => {
              to.meta.functionCode = to.params.actiontype
                ? to.params.actiontype.toUpperCase() == 'SENDFORUNDO'
                  ? 'SENTFOR'
                  : to.params.actiontype.toUpperCase() == 'BACKTOTHEATREUNDO'
                  ? 'BACKTOTHEATRE'
                  : to.params.actiontype.toUpperCase()
                : 'PATIENT';
              next();
            },
          },
        ],
      },
      {
        path: 'form/:formid',
        name: 'episode_form',
        component: FormEntry,
        meta: {
          title: 'Form Entry',
          transitionName: 'slide',
          areaDisabled: true,
          backRoutes: ['episode_view'],
        },
      },
      {
        path: 'theatre',
        name: 'patient_in_theatre',
        component: PatientInTheatre,
        meta: {
          title: 'In Theatre',
          layout: 'fullscreen',
          transitionName: 'slide',
          functionCode: 'PATIENT',
        },
      },
      {
        path: 'booking',
        component: GenericRoute,
        meta: {
          title: 'Patient Booking',
        },
        children: [
          {
            path: 'update',
            name: 'episode_booking_update',
            component: Booking,
            meta: {
              title: 'Patient Booking Update',
              functionCode: ['BOOKAMEND','BOOKEMERAMEND'],
              areaDisabled: true,
              backRoutes: [
                'session_view',
                'episode_view',
                'patient_view',
                'patient_preassessment_view',
                'waiting_list_pas_episode',
                'working_list_view', 
              ],
            },
          },
          {
            path: ':readonly?',
            name: 'episode_booking_view',
            component: Booking,
            meta: {
              title: 'Patient Booking',
              functionCode: ['VIEWBOOK','VIEWEMERBOOK'],
              areaDisabled: true,
              backRoutes: [
                'session_view',
                'episode_view',
                'patient_view',
                'patient_preassessment_view',
                'waiting_list_pas_episode',
                'working_list_view',
              ],
            },
          },
        ],
      },
      {
        path: '',
        name: 'episode_view',
        component: EpisodeView,
        meta: {
          title: 'Episode View',
          functionCode: ['PATIENT', 'LIVELOCATIONS', 'OPLIST'],
          transitionName: 'slide',
          areaDisabled: true,
          backRoutes: [
            'session_list',
            'session_view',
            'live_patients',
            'location_view',
            'waiting_list_pas_episode',
            'working_list_view',
            'working_list_dashboard',
            'whiteboard',
            'whiteboard_daily_view'
          ],
        },
      },
    ],
  },
];
