const {
  crypto_secretbox_MACBYTES,
  crypto_secretbox_easy,
  crypto_secretbox_open_easy,
  crypto_generichash_batch,
  crypto_kdf_CONTEXTBYTES,
  crypto_kdf_KEYBYTES,
  crypto_kdf_derive_from_key,
  crypto_sign_PUBLICKEYBYTES,
  crypto_sign_SECRETKEYBYTES,
  crypto_sign_SEEDBYTES,
  crypto_sign_seed_keypair,
  randombytes_buf,
} = require('sodium-universal');

const base58 = require('bs58');

function blake2b(message, key, size) {
  if (!size) {
    size = 32;
  }

  const buffers = Array.isArray(message) ? message : [Buffer.from(message)];
  const hash = Buffer.allocUnsafe(size);

  crypto_generichash_batch(
    hash,
    buffers.map((b) => Buffer.from(b)),
    key || null
  );
  return hash;
}

function box(key, nonce, buffer) {
  const length = crypto_secretbox_MACBYTES + buffer.length;
  const ciphertext = Buffer.alloc(length);
  crypto_secretbox_easy(ciphertext, buffer, nonce, key);
  return ciphertext;
}

function compress(buffer, encoding) {
  return base58.encode(Buffer.from(buffer, encoding || 'hex'));
}

function inflate(string, encoding) {
  return Buffer.from(base58.decode(string), encoding || 'hex');
}

function kdf(name, masterKey) {
  const key = Buffer.allocUnsafe(crypto_kdf_KEYBYTES);
  const ctx = Buffer.alloc(crypto_kdf_CONTEXTBYTES).fill(name);
  const len = name.length;
  crypto_kdf_derive_from_key(key, len, ctx, masterKey);
  return key;
}

function keygen(seed) {
  if (seed && ('string' === typeof seed || Buffer.isBuffer(seed))) {
    seed = Buffer.allocUnsafe(crypto_sign_SEEDBYTES).fill(seed);
  } else {
    seed = Buffer.allocUnsafe(crypto_sign_SEEDBYTES);
    randombytes_buf(seed, crypto_sign_SEEDBYTES);
  }

  const publicKey = Buffer.allocUnsafe(crypto_sign_PUBLICKEYBYTES);
  const secretKey = Buffer.allocUnsafe(crypto_sign_SECRETKEYBYTES);

  crypto_sign_seed_keypair(publicKey, secretKey, seed);

  return secretKey.slice(0, 32);
}

function unbox(key, nonce, ciphertext) {
  const buffer = Buffer.alloc(ciphertext.length - crypto_secretbox_MACBYTES);
  if (crypto_secretbox_open_easy(buffer, ciphertext, nonce, key)) {
    return buffer;
  }

  throw new Error('Failed to decrypt ciphertext.');
}

module.exports = {
  blake2b,
  box,
  compress,
  inflate,
  kdf,
  keygen,
  unbox,
};
