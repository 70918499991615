const GenericRoute = () => import('/src/components/application/_GenericRoute');

const WorkList = () => import('/src/components/userlists/UserListSelection');
const WorkListView = () => import('/src/components/userlists/UserListView');

export default [
  {
    path: '/working/lists',
    component: GenericRoute,
    meta: {
      title: 'Working List',
    },
    children: [
      {
        path: ':listid',
        component: GenericRoute,
        meta: {
          title: 'Working List',
        },
        children: [
          {
            path: 'dashboard/:dashboarditemid/:dashboardtabitemid/:element/:filters',
            name: 'working_list_dashboard',
            component: WorkListView,
            meta: {
              title: 'Working List View',
              functionCode: 'SMARTLIST',
              backRoutes: ['dashboard'],
            },
          },
          {
            path: '',
            name: 'working_list_view',
            component: WorkListView,
            meta: {
              title: 'Working List View',
              functionCode: 'SMARTLIST',
              backRoutes: ['working_lists'],
            },
          },
        ],
      },
      {
        path: '',
        name: 'working_lists',
        component: WorkList,
        meta: {
          title: 'Working List Selection',
          tags: ['Landing Page'],
          functionCode: 'SMARTLIST',
          hideBackButton: true,
          isLandingRoute: true,
        },
      },
    ],
  },
];
