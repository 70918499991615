// Personnel
const Personnel = () => import('/src/components/admin/personnel/Personnel');
const PersonnelList = () => import('/src/components/admin/personnel/views/PersonnelList');
const PersonnelForm = () => import('/src/components/admin/personnel/forms/PersonnelForm');

// Personnel Designation
const PersonnelDesignation = () => import('/src/components/admin/personnel/PersonnelDesignation');
const PersonnelDesignationList = () => import('/src/components/admin/personnel/views/PersonnelDesignationList');
const PersonnelDesignationForm = () => import('/src/components/admin/personnel/forms/PersonnelDesignationForm');

// Personnel Grade
const PersonnelGrade = () => import('/src/components/admin/personnel/PersonnelGrade');
const PersonnelGradeList = () => import('/src/components/admin/personnel/views/PersonnelGradeList');
const PersonnelGradeForm = () => import('/src/components/admin/personnel/forms/PersonnelGradeForm');

// Personnel Band
const PersonnelBand = () => import('/src/components/admin/personnel/PersonnelBand');
const PersonnelBandList = () => import('/src/components/admin/personnel/views/PersonnelBandList');
const PersonnelBandForm = () => import('/src/components/admin/personnel/forms/PersonnelBandForm');

// Personnel Role
const PersonnelRole = () => import('/src/components/admin/personnel/PersonnelRole');
const PersonnelRoleList = () => import('/src/components/admin/personnel/views/PersonnelRoleList');
const PersonnelRoleForm = () => import('/src/components/admin/personnel/forms/PersonnelRoleForm');

// Personnel Template
const PersonnelTemplate = () => import('/src/components/admin/personnel/PersonnelTemplate');
const PersonnelTemplateList = () => import('/src/components/admin/personnel/views/PersonnelTemplateList');
const PersonnelTemplateForm = () => import('/src/components/admin/personnel/forms/PersonnelTemplateForm');

export default [
  // ======================================== \\
  // Personnel
  // ======================================== \\
  {
    path: 'personnel',
    component: Personnel,
    meta: {
      title: 'Personnel Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':personnelid',
        name: 'admin_personnel_detail',
        component: PersonnelForm,
        meta: {
          title: 'Personnel Maintenance',
          tags: ['Administration'],
          functionCode: 'PERSONNELADMIN',
          backRoutes: ['admin_personnel'],
        },
      },
      {
        path: '',
        name: 'admin_personnel',
        component: PersonnelList,
        meta: {
          title: 'Personnel Maintenance',
          tags: ['Administration'],
          functionCode: 'PERSONNELADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // Personnel Designation
  // ======================================== \\
  {
    path: 'personnel/designation',
    component: PersonnelDesignation,
    meta: {
      title: 'Personnel Designation Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':personneldesignationid',
        name: 'admin_personneldesignations_detail',
        component: PersonnelDesignationForm,
        meta: {
          title: 'Personnel Designation Maintenance',
          tags: ['Administration'],
          functionCode: 'PERSONNELADMIN',
          backRoutes: ['admin_personneldesignations'],
        },
      },
      {
        path: '',
        name: 'admin_personneldesignations',
        component: PersonnelDesignationList,
        meta: {
          title: 'Personnel Designation Maintenance',
          tags: ['Administration'],
          functionCode: 'PERSONNELADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // Personnel Grade
  // ======================================== \\
  {
    path: 'personnel/grade',
    component: PersonnelGrade,
    meta: {
      title: 'Personnel Grade Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':personnelgradeid',
        name: 'admin_personnelgrades_detail',
        component: PersonnelGradeForm,
        meta: {
          title: 'Personnel Grade Maintenance',
          tags: ['Administration'],
          functionCode: 'PERSONNELADMIN',
          backRoutes: ['admin_personnelgrades'],
        },
      },
      {
        path: '',
        name: 'admin_personnelgrades',
        component: PersonnelGradeList,
        meta: {
          title: 'Personnel Grade Maintenance',
          tags: ['Administration'],
          functionCode: 'PERSONNELADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // Personnel Band
  // ======================================== \\
  {
    path: 'personnel/band',
    component: PersonnelBand,
    meta: {
      title: 'Personnel Band Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':personnelbandid',
        name: 'admin_personnelbands_detail',
        component: PersonnelBandForm,
        meta: {
          title: 'Personnel Band Maintenance',
          tags: ['Administration'],
          functionCode: 'PERSONNELADMIN',
          backRoutes: ['admin_personnelbands'],
        },
      },
      {
        path: '',
        name: 'admin_personnelbands',
        component: PersonnelBandList,
        meta: {
          title: 'Personnel Band Maintenance',
          tags: ['Administration'],
          functionCode: 'PERSONNELADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // Personnel Role
  // ======================================== \\
  {
    path: 'personnel/role',
    component: PersonnelRole,
    meta: {
      title: 'Personnel Role Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':personnelroleid',
        name: 'admin_personnelroles_detail',
        component: PersonnelRoleForm,
        meta: {
          title: 'Personnel Role Maintenance',
          tags: ['Administration'],
          functionCode: 'PERSONNELADMIN',
          backRoutes: ['admin_personnelroles'],
        },
      },
      {
        path: '',
        name: 'admin_personnelroles',
        component: PersonnelRoleList,
        meta: {
          title: 'Personnel Role Maintenance',
          tags: ['Administration'],
          functionCode: 'PERSONNELADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // Personnel Template
  // ======================================== \\
  {
    path: 'personnel/template',
    component: PersonnelTemplate,
    meta: {
      title: 'Personnel Template Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':personneltemplateid',
        name: 'admin_personneltemplates_detail',
        component: PersonnelTemplateForm,
        meta: {
          title: 'Personnel Template Maintenance',
          tags: ['Administration'],
          functionCode: 'PERSONNELADMIN',
          backRoutes: ['admin_personneltemplates'],
        },
      },
      {
        path: '',
        name: 'admin_personneltemplates',
        component: PersonnelTemplateList,
        meta: {
          title: 'Personnel Template Maintenance',
          tags: ['Administration'],
          functionCode: 'PERSONNELADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },
];
