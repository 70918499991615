const GenericRoute = () => import('/src/components/application/_GenericRoute');

// Time Definitions
const TimeDefinition = () => import('/src/components/admin/time/TimeDefinition');
const TimeDefinitionList = () => import('/src/components/admin/time/forms/TimeDefinitionList');
const TimeDefinitionForm = () => import('/src/components/admin/time/forms/TimeDefinitionForm');

// Pathway Allocations
const PathwayAllocation = () => import('/src/components/admin/pathwayAlloc/PathwayAllocation');
const PathwayAllocationForm = () => import('/src/components/admin/pathwayAlloc/forms/PathwayAllocationForm');
const PathwayAllocationConfig = () => import('/src/components/admin/pathwayAlloc/forms/PathwayAllocationConfig');

// Pathway
const Pathway = () => import('/src/components/admin/pathway/Pathway');
const PathwayList = () => import('/src/components/admin/pathway/views/PathwayList');
const PathwayMenu = () => import('/src/components/admin/pathway/views/PathwayMenu');
const PathwayView = () => import('/src/components/admin/pathway/views/PathwayView');
const PathwayCreate = () => import('/src/components/admin/pathway/views/PathwayCreate');
const PathwayStandardTimes = () => import('/src/components/admin/pathway/views/PathwayStandardTimes');
const PathwayTimesResort = () => import('/src/components/admin/pathway/views/PathwayTimesResort');

// Patient Statues
const PatientStatus = () => import('/src/components/admin/patientStatus/PatientStatus');
const PatientStatusForm = () => import('/src/components/admin/patientStatus/forms/PatientStatusForm');

// Patient Action Types
const PatientActionType = () => import('/src/components/admin/patientActionType/PatientActionType');
const PatientActionTypeForm = () => import('/src/components/admin/patientActionType/forms/PatientActionTypeForm');

// Patient Booking Templates
const BookingCriteria = () => import('/src/components/admin/pathway/BookingCriteria');
const BookingCriteriaView = () => import('/src/components/admin/pathway/forms/BookingCriteriaView');

// Pathway Actions
const Function = () => import('/src/components/admin/functions/FunctionSelection');
const FunctionConfigSelection = () => import('/src/components/admin/functions/FunctionConfigSelection');
const FunctionConfigSelectionForm = () => import('/src/components/admin/functions/forms/FunctionAdminForm');

// Operative Notes
const OperativeNoteKeyword = () => import('/src/components/admin/operativeNotes/Keyword');
const OperativeNoteTemplate = () => import('/src/components/admin/operativeNotes/OperativeNoteTemplate');

export default [
  // ======================================== \\
  // Time Definitions
  // ======================================== \\
  {
    path: 'time/definition',
    component: TimeDefinition,
    meta: {
      title: 'Time Definition Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':timedefinitionid',
        name: 'admin_timedefinitions_detail',
        component: TimeDefinitionForm,
        meta: {
          title: 'Time Definition Maintenance',
          tags: ['Administration'],
          functionCode: 'PATHWAYADMIN',
          backRoutes: ['admin_timedefinitions'],
        },
      },
      {
        path: '',
        name: 'admin_timedefinitions',
        component: TimeDefinitionList,
        meta: {
          title: 'Time Definition Maintenance',
          tags: ['Administration'],
          functionCode: 'PATHWAYADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // Pathway Allocation
  // ======================================== \\
  {
    path: 'pathway/allocation/:areaid?/:revisionid?',
    component: GenericRoute,
    meta: {
      title: 'Pathway Allocation Maintenance',
      tags: ['Administration'],
    },
    children: [
      {
        path: ':locationid',
        component: GenericRoute,
        meta: {
          title: 'Pathway Allocation Maintenance',
          tags: ['Administration'],
        },
        children: [
          {
            path: 'config/:criteriaid?',
            name: 'admin_pathway_allocation_config',
            component: PathwayAllocationConfig,
            meta: {
              title: 'Pathway Allocation Configuration',
              tags: ['Administration'],
              functionCode: 'PATHWAYADMIN',
              backRoutes: ['admin_pathway_allocationform', 'admin_pathway_allocation_detail'],
            },
          },
          {
            path: '',
            name: 'admin_pathway_allocation_detail',
            component: PathwayAllocationForm,
            meta: {
              title: 'Pathway Allocation Detail',
              tags: ['Administration'],
              functionCode: 'PATHWAYADMIN',
              backRoutes: ['admin_pathway_allocation'],
            },
          },
        ],
      },
      {
        path: '',
        name: 'admin_pathway_allocation',
        component: PathwayAllocation,
        meta: {
          title: 'Pathway Allocation Maintenance',
          tags: ['Administration'],
          functionCode: 'PATHWAYADMIN',
          backRoutes: ['admin_home', 'admin_pathwaymenu'],
        },
      },
    ],
  },

  // ======================================== \\
  // Patient Status
  // ======================================== \\
  {
    path: 'patient/status',
    name: 'admin_patientstatus',
    component: PatientStatus,
    meta: {
      title: 'Patient Status Maintenance',
      tags: ['Administration'],
      functionCode: 'PATHWAYADMIN',
      backRoutes: ['admin_home'],
    },
  },
  {
    path: 'patient/status/:patientstatusid',
    name: 'admin_patientstatus_detail',
    component: PatientStatusForm,
    meta: {
      title: 'Patient Status Maintenance',
      tags: ['Administration'],
      functionCode: 'PATHWAYADMIN',
      backRoutes: ['admin_patientstatus'],
    },
  },

  // ======================================== \\
  // Pathway
  // ======================================== \\
  {
    path: 'pathway',
    component: Pathway,
    meta: {
      title: 'Pathway Configuration',
      tags: ['Administration'],
      functionCode: 'PATHWAYADMIN',
      backRoutes: ['admin_home'],
    },
    children: [
      {
        path: 'view/:pathwayid',
        name: 'admin_pathwaymenu',
        component: PathwayMenu,
        meta: {
          title: 'Pathway Configuration',
          tags: ['Administration'],
          functionCode: 'PATHWAYADMIN',
          areaDisabled: true,
          backRoutes: ['admin_pathway'],
        },
      },
      {
        path: 'view/:pathwayrevisionid/:view',
        name: 'admin_pathwayview',
        component: PathwayView,
        meta: {
          title: 'Pathway Configuration',
          tags: ['Administration'],
          functionCode: 'PATHWAYADMIN',
          areaDisabled: true,
          backRoutes: ['admin_pathwaymenu'],
        },
      },

      {
        path: 'times/:pathwayrevisionid',
        name: 'admin_pathway_times',
        component: PathwayStandardTimes,
        meta: {
          title: 'Pathway Configuration',
          tags: ['Administration'],
          functionCode: 'PATHWAYADMIN',
          areaDisabled: true,
          backRoutes: ['admin_pathway', 'admin_pathwaymenu'],
        },
      },
      {
        path: 'times/:pathwayrevisionid/sort',
        name: 'admin_pathway_times_sort',
        component: PathwayTimesResort,
        meta: {
          title: 'Pathway Configuration',
          tags: ['Administration'],
          functionCode: 'PATHWAYADMIN',
          areaDisabled: true,
          backRoutes: ['admin_pathway_times'],
        },
      },
      {
        path: 'create',
        name: 'admin_pathwaycreate',
        component: PathwayCreate,
        meta: {
          title: 'Pathway Configuration',
          tags: ['Administration'],
          functionCode: 'PATHWAYADMIN',
          areaDisabled: true,
          backRoutes: ['admin_pathway'],
        },
      },
      {
        path: '',
        name: 'admin_pathway',
        component: PathwayList,
        meta: {
          title: 'Pathway Configuration',
          tags: ['Administration'],
          functionCode: 'PATHWAYADMIN',
          backRoutes: ['admin_home'],
        },
      },
    ],
  },

  // ======================================== \\
  // Patient Action Type Properties
  // ======================================== \\
  {
    path: 'patient/action/type',
    name: 'admin_patientactiontypes',
    component: PatientActionType,
    meta: {
      title: 'Patient Action Type Properties Maintenance',
      tags: ['Administration'],
      functionCode: 'PATHWAYADMIN',
      backRoutes: ['admin_home'],
    },
  },
  {
    path: 'patient/action/type/:actiontypeid',
    name: 'admin_patientactiontype_detail',
    component: PatientActionTypeForm,
    meta: {
      title: 'Patient Action Type Maintenance',
      tags: ['Administration'],
      functionCode: 'PATHWAYADMIN',
      backRoutes: ['admin_patientactiontypes'],
    },
  },

  // ======================================== \\
  // Patient Booking Template
  // ======================================== \\
  {
    path: 'booking/form/template',
    name: 'admin_bookingformtemplate',
    component: BookingCriteria,
    meta: {
      title: 'Patient Booking Template Maintenance',
      tags: ['Administration'],
      functionCode: 'PATHWAYADMIN',
      backRoutes: ['admin_home'],
    },
  },
  {
    path: 'booking/form/template/:criteriaid/:revisionid',
    name: 'admin_bookingformtemplate__detail',
    component: BookingCriteriaView,
    meta: {
      title: 'Patient Booking Template Maintenance',
      tags: ['Administration'],
      functionCode: 'PATHWAYADMIN',
      backRoutes: ['admin_bookingformtemplate'],
    },
  },

  // ======================================== \\
  // Function Maintenance
  // ======================================== \\
  {
    path: 'function',
    name: 'admin_functions',
    component: Function,
    meta: {
      title: 'Function Maintenance',
      tags: ['Administration'],
      functionCode: 'PATHWAYADMIN',
      backRoutes: ['admin_home'],
    },
  },
  {
    path: 'function/:functionid',
    name: 'admin_routefunctionconfig',
    component: FunctionConfigSelection,
    meta: {
      title: 'Function Configuration Maintenance',
      tags: ['Administration'],
      functionCode: 'PATHWAYADMIN',
      backRoutes: ['admin_functions'],
    },
  },
  {
    path: 'function/:functionid/config/:functionconfigid',
    name: 'admin_routefunctionconfig_detail',
    component: FunctionConfigSelectionForm,
    meta: {
      title: 'Function Configuration Maintenance',
      tags: ['Administration'],
      functionCode: 'PATHWAYADMIN',
      backRoutes: ['admin_routefunctionconfig'],
    },
  },

  // ======================================== \\
  // Operative Note Keyword
  // ======================================== \\
  {
    path: 'operative/note/keyword',
    name: 'admin_operativenotekeywords',
    component: OperativeNoteKeyword,
    meta: {
      title: 'Operative Note Keyword Maintenance',
      tags: ['Administration'],
      functionCode: 'PATHWAYADMIN',
      backRoutes: ['admin_home'],
    },
  },

  // ======================================== \\
  // Operative Note Template
  // ======================================== \\
  {
    path: 'operative/note/template',
    name: 'admin_operativenotetemplates',
    component: OperativeNoteTemplate,
    meta: {
      title: 'Operative Note Template Maintenance',
      tags: ['Administration'],
      functionCode: 'PATHWAYADMIN',
      backRoutes: ['admin_home'],
    },
  },
];
