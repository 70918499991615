const axios = require('axios');

import { store } from '/src/store';

export const PropertyService = {
  // Get All Global Properties
  getAllGlobalProperties: function (isExternal = false, iSendObject = {}) {
    return axios.post(
        // URL
        'property/global' + ((isExternal || !store.getters.currentUser?.accessToken) ? '/external' : ''),
        // DATA
        iSendObject
      )
      .then((response) => {
        return response.data.result;
      });
  },

  // Get all Global Properties or specific using propertyID or propertyName
  // getGlobalProperties: function () {
  //   return this.getGlobalPropertyRequest(null, null, true);
  // },
  // getGlobalProperty: function (PropertyID = null, PropertyName = null, forcePropUpdate = false) {
  //   return this.getGlobalPropertyRequest(PropertyID, PropertyName, forcePropUpdate);
  // },
  // getGlobalExternalProperty: function (PropertyID = null, PropertyName = null, forcePropUpdate = false) {
  //   return this.getGlobalPropertyRequest(PropertyID, PropertyName, forcePropUpdate, true);
  // },
  // getGlobalPropertyRequest: function (
  //   PropertyID = null,
  //   PropertyName = null,
  //   forcePropUpdate = false,
  //   isExternal = false
  // ) {
  //   return new Promise((resolve) => {
  //     const globalProperties = store.getters.getGlobalProperties;

  //     let existingProp = forcePropUpdate
  //       ? null
  //       : globalProperties.find(
  //           (p) =>
  //             (PropertyID != null && p.PropertyID == PropertyID) ||
  //             (PropertyName != null && p.PropertyName == PropertyName)
  //         );

  //     if (!existingProp) {
  //       axios
  //         .post(
  //           // URL
  //           'property/global' + (isExternal ? '/external' : ''),
  //           //DATA
  //           { PropertyID: PropertyID, PropertyName: PropertyName }
  //         )
  //         .then((response) => {
  //           store.dispatch('updateGlobalProperties', response.data.result);
  //           resolve(response.data.result);
  //         });
  //     } else {
  //       resolve([existingProp]);
  //     }
  //   });
  // },

  // Update/Create/Delete User Properties
  // updateGlobalProperty: function (payload = null) {
  //   if (payload) {
  //     return axios.post('property/global/manipulate', payload).then((response) => {
  //       store.dispatch('updateGlobalProperties', [response.data.result]);
  //       return response.data.result;
  //     });
  //   }
  // },
  bulkUpdateGlobalProperty: function (payload = null) {
    if (payload) {
      return axios.put('Property/global/bulk/manipulate', payload).then((response) => {
        store.dispatch('updateGlobalProperties', response.data.result.Properties);
        return response.data.result;
      });
    }
  },

  // Get Area Properties
  getAreaProperties: function (areaID = null) {
    return axios
      .post(
        // URL
        'Area/properties',
        // DATA
        { AreaID: areaID }
      )
      .then((response) => {
        return response.data.result;
      });
  },

  // Get all Area Properties or specific using propertyID or propertyName
  getAreaProperty: function (areaID = null, propertyID = null, propertyName = null) {
    return axios
      .post(
        // URL
        'property/area',
        // DATA
        {
          AreaID: areaID,
          PropertyID: propertyID,
          PropertyName: propertyName,
        }
      )
      .then((response) => {
        return response.data.result;
      });
  },

  // Update/Create/Delete Area Properties
  updateAreaProperty: function (payload = null) {
    if (payload) {
      return axios.post('property/area/manipulate', payload).then((response) => {
        return response.data.result;
      });
    }
  },

  // Get Role Properties
  getRoleProperties: function (roleID = null) {
    return axios
      .post(
        // URL
        'Role/properties',
        // DATA
        { RoleID: roleID }
      )
      .then((response) => {
        return response.data.result;
      });
  },

  // Get all Role Properties or specific using PropertyID or PropertyName
  getRoleProperty: function (roleID = null, propertyID = null, propertyName = null) {
    return axios
      .post(
        // URL
        'property/role',
        // DATA
        {
          RoleID: roleID,
          PropertyID: propertyID,
          PropertyName: propertyName,
        }
      )
      .then((response) => {
        return response.data.result;
      });
  },

  // Update/Create/Delete Role Properties
  updateRoleProperty: function (payload = null) {
    if (payload) {
      return axios
        .post(
          // URL
          'property/role/manipulate',
          // DATA
          payload
        )
        .then((response) => {
          return response.data.result;
        });
    }
  },

  // Get User Properties
  getUserProperties: function (userID = null) {
    return axios
      .post(
        // URL
        'User/properties',
        // DATA
        { UserID: userID }
      )
      .then((response) => {
        return response.data.result;
      });
  },

  // Get all User Properties or specific using PropertyID or PropertyName
  getUserProperty: function (userID = null, propertyID = null, propertyName = null) {
    return axios
      .post(
        // URL
        'property/user',
        // DATA
        {
          UserID: userID,
          PropertyID: propertyID,
          PropertyName: propertyName,
        }
      )
      .then((response) => {
        return response.data.result;
      });
  },

  // Update/Create/Delete User Properties
  updateUserProperty: function (payload = null) {
    if (payload) {
      // console.log(JSON.stringify(payload));
      return axios.post('property/user/manipulate', payload).then((response) => {
        store.dispatch(
          'updateUserProperties',
          response.data.result && Array.isArray(response.data.result) ? response.data.result : [response.data.result]
        );
        return response.data.result;
      });
    }
  },

  getPropertyHeader: function (PropertyID = null, PropertyTypeID = null) {
    return axios
      .post('property/header', {
        PropertyID: PropertyID,
        PropertyTypeID: PropertyTypeID,
      })
      .then((response) => {
        return response.data.result;
      });
  },
};
