const axios = require('axios');

import { store } from '/src/store';
import { browserStorage } from '/src/mixins/browserStorage';
import { DateFunctions } from '/src/mixins/datefunctions';

export const SessionService = {
  StartDate: '',
  EndDate: '',

  sessionRecord: {},
  sessionList: [],
  specialityList: [],
  locationList: [],

  ExcludeBlankSessions: true,
  IncludePlans: false,
  IncludePatients: false,
  IncludeCancelledPatients: false,
  PersonnelCode: null,
  IncludeAlerts: true,
  IncludeStacking: true,
  IsForWhiteboard: false,
  IsForDailyView: false,
  SpecialtyID: null,

  DepartmentLocationCode: null,

  getSessions: function () {
    if (this.StartDate && this.StartDate.length == 0) this.StartDate = DateFunctions.apiDate(new Date());
    if (this.EndDate && this.EndDate.length == 0) this.EndDate = DateFunctions.apiDate(new Date());

    const sendData = {
      StartDate: this.StartDate,
      EndDate: this.EndDate,
      ExcludeBlankSessions: this.ExcludeBlankSessions,
      IncludePlans: this.IncludePlans,
      IncludeAlerts: true,
      IncludePatients: this.IncludePatients,
      IncludeCancelledPatients: this.IncludeCancelledPatients,
      IncludeStacking: this.IncludeStacking,
      IsForWhiteboard: this.IsForWhiteboard,
      IsForDailyView: this.IsForDailyView,
    };
    if (!this.IsForDailyView) {
      if (browserStorage.app.currentArea?.ID) sendData.AreaID = browserStorage.app.currentArea.ID;
      if (this.DepartmentLocationCode) sendData.DepartmentLocationCode = parseInt(this.DepartmentLocationCode);
    } else {
      sendData.AreaID = this.AreaID;
      sendData.SpecialtyID = this.SpecialtyID;
    }

    //console.log('sessionData', sendData);

    return axios
      .post(
        // ENDPOINT
        'Session',
        // DATA
        sendData
      )
      .then((res) => {
        this.specialityList = [...new Set(res.data.result.map((item) => item.SpecialtyDescription))];
        this.locationList = [...new Set(res.data.result.map((item) => item.LocationDescription))];
        store.dispatch('updateSessionStore', { sessions: res.data.result });
        return res.data.result;
      });
  },

  getSession: function (iSessionRecordID) {
    const sendData = {
      SessionRecordID: iSessionRecordID ? parseInt(iSessionRecordID) : null,
      IncludePatients: this.IncludePatients,
      IncludeCancelledPatients: true,
      IncludeAlerts: true,
      AreaID: browserStorage.app.currentArea?.ID ? browserStorage.app.currentArea.ID : null,
      DepartmentLocationCode: this.DepartmentLocationCode ? parseInt(this.DepartmentLocationCode) : null,
    };

    return axios
      .post(
        // ENDPOINT
        'Session/schema',
        // DATA
        sendData
      )
      .then((response) => {
        store.dispatch('updateSessionStore', { sessions: [response.data.result] });
        return response.data.result;
      });
  },

  getSessionPlanned: function (iSessionRecordID = null, iSessionPlanID = null, iStartDate = null) {
    let sendData = {
      SessionRecordID: iSessionRecordID ? parseInt(iSessionRecordID) : null,
      SessionPlanID: iSessionPlanID ? parseInt(iSessionPlanID) : null,
      StartDate: iStartDate,
    };

    return axios
      .post(
        // ENDPOINT
        'Session/schema',
        // DATA
        sendData
      )
      .then((response) => {
        store.dispatch('updateSessionStore', { sessions: [response.data.result] });
        return response.data.result;
      });
  },
  getSessionPlannedHeader: function (iSessionRecordID) {
    let sendData = { SessionRecordID: parseInt(iSessionRecordID) };

    return axios
      .post(
        // ENDPOINT
        'Session/planned/header',
        // DATA
        sendData
      )
      .then((response) => {
        if (!store.getters.fullSessionStore?.find((s) => s.SessionRecordID == iSessionRecordID)) {
          store.dispatch('updateSessionStore', { sessions: [response.data.result] });
        }

        return response.data.result;
      });
  },
  getSessionUnscheduledHeader: function (sendData) {
    return axios
      .post(
        // ENDPOINT
        'Session/unscheduled/header',
        // DATA
        sendData
      )
      .then((response) => {
        return response.data.result;
      });
  },
  getSessionPlannedPlusPersonnel: function (iSessionRecordID, iSessionPlanID, iStartDate) {
    let sendData = {
      SessionRecordID: parseInt(iSessionRecordID),
      SessionPlanID: parseInt(iSessionPlanID),
      StartDate: iStartDate,
    };

    return axios.all([axios.post('Session/planned', sendData), axios.post('Session/planned/personnel', sendData)]).then((responses) => {
      let returnSession = responses[0].data.result;
      returnSession.PersonnelList = responses[1].data.result;

      return returnSession;
    });
  },
  getSessionPlannedPersonnel: function (iSessionRecordID) {
    let sendData = { SessionRecordID: parseInt(iSessionRecordID) };

    return axios
      .post(
        // ENDPOINT
        'Session/planned/personnel',
        // DATA
        sendData
      )
      .then((response) => {
        return response.data.result;
      });
  },
  createSessionPlanned: function (payload) {
    return axios.post('Session/planned/create', payload).then((response) => {
      let session = response.data.result.CreateSession || response.data.result;
      store.dispatch('updateSessionStore', { sessions: [session] });
      return response.data.result;
    });
  },

  searchSessions: function (
    searchObject = {
      StartDate: null,
      EndDate: null,
      LocationID: null,
      PersonnelID: null,
      SpecialtyID: null,
      SessionTypeID: null,
      SessionIdentifier: null,
      TimeAvailable: null,
      AreaID: null,
      IncludeStacking: true,
      IncludePlans: true,
      ExcludeBlankSessions: false,
      Page: 0,
      PageSize: 100,
      OrderColumn: null,
      OrderDirection: 0,
    }
  ) {
    // searchObject.StartDate = DateFunctions.apiDate(searchObject.StartDate);
    // searchObject.EndDate = DateFunctions.apiDate(searchObject.EndDate);

    return axios
      .post(
        // ENDPOINT
        'Session/find',
        // DATA
        searchObject
      )
      .then((response) => {
        store.dispatch('updateSessionStore', { sessions: response.data.result });
        return response.data.result;
      });
  },

  getSessionOverview: function (
    sendData = {
      StartDate: new Date(),
      LocationID: null,
      SpecialtyCode: null,
      PersonnelCode: null,
      WeekNumbers: '1,2,3,4,5,6',
      AreaID: null,
    }
  ) {
    if (browserStorage.app.currentArea && browserStorage.app.currentArea.ID) sendData.AreaID = browserStorage.app.currentArea.ID;
    if (this.DepartmentLocationCode) sendData.DepartmentLocationCode = parseInt(this.DepartmentLocationCode);

    return axios
      .post(
        // ENDPOINT
        'Session/overview',
        // DATA
        sendData
      )
      .then((response) => {
        store.dispatch('updateSessionStore', { sessions: response.data.result });

        return response.data.result;
      });
  },

  getSessionTemplates: function () {
    if (this.StartDate && this.StartDate.length == 0) this.StartDate = DateFunctions.apiDate(new Date());

    const sendData = {
      StartDate: DateFunctions.apiDate(this.StartDate),
    };

    if (browserStorage.app.currentArea && browserStorage.app.currentArea.ID) sendData.AreaID = browserStorage.app.currentArea.ID;

    return axios
      .post(
        // ENDPOINT
        'Session/template',
        // DATA
        sendData
      )
      .then((res) => {
        return res.data.result;
      });
  },

  getSessionWeek: function () {
    if (this.StartDate && this.StartDate.length == 0) this.StartDate = DateFunctions.apiDate(new Date());

    const sendData = {
      StartDate: DateFunctions.apiDate(this.StartDate),
    };

    if (browserStorage.app.currentArea && browserStorage.app.currentArea.ID) sendData.AreaID = browserStorage.app.currentArea.ID;

    return axios
      .post(
        // ENDPOINT
        'Session/week',
        // DATA
        sendData
      )
      .then((res) => {
        return res.data.result;
      });
  },
  getSessionActualPersonnel: function (
    iObject = {
      SessionRecordID: null,
      PersonnelRoleID: null,
      OrderNumber: null,
    }
  ) {
    return axios
      .post(
        // ENDPOINT
        'Session/actual/personnel',
        // DATA
        iObject
      )
      .then((res) => {
        return res.data.result;
      });
  },

  updateSessionActualPersonnel: function (
    iObject = {
      ActualPersonnelID: null,
      PersonnelID: null,
      SessionRecordID: null,
      PersonnelRoleID: null,
      OrderNumber: null,
      PersonnelTemplateID: null,
      TeamBriefConfirmed: null,
      TeamDebriefConfirmed: null,
      LocumName: '',
    }
  ) {
    return axios
      .put(
        // ENDPOINT
        'Session/actual/personnel/bulk/update',
        // DATA
        { Personnel: iObject }
      )
      .then((res) => {
        return res.data.result;
      });
  },

  // getPatientActualPersonnel: function (iObject = {
  //   OperationRecordID: null,
  //   PersonnelRoleID: null,
  //   OrderNumber: null,
  // }) {
  //   return axios
  //     .post(
  //       // ENDPOINT
  //       'Patient/actual/personnel',
  //       // DATA
  //       iObject
  //     )
  //     .then((res) => {
  //       return res.data.result;
  //     });
  // },

  //  ######  ########  ######   ######  ####  #######  ##    ##
  // ##    ## ##       ##    ## ##    ##  ##  ##     ## ###   ##
  // ##       ##       ##       ##        ##  ##     ## ####  ##
  //  ######  ######    ######   ######   ##  ##     ## ## ## ##
  //       ## ##             ##       ##  ##  ##     ## ##  ####
  // ##    ## ##       ##    ## ##    ##  ##  ##     ## ##   ###
  //  ######  ########  ######   ######  ####  #######  ##    ##
  // ######## ##     ## ######## ########     ###     ######
  // ##        ##   ##     ##    ##     ##   ## ##   ##    ##
  // ##         ## ##      ##    ##     ##  ##   ##  ##
  // ######      ###       ##    ########  ##     ##  ######
  // ##         ## ##      ##    ##   ##   #########       ##
  // ##        ##   ##     ##    ##    ##  ##     ## ##    ##
  // ######## ##     ##    ##    ##     ## ##     ##  ######

  getSessionPriorityType: function (iDataTypeID, iEnabled) {
    let url = iDataTypeID ? 'PriorityType/schema' : 'PriorityType';
    return axios
      .post(url, {
        TypeID: parseInt(iDataTypeID),
        Enabled: iEnabled ? Boolean(iEnabled) : null,
      })
      .then((response) => {
        return response.data.result;
      });
  },

  setSessionPriorityTypeUpdate: function () {
    return axios
      .put('PriorityType/update', {
        TypeID: null,
        TypeDescription: null,
      })
      .then((response) => {
        return response;
      });
  },
  setSessionPriorityTypeSchemaUpdate: function (iDataType) {
    return axios
      .put('PriorityType/schema/update', {
        ...iDataType,
      })
      .then((response) => {
        return response;
      });
  },

  getPriorities: function (iData) {
    return axios.post('EmergencyPriority', iData).then((response) => {
      return response;
    });
  },

  setSessionEmergencyPriorityTypeDelete: function (iDataTypeID) {
    return axios
      .delete('EmergencyPriority/delete', {
        data: { EmergencyPriorityID: parseInt(iDataTypeID) },
      })
      .then((response) => {
        return response;
      });
  },

  emergencyPriorityTypeBulkDelete: function (iData) {
    return axios.delete('EmergencyPriority/bulk/delete', { data: { Data: iData } }).then((response) => {
      return response;
    });
  },

  setSessionPriorityTypeDelete: function (iDataTypeID) {
    return axios.delete('PriorityType/delete', { data: { TypeID: parseInt(iDataTypeID) } }).then((response) => {
      return response;
    });
  },

  getSessionTypes: function (iEnabled = true) {
    return axios
      .post('Session/type', {
        Enabled: iEnabled,
      })
      .then((response) => {
        return response.data.result;
      });
  },
  getSessionType: function (data) {
    return axios.post('Session/type', data).then((response) => {
      return response.data.result;
    });
  },
  updateSessionType: function (iSessionTypeRec) {
    return axios.put(`Session/type/${iSessionTypeRec?.SessionTypeID ? 'update' : 'create'}`, iSessionTypeRec).then((response) => {
      return response.data.result;
    });
  },
  deleteSessionType: function (iSessionTypeID) {
    return axios
      .delete('Session/type/delete', {
        data: {
          SessionTypeID: iSessionTypeID,
        },
      })
      .then((res) => {
        return {
          resultType: 'success',
          resultText: 'Deleted Successfully',
          resultID: iSessionTypeID,
        };
      });
  },

  getSessionProfile: function (
    iObj = {
      SessionProfileID: null,
      AreaID: null,
      Enabled: null,
    }
  ) {
    return axios.post('Session/profile', iObj).then((response) => {
      return response.data.result;
    });
  },
  updateSessionProfile: function (iSessionProfile) {
    if (iSessionProfile) {
      return axios.put('Session/profile/update', iSessionProfile).then((response) => {
        return response.data.result;
      });
    }
  },
  deleteSessionProfile: function (iSessionProfileID) {
    return axios
      .delete('Session/profile/delete', {
        data: {
          SessionProfileID: iSessionProfileID,
        },
      })
      .then((res) => {
        return {
          resultType: 'success',
          resultText: 'Deleted Successfully',
          resultID: iSessionProfileID,
        };
      });
  },

  getPlannedCriteria: function (
    iSearchObj = {
      BookingRevisionID: null,
      SessionRecordID: null,
      SessionPlanID: null,
    }
  ) {
    return axios.post('Patient/planned/criteria', iSearchObj).then((res) => {
      return res.data.result;
    });
  },

  getTimeTemplates: function (iEnabled = true) {
    return axios
      .post('Session/timeTemplate', {
        Enabled: iEnabled,
      })
      .then((response) => {
        return response.data.result;
      });
  },
  getTimeTemplateRec: function (data) {
    return axios.post('Session/timeTemplate', data).then((response) => {
      return response.data.result;
    });
  },
  updateTimeTemplate: function (iTimeTemplateRec) {
    if (iTimeTemplateRec?.TimeTemplateID) {
      return axios.put('Session/timeTemplate/update', iTimeTemplateRec).then((response) => {
        return response.data.result;
      });
    } else {
      return axios.post('Session/timeTemplate/create', iTimeTemplateRec).then((response) => {
        return response.data.result;
      });
    }
  },
  deleteTimeTemplate: function (iTimeTemplateID) {
    return axios
      .delete('Session/timeTemplate/delete', {
        data: {
          TimeTemplateID: iTimeTemplateID,
        },
      })
      .then((res) => {
        return {
          resultType: 'success',
          resultText: 'Deleted Successfully',
          resultID: iTimeTemplateID,
        };
      });
  },

  checkSessionConflict: function (
    sessionCheck = {
      SessionRecordID: null,
      SessionPlanID: null,
      StartDate: null,
      LocationID: null,
      StartTime: null,
      FinishTime: null,
      SessionIdentifier: null,
      ExcludeSessionRecordID: null,
    }
  ) {
    return axios.post('Session/overlap', sessionCheck).then((response) => {
      return response.data.result;
    });
  },

  availableLocations: function (
    sessionCheck = {
      SessionRecordID: null,
      SessionPlanID: null,
      StartDate: null,
      LocationID: null,
      StartTime: null,
      FinishTime: null,
      SessionIdentifier: null,
    }
  ) {
    return axios.post('Session/overlap/allLocations', sessionCheck).then((response) => {
      return response.data.result;
    });
  },

  getSessionUnscheduledList: function (
    iObj = {
      AreaID: null,
      UseDate: null,
    }
  ) {
    return axios.post('Session/unscheduled/lists', iObj).then((response) => {
      return response.data.result;
    });
  },
  getSessionUnscheduled: function (
    iObj = {
      UnscheduledID: null,
      Enabled: true,
    }
  ) {
    return axios.post('Session/unscheduled', iObj).then((response) => {
      return response.data.result;
    });
  },
  updateSessionUnscheduled: function (iSessionUnscheduled) {
    if (iSessionUnscheduled) {
      return axios.put('Session/unscheduled/update', iSessionUnscheduled).then((response) => {
        return response.data.result;
      });
    }
  },

  deleteSessionUnscheduled: function (iSessionUnscheduledID) {
    return axios
      .delete('Session/unscheduled/delete', {
        data: { UnscheduledID: parseInt(iSessionUnscheduledID) },
      })
      .then((response) => {
        return response;
      });
  },

  getSessionSupervision: function (PropertyID = null, PropertyName = null) {
    return axios
      .post('Session/supervision', {
        Enabled: true,
      })
      .then((response) => {
        return response.data.result;
      });
  },
  updateSessionSupervision: function (iSessionSupervisionRec) {
    return axios.put(`Session/supervision/${iSessionSupervisionRec?.SupervisionID ? 'update' : 'create'}`, iSessionSupervisionRec).then((response) => {
      return response.data.result;
    });
  },
  deleteSessionSupervision: function (iSupervisionID) {
    return axios
      .delete('Session/supervision/delete', {
        data: {
          SupervisionID: iSupervisionID,
        },
      })
      .then((res) => {
        return {
          resultType: 'success',
          resultText: 'Deleted Successfully',
          resultID: iSupervisionID,
        };
      });
  },
  getPatientPriorityHistory: function (iOperationRecordID) {
    return axios
      .post('Patient/planned/priorityHistory', {
        OperationRecordID: parseInt(iOperationRecordID),
      })
      .then((response) => {
        return response.data.result;
      });
  },
  getSessionSchema: function (iSessionRecordID) {
    let sendData = { SessionRecordID: iSessionRecordID };
    return axios.post('session/actual/schema', sendData).then((response) => {
      return response.data.result;
    });
  },
  updateSessionActualSchema: function (iSendObject) {
    return axios.put('session/actual/schema/update', iSendObject).then((response) => {
      return response.data.result;
    });
  },
  updateSessionActual: function (iSessionActual) {
    if (iSessionActual) {
      return axios.put('/Session/actual/update', iSessionActual).then((response) => {
        return response.data.result;
      });
    }
  },
  getSessionActual: function (iSessionRecordID) {
    let sendData = { SessionRecordID: iSessionRecordID };
    return axios.post('session/actual', sendData).then((response) => {
      return response.data.result;
    });
  },
  getSlotTemplates: function (iSlotTemplateID = null, iEnabled = null) {
    return axios
      .post('Session/slot/template', {
        SlotTemplateID: iSlotTemplateID,
        Enabled: iEnabled,
      })
      .then((response) => {
        return response.data.result;
      });
  },
  updateSlotTemplate: function (iSlotTemplateRec) {
    if (iSlotTemplateRec) {
      return axios.put('Session/slot/template/update', iSlotTemplateRec).then((response) => {
        return response.data.result;
      });
    }
  },
  deleteSlotTemplate: function (iSlotTemplateID) {
    return axios
      .delete('Session/slot/template/delete', {
        data: {
          SlotTemplateID: iSlotTemplateID,
        },
      })
      .then((res) => {
        return {
          resultType: 'success',
          resultText: 'Deleted Successfully',
          resultID: iSlotTemplateID,
        };
      });
  },
  getSlotTypes: function (iSlotTypeID = null, iEnabled = null) {
    return axios
      .post('Session/slot/type', {
        SlotTypeID: iSlotTypeID,
        Enabled: iEnabled,
      })
      .then((response) => {
        return response.data.result;
      });
  },
  updateSlotType: function (iSlotTypeRec) {
    if (iSlotTypeRec) {
      return axios.put('Session/slot/type/update', iSlotTypeRec).then((response) => {
        return response.data.result;
      });
    }
  },
  deleteSlotType: function (iSlotTypeID) {
    return axios
      .delete('Session/slot/type/delete', {
        data: {
          SlotTypeID: iSlotTypeID,
        },
      })
      .then((res) => {
        return {
          resultType: 'success',
          resultText: 'Deleted Successfully',
          resultID: iSlotTypeID,
        };
      });
  },
  getSlotTemplateLinks: function (iSlotTemplateID = null, iSlotTypeID = null) {
    return axios
      .post('Session/slot/template/link', {
        SlotTemplateID: iSlotTemplateID,
        SlotTypeID: iSlotTypeID,
      })
      .then((response) => {
        return response.data.result;
      });
  },
  getPatientBreakdown: function (iSessionRecordID = null, bIncludeCancelled = false) {
    return axios
      .post('Session/patient/breakdown', {
        SessionRecordID: iSessionRecordID,
        IncludeCancelled: bIncludeCancelled,
      })
      .then((response) => {
        return response.data.result;
      });
  },
  getSummary: function (iSessionRecordID = null) {
    return axios
      .post('Session/summary', {
        SessionRecordID: iSessionRecordID,
      })
      .then((response) => {
        return response.data.result;
      });
  },

  getSessionSlots: function (iRequest) {
    return axios.post('Session/slots', iRequest).then((response) => {
      return response.data.result;
    });
  },
  updateSessionPatients: function (iObject) {
    return axios
      .put(
        // ENDPOINT
        'Session/patient/bulk/update',
        // DATA
        { Patients: iObject }
      )
      .then((res) => {
        return res.data.result;
      });
  },
  sessionRefreshLeadPersonnel: function (iPersonnelTemplateID) {
    return axios
      .post(
        // ENDPOINT
        'Session/refreshLeadPersonnel',
        // DATA
        { PersonnelTemplateID: iPersonnelTemplateID }
      )
      .then((res) => {
        return res.data.result;
      });
  },
  getSessionPlannedHistory: function (
    oReq = {
      SessionRecordID: null,
      ObjectName: null,
      obj_UpdateID: null,
    }
  ) {
    if (oReq) {
      return axios
        .post(
          // URL
          'Session/planned/history',
          // HEADERS
          { ...oReq }
        )
        .then((res) => {
          return res.data.result;
        });
    }
  },
  getSessionStatus: function (iStatusID = null, iEnabled = null) {
    return axios
      .post('Session/status', {
        StatusID: iStatusID,
        Enabled: iEnabled,
      })
      .then((response) => {
        return response.data.result;
      });
  },
  getSessionSchedulerOverview: function (sendData) {
    return axios
      .post(
        // ENDPOINT
        'Session/scheduler/overview',
        // DATA
        sendData
      )
      .then((response) => {
        return response.data.result;
      });
  },
  getSessionSchedulerList: function (sendData) {
    console.log(sendData);
    return axios
      .post(
        // ENDPOINT
        'Session/scheduler/list',
        // DATA
        sendData
      )
      .then((response) => {
        return response.data.result;
      });
  },
  getSessionAnalysis: function (sendData) {
    return axios
      .post(
        // ENDPOINT
        'Session/analysis',
        // DATA
        sendData
      )
      .then((response) => {
        return response.data.result;
      });
  },
  getSessionAnalysisList: function (sendData) {
    return axios
      .post(
        // ENDPOINT
        'Session/analysis/list',
        // DATA
        sendData
      )
      .then((response) => {
        return response.data.result;
      });
  },
  getSessionAnalysisWhiteboard: function (sendData) {
    return axios
      .post(
        // ENDPOINT
        'Session/analysis/whiteboard',
        // DATA
        sendData
      )
      .then((response) => {
        return response.data.result;
      });
  },
  getSessionSchedulerPatients: function (iSessionRecordID = null, bIncludeCancelled = false) {
    return axios
      .post('Session/scheduler/patients', {
        SessionRecordID: iSessionRecordID,
        IncludeCancelled: bIncludeCancelled,
      })
      .then((response) => {
        return response.data.result;
      });
  },
};
