<template>
  <div id="SessionForm">
    <div class="tile">
      <ContentLoading displaystyle="boxed" />
      <div class="form">
        <div class="tilebody">
          <div class="tileheaderdescription">
            <div class="icon session"></div>
            <h2>Session Details</h2>
            <h4>Configure the session details</h4>
          </div>
        </div>
        <div class="tilebody" v-if="SessionRecord">
          <div class="row">
            <div :class="alternateview ? 'col-sm-12' : 'col-sm-6'">
              <div class="form-group isrequired" :class="SessionRecord.SessionProfileID ? 'hasvalue' : ''" :key="renderUpdate">
                <label>Session Profile</label>
                <SessionProfileSelect
                  :initfocus="initializefocusonprofile"
                  v-model="SessionRecord.SessionProfileID"
                  @emitsessionprofile="emitSessionProfile($event)"
                  :disabled="
                    disableProfile ||
                    fixedSessionProfile ||
                    (SessionRecord.SessionRecordID && SessionRecord.SessionPlanID != null && selectedSessionProfile && !selectedSessionProfile.CanAmendSessionProfile)
                  "
                />
                <div class="errormessage" v-if="submitted && !$v.SessionRecord.SessionProfileID.required">Session Profile is required</div>
              </div>
            </div>

            <div class="col-sm-6" v-if="!alternateview">
              <div class="form-group isrequired" :class="SessionRecord.LocationID ? 'hasvalue' : ''">
                <label>Location</label>
                <LocationSelect
                  v-model.number="SessionRecord.LocationID"
                  :locationtype="3"
                  :disabled="!allowLocationChange"
                  @input.native="checkSpecialty()"
                  :initfocus="initializefocusonlocation"
                  :profileareaid="selectedSessionProfile ? selectedSessionProfile.LocationAreaID : null"
                />
                <div class="errormessage" v-if="submitted && !$v.SessionRecord.LocationID.required">Location is required</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group" :class="[requireSessionType ? 'isrequired' : '', requireSessionType && SessionRecord.SessionTypeID ? 'hasvalue' : '']">
                <label>Session Type</label>
                <SessionTypeSelect v-model.number="SessionRecord.SessionTypeID" :disabled="!selectedSessionProfile" ref="createAction.CreateSession.SessionTypeID" />
                <div class="errormessage" v-if="submitted && !$v.SessionRecord.SessionTypeID.required">Session Type is required</div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group" :class="[requireSpecialty ? 'isrequired' : '', requireSpecialty && !hasSpecialtyRestrictionViolations && SessionRecord.SpecialtyID ? 'hasvalue' : '']">
                <label>Specialty</label>
                <SpecialtySelect
                  v-model.number="SessionRecord.SpecialtyID"
                  :locationspecialties="!defaultEmergencySpecialty || !SessionRecord.SpecialtyID ? locationSpecialties : []"
                  :consultantspecialties="consultantSpecialties"
                  @input.native="checkSpecialty(true)"
                  :key="SessionRecord.SpecialtyID"
                  :disabled="!selectedSessionProfile || (action && ['time', 'edit'].includes(action) && isactualsession)"
                  :sessionnum="sessionnum"
                  id="SessionRecord.SpecialtyID"
                />
                <div class="errormessage" v-if="consultantSpecialtyMismatch">The allocated consultant does not have this specialty configured, please review</div>
                <div class="errormessage" v-if="autoSetSpec">Defaulted to consultant's specialty, please review</div>
                <div class="errormessage" v-if="hasSpecialtyRestrictionViolations">Specialty is invalid for this location</div>
                <div class="errormessage" v-if="submitted && !$v.SessionRecord.SpecialtyID.required">Specialty is required</div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="SessionRecord.Comments">Comments</label>
                <textarea class="form-control" id="SessionRecord.Comments" rows="5" v-model="SessionRecord.Comments" maxlength="1000" v-lengthcheck ref="SessionRecord.Comments"></textarea>
              </div>
            </div>
            <div class="col-sm-6" v-if="selectedSessionProfile && selectedSessionProfile.CanEnterFixedSessionComments">
              <div class="form-group">
                <label for="sessionRecord.FixedComments">Fixed Comments</label>
                <textarea
                  id="sessionRecord.FixedComments"
                  class="form-control"
                  v-model="SessionRecord.FixedComments"
                  v-lengthcheck
                  rows="5"
                  maxlength="1000"
                  :disabled="SessionRecord.SessionRecordID && selectedSessionProfile && !selectedSessionProfile.CanEditFixedSessionComments"
                  ref="SessionRecord.FixedComments"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="row" v-if="showConfirmFlag">
            <div class="col-sm-3">
              <div class="form-group">
                <div class="checkboxitem">
                  <input
                    type="checkbox"
                    @change="emitIsConfirmed()"
                    v-model="sessionIsConfirmed"
                    :id="'sessionIsConfirmed' + sessionnum"
                    :ref="'sessionIsConfirmed' + sessionnum"
                    :name="'sessionIsConfirmed' + sessionnum"
                  />
                  <label :for="'sessionIsConfirmed' + sessionnum">
                    <span></span>
                    Confirm Session
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'lodash';
import { required, requiredIf } from 'vuelidate/lib/validators';

import { PersonnelService } from '/src/services/personnel';

export default {
  props: [
    'value',
    'submitted',
    'action',
    'sessionnum',
    'alternateview',
    'personnelpresent',
    'locationspecialties',
    'initializefocusonselector',
    'initializefocusonlocation',
    'initializefocusonprofile',
    'isactualsession',
  ],
  name: 'SessionForm',
  data() {
    return {
      emittedProfile: false,
      loadingContent: true,
      SessionRecord: null,
      selectedSessionProfileID: null,
      selectedSessionProfile: null,
      disableProfile: false,
      renderUpdate: 0,
      hasSpecialtyRestrictionViolations: false,
      consultantID: null,
      consultantSpecialitiesList: [],
      consultantSpecialtyMismatch: false,
      autoSetSpec: false,
      personnelList: [],
      emergencySpecialtyID: null,
      sessionIsConfirmed: false,
    };
  },
  computed: {
    ...mapGetters({
      getGlobalProperty: 'getGlobalProperty',
    }),
    showConfirmFlag: function () {
      return (
        this.SessionRecord &&
        this.SessionRecord.BookingRequiresConfirmation &&
        this.action != 'create' &&
        (!this.SessionRecord.Patients || this.SessionRecord.Patients.length == 0) &&
        this.hasConfirmAccess
      );
    },
    hasConfirmAccess: function () {
      return this.parseBool(this.SessionRecord.IsConfirmed) == false ? this.checkAccessRights('SESSIONCONFIRM') : this.checkAccessRights('SESSIONCONFIRMUNDO');
    },
    allowLocationChange: function () {
      return (
        this.action &&
        ((this.action == 'create' && this.selectedSessionProfile) ||
          (this.action == 'edit' &&
            this.selectedSessionProfile &&
            (this.selectedSessionProfile.EditLocationIDWhenPopulated ||
              (this.SessionRecord && (!this.SessionRecord.Patients || this.SessionRecord.Patients.length == 0 || !this.SessionRecord.Patients.length)))))
      );
    },
    defaultEmergencySpecialty: function () {
      return this.selectedSessionProfile && this.selectedSessionProfile.EmergencySession && this.emergencySpecialtyID != null;
    },
    requireSpecialty: function () {
      return this.selectedSessionProfile && this.selectedSessionProfile.RequireSpecialty;
    },
    requireSessionType: function () {
      return this.selectedSessionProfile && this.selectedSessionProfile.RequireSessionType;
    },
    fixedSessionProfile: function () {
      if (!this.currentArea || !this.currentArea.FixedSessionProfile || !this.currentArea.DefaultSessionProfileID) {
        return false;
      }
      return this.currentArea.FixedSessionProfile && this.currentArea.DefaultSessionProfileID != null;
    },
    // selectedSessionProfile: function () {
    //   return this.sessionProfileList.find(
    //     (sp) =>
    //       sp.SessionProfileID ===
    //       this.SessionRecord.SessionProfileID
    //   );
    // },
    locationSpecialties: function () {
      if (!this.SessionRecord || !this.SessionRecord.LocationID || this.SessionRecord.LocationID == null) {
        return [];
      } else {
        if (this.locationspecialties) {
          let thisSetOfLocations = this.locationspecialties.filter((ls) => ls.LocationID == this.SessionRecord.LocationID);
          return thisSetOfLocations;
        } else {
          return [];
        }
      }
    },
    consultantSpecialties: function () {
      return this.consultantSpecialitiesList;
    },
  },
  watch: {
    personnelpresent: function (newVal) {
      if (this.SessionRecord.Patients && this.SessionRecord.Patients.length > 0) {
        this.disableProfile = true;
      } else {
        this.disableProfile = newVal;
      }
      //this.renderUpdate++;
    },
  },
  created() {
    this.SessionRecord = this.value;
    if (this.showConfirmFlag) {
      this.sessionIsConfirmed = this.parseBool(this.SessionRecord.IsConfirmed);
    }
    this.getGlobalProperty(517).then((result) => {
      this.emergencySpecialtyID = result && result.Value ? parseInt(result.Value) : null;
      if ((this.SessionRecord.Personnel && this.SessionRecord.Personnel.length > 0) || (this.SessionRecord.Patients && this.SessionRecord.Patients.length > 0)) {
        this.disableProfile = true;
      }

      if (this.initializefocusonselector) {
        this.debounceFocusOnSelector();
      }
    });
  },
  mounted() {
    this.$root.$on('personnelChange', (personnelList, sessionNum) => {
      this.changePersonnel(personnelList, sessionNum);
    });
    this.consultantSpecialtyMismatch = false;
    this.loadingContent = false;
  },
  beforeDestroy() {
    delete this.SessionRecord;
  },
  components: {
    LocationSelect: () => import('/src/components/datalists/views/LocationSelect'),
    SessionProfileSelect: () => import('/src/components/datalists/views/SessionProfileSelect'),
    SpecialtySelect: () => import('/src/components/datalists/views/SpecialtySelect'),
    SessionTypeSelect: () => import('/src/components/datalists/views/SessionTypeSelect'),
  },
  methods: {
    ...mapActions(['addAppMessage']),
    debounceFocusOnSelector: debounce(function () {
      this.setFocusOnSelector();
    }, 10),
    setFocusOnSelector: function () {
      this.$nextTick(function () {
        let arr = [...document.getElementsByTagName('SELECT')];
        if (arr && Array.isArray(arr) && arr.length > 0) {
          let focused = false;
          arr.forEach((entry) => {
            if (entry.className !== 'styleselect area' && !focused) {
              focused = true;
              entry.focus();
            }
          });
        }
      });
    },
    emitSessionProfile: function (iPayload) {
      this.emittedProfile = true;
      this.selectedSessionProfile = iPayload;
      //this.SessionRecord.SessionProfileID = iPayload ? iPayload.SessionProfileID : null;
      this.SessionRecord.PersonnelTemplateID = iPayload ? iPayload.PersonnelTemplateID : null;
      this.checkSpecialty();
      this.$emit('emitsessionprofile', this.selectedSessionProfile);
    },
    checkEmergencySpecialty: function () {
      if (this.defaultEmergencySpecialty && this.SessionRecord.SpecialtyID == null) {
        this.SessionRecord.SpecialtyID = this.emergencySpecialtyID;
      }
    },
    checkSpecialty: function (manual) {
      this.hasSpecialtyRestrictionViolations = false;
      if (this.defaultEmergencySpecialty) {
        this.checkEmergencySpecialty();
      } else {
        if (this.SessionRecord.SpecialtyID && this.SessionRecord.SpecialtyID !== null && this.locationSpecialties.length > 0) {
          this.hasSpecialtyRestrictionViolations = !this.locationSpecialties.some((ls) => ls.SpecialtyID == this.SessionRecord.SpecialtyID);

          if (this.hasSpecialtyRestrictionViolations) {
            this.$emit('setrestrictions', this.hasSpecialtyRestrictionViolations);
            this.moveToSpecialty();
          }
        }
      }
      if (manual === true) {
        this.autoSetSpec = false;
        this.consultantSpecialtyMismatch = false;
      }
    },
    emitIsConfirmed: function () {
      this.$emit('sessionisconfirmed', this.sessionIsConfirmed);
    },
    changePersonnel: function (personnelList, sessionNum) {
      this.consultantSpecialtyMismatch = false;
      this.autoSetSpec = false;
      if ((!sessionNum || !this.sessionnum || sessionNum == this.sessionnum) && this.SessionRecord) {
        if (personnelList.length == 0 || !personnelList.some((pl) => pl.PersonnelTypeEnum & 1 && pl.LeadPersonnelTypeID == 1 && pl.PersonnelID != null)) {
          this.consultantSpecialitiesList = [];
          this.consultantID = null;
        }
        if (
          personnelList.some((pl) => pl.PersonnelTypeEnum & 1 && pl.LeadPersonnelTypeID == 1 && pl.PersonnelID != null) &&
          (!this.defaultEmergencySpecialty || !this.SessionRecord.SpecialtyID) &&
          this.emittedProfile
        ) {
          let thisConsultant = personnelList.find((pl) => pl.PersonnelTypeEnum & 1 && pl.LeadPersonnelTypeID == 1 && pl.PersonnelID != null);
          if (thisConsultant.PersonnelID !== this.consultantID && thisConsultant.PersonnelID !== null) {
            this.consultantID = thisConsultant.PersonnelID;
            this.checkConsultantSpecialties();
          }
        }
      }
    },
    checkConsultantSpecialties: function () {
      this.autoSetSpec = false;
      this.consultantSpecialtyMismatch = false;

      if (this.sessionPlan && this.consultantID) {
        PersonnelService.getSpecialty({ PersonnelID: parseInt(this.consultantID) }).then((result) => {
          this.consultantSpecialties = result;
          if (result.length > 0) {
            let thisMatchingID = this.locationSpecs?.length ? this.locationSpecs.find((ls) => result.some((cs) => cs.SpecialtyID == ls.SpecialtyID))?.SpecialtyID : null;

            thisMatchingID ??= result[0].SpecialtyID;
            if (thisMatchingID && this.sessionPlan.SpecialtyID != thisMatchingID) {
              if (this.sessionPlan.SpecialtyID == null) {
                this.autoSetSpec = true;
                this.consultantSpecialtyMismatch = false;
                this.sessionPlan.SpecialtyID = thisMatchingID;
                this.consultantSpecialties = [];
              } else {
                this.autoSetSpec = false;
                this.consultantSpecialtyMismatch = !result.some((s) => s.SpecialtyID == this.sessionPlan.SpecialtyID);
              }

              if (this.autoSetSpec || this.consultantSpecialtyMismatch) {
                this.moveToSpecs();
              }
            }
          }
        });
      }
    },
    moveToSpecialty: function () {
      let fieldObj = document.getElementById('SessionRecord.SpecialtyID');
      if (fieldObj) {
        fieldObj.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }
    },
  },
  validations: {
    SessionRecord: {
      SessionProfileID: {
        required,
      },
      LocationID: {
        required,
      },
      SpecialtyID: {
        required: requiredIf(function () {
          return this.requireSpecialty;
        }),
      },
      SessionTypeID: {
        required: requiredIf(function () {
          return this.requireSessionType;
        }),
      },
    },
  },
};
</script>
