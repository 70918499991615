const axios = require('axios');

export const AreaService = {
  getArea: function (payload = { ID: null, Enabled: null }) {
    return axios
      .post(
        // URL
        'Area',
        // DATA
        payload
      )
      .then((response) => {
        return response.data.result;
      });
  },

  updateArea: function (area) {
    return axios
      .put(
        // URL
        'Area/update',
        // DATA
        area
      )
      .then((response) => {
        return response.data.result;
      });
  },

  deleteArea: function (iAreaID) {
    return axios
      .delete(
        // URL
        'Area/delete',
        // DATA
        { data: { ID: parseInt(iAreaID) } }
      )
      .then((response) => {
        return {
          resultType: 'success',
          resultText: 'Area Deleted Successfully',
        };
      });
  },
  getAreaRelationships: function (payload = { ID: null, Enabled: null }) {
    return axios
      .post(
        // URL
        'Area/relationship',
        // DATA
        payload
      )
      .then((response) => {
        return response.data.result;
      });
  },

  deleteAreaRelationships: function (iAreaRelationshipID) {
    return axios
      .delete(
        // URL
        'Area/relationship/delete',
        // DATA
        { data: { ID: parseInt(iAreaRelationshipID) } }
      )
      .then((response) => {
        return {
          resultType: 'success',
          resultText: 'Area Relationship Deleted Successfully',
        };
      });
  },
};
