<template>
  <div id="notAuth">
    <router-view></router-view>

    <AppMessages />
  </div>
</template>

<script>
const AppMessages = () => import('/src/components/application/AppMessages');

export default {
  data() {
    return {};
  },
  components: {
    AppMessages,
  },
};
</script>

<style scoped>
</style>