const axios = require('axios');

import { store } from '/src/store';

export const MaintenanceService = {
  getAnaestheticTypes: function () {
    return axios.post('Anaesthetic/type', { AnaestheticTypeID: null, Enabled: null }).then((response) => {
      return response.data.result;
    });
  },
  getAnaestheticTypeRec: function (data) {
    return axios.post('Anaesthetic/type', data).then((response) => {
      return response.data.result;
    });
  },
  updateAnaestheticType: function (data) {
    return axios.put('Anaesthetic/type/update', data).then((response) => {
      return response.data.result;
    });
  },
  deleteAnaestheticType: function (iAnaestheticTypeID) {
    return axios.delete('Anaesthetic/type/delete', { data: { AnaestheticTypeID: iAnaestheticTypeID } }).then((res) => {
      return {
        resultType: 'success',
        resultText: 'Deleted Successfully',
        resultID: iAnaestheticTypeID,
      };
    });
  },

  getAnaestheticTypeGroups: function () {
    return axios.post('Anaesthetic/type/group', { AnaestheticTypeGroupID: null, Enabled: null }).then((response) => {
      return response.data.result;
    });
  },
  getAnaestheticTypeGroupRec: function (data) {
    return axios.post('Anaesthetic/type/group', data).then((response) => {
      return response.data.result;
    });
  },
  getAnaestheticTypeGroupSchema: function (data) {
    return axios.post('Anaesthetic/type/group/schema', data).then((response) => {
      return response.data.result;
    });
  },
  updateAnaestheticTypeGroup: function (data) {
    return axios.put('Anaesthetic/type/group/update', data).then((response) => {
      return response.data.result;
    });
  },
  updateAnaestheticTypeGroupSchema: function (data) {
    return axios.put('Anaesthetic/type/group/schema/update', data).then((response) => {
      return response.data.result;
    });
  },
  deleteAnaestheticTypeGroup: function (iAnaestheticTypeGroupID) {
    return axios
      .delete('Anaesthetic/type/group/delete', { data: { AnaestheticTypeGroupID: iAnaestheticTypeGroupID } })
      .then((response) => {
        return {
          resultType: 'success',
          resultText: 'Deleted Successfully',
          resultID: iAnaestheticTypeGroupID,
        };
      });
  },

  getAnaestheticTypeGroupLinks: function (data) {
    return axios.post('Anaesthetic/type/group/link', data).then((response) => {
      return response.data.result;
    });
  },
  updateAnaestheticTypeGroupLink: function (data) {
    return axios.put('Anaesthetic/type/group/link/update', data).then((response) => {
      return response.data.result;
    });
  },

  getAdmissionTypes: function () {
    return axios
      .post('AdmissionType', { AdmissionTypeID: null, Enabled: null, obj_Deleted: false })
      .then((response) => {
        return response.data.result;
      });
  },
  getAdmissionTypesIncDeleted: function () {
    return axios.post('AdmissionType', { AdmissionTypeID: null, Enabled: null }).then((response) => {
      return response.data.result;
    });
  },
  getAdmissionTypeRec: function (data) {
    return axios.post('AdmissionType', data).then((response) => {
      return response.data.result;
    });
  },
  updateAdmissionType: function (data) {
    return axios.put('AdmissionType/update', data).then((response) => {
      return response.data.result;
    });
  },
  deleteAdmissionType: function (iAdmissionTypeID) {
    return axios.delete('AdmissionType/delete', { data: { AdmissionTypeID: iAdmissionTypeID } }).then((response) => {
      return {
        resultType: 'success',
        resultText: 'Deleted Successfully',
        resultID: iAdmissionTypeID,
      };
    });
  },

  getAdmissionTypeGroups: function () {
    return axios.post('AdmissionType/group', { AdmissionTypeGroupID: null, Enabled: null }).then((response) => {
      return response.data.result;
    });
  },
  getAdmissionTypeGroupRec: function (data) {
    return axios.post('AdmissionType/group', data).then((response) => {
      return response.data.result;
    });
  },
  updateAdmissionTypeGroup: function (data) {
    return axios.put('AdmissionType/group/update', data).then((response) => {
      return response.data.result;
    });
  },
  deleteAdmissionTypeGroup: function (iAdmissionTypeGroupID) {
    return axios
      .delete('AdmissionType/group/delete', { data: { AdmissionTypeGroupID: iAdmissionTypeGroupID } })
      .then((response) => {
        return {
          resultType: 'success',
          resultText: 'Deleted Successfully',
          resultID: iAdmissionTypeGroupID,
        };
      });
  },

  getAdmissionTypeGroupLinks: function (data) {
    return axios.post('AdmissionType/group/link', data).then((response) => {
      return response.data.result;
    });
  },
  updateAdmissionTypeGroupLink: function (data) {
    return axios.put('AdmissionType/group/link/update', data).then((response) => {
      return response.data.result;
    });
  },

  getAdmissionTypeGroupSchema: function (data) {
    return axios.post('AdmissionType/group/schema', data).then((response) => {
      return response.data.result;
    });
  },
  updateAdmissionTypeGroupSchema: function (data) {
    return axios.put('AdmissionType/group/schema/update', data).then((response) => {
      return response.data.result;
    });
  },
  getRecurrencePattern: function (data) {
    return axios.post('RecurrencePattern', data).then((response) => {
      return response.data.result;
    });
  },
  updateRecurrencePattern: function (data) {
    return axios.put('RecurrencePattern/update', data).then((response) => {
      return response.data.result;
    });
  },
  deleteRecurrencePattern: function (iPatternID) {
    return axios.delete('RecurrencePattern/delete', { data: { PatternID: iPatternID } }).then((response) => {
      return {
        resultType: 'success',
        resultText: 'Deleted Successfully',
        resultID: iPatternID,
      };
    });
  },

  getWebLinks: function (data) {
    //console.log(data);
    return axios.post('WebLink', data).then((response) => {
      store.dispatch('updateWebLinks', response.data.result);
    });
  },

  getWebLink: function (data = { WebLinkID: null, Enabled: true }) {
    let webLink = store.state.webLinks.find((p) => p.WebLinkID == data.WebLinkID);

    if (!webLink) {
      return axios.post('WebLink', data).then((response) => {
        store.dispatch('updateWebLinks', response.data.result);
        return response.data.result;
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve(data.WebLinkID && data.WebLinkID != null ? webLink : [webLink]);
      });
    }
  },

  getWebLinkField: function (data) {
    return axios.post('WebLink/field', data).then((response) => {
      return response.data.result;
    });
  },
  updateWebLink: function (data) {
    return axios.put('WebLink/update', data).then((response) => {
      return response.data.result;
    });
  },
  deleteWebLink: function (iWebLinkID) {
    return axios.delete('WebLink/delete', { data: { WebLinkID: iWebLinkID } }).then((response) => {
      return {
        resultType: 'success',
        resultText: 'Deleted Successfully',
        resultID: iWebLinkID,
      };
    });
  },
  getObjectHistory: function (historyReq) {
    return axios.post('Object/history', historyReq).then((response) => {
      return response.data.result;
    });
  },
};
