const Login = () => import('/src/components/user/Login');
const Register = () => import('/src/components/user/Register');
const SwitchUser = () => import('/src/components/user/SwitchUser');
const PinEntry = () => import('/src/components/user/PinEntry');
const UserAccount = () => import('/src/components/user/UserAccount');

// const LandingPage = () => import('/src/components/landing/LandingPage');
// const LandingPageAdmin = () => import('/src/components/landing/LandingPage_Admin');
// const LandingPageCoOrd = () => import('/src/components/landing/LandingPage_CoOrd');
// const LandingPageScheduler = () => import('/src/components/landing/LandingPage_Scheduler');
// const LandingPageConsultant = () => import('/src/components/landing/LandingPage_Consultant');

export default [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'Login',
      layout: 'notauth',
      isLoginRoute: true,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: {
      title: 'Register',
      layout: 'notauth',
      isLoginRoute: true,
    },
  },
  {
    path: '/switch/user',
    name: 'switch_user',
    component: SwitchUser,
    meta: {
      title: 'Switch User',
      layout: 'notauth',
      isLoginRoute: true,
      isMultiUserRoute: true,
    },
  },
  {
    path: '/locked',
    name: 'locked',
    component: SwitchUser,
    meta: {
      title: 'Locked',
      layout: 'notauth',
      isLoginRoute: true,
      isMultiUserRoute: true,
    },
  },
  {
    path: '/add/login',
    name: 'add_login',
    component: Login,
    meta: {
      title: 'Add Login',
      layout: 'notauth',
      isLoginRoute: true,
      isMultiUserRoute: true,
    },
  },
  {
    path: '/pinentry/:id',
    name: 'pinentry',
    component: PinEntry,
    meta: {
      title: 'PIN Entry',
      layout: 'notauth',
      isLoginRoute: true,
      isMultiUserRoute: true,
    },
  },
  {
    path: '/useraccount',
    name: 'useraccount',
    component: UserAccount,
    meta: {
      title: 'User Account',
    },
  },

  // {
  //   path: '/landing',
  //   name: 'landingpage',
  //   meta: {
  //     title: 'Aqua Suite Home',
  //   },
  //   component: LandingPageCoOrd,
  // },
  // {
  //   path: '/landingnomenu',
  //   name: 'landingpagenomenu',
  //   meta: {
  //     title: 'Landing Page No Menu',
  //     layout: 'fullscreen',
  //   },
  //   component: LandingPage,
  // },

  // {
  //   path: '/landing/admin',
  //   name: 'admin_landingpage',
  //   component: LandingPageAdmin,
  //   meta: {
  //     title: 'Aqua Suite Home :',
  //   },
  // },
  // {
  //   path: '/landing/coordinator',
  //   name: 'coord_landingpage',
  //   component: LandingPageCoOrd,
  //   meta: {
  //     title: 'Aqua Suite Home : CO-ORDINATOR',
  //   },
  // },
  // {
  //   path: '/landing/scheduler',
  //   name: 'scheduler_landingpage',
  //   component: LandingPageScheduler,
  //   meta: {
  //     title: 'Aqua Suite Home : SCHEDULER',
  //   },
  // },
  // {
  //   path: '/landing/consultant',
  //   name: 'consultant_landingpage',
  //   component: LandingPageConsultant,
  //   meta: {
  //     title: 'Aqua Suite Home : CONSULTANT',
  //   },
  // },
];
